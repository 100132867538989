import { ExamineeAdditionalInformationFieldOption } from './ExamineeAdditionalInformationFieldOption';
import { EmptyExamineeFamily, ExamineeFamily } from './ExamineeFamily';
import { NewType, UpdateType } from './removeDates';

import { ValueType } from './ValueType';

export interface ExamineeAdditionalInformationField {
  id: number;
  name: string;
  valueType: ValueType;
  valueOptions: ExamineeAdditionalInformationFieldOption[];
  required: boolean;
  family: ExamineeFamily;
  unit?: string;
  page?: number;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export type NewExamineeAdditionalInformationField = NewType<
  ExamineeAdditionalInformationField,
  'name' | 'unit' | 'valueType' | 'required'
> & { valueOptions: string[] };

export type UpdateExamineeAdditionalInformationField = UpdateType<
  ExamineeAdditionalInformationField,
  'name' | 'unit' | 'page'
>;

export const EmptyNewExamineeAdditionalInformationField: NewExamineeAdditionalInformationField = {
  name: '',
  unit: '',
  valueType: ValueType.BOOLEAN,
  required: false,
  valueOptions: [],
};

export const EmptyExamineeAdditionalInformationField: ExamineeAdditionalInformationField = {
  id: 0,
  name: '',
  valueType: ValueType.BOOLEAN,
  required: false,
  family: EmptyExamineeFamily,
  valueOptions: [],
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
};
