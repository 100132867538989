import 'react-bootstrap-typeahead/css/Typeahead.css';

import { FieldAttributes, useField } from 'formik';
import { FunctionComponent, useEffect, useState } from 'react';
import { Option, TypeaheadPropsAndState } from 'react-bootstrap-typeahead/types/types';

import { Typeahead as _Typeahead } from 'react-bootstrap-typeahead';
import styled from 'styled-components';

const Typeahead = styled(_Typeahead)`
  display: block;
  width: 100%;
  border: 0;
  background: transparent;
  font-size: 0.75rem;
  -webkit-appearance: none;
  outline-color: rgba(0, 0, 0, 0);
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  .rbt-input-multi {
    border: 0;
    background: transparent;
    font-size: 0.75rem;
    -webkit-appearance: none;
    padding: 0.75rem;
  }
  .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.3rem;
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
  }
`;

const ERROR_TEXT = 'Bitte wählen Sie einen Eintrag aus.';

function filterBy(option: any, state: TypeaheadPropsAndState) {
  if (state.selected.length) {
    return true;
  }
  return option.label.toLowerCase().indexOf(state.text.toLowerCase()) > -1;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  width: 100%;
`;

type onNewOptionCallback = (label: string) => Promise<number>;

interface MultipleTypeaheadInputProps {
  forbidNew?: boolean;
  disabled?: boolean;
  options: Option[];
  newSelectionPrefix?: string;
  onNewOption?: onNewOptionCallback;
  onChange?: (options?: Option[]) => void;
}

const MultipleTypeaheadInput: FunctionComponent<MultipleTypeaheadInputProps & FieldAttributes<any>> = ({
  children,
  valueType,
  disabled,
  forbidNew,
  options,
  emptyLabel,
  newSelectionPrefix,
  onNewOption,
  onChange,
  ...props
}: MultipleTypeaheadInputProps & FieldAttributes<any>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(props);
  const [values, setValues] = useState<string[]>([]);

  useEffect(() => {
    setValues(
      (field.value ?? []).map((v: any) => {
        if (v.value) return v.value;
        const pre = v.type === 'customer_group' ? 'cg' : 'c';
        return `${pre}_${v.sp_id}`;
      }),
    );
  }, [field.value]);

  return (
    <Wrapper>
      <Typeahead
        id={field.name}
        multiple
        disabled={disabled}
        filterBy={filterBy}
        options={options}
        maxResults={5}
        placeholder={emptyLabel}
        newSelectionPrefix={newSelectionPrefix}
        {...props}
        selected={options.filter((option: any) => {
          return values && values.includes(option.value);
        })}
        allowNew={false}
        onChange={(selected: any[]) => {
          if (selected.length > 0) {
            const data = selected ?? [];
            // .map((v: any) => {
            //   const pre = v.type === 'customer_group' ? 'cg' : 'c';
            //   return {} ;
            // });
            if (onChange) {
              onChange(data);
              return;
            }
            helpers.setTouched(true);
            helpers.setValue(data, true);
            helpers.setError(undefined);
          } else {
            if (onChange) {
              onChange(undefined);
            } else {
              helpers.setTouched(true);
              helpers.setValue(undefined, true);
              helpers.setError(ERROR_TEXT);
            }
          }
        }}
      />
    </Wrapper>
  );
};

export default MultipleTypeaheadInput;
