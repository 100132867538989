import { Alert, Col, Row } from 'react-bootstrap';
import { EmptyExaminee, ExamineeWithLastTest } from '../../interfaces/Examinee';
import { ErrorLevel, useAddError, useClearError } from '../../context/error';
import { Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';

import BareInput from '../../components/form/BareInput';
import { ReactComponent as CheckIcon } from './check.svg';
import ExamineeAdditionalInformationSection from '../../components/common/examinee/ExamineeAdditionalInformationSection';
import ExamineeFamilySelect from '../../components/common/examinee/ExamineeFamilySelect';
import ExamineeTestTaskIntervalList from '../../components/common/ExamineeTestTaskIntervalList';
import ExamineeTypeSelect from '../../components/common/examinee/ExamineeTypeSelect';
import { ExamineesService } from '../../services';
import FormikDebug from '../../components/dev/formik-debug';
import InputGroup from '../../components/form/InputGroup';
import InputSection from '../../components/form/InputSection';
import InstallationSiteSelect from '../../components/common/examinee/InstallationSiteSelect';
import SubmitButton from '../../components/SubmitButton';
import Tests from '../../components/common/TestList';
import Title from '../../components/title';
import VendorSelect from '../../components/common/examinee/VendorSelect';
import { confirm } from '../../components/dialogs/Confirmation';
import { get, reduce } from 'lodash';
import qs from 'query-string';
import { ExamineeAdditionalInformationValue } from '../../interfaces/ExamineeAdditionalInformationValue';
import DueList from '../../components/common/DueList/DueList';
import styled from 'styled-components';

const shouldEditing = (location: Location) => {
  const queryParams = qs.parse(location.search, {});
  return queryParams.edit === '1' || queryParams.edit === 'true';
};

const P = styled.p<{ small: boolean }>`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  color: ${(props) => (props.small ? '#b3b3b3' : '#000')};
`;

const L = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: start;
  align-content: start;
  align-items: flex-start;
  flex: 1;
  color: #a1a1a1;
  opacity: 1;
  padding: 0.75rem;
`;

type Ex = Omit<ExamineeWithLastTest, 'additionalInformation'> & {
  family?: number;
  additionalInformation?: Record<string, any>;
};

const Show: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [editing, setEditing] = useState(shouldEditing(location));

  const [item, setItem] = useState<Ex>(EmptyExaminee);

  const loadData = useCallback(async () => {
    if (!params.id) {
      setItem(EmptyExaminee);
    }
    const _item = await ExamineesService(addError).get<ExamineeWithLastTest>(params.id ?? '');
    if (!_item || !_item.id) {
      navigate('..');
    } else {
      setItem({
        ..._item,
        additionalInformation: reduce<ExamineeAdditionalInformationValue, Record<string, any>>(
          _item.additionalInformation ?? [],
          (prev, v: ExamineeAdditionalInformationValue, k) => {
            if (v.field) {
              const d = v.field.id.toString();
              prev[d] = v.value;
            }
            return prev;
          },
          {},
        ),
      });
    }
  }, [addError, navigate, params.id]);

  useEffect(() => {
    loadData();
  }, [addError, loadData, navigate, params.id]);

  const onSubmit = async (values: Ex, { setSubmitting }: FormikHelpers<Ex>) => {
    setSubmitting(true);
    try {
      if (params.id) {
        clearError();
        // const data = getChangedValues(values, item);
        // // const updateData: UpdateExaminee = {
        // //   ...data,
        // //   family: data?.family?.id,
        // // };
        // await ExamineesService(addError).update(params.id, data);
        setEditing(false);
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onDelete = async (setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true);
    try {
      if (params.id) {
        if (
          await confirm(
            'Prüfling löschen?',
            'Der Prüfling wird entgültig gelöscht. Sind Sie sich sicher?',
            'Löschen',
            'Abbruch',
            {
              okColor: 'danger',
            },
          )
        ) {
          await ExamineesService(addError).destroy(params.id);
          navigate('..');
        }
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={item}
      onSubmit={onSubmit}
      validate={async (values: Ex) => {
        const errors: any = {};

        if (!values.installationSite || values.installationSite.id === 0) {
          errors['installationSite'] = true;
        }

        if (!values.vendor || values.vendor.id === 0) {
          errors['vendor'] = true;
        }

        return errors;
      }}
    >
      {({
        handleSubmit,
        isValidating,
        isValid,
        errors,
        values,
        resetForm,
        isSubmitting,
        setSubmitting,
        setFieldValue,
      }) => (
        <>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Title
              onEdit={() => setEditing(true)}
              onDelete={() => onDelete(setSubmitting)}
              onCancel={() => {
                resetForm({
                  values: item,
                });
                setEditing(false);
              }}
              editing={editing}
              editingValid={!isValidating && isValid}
              isSubmitting={isSubmitting}
              to="/examinees"
              title={item.tag}
            />
            <Row className="mt-3 d-xs-block d-lg-none">
              <Col>
                <SubmitButton
                  breakat={1200}
                  block={true}
                  colored="true"
                  type="button"
                  float="right"
                  onClick={() => navigate(`/tests/new/${values.id}`)}
                >
                  <CheckIcon />
                  <span>Jetzt prüfen</span>
                </SubmitButton>
              </Col>
            </Row>
            {item.last?.result === false && (
              <Row>
                <Col xs={12}>
                  <Alert variant={'danger'}>
                    <b>[Prüfling gesperrt]</b> Der Prüfling ist bei der letzten Prüfung durchgefallen und darf nicht
                    weiter verwendet werden.
                  </Alert>
                </Col>
              </Row>
            )}
            <Row>
              <InputSection title="Stammdaten" xs={12} xl={6}>
                <InputGroup
                  error={errors.tag ? errors.tag : undefined}
                  valid={!errors.tag}
                  title="Eindeutige ID"
                  name="tag"
                >
                  <BareInput disabled={true} type="text" name="tag" />
                </InputGroup>
                <ExamineeFamilySelect
                  name="type.family.id"
                  onValueSelected={(f) => {
                    setFieldValue('type', 0);
                  }}
                  editing={false}
                />
                <ExamineeTypeSelect name="type.id" editing={editing} />
                <VendorSelect name="vendor.id" editing={editing} />
              </InputSection>
              <InputSection title="Aufstellort" xs={12} xl={6}>
                <InputGroup
                  // error={errors.location ? "" : undefined}
                  valid={!errors.location}
                  title="Standort"
                  name="location"
                >
                  <L>
                    <P small={false}>
                      [{values.location.tag}] {values.location.name}
                    </P>
                    <P small={true}>
                      {values.location.address.street}, {values.location.address.postalCode}{' '}
                      {values.location.address.city}
                    </P>
                  </L>
                  {/* <LocationSelectButton value={get(values, 'location.id')} /> */}
                </InputGroup>
                <InstallationSiteSelect name="installationSite.id" editing={editing} />
                <InputGroup
                  // error={errors.installationSiteDescription}
                  valid={!errors.installationSiteDescription}
                  title="Bezeichnung"
                  name="installationSiteDescription"
                >
                  <BareInput disabled={isSubmitting} type="text" name="installationSiteDescription" />
                </InputGroup>
              </InputSection>
              <InputSection title="zusätzliche Felder" xs={12} xl={6}>
                <ExamineeAdditionalInformationSection editing={editing} />
              </InputSection>
              <InputSection title="" xs={12} xl={6}></InputSection>
              {item.last?.result !== false && (
                <>
                  <InputSection
                    title="Prüfintervalle"
                    xs={12}
                    xl={6}
                    deleteText="Neues Intervall"
                    onDelete={() => {
                      navigate(`/examinees/${item.id}/intervals/new`);
                    }}
                  >
                    <ExamineeTestTaskIntervalList loading={false} examinee={item} />
                  </InputSection>
                  <InputSection title="nächste Prüfungen" xs={12} xl={6}>
                    <DueList items={item.dues} />
                  </InputSection>
                </>
              )}
              <InputSection
                title="Prüfungen"
                xs={12}
                deleteText="Neue Prüfung"
                onDelete={() => {
                  navigate(`/tests/new/${values.id}`);
                }}
              >
                <Tests examinee={values.id} />
              </InputSection>
            </Row>
          </form>
          <FormikDebug />
        </>
      )}
    </Formik>
  );
};

export default Show;
