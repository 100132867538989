import { Col, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';

import BareInput from '../../components/form/BareInput';
import { Formik } from 'formik';
import { FunctionComponent } from 'react';
import InputGroup from '../../components/form/InputGroup';
import { ReactComponent as UserIcon } from './Gears.svg';
import styled from 'styled-components';
import validateExamineeTagExists from '../../helpers/validate/validateExamineeTagExists';

const LoginContent = styled.div``;

const FullWidthButton = styled.button`
  color: #fff;
  background-color: #009842;
  border-color: #009842;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  display: block;
  width: 100%;
  height: auto;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border: 1px solid transparent;
  user-select: none;
  text-align: center;
  vertical-align: middle;
  font-weight: 400;
  &:not(:disabled) {
    user-select: none;
cursor: pointer;
  }
}
`;

const ExamineeForm: FunctionComponent = () => {
  const navigate = useNavigate();

  const [search] = useSearchParams();

  return (
    <LoginContent>
      <Formik
        initialValues={{ id: search.get('id') ?? '' }}
        validate={(values) => {
          const errors: any = {};
          if (!values.id) {
            errors.email = 'Required';
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setFieldError }) => {
          try {
            setSubmitting(true);
            const error = await validateExamineeTagExists(values.id);
            if (error) {
              setFieldError('id', error);
            } else {
              navigate(values.id);
            }
          } catch (error) {
            setFieldError('id', 'Es ist Fehler aufgetreten');
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row className="justify-content-md-center mt-5">
              <Col xs={12} md={8} lg={6} xl={4}>
                <InputGroup name="id" icon={<UserIcon />} error={errors.id && touched.id ? errors.id : undefined}>
                  <BareInput
                    placeholder="ID des Gerätes / der Maschine"
                    type="text"
                    name="id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.id}
                    autoComplete="off"
                  />
                </InputGroup>
                <FullWidthButton type="submit" disabled={isSubmitting}>
                  Gerät abrufen
                </FullWidthButton>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </LoginContent>
  );
};

export default ExamineeForm;
