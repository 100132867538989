import { Button as BButton, Col, Modal, Row } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { FunctionComponent, Suspense, lazy, useState } from 'react';

import { ReactComponent as ArrowIcon } from './arrow.svg';
import BareInput from '../form/BareInput';
import InputGroup from '../form/InputGroup';
import styled from 'styled-components';
import { useAuth } from '../../context/auth';

const PasswordStrengthBar = lazy(() => import('react-password-strength-bar'));

const Button = styled(BButton)`
  float: left;
  color: #a1a1a1;
  opacity: 1;
  svg {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 0 0 4px 6px;
    &,
    path,
    g {
      fill: #a1a1a1;
      color: #a1a1a1;
    }
  }
  span {
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 1px;
  }
  &:disabled,
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: #a1a1a1 !important;
  }
  &:hover {
    color: var(--bs-primary);
    opacity: 0.8;
    &,
    path,
    g {
      fill: var(--bs-primary);
      color: var(--bs-primary);
    }
  }
`;

// const PSB = styled(PasswordStrengthBar)`
//   max-width: 50%;
//   margin-left: 48%;
//   margin-right: 2%;
// `;

const BGModalBody = styled(Modal.Body)`
  background: #f2f2f2;
  padding: 32px;
  max-height: 500px;
  min-height: 286px;
  overflow-y: scroll;
  .password-strength-bar {
    max-width: 50%;
    margin-left: 48%;
    margin-right: 2%;
  }
`;

type ChangePasswordInput = {
  password: string;
  newPassword: string;
  newPassword2: string;
  score: number;
};

const ChangePasswordButton: FunctionComponent<{
  showArrow?: boolean;
}> = ({ showArrow = true }) => {
  const auth = useAuth();
  const [modalShow, setModalShow] = useState<boolean>(false);

  const onSubmit = async (
    values: ChangePasswordInput,
    { setSubmitting, resetForm }: FormikHelpers<ChangePasswordInput>,
  ) => {
    setSubmitting(true);
    try {
      const res = await auth.changePassword(values.password, values.newPassword);
      if (res) {
        resetForm();
        setModalShow(false);
      }
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Button variant="link" onClick={() => setModalShow(true)}>
        <span>Passwort ändern</span>
        {showArrow && <ArrowIcon />}
      </Button>
      <Modal aria-labelledby="contained-modal-title-vcenter" centered show={modalShow}>
        <Formik
          initialValues={{
            password: '',
            newPassword: '',
            newPassword2: '',
            score: 0,
          }}
          onSubmit={onSubmit}
          validate={({ password, newPassword, newPassword2, score }: ChangePasswordInput) => {
            const errors: any = {};
            if (password === undefined || password === null || password.toString().trim() === '') {
              errors.password = 'Password fehlt';
            }
            if (score < 3) {
              errors.newPassword = true;
            }
            if (newPassword !== newPassword2) {
              errors.newPassword2 = 'Die Passwörter stimmen nicht überein';
            }
            console.log(errors);
            return Object.keys(errors).length > 0 ? errors : undefined;
          }}
        >
          {({ handleSubmit, isValidating, isValid, errors, isSubmitting, touched, setFieldValue, values }) => (
            <>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Passwort ändern</Modal.Title>
              </Modal.Header>
              <BGModalBody>
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12}>
                      <InputGroup valid={!errors.password} title="Altes Passwort" name="name">
                        <BareInput
                          disabled={isSubmitting}
                          type="password"
                          name="password"
                          autoComplete="current-password"
                        />
                      </InputGroup>
                    </Col>
                    <Col xs={12}>
                      <InputGroup valid={!errors.newPassword} title="Neues Passwort" name="newPassword">
                        <BareInput
                          disabled={isSubmitting}
                          type="password"
                          name="newPassword"
                          autoComplete="new-password"
                        />
                      </InputGroup>
                      <Suspense fallback={<div />}>
                        <PasswordStrengthBar
                          className="password-strength-bar"
                          password={values.newPassword}
                          minLength={8}
                          scoreWords={['schwach', 'schwach', 'okay', 'gut', 'stark']}
                          shortScoreWord="zu kurz"
                          onChangeScore={(score) => setFieldValue('score', score)}
                        />
                      </Suspense>
                    </Col>
                    <Col xs={12}>
                      <InputGroup
                        error={touched.newPassword2 ? errors.newPassword2 : undefined}
                        valid={!errors.newPassword2}
                        title="Neues Passwort"
                        name="newPassword2"
                      >
                        <BareInput
                          disabled={isSubmitting}
                          type="password"
                          name="newPassword2"
                          autoComplete="new-password"
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </form>
              </BGModalBody>
              <Modal.Footer>
                <BButton variant="light" onClick={() => setModalShow(false)}>
                  Abbrechen
                </BButton>
                <BButton
                  disabled={!(!isValidating && isValid && Object.keys(touched).length > 0)}
                  variant="primary"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Passwort ändern
                </BButton>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ChangePasswordButton;
