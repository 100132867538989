import { NewType, UpdateType } from './removeDates';
import { EmptyTestValue, TestValue } from './TestValue';

export interface TestValueOption {
  id: number;
  option: string;
  testValue: TestValue;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export type NewTestValueOption = NewType<TestValueOption, 'option'> & { testValue: number };

export type UpdateTestValueOption = UpdateType<TestValueOption, 'option'>;

export const EmptyNewTestValueOption: NewTestValueOption = {
  option: '',
  testValue: 0,
};

export const EmptyTestValueOption: TestValueOption = {
  id: 0,
  option: '',
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  testValue: EmptyTestValue,
};
