import { Container } from 'react-bootstrap';
import { FunctionComponent } from 'react';
import Header from '../components/header/frugal';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Footer from '../components/footer';

const Main = styled.main`
  background: #f2f2f2;
  padding: 32px;

  @media (max-width: 768px) {
    padding: 32px 16px;
  }

  > .container,
  > .container-fluid {
    padding: 60px 15px 0;
    heigth: 100%;
  }
`;

const Wrapper = styled(Outlet)``;

const PublicLayout: FunctionComponent = () => {
  return (
    <>
      <Header />
      <Main className="flex-grow-1 flex-shrink-0">
        <Container fluid={true}>
          <Wrapper />
        </Container>
      </Main>
      <Footer />
    </>
  );
};

export default PublicLayout;
