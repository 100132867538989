import { FunctionComponent, ReactNode, useState } from 'react';
import { useNetworkStatus } from 'react-network-status';
import { NETWORK_CHECK_CONFIG, FORCE_OFFLINE } from '../../config';

const HideOffline: FunctionComponent<{ children: ReactNode; allowOffline?: boolean }> = ({
  allowOffline,
  children,
}) => {
  const [networkStatus, setNetworkStatus] = useState(true);

  useNetworkStatus((networkStatusUpdate) => {
    setNetworkStatus(networkStatusUpdate);
  }, NETWORK_CHECK_CONFIG);

  if (allowOffline !== true && FORCE_OFFLINE === true) {
    return null;
  }

  if (networkStatus || allowOffline === true) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export default HideOffline;
