import axios from 'axios';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import packageJson from '../../package.json';

const buildDateGreaterThan = (latestDate: number | undefined, currentDate: number | undefined) => {
  const momLatestDateTime = latestDate ? DateTime.fromMillis(latestDate) : DateTime.now();
  const momCurrentDateTime = currentDate ? DateTime.fromMillis(currentDate) : DateTime.now();

  if (momLatestDateTime > momCurrentDateTime) {
    return true;
  } else {
    return false;
  }
};

function withClearCache<T>(WrappedComponent: React.ComponentType<T>) {
  function ClearCacheComponent(props: any) {
    // const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    useEffect(() => {
      // ignore service workers in development
      if (process.env.NODE_ENV === 'development') {
        // setIsLatestBuildDate(true);
        return;
      }

      axios
        .get<{
          buildDate: any;
        }>('/meta.json')
        .then((meta) => {
          const latestVersionDate = meta.data.buildDate;
          const currentVersionDate = packageJson.buildDate;

          const shouldForceRefresh = buildDateGreaterThan(latestVersionDate, currentVersionDate);
          if (shouldForceRefresh) {
            // setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            // setIsLatestBuildDate(true);
          }
        })
        .catch((e) => {
          // setIsLatestBuildDate(true);
        });
    }, []);

    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }
      // delete browser cache and hard reload
      window.location.reload();
    };

    // if (isLatestBuildDate) {
    return <WrappedComponent {...props} />;
    // }

    // return null;
  }

  return ClearCacheComponent;
}

export default withClearCache;
