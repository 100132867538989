import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import { v4 } from 'uuid';

export interface ErrorContextType {
  errors: SafeDocError[];
  add: AddErrorFunction;
  close: (error: SafeDocError) => void;
  clear: () => void;
}

export enum ErrorLevel {
  'danger',
  'warning',
  'info',
}

interface SafeDocError {
  level: ErrorLevel;
  message: string;
  title?: string;
  redirect?: string;
  requestID?: string;
  id: string;
  ttl?: Date;
}

export type AddSafeDocError = Omit<SafeDocError, 'id'>;

export interface AddErrorFunction {
  (error: AddSafeDocError): void;
}

const ErrorContext = React.createContext<ErrorContextType>(null!);

export function ErrorProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();

  const [errors, setErrors] = useState<SafeDocError[]>([]);

  useEffect(() => {
    // check every 10 seconds if errors should be removed
    const interval = setInterval(() => {
      setErrors((curr) => curr.filter((e) => e.ttl && e.ttl > new Date()));
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const add: AddErrorFunction = useCallback(
    (error: AddSafeDocError) => {
      // If error has redirect, navigate to it
      if (error.redirect) {
        const q = queryString.stringify(error);
        return navigate(`${error.redirect}?${q}`);
      }

      const id = v4();
      // Add one minute to ttl of error if not set
      if (!error.ttl) {
        error.ttl = new Date();
        error.ttl.setMinutes(error.ttl.getMinutes() + 1);
      }
      // Add error to state
      setErrors((curr) => [{ id, ...error }, ...curr]);
    },
    [navigate],
  );

  const close = useCallback((error: SafeDocError) => {
    setErrors((curr) => curr.filter((e) => e.id !== error.id));
  }, []);

  const clear = useCallback(() => {
    setErrors((curr) => []);
  }, []);

  const memoized = useMemo(() => ({ add, close, clear }), [add, close, clear]);

  const value = { ...memoized, errors };

  return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>;
}

export function useError() {
  return React.useContext(ErrorContext);
}

export function useAddError() {
  return React.useContext(ErrorContext).add;
}

export function useClearError() {
  return React.useContext(ErrorContext).clear;
}
