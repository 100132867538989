import { AddSafeDocError, ErrorLevel, useAddError, useClearError } from '../../../context/error';
import { Col, Modal, Row, ToggleButton } from 'react-bootstrap';
import { FieldArray, Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useEffect, useState } from 'react';
import { NewTestValue, TestValueType } from '../../../interfaces/TestValue';
import { SELECTABLE_BOOLEAN, SELECTABLE_TYPES, SELECTABLE_VALUE_TYPES } from './statics';
import { Step, Wizard } from '../../../components/wizard';
import { TestTaskService, TestValueGroupService, TestValueService } from '../../../services';
import { useNavigate, useParams } from 'react-router-dom';
import FormikDebug from '../../../components/dev/formik-debug';
import { Button as BCButton } from 'react-bootstrap';
import { ReactComponent as DeleteIcon } from './trash.svg';
import { ReactComponent as PlusIcon } from './plus.svg';

import BareInput from '../../../components/form/BareInput';
import InputGroup from '../../../components/form/InputGroup';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { TestTask } from '../../../interfaces/TestTask';
import { TestValueGroup } from '../../../interfaces/TestValueGroup';
import { ValueType } from '../../../interfaces/ValueType';
import { get } from 'lodash';
import styled from 'styled-components';
import validateMinLength from '../../../helpers/validate/validateMinLength';

type VT = Omit<NewTestValue, 'testTask' | 'valueType' | 'required' | 'type'> & {
  valueType?: ValueType;
  type?: TestValueType;
  required?: boolean;
};

const getColor = (colored?: 'true' | 'danger') => {
  if (colored === 'danger') return 'var(--bs-red)';
  if (colored === 'true') return 'var(--bs-primary)';
  return '#a1a1a1';
};

const B = styled(BCButton)<{
  colored?: 'true' | 'danger';
  hovercolored?: 'true' | 'danger';
  float?: 'left' | 'right';
  block?: boolean;
  onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void;
}>`
  float: ${(props) => props.float ?? 'left'};
  color: ${(props) => getColor(props.colored)};
  opacity: 1;
  cursour: pointer;
  svg {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 0 6px 4px 0;
    cursour: pointer;
    &,
    path,
    g {
      fill: ${(props) => getColor(props.colored)};
      color: ${(props) => getColor(props.colored)};
    }
  }
  span {
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 1px;
  }
  &:disabled,
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: #a1a1a1 !important;
    svg {
      &,
      path,
      g {
        fill: #a1a1a1 !important;
        color: #a1a1a1 !important;
      }
    }
  }
  &:hover {
    color: ${(props) => getColor(props.hovercolored ?? props.colored)};
    opacity: 0.8;
    &,
    path,
    g {
      fill: ${(props) => getColor(props.hovercolored ?? props.colored)};
      color: ${(props) => getColor(props.hovercolored ?? props.colored)};
    }
  }
  @media (max-width: 767px) {
    width: ${(props) => (props.block ? 100 : 50)}%;
    padding: 1rem;
    margin-top: 1rem;
    // color: #a1a1a1;
    background: #efefef;
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

const AR = styled.div`
  width: 100%;
  display: flex;
  button {
  }
`;

const T = styled(ToggleButton)`
  height: 50px;
  width: 100%;
  display: flex;
  > div {
    margin: auto;
  }
`;

const P = styled.p<{ small: boolean }>`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  color: ${(props) => (props.small ? '#b3b3b3' : '#000')};
`;

const B2 = styled.div`
  min-width: 75px;
  p {
    text-align: center;
  }
`;

const ValueTypeButton: FunctionComponent<{ name: string }> = ({ name }) => (
  <B2>
    <P small={false}>{name}</P>
    <P small={true}></P>
  </B2>
);

const WD = styled.div`
  width: 80%;
`;

const getTitleForSavingStep = (step: number, hasError?: boolean) => {
  if (hasError) return 'Es ist ein Fehler aufgetreten!';
  if (step === 0) return 'Prüfwert wird gespeichert...';
  if (step === 1) return 'Fertig!';
  return '';
};

const NewModal: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const navigate = useNavigate();
  const params = useParams();

  const [task, setTask] = useState<TestTask>();
  const [group, setGroup] = useState<TestValueGroup>();

  const [savingStep, setSavingStep] = useState(-1);
  const [savingResult, setSavingResult] = useState<number | AddSafeDocError>();

  useEffect(() => {
    const loadTask = async () => {
      if (params.id) {
        const _task = await TestTaskService(addError).get(params.id);
        setTask(_task);
      }
    };
    loadTask();
  }, [addError, params.id]);

  useEffect(() => {
    const loadTask = async () => {
      if (params.tgid) {
        const _group = await TestValueGroupService(addError).get(params.tgid);
        setGroup(_group);
      }
    };
    loadTask();
  }, [addError, params.tgid]);

  const [item] = useState<VT>({
    name: '',
    unit: '',
    description: '',
    type: undefined,
    valueType: undefined,
    required: undefined,
    valueOptions: [],
  });

  const [step, setStep] = useState(0);

  const onSubmit = async (values: VT, { setSubmitting }: FormikHelpers<VT>) => {
    setSubmitting(true);
    clearError();
    try {
      setSavingStep(0);

      const newTestValue: NewTestValue = {
        name: values.name,
        unit: values.unit,
        description: values.description,
        type: values.type,
        valueOptions: (values.valueOptions ?? []).filter((f: string | undefined) => f && f.trim().length > 0),
        valueType: values.valueType!,
        required: values.required!,
        testTask: task!.id,
        group: group!.id,
        prio: 0,
      };

      const res = await TestValueService(addError).create(newTestValue, true);
      setSavingResult(res);
      setSavingStep(1);
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal size="lg" centered show={true}>
      <Formik validateOnChange enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
        {({ setFieldTouched, handleChange, errors, isSubmitting, touched, values, setFieldValue, submitForm }) => (
          <form>
            <Wizard
              step={step}
              preparing={!task || !group}
              onChange={(s) => {
                setStep(s);
              }}
              onClose={() =>
                // navigate(pathname.split("/").slice(0, -1).join("/"))
                navigate(`/settings/tasks/${task?.id}`)
              }
              onComplete={() => {
                submitForm();
              }}
            >
              <Step
                title={`Neuer Prüfwert bei ${task?.initials}-Prüfungen in der Gruppe ${group?.name}`}
                description="Start"
                valid={true}
              >
                <p>
                  Dieser Assistent unterstütz Sie bei der Erstellung eines neuen Wertes, der durch den Prüfer bei der
                  Prüfung erfasst wird.
                </p>
              </Step>

              <Step title="Festellen des Wertes" description="Format" valid={!errors.type && touched.type}>
                <WD>
                  <Row>
                    <Col>
                      <p>Wie wird der Wert festgestellt?</p>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    {SELECTABLE_TYPES.map(({ value, title }, idx) => (
                      <Col xs={4}>
                        <T
                          className="m-2"
                          key={idx}
                          id={`radio-${idx}`}
                          type="radio"
                          variant="outline-dark"
                          name="radio"
                          value={value}
                          checked={values.type === value}
                          onChange={() => {
                            setFieldTouched('type');
                            setFieldValue('type', value, true);
                          }}
                        >
                          <ValueTypeButton name={title} />
                        </T>
                      </Col>
                    ))}
                  </Row>
                </WD>
              </Step>

              <Step title="Format des Wertes" description="Format" valid={!errors.valueType && touched.valueType}>
                <WD>
                  <Row>
                    <Col>
                      <p>Bitte wählen Sie in passendes Eingabeformat.</p>
                    </Col>
                  </Row>
                  <Row className="">
                    {SELECTABLE_VALUE_TYPES.map(({ value, title }, idx) => (
                      <Col xs={4}>
                        <T
                          className="m-2"
                          key={idx}
                          id={`radio-${idx}`}
                          type="radio"
                          variant="outline-dark"
                          name="radio"
                          value={value}
                          checked={values.valueType === value}
                          onChange={() => {
                            setFieldTouched('valueType');
                            setFieldValue('valueType', value, true);
                          }}
                        >
                          <ValueTypeButton name={title} />
                        </T>
                      </Col>
                    ))}
                  </Row>
                </WD>
              </Step>

              <Step title="Name des Wertes" description="Name" valid={!errors.name && !!touched.name}>
                <p>Bitte geben Sie den Namen des Wertes ein.</p>
                <InputGroup error={errors.name} valid={!errors.name} title="Name" name="name" variant="gray-200">
                  <BareInput
                    disabled={isSubmitting}
                    type="text"
                    name="name"
                    minLength={1}
                    validate={(v: any) => validateMinLength(1, v)}
                    onChange={(e: any) => {
                      setFieldTouched('name');
                      handleChange(e);
                    }}
                  />
                </InputGroup>
              </Step>

              <Step title="Beschreibung des Wertes" description="Name" valid={!errors.name && !!touched.name}>
                <p>Bitte geben Sie eine Beschreibung ein, die dem Kunden später angezeigt wird.</p>
                <InputGroup
                  error={errors.description}
                  valid={!errors.description}
                  title="Beschreibung"
                  name="description"
                  variant="gray-200"
                >
                  <BareInput
                    disabled={isSubmitting}
                    type="text"
                    name="description"
                    validate={(v: any) => validateMinLength(1, v)}
                    onChange={(e: any) => {
                      setFieldTouched('description');
                      handleChange(e);
                    }}
                  />
                </InputGroup>
              </Step>

              {values.valueType === ValueType.NUMBER && (
                <Step title="Einheit des Wertes" description="Einheit" valid={true}>
                  <p>Bitte geben Sie die Einheit ein, in der der Wert gemessen wird.</p>
                  <InputGroup error={errors.unit} valid={!errors.unit} title="Einheit" name="unit" variant="gray-200">
                    <BareInput
                      disabled={isSubmitting}
                      type="text"
                      name="unit"
                      placeholder="ohne Einheit"
                      onChange={(e: any) => {
                        setFieldTouched('unit');
                        handleChange(e);
                      }}
                    />
                  </InputGroup>
                </Step>
              )}

              {values.valueType === ValueType.OPTION && (
                <Step
                  title="Auswahlmöglichkeiten"
                  description="Auswahlmöglichkeiten"
                  valid={
                    (values.valueOptions ?? []).filter((f: string | undefined) => f && f.trim().length > 0).length > 0
                  }
                >
                  <p>Bitte geben Sie die Auswahlmöglichkeiten ein, die der Wert annehmen kann.</p>
                  <FieldArray
                    name="valueOptions"
                    render={(arrayHelpers) => (
                      <>
                        {values.valueOptions && values.valueOptions.length > 0
                          ? values.valueOptions.map((_: string, index: number) => (
                              <AR key={index}>
                                <InputGroup
                                  error={errors.unit}
                                  valid={!errors.unit}
                                  title="Auswahl"
                                  name={`valueOptions.${index}`}
                                  variant="gray-200"
                                >
                                  <BareInput
                                    disabled={isSubmitting}
                                    type="text"
                                    name={`valueOptions.${index}`}
                                    placeholder=""
                                    onChange={(e: any) => {
                                      setFieldTouched(`valueOptions.${index}`);
                                      handleChange(e);
                                    }}
                                  />
                                </InputGroup>
                                <B variant="link" hovercolored="danger" onClick={() => arrayHelpers.remove(index)}>
                                  <DeleteIcon />
                                </B>
                              </AR>
                            ))
                          : null}
                        <B variant="link" hovercolored="true" onClick={() => arrayHelpers.push('')}>
                          <PlusIcon />
                        </B>
                      </>
                    )}
                  />
                </Step>
              )}

              <Step title="Pflichtfeld" description="Pflichtfeld" valid={!errors.required && touched.required}>
                <WD>
                  <Row>
                    <Col>
                      <p>Muss der Wert bei der Prüfung vom Prüfer erfasst werden?</p>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    {SELECTABLE_BOOLEAN.map(({ value, title }, idx) => (
                      <Col xs={4}>
                        <T
                          className="m-2"
                          key={idx}
                          id={`radio-${idx}`}
                          type="radio"
                          variant="outline-dark"
                          name="radio"
                          value={value ? 'true' : 'false'}
                          checked={values.required === value}
                          onChange={() => {
                            setFieldTouched('required');
                            setFieldValue('required', value, true);
                          }}
                        >
                          <ValueTypeButton name={title} />
                        </T>
                      </Col>
                    ))}
                  </Row>
                </WD>
              </Step>

              <Step
                title={getTitleForSavingStep(
                  savingStep,
                  savingResult !== undefined && typeof savingResult !== 'number',
                )}
                description="Abschluss"
                notCloseable={savingStep !== 1}
              >
                {savingStep < 1 && <LoadingIndicator size={40} />}
                {savingStep === 1 && typeof savingResult === 'number' && (
                  <p>Der Prüfwert {values?.name} wurde erfolgreich erstellt.</p>
                )}
                {savingStep === 1 && typeof savingResult !== 'number' && <p>{savingResult?.message}</p>}
              </Step>
            </Wizard>
            <FormikDebug />
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default NewModal;
