import { FunctionComponent, useEffect, useState } from 'react';

import styled from 'styled-components';
import { ButtonWithIcon } from '../../../components/buttons/ButtonWithIcon';
import { useAddError } from '../../../context/error';
import { Location } from '../../../interfaces/Location';
import { LocationService } from '../../../services';
import { ReactComponent as ArrowIcon } from './arrow.svg';

interface LocationSelectButtonProps {
  data?: Location;
  value: number | string;
  onClick?: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
}

const P = styled.p<{ small: boolean }>`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  color: ${(props) => (props.small ? '#b3b3b3' : '#000')};
`;

const LocationSelectButton: FunctionComponent<LocationSelectButtonProps> = ({ value, data, onClick }) => {
  const addError = useAddError();

  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState<Location>();

  useEffect(() => {
    const loadLoaction = async () => {
      try {
        setLoading(true);
        if (Number(value) > 0) {
          const _item = await LocationService(addError).get(value.toString(), {
            fields: ['id', 'name', 'tag', 'address'].join(','),
          });
          setLocation(_item);
        } else {
          setLocation(undefined);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    if (data) {
      setLocation(data);
    } else {
      loadLoaction();
    }
  }, [addError, data, value]);

  return (
    <ButtonWithIcon variant="link" onClick={onClick}>
      {loading && <span>laden...</span>}
      {!loading && !location && (
        <span>
          <P small={false}>Standort auswählen</P>
          <P small={true}>Klicken Sie hier, um nach einem Standort zu suchen und diesen auszuwählen.</P>
        </span>
      )}
      {!loading && location && location.address && (
        <span>
          <P small={false}>
            [{location.tag}] {location.name}
          </P>
          <P small={true}>
            {location.address.street}, {location.address.postalCode} {location.address.city}
          </P>
        </span>
      )}
      {onClick && <ArrowIcon />}
    </ButtonWithIcon>
  );
};

export default LocationSelectButton;
