import { EmptyTestTask, TestTask } from './TestTask';
import { EmptyTestValueGroup, TestValueGroup } from './TestValueGroup';
import { NewType, UpdateType } from './removeDates';

import { TestValueThreshold } from './TestValueThreshold';
import { ValueType } from './ValueType';
import { TestValueOption } from './TestValueOption';

export enum TestValueType {
  'SICHTPRÜFUNG' = 'view',
  'MESSPRÜFUNG' = 'measure',
  'FUNKTIONSPRÜFUNG' = 'function',
}

export interface TestValue {
  id: number;
  name: string;
  unit?: string;
  description?: string;
  valueType: ValueType;
  valueOptions: TestValueOption[];
  required: boolean;
  testTask: TestTask;
  thresholds: TestValueThreshold[];
  defaultValue?: string;
  type?: TestValueType;
  prio: number;
  group: TestValueGroup;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export type NewTestValue = NewType<
  TestValue,
  'name' | 'unit' | 'description' | 'valueType' | 'required' | 'prio' | 'type'
> & { valueOptions: string[] };

export type UpdateTestValue = UpdateType<TestValue, 'name' | 'unit' | 'description' | 'prio'>;

export const EmptyNewTestValue: NewTestValue = {
  name: '',
  unit: '',
  description: '',
  valueType: ValueType.BOOLEAN,
  type: TestValueType.MESSPRÜFUNG,
  required: false,
  prio: 0,
  valueOptions: [],
};

export const EmptyTestValue: TestValue = {
  id: 0,
  name: '',
  unit: '',
  description: '',
  valueType: ValueType.BOOLEAN,
  required: false,
  testTask: EmptyTestTask,
  group: EmptyTestValueGroup,
  prio: 0,
  thresholds: [],
  valueOptions: [],
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
};
