import { AddErrorFunction } from '../context/error';

import axios from 'axios';
import { get as _get } from 'lodash';
import queryString from 'query-string';
import { API } from '../config';
import { SafeplanCustomer } from '../interfaces/SafeplanCustomer';
import { SafeplanCustomerGroup } from '../interfaces/SafeplanCustomerGroup';
import { SafeplanTechnician } from '../interfaces/SafeplanTechnician';
import handleError from './errorHandler';
import getToken from './getToken';

const SafeplanAdapterService = (addError: AddErrorFunction) => {
  const cachePolicy = () => {
    return 'no-cache, max-age=0';
  };

  const technicians = (query?: string) => {
    return axios
      .get(`${API}/safeplan/technicians`, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        const r = _get(res, 'data.data') as SafeplanTechnician[];
        if (!query) return r;
        return r.filter((t) => t.name.indexOf(query) > -1);
      })
      .catch((e) => {
        addError(handleError(e));
        return [] as SafeplanTechnician[];
      });
  };

  const customerGroups = (search?: string) => {
    const query = queryString.stringify({
      search,
    });
    return axios
      .get(`${API}/safeplan/customer-groups?${query}`, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        const r = _get(res, 'data.data') as SafeplanCustomerGroup[];
        return r;
      })
      .catch((e) => {
        addError(handleError(e));
        return [] as SafeplanCustomerGroup[];
      });
  };

  const customers = (search?: string) => {
    const query = queryString.stringify({
      search,
    });
    return axios
      .get(`${API}/safeplan/customers?${query}`, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        const r = _get(res, 'data.data') as SafeplanCustomer[];
        return r;
      })
      .catch((e) => {
        addError(handleError(e));
        return [] as SafeplanCustomer[];
      });
  };

  return { technicians, customerGroups, customers };
};

export default SafeplanAdapterService;
