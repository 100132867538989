/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-empty-function */
import { FunctionComponent, useEffect, useRef, useState } from 'react';

import BareInput from '../../components/form/BareInput';
import { Examinee } from '../../interfaces/Examinee';
import { Location } from '../../interfaces/Location';
import { ExamineesUtils } from '../../services';
import InputGroup from '../../components/form/InputGroup';
import { debounce, last } from 'lodash';
import styled from 'styled-components';
import { useMain } from '../../context/main';
import validateExamineeTag from '../../helpers/validate/validateExamineeTag';
import Html5QrcodePlugin from '../../components/qr-scanner/Html5QrcodePlugin';
import OfflineService from '../../services/OfflineService';

interface DeviceTagSelectorProps {
  selected?: number;
  onExamineeChanged?: (scan: boolean, locked: boolean, value: Examinee | undefined) => void;
  onInputChanged?: (value: string) => void;
  onSearching?: (value: boolean) => void;
  onTyping?: () => void;
}

const ErrorWrapper = styled.div`
  width: 100%;
  background-color: #aa0000 !important;
  border-radius: 0.3rem;
  border-color: #fff;
  color: #fff;
  padding: 8px;
  margin-bottom: 8px;
  p {
    font-size: 1rem;
    margin: 0;
    line-height: 1.5;
  }
`;

const UsedWrapper = styled.div`
  width: 100%;
  background-color: var(--bs-primary);
  border-radius: 0.3rem;
  border-color: #fff;
  color: #fff;
  padding: 8px;
  user-select: none;
  cursor: pointer;
  margin-bottom: 8px;
  p {
    font-size: 1rem;
    margin: 0;
    line-height: 1.5;
  }
`;

const CamWrapper = styled.div`
  width: 100%;
  #html5qr-code-full-region {
    border: 0 !important;
    background-color: var(--bs-primary);
    border-radius: 0.3rem;
    border-color: #fff;
    svg {
      fill: #fff;
      stroke: #fff;
    }
  }
  #html5qr-code-full-region__dashboard_section {
    > div:nth-child(2) {
      display: none;
    }
  }
  #html5qr-code-full-region__dashboard_section_csr {
    button {
      width: 100%;
      color: #fff;
      padding: 0px 8px;
      user-select: none;
      cursor: pointer;
      border: 0;
      background: 0;
    }
  }
`;

const regex =
  /^((?:http([süäö])?(:?)([\/\-_]{0,2}))?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+[\/\-_])?([0-9]+[\/\-_]?)$/gim;
// /^((?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+\/)?([0-9]+\/?)$/gim;

const DeviceTagSelector: FunctionComponent<DeviceTagSelectorProps> = ({
  selected,
  onExamineeChanged,
  onSearching,
  onInputChanged,
  onTyping,
}) => {
  const { selectLocationByObject } = useMain();

  const [isSearching, setSearching] = useState(false);
  // const [value, setValue] = useState<string | undefined>(defaultValue);
  const [error, setError] = useState<string | React.ReactNode>();
  const [used, setUsed] = useState<Location | boolean | undefined>(undefined);
  const [offline, setOffline] = useState<boolean>(false);

  const cleanup = (value: string) => {
    const r = regex.exec(value);
    if (r !== null) {
      const id = last(r);
      if (id) {
        value = id;
      }
    }
    return value;
  };

  const handleChange = async (_value: string, scan: boolean) => {
    const value = cleanup(_value);
    onInputChanged && onInputChanged(value.toLowerCase());
    handleValueChange(value.toLowerCase(), scan);
  };

  const handleValueChange = debounce(async (_value: string, scan: boolean) => {
    const e = await validateExamineeTag(_value);
    setError(e);
    setUsed(undefined);
    check(_value, scan);
  }, 120);

  const notifySearching = (s: boolean) => {
    setSearching(s);
    onSearching && onSearching(s);
  };

  const getByTagOffline = async (_value: string, scan: boolean): Promise<Examinee | undefined> => {
    return OfflineService()
      .getByTag(_value, selected)
      .then((e) => {
        setUsed(undefined);
        return e;
      })
      .catch((error) => {
        setUsed(true);
        throw error;
        // onExamineeChanged && onExamineeChanged(scan, true, undefined);
        // return undefined;
      });
  };

  const getByTag = async (_value: string, scan: boolean) => {
    const ex = await ExamineesUtils()
      .getByTag(_value, true)
      .catch((e) => {
        if (e.message === 'Offline') {
          setOffline(true);
          return getByTagOffline(_value, scan);
        }
        return undefined;
      });
    return ex;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const check = async (_value: string, scan: boolean) => {
    notifySearching(true);
    try {
      const ex = await getByTag(_value, scan);
      if (!(await validateExamineeTag(_value))) {
        const exl = ex?.location;
        if (exl && exl.id !== selected) {
          // examinee tag is used by another location
          setUsed(exl);
          onExamineeChanged && onExamineeChanged(scan, true, undefined);
        } else {
          // examinee tag is not used or used by this location
          setUsed(undefined);
          onExamineeChanged && onExamineeChanged(scan, false, ex);
        }
      } else {
        // examinee tag is invalid

        onExamineeChanged && onExamineeChanged(scan, true, undefined);
      }
    } catch (error) {
      // examinee tag is invalid
      onExamineeChanged && onExamineeChanged(scan, true, undefined);
    } finally {
      notifySearching(false);
    }
  };

  const handleScan = async (code: string) => {
    const r = regex.exec(code);
    if (r !== null) {
      const id = last(r);
      if (id && id.length > 0) {
        await check(id, true);
        return true;
      }
    }
    return false;
  };

  const ref = useRef<HTMLInputElement>(null);

  useEffect(
    () => {
      if (ref.current) {
        setTimeout(() => {
          if (ref.current) {
            (ref.current as any).focus();
          }
        }, 200);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Wrapper>
      <InputGroup
        error={typeof error === 'string' ? error : undefined}
        valid={!error}
        title="Prüfling-ID"
        name="tag"
        variant="gray-200"
        loading={isSearching}
      >
        <BareInput
          innerRef={ref}
          autoFocus={true}
          type="text"
          name="tag"
          validate={validateExamineeTag}
          onChange={(e: any) => {
            handleChange(e.nativeEvent.target.value, false);
          }}
          onBlur={(e: any) => {
            handleChange(e.nativeEvent.target.value, false);
          }}
        />
        {typeof error !== 'string' ? error : undefined}
      </InputGroup>
      {used && !offline && (
        <UsedWrapper
          onClick={() => {
            if (typeof used === 'boolean') return;
            selectLocationByObject(used!);
            setUsed(undefined);
          }}
        >
          <p>Dieser Prüfling wurde einem anderen Standort zugeordnet.</p>
          <p>Zum Standort wechseln</p>
        </UsedWrapper>
      )}
      {used && offline && (
        <ErrorWrapper>
          <p>Dieser Prüfling wurde einem anderen Standort zugeordnet.</p>
          <p>Im Offline-Modus kann nicht automatisch zu diesem Standort gewechselt werden.</p>
        </ErrorWrapper>
      )}
      <CamWrapper>
        <Html5QrcodePlugin verbose={false} fps={10} qrCodeErrorCallback={() => {}} qrCodeSuccessCallback={handleScan} />
      </CamWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export default DeviceTagSelector;
