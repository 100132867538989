import { EmptyExamineeFamily, ExamineeFamily, UpdateExamineeFamily } from '../../interfaces/ExamineeFamily';
import { ErrorLevel, useAddError, useClearError } from '../../context/error';
import { ExamineeFamiliesService, TestTaskService } from '../../services';
import { FieldArray, Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useEffect, useState } from 'react';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';
import { get, reduce } from 'lodash';

import BareInput, { NumberInput } from '../../components/form/BareInput';
import BreakSection from '../../components/form/BreakSection';
import { ButtonWithIcon } from '../../components/buttons/ButtonWithIcon';
import InputGroup from '../../components/form/InputGroup';
import InputSection from '../../components/form/InputSection';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { Row } from 'react-bootstrap';
import { TestTask } from '../../interfaces/TestTask';
import Title from '../../components/title';
import { confirm } from '../../components/dialogs/Confirmation';
import qs from 'query-string';
import styled from 'styled-components';
import validateName from '../../helpers/validate/validateName';
import { ReactComponent as ArrowIcon } from './arrow.svg';
import formatValueType from '../../helpers/format/valueType';

const PG = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: flex-start;
  align-items: stretch;
  float: left;
  height: 100%;
`;

const P = styled.p<{ small: boolean }>`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  color: ${(props) => (props.small ? '#b3b3b3' : '#000')};
`;

// const ButtonWithIcon = styled(Button)`
//   float: left;
//   color: #a1a1a1;
//   opacity: 1;
//   background: transparent;
//   svg {
//     width: 14px;
//     height: 14px;
//     display: inline-block;
//     margin: 0 0 4px 6px;
//     &,
//     path,
//     g {
//       fill: #a1a1a1;
//       color: #a1a1a1;
//     }
//   }
//   span {
//     display: inline-block;
//     vertical-align: bottom;
//     margin-bottom: 1px;
//   }
//   &:disabled,
//   &.disabled {
//     cursor: not-allowed;
//     opacity: 0.5;
//     color: #a1a1a1 !important;
//   }
//   &:hover {
//     color: var(--bs-primary);
//     opacity: 0.8;
//     &,
//     path,
//     g {
//       fill: var(--bs-primary);
//       color: var(--bs-primary);
//     }
//   }
// `;

const getChangedValues = (values: ExamineeFamily, initialValues: ExamineeFamily): Partial<ExamineeFamily> => {
  return reduce(
    Object.entries(values),
    (acc, [key, value]) => {
      const hasChanged = (initialValues as any)[key] !== value;
      if (hasChanged) {
        (acc as any)[key] = value;
      }
      return acc;
    },
    {},
  );
};

const shouldEditing = (location: Location) => {
  const queryParams = qs.parse(location.search, {});
  return queryParams.edit === '1' || queryParams.edit === 'true';
};

const Show: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(shouldEditing(location));

  const [item, setItem] = useState<ExamineeFamily>(EmptyExamineeFamily);
  const [testTasks, setTestTasks] = useState<TestTask[]>([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        if (!params.id) {
          return;
        }
        const [_item, { items: _testTasks }] = await Promise.all([
          ExamineeFamiliesService(addError).get(params.id),
          TestTaskService(addError).list(),
        ]);
        if (!_item || !_item.id) {
          navigate('..');
        }
        setItem(_item);
        setTestTasks(_testTasks);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, navigate, params.id]);

  const onSubmit = async (values: ExamineeFamily, { setSubmitting }: FormikHelpers<ExamineeFamily>) => {
    setSubmitting(true);
    try {
      if (params.id) {
        clearError();
        const data = getChangedValues(values, item);
        const updateData: UpdateExamineeFamily = {
          ...data,
          testTasks: values.testTasks.map((tt) => tt.id),
        };
        await ExamineeFamiliesService(addError).update(params.id, updateData);
        setEditing(false);
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onDelete = async (setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true);
    try {
      if (params.id) {
        if (
          await confirm(
            'Prüflingsgattung löschen?',
            'Die Prüflingsgattung wird entgültig gelöscht. Sind Sie sich sicher?',
            'Löschen',
            'Abbruch',
            {
              okColor: 'danger',
            },
          )
        ) {
          await ExamineeFamiliesService(addError).destroy(params.id);
          navigate('..');
        }
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isValidating, isValid, errors, values, resetForm, isSubmitting, setSubmitting }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Title
            onEdit={() => setEditing(true)}
            onDelete={() => onDelete(setSubmitting)}
            onCancel={() => {
              resetForm({
                values: item,
              });
              setEditing(false);
            }}
            editing={editing}
            editingValid={!isValidating && isValid}
            isSubmitting={isSubmitting}
            to="/settings/examinee-families"
            title={item.name}
          />
          <Row>
            <InputSection title="Stammdaten" xs={12} xl={6}>
              <InputGroup error={errors.name} valid={editing ? !errors.name : undefined} title="Name" name="name">
                <BareInput disabled={!editing || isSubmitting} type="text" name="name" validate={validateName} />
              </InputGroup>
              <InputGroup error={errors.prio} valid={editing ? !errors.prio : undefined} title="Priorität" name="prio">
                <NumberInput required={true} name="prio" disabled={!editing || isSubmitting} float={false} />
              </InputGroup>
            </InputSection>
            <BreakSection xs={0} xl={6} />
            <InputSection title="Zusätzliche Felder Prüfling" xs={12} md={6}>
              {editing && (
                <InputGroup valid={true} title="Neu" name="new">
                  <ButtonWithIcon variant="link" onClick={() => navigate(`examinee-additional-information-fields/new`)}>
                    <PG>
                      <P small={false}>neues zusätzliches Feld</P>
                      <P small={true}>Fügen Sie ein zusätzliches Feld hinzu.</P>
                    </PG>
                    <ArrowIcon />
                  </ButtonWithIcon>
                </InputGroup>
              )}
              {(item.examineeAdditionalInformation ?? [])
                .sort((a, b) => (a.page ?? 0) - (b.page ?? 0))
                .map((tv) => (
                  <InputGroup
                    key={tv.id}
                    error={errors.examineeAdditionalInformation}
                    valid={editing ? !errors.examineeAdditionalInformation : undefined}
                    title={formatValueType(tv.valueType)}
                    name="examineeAdditionalInformation"
                  >
                    <ButtonWithIcon
                      variant="link"
                      disabled={false}
                      onClick={() => navigate(`examinee-additional-information-fields/${tv.id}`)}
                    >
                      <PG>
                        <P small={false}>
                          {tv.name}
                          {tv.required ? '(erforderlich)' : ''}
                        </P>
                        {tv.page ? <P small={true}>Seite {tv.page}</P> : false}
                      </PG>
                      <ArrowIcon />
                    </ButtonWithIcon>
                  </InputGroup>
                ))}
            </InputSection>
            <InputSection title="Zusätzliche Felder Prüfung" xs={12} xl={6}>
              {editing && (
                <InputGroup valid={true} title="Neu" name="new">
                  <ButtonWithIcon variant="link" onClick={() => navigate(`test-additional-information-fields/new`)}>
                    <PG>
                      <P small={false}>neues zusätzliches Feld</P>
                      <P small={true}>Fügen Sie ein zusätzliches Feld hinzu.</P>
                    </PG>
                    <ArrowIcon />
                  </ButtonWithIcon>
                </InputGroup>
              )}
              {(item.testAdditionalInformation ?? []).map((tv) => (
                <InputGroup
                  key={tv.id}
                  error={errors.testAdditionalInformation}
                  valid={editing ? !errors.testAdditionalInformation : undefined}
                  title={formatValueType(tv.valueType)}
                  name="testAdditionalInformation"
                >
                  <ButtonWithIcon
                    variant="link"
                    disabled={false}
                    onClick={() => navigate(`test-additional-information-fields/${tv.id}`)}
                  >
                    <PG>
                      <P small={false}>
                        {tv.name}
                        {tv.required ? '(erforderlich)' : ''}
                      </P>
                    </PG>
                    <ArrowIcon />
                  </ButtonWithIcon>
                </InputGroup>
              ))}
            </InputSection>
            <InputSection title="Prüfarten" xs={12} xl={6}>
              <FieldArray
                name="testTasks"
                render={(arrayHelpers) => (
                  <div>
                    {(testTasks ?? []).map((tt) => (
                      <InputGroup
                        variant={(values.testTasks ?? []).find((t) => t.id === tt.id) ? 'light' : 'gray-200'}
                        title={tt.initials}
                        name={tt.initials}
                        key={tt.id}
                      >
                        <ButtonWithIcon
                          variant="link"
                          disabled={!editing}
                          onClick={() => {
                            const index = (values.testTasks ?? []).findIndex((t) => t.id === tt.id);
                            if (index > -1) {
                              arrayHelpers.remove(index);
                            } else {
                              arrayHelpers.insert(0, tt);
                            }
                          }}
                        >
                          <P small={false}>{tt.name}</P>
                          {editing && (
                            <P small={true}>
                              {(values.testTasks ?? []).find((t) => t.id === tt.id) && 'Zum Deaktiviern klicken'}
                              {!(values.testTasks ?? []).find((t) => t.id === tt.id) && 'Zum Aktiviern klicken'}
                            </P>
                          )}
                          {/* <ArrowIcon /> */}
                        </ButtonWithIcon>
                      </InputGroup>
                    ))}
                  </div>
                )}
              />
            </InputSection>
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default Show;
