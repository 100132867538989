import { FieldAttributes, useField } from 'formik';
import { BlockPicker } from 'react-color';

import { Popover } from '@headlessui/react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const reg = /^#([0-9a-f]{3}){1,2}$/i;

const SPopover = styled(Popover)`
  position: relative;
`;

const SPopoverPanel = styled(Popover.Panel)`
  position: absolute;
  z-index: 10;
  margin-top: 10px;
`;

const ColorInput: FunctionComponent<
  FieldAttributes<any> & {
    disabled?: boolean;
  }
> = ({ children, valueType, disabled, ...props }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(props);
  return (
    <SPopover>
      <Popover.Button
        disabled={disabled}
        style={{
          backgroundColor: field.value,
          width: '28px',
          height: '28px',
          border: 0,
        }}
      ></Popover.Button>

      <SPopoverPanel>
        <BlockPicker
          triangle={'hide'}
          disabled={disabled}
          {...field}
          {...props}
          color={field.value}
          onChangeComplete={(e) => {
            helpers.setValue(e.hex, true);
            helpers.setTouched(true);
            const test = reg.test(e.hex);
            if (!test) {
              helpers.setError('Invalid color');
            } else {
              helpers.setError(undefined);
            }
          }}
        />
      </SPopoverPanel>
    </SPopover>
  );
};

export default ColorInput;
