import { FunctionComponent, useEffect, useState } from 'react';
import Table, { TableColProps } from '../../table';

import { FilterType } from '../../../services/Service';
import { TestingDevice } from '../../../interfaces/TestingDevice';
import { TestingDeviceService } from '../../../services';
import { useAddError } from '../../../context/error';
import { Tester } from '../../../interfaces/Tester';

interface TestingDeviceListProps {
  tester?: Tester;
  query?: string;
}

const columns: TableColProps<TestingDevice>[] = [
  { title: 'Hersteller', values: 'vendor' },
  { title: 'Name', values: 'name' },
  { title: 'Seriennummer', values: 'serial' },
  {
    title: 'Prüfer',
    values: (item: TestingDevice) => {
      return (
        <span>
          {item.testers?.map((t, i) => {
            const isLast = i === item.testers.length - 1;
            return (
              <span key={t.id}>
                {t.user.name}
                {t.user.deleted_at ? ' (Gesperrt)' : ''}
                {isLast ? '' : ', '}
              </span>
            );
          }) ?? ''}
        </span>
      );
    },
  },
];

const TestingDeviceList: FunctionComponent<TestingDeviceListProps> = ({ query, tester }) => {
  const addError = useAddError();
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<TestingDevice[]>([]);

  const [filter, setFilter] = useState<FilterType<TestingDevice> | undefined | false>(false);

  useEffect(() => {
    const f: FilterType<TestingDevice> = {
      'testers.id': tester?.id ?? undefined,
    };
    setFilter(Object.values(f).filter((v) => !!v).length > 0 ? f : undefined);
  }, [tester]);

  useEffect(() => {
    const loadData = async () => {
      try {
        setItems([]);
        setLoading(true);
        if (query && query.length > 0) {
          const { items } = await TestingDeviceService(addError).search(query);
          setItems(items);
        } else {
          const { items } = await TestingDeviceService(addError).list(
            100,
            0,
            filter as FilterType<TestingDevice> | undefined,
          );
          setItems(items);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    if (filter !== false) {
      loadData();
    }
  }, [addError, filter, query]);

  return <Table navigatePrefix="/settings/testing-devices/" loading={loading} items={items} columns={columns} />;
};

export default TestingDeviceList;
