import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { ReactConfirmProps, confirmable, createConfirmation } from 'react-confirm';

import { AddErrorFunction } from '../../context/error';
import DownloadService from '../../services/DownloadService';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

const Content = styled.div`
  position: relative;
  min-height: 122px;
`;

interface DownloadProps extends ReactConfirmProps {
  url: string;
  // body: Record<string, any>;
  filter?: any;
  params?: Record<string, string>;
  show: boolean;
  proceed: (value?: string | boolean) => void;
  addError: AddErrorFunction;
}

const Download: FunctionComponent<DownloadProps> = ({ url, filter, params, show, proceed, addError }) => {
  const [loading, setLoading] = useState<boolean>(true);

  const _download = useCallback(async () => {
    if (!addError || !proceed || !url) {
      return;
    }
    await DownloadService(addError).downloadFile(url, filter, params);
    setLoading(false);
    proceed(true);
  }, [addError, filter, params, proceed, url]);

  useEffect(() => {
    _download();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="static-modal">
      <Modal show={show} onHide={() => proceed(false)} backdrop={'static'} keyboard={false}>
        <Modal.Header>
          <Modal.Title>Download wird vorbereitet...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Content>{loading && <LoadingIndicator />}</Content>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant={okColor} onClick={() => proceed(true)}>
              {proceedLabel}
            </Button>
          </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export function download(addError: AddErrorFunction, url: string, filter?: any, params?: Record<string, string>) {
  return createConfirmation(confirmable(Download))({
    addError,
    url,
    filter,
    params,
  });
}
