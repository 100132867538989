import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import BareInput, { NumberInput } from '../../../components/form/BareInput';
import SelectInput, { ToggleInputBooleanValueUndefined } from '../../../components/form/ToggleInput';
import { EmptyExamineeFamily, ExamineeFamily } from '../../../interfaces/ExamineeFamily';

import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { CommonExamineeSelectProps } from '.';
import InputGroup from '../../../components/form/InputGroup';
import ToggleInput from '../../../components/form/ToggleInput';
import { useAddError } from '../../../context/error';
import { Examinee } from '../../../interfaces/Examinee';
import { ValueType } from '../../../interfaces/ValueType';
import { ExamineeFamiliesService } from '../../../services';
import { examineeFamilyStore } from '../../../storage';
import LoadingIndicator from '../LoadingIndicator';

const ExamineeAdditionalInformationSection: FunctionComponent<CommonExamineeSelectProps> = ({ variant, editing }) => {
  const { values, isSubmitting, setFieldValue } = useFormikContext<
    Examinee & {
      family: number;
      additionalInformation?: Record<string, string>;
    }
  >();

  const addError = useAddError();
  const [loading, setLoading] = useState(true);
  const [families, setFamilies] = useState<ExamineeFamily[]>([]);
  const [family, setFamily] = useState<ExamineeFamily>(EmptyExamineeFamily);

  const loadFamilies = useCallback(async () => {
    const { items } = await ExamineeFamiliesService(addError, examineeFamilyStore).list(3000);
    setFamilies(items);
  }, [addError]);

  useEffect(() => {
    const loadData = async () => {
      try {
        loadFamilies();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, loadFamilies]);

  useEffect(() => {
    const _fam = families.find((f) => f.id === get(values, 'type.family.id', values.family ?? 0));
    setFamily(_fam ?? EmptyExamineeFamily);
  }, [families, setFieldValue, values, values.family, values.type, values.type.family]);

  if (!family) {
    return null;
  }

  if (family && (family.examineeAdditionalInformation ?? []).length === 0) {
    return <p>Es werden für diese Prüflingsart keine zusätzlichen Informationen benötigt. </p>;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {family &&
        (family.examineeAdditionalInformation ?? []).map((tv) => (
          <InputGroup
            longName={true}
            variant={variant}
            key={tv.id}
            title={tv.name}
            name={`additionalInformation.${tv.id}`}
          >
            {tv.valueType === ValueType.STRING && (
              <BareInput disabled={!editing || isSubmitting} type="text" name={`additionalInformation.${tv.id}`} />
            )}
            {(tv.valueType === ValueType.NUMBER || tv.valueType === ValueType.FLOAT) && (
              <NumberInput
                name={`additionalInformation.${tv.id}`}
                disabled={!editing || isSubmitting}
                float={tv.valueType === ValueType.FLOAT}
              />
            )}
            {tv.valueType === ValueType.OPTION && (
              <SelectInput
                validate={(value: any) => {
                  const isRequired = tv.required;
                  const empty = value === ToggleInputBooleanValueUndefined;

                  if (isRequired && empty) {
                    return true;
                  }

                  return empty;
                }}
                valueType="text"
                name={`additionalInformation.${tv.id}`}
                disabled={isSubmitting}
              >
                <option disabled selected value={ToggleInputBooleanValueUndefined}>
                  Bitte auswählen
                </option>
                {tv.valueOptions.map((opt) => (
                  <option key={opt.option} value={opt.option}>
                    {opt.option}
                  </option>
                ))}
              </SelectInput>
            )}
            {tv.valueType === ValueType.BOOLEAN && (
              <ToggleInput name={`additionalInformation.${tv.id}`} disabled={!editing || isSubmitting} />
            )}
          </InputGroup>
        ))}
    </>
  );
};

export default ExamineeAdditionalInformationSection;
