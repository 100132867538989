import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const SquareButton = styled(NavLink)<{
  highlight?: boolean;
}>`
  width: 100%;
  max-width: 20rem;
  max-height: 20rem;
  position: relative;
  margin: 0;
  color: ${(props) => (props.highlight ? 'var(--bs-primary)' : 'var(--bs-body-color)')};
  text-decoration: none;
  div {
    background: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    max-width: 20rem;
    min-height: 175px;
    margin: auto;
    border: 0;
    border-radius: 3px;
    font-weight: bold;
    svg {
      order: 0;
      flex: 1 1 auto;
      align-self: auto;
      max-width: 3rem;
      margin: 1rem auto 0 auto;
      color: ${(props) => (props.highlight ? 'var(--bs-primary)' : 'var(--bs-body-color)')};
      fill: ${(props) => (props.highlight ? 'var(--bs-primary)' : 'var(--bs-body-color)')};
      g,
      g path {
        color: ${(props) => (props.highlight ? 'var(--bs-primary)' : 'var(--bs-body-color)')} !important;
        fill: ${(props) => (props.highlight ? 'var(--bs-primary)' : 'var(--bs-body-color)')} !important;
      }
    }
    span {
      margin: 0 1rem 2rem 1rem;
      order: 0;
      flex: 0 1 auto;
      align-self: auto;
    }
  }
  &:hover {
    color: ${(props) => (props.highlight ? 'var(--bs-primary)' : 'var(--bs-body-color)')};
    fill: ${(props) => (props.highlight ? 'var(--bs-primary)' : 'var(--bs-body-color)')};
  }
`;

export default SquareButton;
