import { FieldAttributes, useField } from 'formik';

import { FunctionComponent } from 'react';
import { isNumber } from 'lodash';
import styled from 'styled-components';

const Select = styled.select`
  border: 0;
  background: transparent;
  font-size: 0.75rem;
  -webkit-appearance: none;
  outline-color: rgba(0, 0, 0, 0);
`;

const ERROR_TEXT = 'Bitte wählen Sie einen Eintrag aus.';

// export const ToggleInputBooleanValueYes = 'true';
// export const ToggleInputBooleanValueNo = 'false';
// export const ToggleInputBooleanValueUndefined = undefined;

const SelectInput: FunctionComponent<
  FieldAttributes<any> & {
    valueType: undefined | 'number' | 'text' | 'boolean';
    disabled?: boolean;
    onValueSelected?: (value: string | number | boolean | undefined) => void;
  }
> = ({ children, valueType, disabled, onValueSelected, ...props }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(props);
  return (
    <Select
      disabled={disabled}
      {...field}
      {...props}
      onChange={(v) => {
        let value = undefined;
        let error: string | undefined = ERROR_TEXT;

        if (valueType === 'text') {
          value = v.target.value;
          error = !(value && value.length > 0) ? ERROR_TEXT : undefined;
        } else if (isNumber(v.target.value) || isNumber(Number(v.target.value))) {
          value = Number(v.target.value);
          error = value > -1 ? undefined : ERROR_TEXT;
        }

        helpers.setTouched(true);
        helpers.setValue(value, true);
        helpers.setError(error);

        onValueSelected && onValueSelected(value);
      }}
    >
      {children}
    </Select>
  );
};

export default SelectInput;
