import { EmptyTestTask, TestTask } from './TestTask';
import { NewType, UpdateType } from './removeDates';

import { TestValue } from './TestValue';

export interface TestValueGroup {
  id: number;
  name: string;
  testTask: TestTask;
  testValues: TestValue[];
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export type NewTestValueGroup = NewType<TestValueGroup, 'name'> & { testTask: number };

export type UpdateTestValueGroup = UpdateType<TestValueGroup, 'testValues' | 'name'>;

export const EmptyNewTestValueGroup: NewTestValueGroup = {
  name: '',
  testTask: 0,
  testValues: [],
};

export const EmptyTestValueGroup: TestValueGroup = {
  id: 0,
  name: '',
  testTask: EmptyTestTask,
  testValues: [],
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
};
