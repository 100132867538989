import Table, { TableColProps } from '../../../../components/table';

import { FunctionComponent } from 'react';
import { TestValue } from '../../../../interfaces/TestValue';
import { TestValueThreshold } from '../../../../interfaces/TestValueThreshold';
import formatThresholdType from '../../../../helpers/format/thresholdType';
import { useParams } from 'react-router-dom';

interface TestValeThresholdListProps {
  item: TestValue;
  loading: boolean;
}

const columns: TableColProps<TestValueThreshold>[] = [
  { title: 'Reihenfolge', values: 'prio' },
  { title: 'Beschreibung', values: 'description' },
  {
    title: 'Test n.i.O.',
    values: (item) => {
      return item.triggersFailure ? 'Ja' : 'Nein';
    },
  },
  { title: 'Art', values: (item) => formatThresholdType(item.thresholdType) },
  { title: 'Wert', values: 'thresholdValue' },
];

const TestValeThresholdList: FunctionComponent<TestValeThresholdListProps> = ({ loading, item }) => {
  const params = useParams();
  return (
    <Table
      loading={loading}
      items={item.thresholds.sort((a, b) => b.prio - a.prio)}
      columns={columns}
      navigatePrefix={`/settings/tasks/${params.id}/test-value-groups/${params.tgid}/test-values/${params.tvid}/thresholds/`}
    />
  );
};

export default TestValeThresholdList;
