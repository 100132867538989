import { FunctionComponent, useCallback, useState } from 'react';
import { Step, Wizard } from '../../components/wizard';

import { ButtonWithIcon } from '../../components/buttons/ButtonWithIcon';
import InputGroup from '../../components/form/InputGroup';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { Location } from '../../interfaces/Location';
import { LocationService } from '../../services';
import { Modal } from 'react-bootstrap';
import { ReactComponent as SearchIcon } from './search.svg';
import { debounce } from 'lodash';
import styled from 'styled-components';
import { useAddError } from '../../context/error';
import { useNavigate } from 'react-router-dom';

const Input = styled.input`
  border: 0;
  background: transparent;
  font-size: 0.75rem;
  -webkit-appearance: none;
  outline-color: rgba(0, 0, 0, 0);
`;

const P = styled.p<{ small: boolean }>`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  color: ${(props) => (props.small ? '#b3b3b3' : '#000')};
`;

// const ButtonWithIcon = styled(Button)`
//   float: left;
//   color: #a1a1a1;
//   opacity: 1;
//   display: block;
//   width: 100%;
//   text-align: left;
//   padding-left: 52px;
//   svg {
//     width: 14px;
//     height: 14px;
//     display: inline-block;
//     margin: 11px 0 11px 11px;
//     &,
//     path,
//     g {
//       fill: #a1a1a1;
//       color: #a1a1a1;
//     }
//   }
//   span {
//     display: inline-block;
//     vertical-align: bottom;
//     margin-bottom: 1px;
//   }
//   &:disabled,
//   &.disabled {
//     cursor: not-allowed;
//     opacity: 0.5;
//     color: #a1a1a1 !important;
//   }
//   &:hover {
//     color: var(--bs-primary);
//     opacity: 0.8;
//     &,
//     span p,
//     path,
//     g {
//       fill: var(--bs-primary);
//       color: var(--bs-primary);
//     }
//   }
// `;

const Scroll = styled.div`
  overflow-y: auto;
  max-height: 200px;
  min-height: 200px;
  width: 100%;
`;

const SelectLocationModal: FunctionComponent = () => {
  const addError = useAddError();

  const navigate = useNavigate();
  const [items, setItems] = useState<Location[]>();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceLoadData = useCallback(
    debounce((term: string) => {
      const loadData = async () => {
        try {
          setLoading(true);
          const { items } = await LocationService(addError).search(term);
          setItems(items);
        } catch (error) {
        } finally {
          setLoading(false);
        }
      };
      loadData();
    }, 400),
    [addError],
  );

  return (
    <Modal size="lg" centered show={true}>
      <Wizard showHeader={false} step={0} onClose={() => navigate('/examinees/new')}>
        <Step title={'Standort auswählen'} description="" valid={true} padding="0" alignItems="flex-start">
          <InputGroup icon={<SearchIcon />} name="searchTerm" variant="gray-200">
            <Input
              disabled={false}
              type="text"
              name="searchTerm"
              onChange={(event) => {
                const { value } = event.target;
                debounceLoadData(value);
              }}
            />
          </InputGroup>
          {loading && <LoadingIndicator size={40} />}
          <Scroll>
            {(items ?? []).map((location) => {
              return (
                <ButtonWithIcon
                  key={location.id}
                  variant="link"
                  onClick={() => {
                    const a = JSON.parse(window.localStorage.getItem('examinees-new-form') ?? '{}');
                    a.location = location.id;
                    window.localStorage.setItem('examinees-new-form', JSON.stringify(a));
                    navigate('/examinees/new');
                  }}
                >
                  <span>
                    <P small={false}>
                      [{location.tag}] {location.name}
                    </P>
                    <P small={true}>
                      {location.address.street}, {location.address.postalCode} {location.address.city}
                    </P>
                  </span>
                  {/* <ArrowIcon /> */}
                </ButtonWithIcon>
              );
            })}
          </Scroll>
        </Step>
      </Wizard>
    </Modal>
  );
};

export default SelectLocationModal;
