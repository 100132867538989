import OfflineList from '../offline/OfflineList';
import MainLayout from '../../layouts/MainLayout';
import RequireAuth from '../../guards/RequireAuth';
import { Route } from 'react-router';

const Routes = (
  <Route
    path="/offline"
    element={
      <RequireAuth>
        <MainLayout />
      </RequireAuth>
    }
  >
    <Route path="/offline" element={<OfflineList />} />
  </Route>
);

export default Routes;
