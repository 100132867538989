import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './date.style.scss';

import React, { FunctionComponent, useMemo } from 'react';

import * as moment from 'moment';
import 'moment/locale/de';

import { DR } from './date';
import styled from 'styled-components';

interface FixedDateProps {
  unit: moment.unitOfTime.StartOf;
  offset: moment.DurationInputArg1;
  offsetUnit: moment.unitOfTime.DurationConstructor;
  value: DR;
  onClick: (value: DR) => void;
  children: React.ReactNode;
}

const Button = styled.button<{
  active: boolean;
}>`
  background: #86cba4;
  border: 1px solid #81c39e;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 0.375rem;
  margin: 0.375rem 0;
  &:hover {
    background: #009842;
    border-color: #006f30;
  }
  &:focus {
    outline: none;
  }
  ${(props) =>
    props.active
      ? `
    background: #009842;
    border-color: #006f30;
    `
      : ''}
`;

const FixedDate: FunctionComponent<FixedDateProps> = ({ unit, offset, offsetUnit, value, onClick, children }) => {
  const range = useMemo(
    () => ({
      startDate: moment.default().startOf(unit).subtract(offset, offsetUnit),
      endDate: moment.default().endOf(unit).subtract(offset, offsetUnit),
    }),
    [offset, offsetUnit, unit],
  );

  const isActive = useMemo(() => {
    if (value.startDate && value.endDate) {
      return value.startDate.isSame(range.startDate, 'day') && value.endDate.isSame(range.endDate, 'day');
    }
    return false;
  }, [range, value]);

  const submit = () => {
    onClick(range);
  };

  return (
    <Button active={isActive} onClick={submit}>
      {children}
    </Button>
  );
};

export default FixedDate;
