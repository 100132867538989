import { Formik, FormikHelpers } from 'formik';
import { get, reduce } from 'lodash';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ErrorLevel, useAddError, useClearError } from '../../context/error';
import { EmptyUser, User } from '../../interfaces/User';

import qs from 'query-string';
import { Row } from 'react-bootstrap';
import RoleView from '../../components/RoleView';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import FormikDebug from '../../components/dev/formik-debug';
import BareInput from '../../components/form/BareInput';
import InputGroup from '../../components/form/InputGroup';
import InputSection from '../../components/form/InputSection';
import SelectInput from '../../components/form/SelectInput';
import Title from '../../components/title';
import { validateEmail } from '../../helpers/validate/validateEmail';
import validateName from '../../helpers/validate/validateName';
import { UserService } from '../../services';

import ChangePasswordButton from '../../components/auth/ChangePasswordButton';
import { useAuth } from '../../context/auth';

const getChangedValues = (values: User, initialValues: User): Partial<User> => {
  return reduce(
    Object.entries(values),
    (acc, [key, value]) => {
      const hasChanged = (initialValues as any)[key] !== value;
      if (hasChanged) {
        (acc as any)[key] = value;
      }
      return acc;
    },
    {},
  );
};

const shouldEditing = (location: Location) => {
  const queryParams = qs.parse(location.search, {});
  return queryParams.edit === '1' || queryParams.edit === 'true';
};

const Show: FunctionComponent = () => {
  const auth = useAuth();
  const imo = useMemo(() => auth.isImpersonating(), [auth]);

  const addError = useAddError();
  const clearError = useClearError();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(shouldEditing(location));

  const [item, setItem] = useState<User>(EmptyUser);

  useEffect(() => {
    const loadData = async () => {
      try {
        const _item = await UserService(addError).get('me');
        if (!_item || !_item.id) {
          navigate('/dashboard');
        }
        setItem(_item);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, navigate, params.id]);

  const onSubmit = async (values: User, { setSubmitting }: FormikHelpers<User>) => {
    setSubmitting(true);
    try {
      clearError();
      const data = getChangedValues(values, item);
      await UserService(addError).update('me', data);
      setEditing(false);
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  if (imo) {
    return <Alert variant={'danger'}>This function is not available if impersionate is activated.</Alert>;
  }

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isValidating, isValid, errors, resetForm, isSubmitting, setSubmitting }) => (
        <>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Title
              onEdit={() => setEditing(true)}
              onCancel={() => {
                resetForm({
                  values: item,
                });
                setEditing(false);
              }}
              editing={editing}
              editingValid={!isValidating && isValid}
              isSubmitting={isSubmitting}
              title="Mein Benutzerkonto"
            />
            <Row>
              <InputSection title="Stammdaten" xs={12} xl={6}>
                <InputGroup error={errors.name} valid={editing ? !errors.name : undefined} title="Name" name="name">
                  <BareInput disabled={!editing || isSubmitting} type="text" name="name" validate={validateName} />
                </InputGroup>
                <InputGroup
                  error={errors.company}
                  valid={editing ? !errors.company : undefined}
                  title="Unternehmen"
                  name="company"
                >
                  <BareInput disabled={!editing || isSubmitting} type="text" name="company" />
                </InputGroup>
                <InputGroup
                  error={errors.email}
                  valid={editing ? !errors.email : undefined}
                  title="E-Mail"
                  name="email"
                >
                  <BareInput disabled={!editing || isSubmitting} type="email" name="email" validate={validateEmail} />
                </InputGroup>
              </InputSection>
              <InputSection title="Voreinstellungen" xs={12} xl={6}>
                <InputGroup
                  error={errors.itemsPerPage}
                  valid={editing ? !errors.itemsPerPage : undefined}
                  title="Zeilen pro Seite"
                  name="itemsPerPage"
                >
                  <SelectInput valueType="number" name="itemsPerPage" disabled={!editing || isSubmitting}>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </SelectInput>
                </InputGroup>
              </InputSection>
              <InputSection title="Rechte" xs={12} xl={6}>
                <InputGroup title="Rolle" name="role">
                  <SelectInput name="role" disabled={true}>
                    <option value="ADMINISTRATOR">
                      <RoleView role={'ADMINISTRATOR'} />
                    </option>
                    <option value="MANAGER">
                      <RoleView role={'MANAGER'} />
                    </option>
                    <option value="STANDARD">
                      <RoleView role={'STANDARD'} />
                    </option>
                    <option value="VIEWER">
                      <RoleView role={'VIEWER'} />
                    </option>
                  </SelectInput>
                </InputGroup>
              </InputSection>
              <InputSection title="Sicherheit" xs={12} xl={6}>
                <InputGroup title="Password" name="password">
                  <ChangePasswordButton />
                </InputGroup>
              </InputSection>
            </Row>
          </form>
          <FormikDebug />
        </>
      )}
    </Formik>
  );
};

export default Show;
