import { UpdateType } from './removeDates';

export interface Preference {
  id: number;
  key: string;
  description: string;
  value: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export type UpdatePreference = UpdateType<Preference, 'value'>;

export const EmptyPreference: Preference = {
  id: 0,
  key: '',
  value: '',
  description: '',
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
};
