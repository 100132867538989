import { get } from 'lodash';
import { useAuth } from '../context/auth';
import { User } from '../interfaces/User';
import { Leaves } from '../interfaces/keyof-of-a-nested-object';

export default function RequireTrue({
  children,
  k,
  noTesterChildren = null,
}: {
  children: JSX.Element;
  k: Leaves<User>;
  noTesterChildren?: JSX.Element | null;
}) {
  const auth = useAuth();

  if (!auth.user) {
    return <></>;
  }

  const value = get(auth.user, k);
  const isTrue = value === true || value === 'true';

  if (isTrue) {
    return children;
  } else {
    return noTesterChildren;
  }
}
