import Form from './Form';
import PublicLayout from '../../layouts/PublicLayout';
import RequireNoAuth from '../../guards/RequireNoAuth';
import { Route } from 'react-router';
import Show from './Show';

const Routes = (
  <Route path="/" element={<PublicLayout />}>
    <Route
      path="/"
      element={
        <RequireNoAuth>
          <Form />
        </RequireNoAuth>
      }
    />
    <Route path="/:tag" element={<Show />} />
  </Route>
);

export default Routes;
