import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { FilterType } from '../../../services/Service';
import { Test } from '../../../interfaces/Test';
import { TestService } from '../../../services';
import { useAddError } from '../../../context/error';
import TestList from './TestList';
import { useSearchParams } from 'react-router-dom';
import { cleanUp, removeKeysWithUndefindedValue } from '../../table/tools';

interface TestsProps {
  location?: number;
  examinee?: number;
}

const Tests: FunctionComponent<TestsProps> = ({ location, examinee }) => {
  const addError = useAddError();
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<Test[]>([]);

  const [pagination, setPagination] = useState({ page: 0, limit: 25 });
  const [count, setCount] = useState<number>(0);

  const [search, setSearch] = useSearchParams();
  const [columnFilters, _setColumnFilters] = useState<Record<string, (number | string | boolean | [Date, Date])[]>>(
    JSON.parse(
      search.get('filter') &&
        search.get('filter') !== undefined &&
        search.get('filter') !== null &&
        search.get('filter') !== 'undefined' &&
        search.get('filter') !== 'null'
        ? (search.get('filter') as string)
        : '{}',
    ),
  );

  const setColumnFilters = (cf: Record<string, (number | string | boolean | [Date, Date])[]>) => {
    _setColumnFilters(cf);
    const acf = cleanUp(cf);
    const e = removeKeysWithUndefindedValue({
      ...search,
      filter: JSON.stringify(acf),
    });
    setSearch(e);
  };

  const buildFilter = useCallback(() => {
    const filter: FilterType<Test> = {
      'examinee.location.id': location,
      'examinee.id': examinee,
      ...columnFilters,
    };
    return filter;
  }, [columnFilters, location, examinee]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const filter = buildFilter();
        const { items, count } = await TestService(addError).list<Test>(pagination.limit, pagination.page, filter);
        setItems(items);
        setCount(count);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, buildFilter, columnFilters, pagination.limit, pagination.page]);

  return (
    <TestList
      showExamineeTag={examinee === undefined}
      loading={loading}
      items={items}
      columnFilters={columnFilters}
      onFilterChanged={setColumnFilters}
      onPagination={(page, limit) => setPagination({ page, limit })}
      pages={Math.ceil(count / pagination.limit)}
    />
  );
};

export default Tests;
