import { FunctionComponent } from 'react';
import Table, { TableColProps } from '../../table';

import styled from 'styled-components';
import { Test } from '../../../interfaces/Test';
import {
  CustomerService,
  ExamineeTypesService,
  LocationService,
  TestTaskService,
  TesterService,
} from '../../../services';

interface TestListProps {
  items: Test[];
  loading: boolean;
  onPagination?: (page: number, size: number) => void;
  pages?: number;
  columnFilters?: Record<string, (number | string | boolean | [Date, Date])[]>;
  onFilterChanged?: (f: Record<string, (number | string | boolean | [Date, Date])[]>) => void;
  showExamineeTag?: boolean;
  onClick?: (item: Test) => void;
}

const ResultColored = styled.span<{ result: string }>`
  color: ${(props) => (props.result === 'true' ? 'var(--bs-primary)' : 'var(--bs-red)')};
`;

const P = styled.p<{ small: boolean }>`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  color: ${(props) => (props.small ? '#b3b3b3' : '#000')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
`;

export const columns: (ext: boolean) => TableColProps<Test>[] = (ext = false) => [
  ...(ext
    ? ([
        { title: 'Prüfling', width: 1, values: 'examinee.tag' },
        {
          title: 'Standort',
          width: 2,
          key: 'examinee.location.id',
          filterKey: 'examinee.location.id',
          values: (item) => {
            return (
              <>
                <P small={false}>
                  [{item.examinee?.location?.tag}] {item.examinee?.location?.name}
                </P>
                <P small={true}>
                  {item.examinee?.location?.address?.postalCode} {item.examinee?.location?.address?.city}
                </P>
              </>
            );
          },
          filterable: (addError, limit, page, query) =>
            (query && query.length > 0
              ? LocationService(addError)
                  .search(query)
                  .then((res) => res.items)
              : LocationService(addError)
                  .list(limit, page)
                  .then((res) => res.items)
            ).then((i) => i.map((v) => ({ value: v.id, title: `[${v.tag}] ${v.name}, ${v.address.city}` }))),
        },
        {
          title: 'Kunde (ID aus safeplan)',
          width: 2,
          values: 'examinee.location.customer_sp_id',
          filterKey: 'examinee.location.customer_sp_id',
          key: 'examinee.location.customer_sp_id',
          filterable: (addError, limit, page, query) =>
            (query && query.length > 0
              ? CustomerService(addError)
                  .search(query)
                  .then((res) => res.items)
              : CustomerService(addError)
                  .list(limit, page)
                  .then((res) => res.items)
            ).then((i) => i.map((v) => ({ value: v._id, title: `${v.name} (${v._id})` }))),
        },
        {
          title: 'Prüflingsart',
          width: 1,
          values: 'examinee.type.name',
          key: 'examinee.type.id',
          filterable: (addError, limit, page, query) =>
            (query && query.length > 0
              ? ExamineeTypesService(addError)
                  .search(query)
                  .then((res) => res.items)
              : ExamineeTypesService(addError)
                  .list(limit, page)
                  .then((res) => res.items)
            ).then((i) => i.map((v) => ({ value: v.id, title: `${v.name}` }))),
        },
      ] as TableColProps<Test>[])
    : ([] as TableColProps<Test>[])),
  {
    title: 'Prüfart',
    width: 1,
    values: 'testTask.initials',
    key: 'testTask.id',
    filterable: (addError, limit, page, query) =>
      (query && query.length > 0
        ? TestTaskService(addError)
            .search(query)
            .then((res) => res.items)
        : TestTaskService(addError)
            .list(limit, page)
            .then((res) => res.items)
      ).then((i) => i.map((v) => ({ value: v.id, title: `${v.name} (${v.initials})` }))),
  },
  {
    title: 'Datum',
    width: 1,
    type: 'date',
    filterKey: 'created_at',
    key: 'created_at',
    filterable: true,
    values: (item: Test) => {
      return new Date(item.created_at).toLocaleDateString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },
  },
  {
    title: 'Prüfer',
    width: 1,
    values: 'tester.initials',
    key: 'tester.id',
    filterable: (addError, limit, page, query) => {
      return (
        query && query.length > 0
          ? TesterService(addError)
              .search(query)
              .then((res) => res.items)
          : TesterService(addError)
              .list(limit, page)
              .then((res) => res.items)
      ).then((i) => i.map((v) => ({ value: v.id, title: v.user.name })));
    },
  },
  {
    title: 'Bemerkung',
    width: 1,
    values: 'comment',
  },
  {
    title: 'Bestanden',
    width: 1,
    values: (item: Test) => {
      return <ResultColored result={item.result?.toString() ?? ''}>{item.result ? 'Ja' : 'Nein'}</ResultColored>;
    },
    key: 'result',
    type: 'boolean',
    filterable: true,
  },
];

const TestList: FunctionComponent<TestListProps> = ({
  items,
  loading,
  pages,
  onPagination,
  columnFilters,
  onFilterChanged,
  showExamineeTag,
  onClick,
}) => {
  return (
    <Table
      navigatePrefix="/tests/"
      loading={loading}
      items={items}
      columns={columns(showExamineeTag ?? true)}
      pages={pages}
      onPagination={onPagination}
      columnFilters={columnFilters}
      onFilterChanged={onFilterChanged}
      onClick={onClick}
    />
  );
};

export default TestList;
