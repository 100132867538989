import { FunctionComponent, useEffect, useState } from 'react';
import Table, { TableColProps } from '../../components/table';

import styled from 'styled-components';
import { useAddError } from '../../context/error';
import OfflineService from '../../services/OfflineService';
import { LocalExamineeNoLocationPossible } from '../../storage';

const P = styled.p<{ small: boolean }>`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  color: ${(props) => (props.small ? '#b3b3b3' : '#000')};
`;

const columns: TableColProps<LocalExamineeNoLocationPossible>[] = [
  { title: 'Eindeutige Nummer', values: 'tag' },
  {
    title: 'Standort',
    values: (item: LocalExamineeNoLocationPossible) => {
      if (!item.location) return <>[Offline nicht verfügbar]</>;
      return (
        <span>
          <P small={false}>
            [{item.location!.tag}] {item.location!.name}
          </P>
          <P small={true}>
            {item.location!.address.street}, {item.location!.address.postalCode} {item.location!.address.city}
          </P>
        </span>
      );
    },
    key: 'location.id',
  },
  {
    title: 'Hersteller',
    key: 'vendor.id',
    values: 'vendor.name',
  },
  {
    title: 'Prüflingsart',
    values: 'type.name',
    key: 'type.id',
  },
  {
    title: 'Prüflingsgattung',
    values: 'type.family.name',
    key: 'type.family.id',
  },
];

const ExamineeList: FunctionComponent = () => {
  const [loading, setLoading] = useState(true);

  const [items, setItems] = useState<LocalExamineeNoLocationPossible[]>([]);
  const addError = useAddError();

  const loadData = async () => {
    try {
      const { items } = await OfflineService().localExaminees();
      setItems(items);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [addError]);

  return <Table loading={loading} items={items} columns={columns} />;
};

export default ExamineeList;
