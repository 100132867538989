/* eslint-disable @typescript-eslint/no-empty-function */
import './App.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthProvider, useAuth } from '../context/auth';
import AuthRoute from '../routes/auth';
import Dashboard from '../routes/dashboard';
import { ErrorProvider } from '../context/error';
import Errors from '../components/errors';
import ApiKeysRoutes from '../routes/api-keys';
import ExamineeFamiliesRoutes from '../routes/examinee-families';
import ExamineeTypesRoutes from '../routes/examinee-types';
import ExamineesRoute from '../routes/examinees';
import InstallationSitesRoutes from '../routes/installation-sites';
import { MainProvider } from '../context/main';
import PaperRoute from '../routes/paper';
import PublicRoute from '../routes/public';
import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import SettingsRoutes from '../routes/settings';
import SystemRoute from '../routes/system';
import ErrorsRoute from '../routes/errors';
import TasksRoute from '../routes/tasks';
import TestersRoute from '../routes/testers';
import TestingDevicesRoutes from '../routes/testing-devices';
import TestsRoutes from '../routes/tests';
import ToursRoute from '../routes/tours';
import UserRoute from '../routes/user';
import UsersRoute from '../routes/users';
import VendorsRoute from '../routes/vendors';
import AnalyticsPerformanceRoute from '../routes/analytics';
import LocationsRoute from '../routes/locations';
import PreferencesRoute from '../routes/preferences';
import ImportRoute from '../routes/import';
import OfflineRoute from '../routes/offline';
import { useServiceWorker } from '../useServiceWorker';
import styled from 'styled-components';
import WatchLocationOffline from '../components/watchOffline/watchLocationOffline';
import { datadogRum } from '@datadog/browser-rum';

import { offline } from '../offline';
import { UserService } from '../services';
import { User } from '../interfaces/User';
import Impersonate from '../components/Impersonate';
import { ApmRoutes } from '@elastic/apm-rum-react';

const Button = styled.p`
  color: #009842;
  font-size: 1em;
  margin: 0.5em 0 0 0;
  user-select: none;
  cursor: pointer;
`;

const Offline = React.memo(() => {
  const auth = useAuth();
  const [timer, setTimer] = React.useState<any>();

  useEffect(() => {
    if (auth.user) {
      const user = auth.user as User;

      console.log('syncing preferences');
      UserService(() => {}).syncPreferences();

      if (user.tester && process.env.NODE_ENV === 'production') {
        offline();
        setTimer(setInterval(offline, 1000 * 60 * 5)); // alle 5 Minuten
        datadogRum.setGlobalContextProperty('offline mode', 'activated');
      } else if (user.tester) {
        console.log('offline mode deactivated - development mode');
        datadogRum.setGlobalContextProperty('offline mode', 'development mode');
        timer && clearInterval(timer);
      } else if (process.env.NODE_ENV === 'production') {
        console.log('offline mode deactivated - no tester');
        datadogRum.setGlobalContextProperty('offline mode', 'no tester');
        timer && clearInterval(timer);
      }
    }

    return () => {
      timer && clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user]);

  return <></>;
});

function App() {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  useEffect(() => {
    if (showReload && waitingWorker) {
      toast(
        () => (
          <div>
            A new version of this page is available
            <Button onClick={() => reloadPage()}>Refresh</Button>
          </div>
        ),
        {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          hideProgressBar: true,
          pauseOnHover: false,
          position: 'bottom-left',
        },
      );
    }
  }, [waitingWorker, showReload, reloadPage]);

  return (
    <ErrorProvider>
      <ReactTooltip />
      <AuthProvider>
        <Offline />
        <ToastContainer />
        <Impersonate />
        <MainProvider>
          <ApmRoutes>
            {ErrorsRoute}
            {ApiKeysRoutes}
            {AnalyticsPerformanceRoute}
            {AuthRoute}
            {PublicRoute}
            {Dashboard}
            {TestsRoutes}
            {ExamineesRoute}
            {ExamineeFamiliesRoutes}
            {ExamineeTypesRoutes}
            {InstallationSitesRoutes}
            {LocationsRoute}
            {SettingsRoutes}
            {TasksRoute}
            {TestingDevicesRoutes}
            {TestersRoute}
            {UserRoute}
            {UsersRoute}
            {VendorsRoute}
            {ToursRoute}
            {SystemRoute}
            {PreferencesRoute}
            {OfflineRoute}
            {ImportRoute}
            {PaperRoute}
          </ApmRoutes>
          <WatchLocationOffline />
          <Errors />
        </MainProvider>
      </AuthProvider>
    </ErrorProvider>
  );
}

export default App;
