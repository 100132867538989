import CenterLayout from '../../layouts/CenterLayout';
import Login from './Login';
import React from 'react';
import RequireNoAuth from '../../guards/RequireNoAuth';
import Reset from './Reset';
import { Route } from 'react-router';

const Routes = (
  <Route path="auth" element={<CenterLayout />}>
    <Route
      path="login"
      element={
        <RequireNoAuth>
          <Login />
        </RequireNoAuth>
      }
    />
    <Route
      path="reset"
      element={
        <RequireNoAuth>
          <Reset />
        </RequireNoAuth>
      }
    />
  </Route>
);

export default Routes;
