import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';

import BareInput from '../../components/form/BareInput';
import { Formik } from 'formik';
import { FunctionComponent } from 'react';
import InputGroup from './InputGroup';
import { ReactComponent as UserIcon } from './UserIcon.svg';
import styled from 'styled-components';
import { useAuth } from '../../context/auth';

const LoginContent = styled.div`
  width: 25%;
`;

const FullWidthButton = styled.button`
  color: #fff;
  background-color: #009842;
  border-color: #009842;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  display: block;
  width: 100%;
  height: auto;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border: 1px solid transparent;
  user-select: none;
  text-align: center;
  vertical-align: middle;
  font-weight: 400;
  &:not(:disabled) {
    user-select: none;
cursor: pointer;
  }
  &:disabled {
    opacity: 0.5;
  }
}
`;

const Link = styled(NavLink)`
  padding: 0.75rem 0 0.75rem 0.5rem;
  float: right;
  margin: 0;
  height: 100%;
  display: block;
  font-weight: 700;
  font-size: 1rem;
  color: #86cba4;
  padding-bottom: 16px;
  border-bottom: 2px solid rgba(1, 1, 1, 0);
  transition: color 0.22s ease 0s;
  text-decoration: none;
  background-color: initial;
  svg {
    fill: #86cba4;
  }
  &.active,
  &:hover {
    // border-bottom: 2px solid #009842;
    color: #009842;
    svg {
      fill: #009842;
    }
  }
`;

const Reset: FunctionComponent = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [search] = useSearchParams();

  return (
    <LoginContent>
      <h3>Passwort zurücksetzen</h3>
      <p>Geben Sie die mit Ihrem Konto assoziierte E-Mail ein und wir senden Ihnen neues Passwort zu.</p>
      <Formik
        initialValues={{ email: search.get('email') ?? '' }}
        validate={(values) => {
          const errors: any = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Bitte geben Sie eine gültige E-Mail-Adresse ein';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          setSubmitting(true);
          setTimeout(() => {
            auth
              .resetPassword(values.email)
              .then(() => {
                setSubmitting(false);
                navigate('/auth/login');
              })
              .catch((e) => {
                const { message, response } = e;
                const { status } = response ?? { status: 500 };
                if (status === 404) {
                  setFieldError('email', 'Benutzer wurde nicht gefunden.');
                } else {
                  setFieldError('email', message);
                }
                setSubmitting(false);
              });
          }, 1000);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <InputGroup icon={<UserIcon />} error={errors.email && touched.email ? errors.email : undefined}>
              <BareInput
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                autoComplete="username"
              />
            </InputGroup>

            <FullWidthButton type="submit" disabled={isSubmitting}>
              Weiter
            </FullWidthButton>
            <Link to={`/auth/login`}>Zurück zur Anmeldung</Link>
          </form>
        )}
      </Formik>
    </LoginContent>
  );
};

export default Reset;
