import { FunctionComponent } from 'react';

import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import LoadingIndicator from '../../components/common/LoadingIndicator';

const Wrapper = styled.div`
  padding: 1rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  color: #000;
  height: 14rem;
`;

const Text = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  margin-top: 8rem;
`;

interface SyncModalProps {
  status?: string;
}

const SyncModal: FunctionComponent<SyncModalProps> = ({ status }) => {
  return (
    <Modal size="sm" centered show={true}>
      <Wrapper>
        <LoadingIndicator />
        <Text>{status ?? 'Upload läuft...'}</Text>
      </Wrapper>
    </Modal>
  );
};

export default SyncModal;
