import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './date.style.scss';
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';

import { FunctionComponent, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ReactConfirmProps, confirmable, createConfirmation } from 'react-confirm';

import styled from 'styled-components';

import * as moment from 'moment';
import 'moment/locale/de';
import FixedDate from './FixedDate';

// console.log(moment.locale()); // de

interface DateFilterProps extends ReactConfirmProps {
  cancelLabel: string;
  clearLabel: string;
  proceedLabel: string;
  title: string;
  show: boolean;
  preSelected: [Date | null, Date | null];
  proceed: (value?: string) => void;
}

const Content = styled(Modal.Body)`
  padding: 0;
  background: #fff;
  display: flex;
  justify-content: center;
  min-height: 338px;
`;

const FixedDates = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 32px;
  border-left: 1px solid #f2f2f2;
`;

const ClearButton = styled(Button)`
  color: rgb(84, 84, 84);
`;

export type DR = {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
};

const DateFilter: FunctionComponent<DateFilterProps> = ({ title, preSelected, show, proceed }) => {
  const locale = 'de';

  const [selected, setSelected] = useState<DR>({
    startDate: preSelected[0] ? moment.default(preSelected[0]) : null,
    endDate: preSelected[1] ? moment.default(preSelected[1]) : null,
  });

  const onSubmit = () => {
    const dates = [selected.startDate?.startOf('day').toDate(), selected.endDate?.endOf('day').toDate()];
    proceed(JSON.stringify(dates));
  };

  const [focusedInput, setFocusedInput] = useState<FocusedInputShape>('startDate');
  const onFocusChange = (focusedInput: FocusedInputShape | null) => {
    setFocusedInput(!focusedInput ? 'startDate' : focusedInput);
  };

  // const setFixedDate = (
  //   unit: moment.unitOfTime.StartOf,
  //   offset: moment.DurationInputArg1,
  //   offsetUnit: moment.unitOfTime.DurationConstructor,
  // ) => {
  //   const startDate = moment.default().startOf(unit).subtract(offset, offsetUnit);
  //   const endDate = moment.default().endOf(unit).subtract(offset, offsetUnit);
  //   setSelected({ startDate, endDate });
  // };

  const [isAwefulWorkaroundRequired, setAwefulWorkaroundRequired] = useState(false);

  useEffect(() => {
    moment.locale(locale);
    setAwefulWorkaroundRequired(true);
    setTimeout(() => setAwefulWorkaroundRequired(false), 0);
  }, [locale]);

  if (isAwefulWorkaroundRequired) {
    return null;
  }

  return (
    <div className="static-modal">
      <Modal show={show} onHide={() => proceed(undefined)} backdrop={true} keyboard={true}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Content>
          <DayPickerRangeController
            noBorder
            hideKeyboardShortcutsPanel
            onDatesChange={(d) => {
              setSelected(d);
            }}
            focusedInput={focusedInput}
            onFocusChange={onFocusChange}
            startDate={selected.startDate}
            endDate={selected.endDate}
            initialVisibleMonth={null}
            enableOutsideDays={true}
            numberOfMonths={1}
            minimumNights={0}
          />
          <FixedDates>
            <FixedDate value={selected} unit="month" offset={1} offsetUnit="months" onClick={(d) => setSelected(d)}>
              Letztes Jahr
            </FixedDate>
            <FixedDate value={selected} unit="month" offset={1} offsetUnit="months" onClick={(d) => setSelected(d)}>
              Letzter Monat
            </FixedDate>
            <FixedDate value={selected} unit="week" offset={1} offsetUnit="weeks" onClick={(d) => setSelected(d)}>
              Letzte Woche
            </FixedDate>
            <FixedDate value={selected} unit="day" offset={1} offsetUnit="days" onClick={(d) => setSelected(d)}>
              Gestern
            </FixedDate>
            <FixedDate value={selected} unit="day" offset={0} offsetUnit="days" onClick={(d) => setSelected(d)}>
              Heute
            </FixedDate>
            <FixedDate value={selected} unit="week" offset={0} offsetUnit="weeks" onClick={(d) => setSelected(d)}>
              Diese Woche
            </FixedDate>
            <FixedDate value={selected} unit="month" offset={0} offsetUnit="months" onClick={(d) => setSelected(d)}>
              Dieser Monat
            </FixedDate>
            <FixedDate value={selected} unit="year" offset={0} offsetUnit="years" onClick={(d) => setSelected(d)}>
              Dieses Jahr
            </FixedDate>
          </FixedDates>
        </Content>
        <Modal.Footer>
          <ClearButton variant="light" onClick={() => proceed('[]')}>
            Zurücksetzen
          </ClearButton>
          <Button variant="light" onClick={() => proceed(undefined)}>
            Schließen
          </Button>
          <Button variant="primary" onClick={() => onSubmit()}>
            Anwenden
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export function dateFilter(title: string, preSelected: [Date, Date]) {
  return createConfirmation(confirmable(DateFilter))({
    title,
    preSelected,
  });
}
