import 'react-bootstrap-typeahead/css/Typeahead.css';

import { FieldAttributes, useField } from 'formik';
import { FunctionComponent, useState } from 'react';
import { Option, TypeaheadPropsAndState } from 'react-bootstrap-typeahead/types/types';

import LoadingIndicator from '../common/LoadingIndicator';
import { Typeahead as _Typeahead } from 'react-bootstrap-typeahead';
import styled from 'styled-components';

const Typeahead = styled(_Typeahead)`
  display: block;
  width: 100%;
  border: 0;
  background: transparent;
  font-size: 0.75rem;
  -webkit-appearance: none;
  outline-color: rgba(0, 0, 0, 0);
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.3rem;
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
  }
`;

const ERROR_TEXT = 'Bitte wählen Sie einen Eintrag aus.';

function filterBy(option: any, state: TypeaheadPropsAndState) {
  if (state.selected.length) {
    return true;
  }
  return option.label.toLowerCase().indexOf(state.text.toLowerCase()) > -1;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  width: 100%;
`;

type onNewOptionCallback = (label: string) => Promise<number>;

interface TypeaheadInputProps {
  forbidNew?: boolean;
  disabled?: boolean;
  options: Option[];
  newSelectionPrefix?: string;
  onNewOption?: onNewOptionCallback;
  onChange?: (option?: Option) => void;
}

const TypeaheadInput: FunctionComponent<TypeaheadInputProps & FieldAttributes<any>> = ({
  children,
  valueType,
  disabled,
  forbidNew,
  options,
  emptyLabel,
  newSelectionPrefix,
  onNewOption,
  onChange,
  ...props
}: TypeaheadInputProps & FieldAttributes<any>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(props);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const value = typeof field.value === 'object' ? (field.value ? field.value.id : 0) : field.value;

  return (
    <Wrapper>
      <Typeahead
        id={field.name}
        disabled={disabled}
        filterBy={filterBy}
        options={options}
        maxResults={5}
        multiple={false}
        placeholder={emptyLabel}
        newSelectionPrefix={newSelectionPrefix}
        // {...field}
        {...props}
        selected={options.filter((option: any) => option.value === value)}
        allowNew={(options: any[], state: TypeaheadPropsAndState) => {
          if (forbidNew) {
            return false;
          }
          if (!options || options.length === 0) {
            return true;
          } else if (onNewOption) {
            let value = '';
            if (options.length > 0) {
              const option = options[0];
              value = option.label;
            }
            return options.find((option) => {
              return option.label.toLowerCase().indexOf(value) > -1;
            });
          } else {
            return false;
          }
        }}
        onBlur={(e) => {
          const v = e.target.value;
          const optionIndex = (options as Option[]).findIndex((option) => {
            if (typeof option === 'string') {
              return option.toLowerCase() === v.toLowerCase();
            } else {
              return option.label.toLowerCase() === v.toLowerCase();
            }
          });
          if (optionIndex > -1) {
            const option = options[optionIndex];
            helpers.setTouched(true);
            helpers.setValue(option.value, true);
            helpers.setError(undefined);
          }
        }}
        onChange={async (selected: any[]) => {
          if (selected.length > 0) {
            const option = selected[0];
            if (onChange) {
              onChange(option);
              return;
            }
            if (option.customOption) {
              try {
                setLoadingCreate(true);
                const id = await onNewOption(option.label);
                helpers.setTouched(true);
                helpers.setValue(id, true);
                helpers.setError(undefined);
              } catch (error) {
              } finally {
                setLoadingCreate(false);
              }
            } else {
              helpers.setTouched(true);
              helpers.setValue(option.value, true);
              helpers.setError(undefined);
            }
          } else {
            if (onChange) {
              onChange(undefined);
            } else {
              helpers.setTouched(true);
              helpers.setValue(undefined, true);
              helpers.setError(ERROR_TEXT);
            }
          }
        }}
      />
      {loadingCreate && <LoadingIndicator size={16} />}
    </Wrapper>
  );
};

export default TypeaheadInput;
