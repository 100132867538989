import { FunctionComponent, useCallback } from 'react';
import { TableColProps } from '../../components/table';

import { TestTask } from '../../interfaces/TestTask';
import { TestTaskService } from '../../services';
import { useAddError } from '../../context/error';
import { useNavigate } from 'react-router-dom';
import TableWithSearch, { OnDataParams, OnSearchParams } from '../../views/TableWithSearchView';
import { useAuth } from '../../context/auth';
import { get } from 'lodash';

const columns: TableColProps<TestTask>[] = [
  {
    title: 'Name',
    values: 'name',
    key: 'name',
    type: 'string',
    filterable: (addError, limit, page, query) =>
      (query && query.length > 0
        ? TestTaskService(addError)
            .search(query)
            .then((res) => res.items)
        : TestTaskService(addError)
            .list(limit, page)
            .then((res) => res.items)
      ).then((i) => i.map((v) => ({ value: v.name, title: v.name }))),
  },
  {
    title: 'Norm',
    values: 'norm',
    key: 'norm',
    type: 'string',
    filterable: (addError, limit, page, query) =>
      (query && query.length > 0
        ? TestTaskService(addError)
            .search(query)
            .then((res) => res.items)
        : TestTaskService(addError)
            .list(limit, page)
            .then((res) => res.items)
      ).then((i) => i.filter((v) => !!v.norm).map((v) => ({ value: v.norm!, title: v.norm! }))),
  },
];

const List: FunctionComponent = () => {
  const navigate = useNavigate();
  const addError = useAddError();
  const auth = useAuth();

  const onNew = useCallback(() => {
    navigate('new');
  }, [navigate]);

  if (!auth || !auth.user) {
    return null;
  }

  return (
    <TableWithSearch
      title="Prüfarten"
      to="/settings"
      defaultLimit={get(auth, 'user.itemsPerPage', 25)}
      columns={columns}
      onData={function ({ filter, limit, page }: OnDataParams<TestTask>) {
        return TestTaskService(addError).list<TestTask>(limit, page, filter);
      }}
      onSearch={function ({ limit, page, term }: OnSearchParams) {
        return TestTaskService(addError).search<TestTask>(term, limit, page);
      }}
      onNew={onNew}
    />
  );
};

export default List;
