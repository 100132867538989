import { FunctionComponent, useEffect, useRef } from 'react';
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';

import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../context/auth';
import RequireRole from '../../guards/RequireRole';
import RequireTester from '../../guards/RequireTester';
import RequireTrue from '../../guards/RequireTrue';
import HideOffline from '../NetworkStatus/HideOffline';
import ShowOffline from '../NetworkStatus/ShowOffline';
import LocationSelect from '../common/LocationSelect';
import LocationShow from '../common/LocationShow';
import JobsDropDown from '../jobs/jobs.dropdown';
import DropdownUsernameHeader from './DropdownUsernameHeader';
import OfflineButton from './OfflineButton';
import { ReactComponent as GearIcon } from './gear.svg';
import { ReactComponent as _Logo } from './logo.svg';
import { ReactComponent as MenuIcon } from './menu.svg';

const Logo = styled(_Logo)`
  height: 28px;
  margin: 10px auto;
`;

const WhiteNavbar = styled(Navbar)`
  background: #fff;
`;

const NavOnLarge = styled(Nav)`
  display: none;
  @media (min-width: 1200px) {
    display: flex;
    padding: 0 1rem;
  }
`;

const NavOnLargeMinWidth = styled(Nav)`
  display: none;
  @media (min-width: 1200px) {
    display: flex;
    padding: 0 1rem;
    min-width: 15rem;
  }
  @media (min-width: 1400px) {
    min-width: 25rem;
  }
`;

const NavOnSmallFullWidth = styled(Nav)`
  display: flex;
  flex: 1 0 auto;
  max-width: 100%;
  padding: 1rem 0;
  @media (min-width: 1200px) {
    display: none;
    padding: 0 1rem;
  }
`;

const NavOnSmall = styled(Nav)`
  display: flex;
  @media (min-width: 1200px) {
    display: none;
    padding: 0 1rem;
  }
`;

const Link = styled(NavLink)`
  padding: 0.75rem 0.5rem;
  margin: 0;
  @media (min-width: 1200px) {
    margin: 0 28px -0.5rem 28px;
    padding: 0.5rem 1rem;
  }
  height: 100%;
  display: block;
  font-weight: 700;
  font-size: 1rem;
  color: #86cba4;
  padding-bottom: 16px;
  border-bottom: 2px solid rgba(1, 1, 1, 0);
  transition: color 0.22s ease 0s;
  text-decoration: none;
  background-color: initial;
  svg {
    fill: #86cba4;
  }
  &.active,
  &:hover {
    // border-bottom: 2px solid #009842;
    color: #009842;
    svg {
      fill: #009842;
    }
  }
`;

const LinkButton = styled.button`
  padding: 0.75rem 0.5rem;
  margin: 0;
  text-align: left;
  @media (min-width: 1200px) {
    // margin: 0 28px -0.5rem 28px;
    // padding: 0.5rem 1rem;
    text-align: left;
  }
  height: 100%;
  display: block;
  font-weight: 700;
  border: 0;
  color: #86cba4;
  padding-bottom: 16px;
  border-bottom: 2px solid rgba(1, 1, 1, 0);
  transition: color 0.22s ease 0s;
  text-decoration: none;
  background-color: initial;
  svg {
    fill: #86cba4;
  }
  &.active,
  &:hover {
    // border-bottom: 2px solid #009842;
    color: #009842;
    svg {
      fill: #009842;
    }
  }
`;

const Dropdown = styled(NavDropdown)`
  color: #86cba4;
  svg {
    fill: #86cba4;
  }
  &.active,
  &:hover {
    color: #009842;
    svg {
      fill: #009842;
    }
  }
  .dropdown-toggle {
    &:after {
      display: none;
    }
  }
  .dropdown-menu {
    min-width: 232px;
    border: 0;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgb(136 152 170 / 10%), 0 15px 35px 0 rgb(49 49 93 / 10%), 0 5px 15px 0 rgb(0 0 0 / 8%);
    .dropdown-item {
      a,
      button {
        font-size: 0.875rem;
        margin: 0px;
        padding: 0px;
      }
    }
    &:after {
      content: '';
      z-index: 1;
      width: 21px;
      height: 21px;
      margin: -7px;
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSI5Ij48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGZpbGw9IiM4ODk4QUEiIGZpbGwtb3BhY2l0eT0iLjEiIGQ9Ik0xIDkuMDkyaDE5bC02LjQwMi02Ljc0Yy0xLjcxNy0xLjgwNi00LjQ4NS0xLjgtNi4xOTYgMEwxIDkuMDkzek0yMC4zNDIgOGwtNi4wMi02LjMzNmMtMi4xMDgtMi4yMi01LjUzOC0yLjIxOC03LjY0NSAwTC42NTggOGgxOS42ODR6Ii8+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTcuNDAyIDIuMzUzYzEuNzExLTEuODAxIDQuNDgtMS44MDcgNi4xOTYgMEwyMCA5LjA5M0gxbDYuNDAyLTYuNzR6Ii8+PC9nPjwvc3ZnPg==);
      background-repeat: no-repeat;
      background-position: 50%;
      position: absolute;
      top: -8px;
      right: 12px;
    }
  }
`;

const Header: FunctionComponent = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const UserDropDown = useMemo(
    () => (
      <Dropdown title={<MenuIcon />} id="menu" aria-labelledby="menu-button" role="menu" align="end">
        <DropdownUsernameHeader user={auth.user} />
        <NavDropdown.Item eventKey="4.2">
          <Link to="/settings/user">Mein Profil</Link>
        </NavDropdown.Item>
        <NavDropdown.Item eventKey="4.3">
          <LinkButton
            onClick={() => {
              auth.signout();
              navigate('/auth/login');
            }}
          >
            Abmelden
          </LinkButton>
        </NavDropdown.Item>
      </Dropdown>
    ),
    [auth, navigate],
  );

  const refCollapse = useRef<HTMLDivElement>(null);

  /**
   * Close Menu on loading page
   */
  useEffect(() => {
    setTimeout(() => {
      if (refCollapse && refCollapse.current) {
        refCollapse.current.classList.remove('show');
      }
    }, 80);
  }, []);

  return (
    <WhiteNavbar variant="light" fixed="top" expand="xl">
      <Container fluid>
        <Navbar.Brand href="/">
          <Logo />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          style={{
            width: '100%',
          }}
          id="basic-navbar-nav"
          ref={refCollapse}
        >
          <NavOnSmallFullWidth>
            <HideOffline>
              <LocationSelect />
            </HideOffline>
            <ShowOffline>
              <LocationShow />
            </ShowOffline>
          </NavOnSmallFullWidth>
          {/* Request from show on next week also implies that a tester is created, otherwise the value is undefined */}
          <RequireTrue k="tester.pref_show_my_week">
            <Nav className="">
              <Link to="/tours/this-week" reloadDocument={true}>
                Meine Woche
              </Link>
            </Nav>
          </RequireTrue>
          <RequireTester>
            <Nav className="">
              <Link to="/tests/new" reloadDocument={true}>
                Prüfen
              </Link>
            </Nav>
          </RequireTester>
          <HideOffline>
            <RequireRole roles={['ADMINISTRATOR', 'MANAGER']}>
              <Nav className="">
                <Link to="/analytics/performance" reloadDocument={true}>
                  Auswertung
                </Link>
              </Nav>
            </RequireRole>
            <RequireRole roles={['VIEWER']}>
              <Nav className="">
                <Link to="/locations" reloadDocument={true}>
                  Standorte
                </Link>
              </Nav>
            </RequireRole>
          </HideOffline>
          <HideOffline>
            <Nav className="">
              <Link to="/tests" reloadDocument={true}>
                Prüfungen
              </Link>
            </Nav>
          </HideOffline>
          <HideOffline>
            <Nav className="">
              <Link to="/examinees" reloadDocument={true}>
                Prüflinge
              </Link>
            </Nav>
          </HideOffline>
          <OfflineButton className="me-auto" />
          <NavOnLargeMinWidth>
            <HideOffline>
              <LocationSelect />
            </HideOffline>
            <ShowOffline>
              <LocationShow />
            </ShowOffline>
          </NavOnLargeMinWidth>
          <HideOffline>
            <NavOnSmall>
              <RequireRole roles={['ADMINISTRATOR', 'MANAGER']}>
                <Link to="/settings" reloadDocument={true}>
                  Einstellungen
                </Link>
              </RequireRole>
              <Link to="/settings/user" reloadDocument={true}>
                Mein Profil
              </Link>
              <LinkButton
                onClick={() => {
                  auth.signout();
                  navigate('/auth/login');
                }}
              >
                Abmelden
              </LinkButton>
            </NavOnSmall>
            <NavOnLarge>
              <HideOffline>
                <JobsDropDown />
              </HideOffline>
              <RequireRole roles={['ADMINISTRATOR', 'MANAGER']}>
                <Link to="/settings" reloadDocument={true}>
                  <GearIcon />
                </Link>
              </RequireRole>
              {UserDropDown}
            </NavOnLarge>
          </HideOffline>
        </Navbar.Collapse>
      </Container>
    </WhiteNavbar>
  );
};

export default Header;
