import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid';

import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

export enum PaginationButtonDirection {
  forwards,
  backwards,
}

export interface PaginationButtonProps {
  direction: PaginationButtonDirection;
  disabled: boolean;
  onClick: () => void;
}

const Button = styled.button<{ disabled: boolean }>`
  border: 0;
  display: flex;
  opacity: ${(props) => (props.disabled ? '0.8' : '1')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  user-select: ${(props) => (props.disabled ? 'none' : 'auto')};
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  background: transparent;
  svg,
  span {
    margin: 0 0.4rem;
  }
  svg {
    width: 1.4rem;
  }
`;

const PaginationButton: FunctionComponent<PaginationButtonProps> = ({ direction, disabled, onClick }) => {
  return (
    <Button onClick={() => onClick()} disabled={disabled}>
      {direction === PaginationButtonDirection.forwards && (
        <>
          <span>Nächste</span>
          <ArrowRightIcon />
        </>
      )}
      {direction === PaginationButtonDirection.backwards && (
        <>
          <ArrowLeftIcon /> <span>Vorherige</span>
        </>
      )}
    </Button>
  );
};

export default PaginationButton;
