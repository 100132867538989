import { useField } from 'formik';
import { FunctionComponent, useMemo } from 'react';
import { StyledTextarea } from '../../components/form/TextAreaInput';

interface FooterColInputProps {
  name: string;
  disabled: boolean;
}

const FooterColInput: FunctionComponent<FooterColInputProps> = ({ name, disabled }) => {
  const [field, meta, helpers] = useField(name);

  const value = useMemo(() => {
    if (field.value === undefined) {
      return '';
    }
    return field.value.join('\n');
  }, [field.value]);

  return (
    <StyledTextarea
      disabled={disabled}
      {...field}
      {...meta}
      {...helpers}
      value={value}
      onChange={(e) => {
        const value = e.target.value;
        const lines = value.split('\n');
        helpers.setValue(lines);
      }}
    />
  );
};

export default FooterColInput;
