import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { CommonExamineeSelectProps } from '.';
import { ExamineeFamiliesService } from '../../../services';
import { ExamineeFamily } from '../../../interfaces/ExamineeFamily';
import InputGroup from '../../form/InputGroup';
import SelectInput from '../../form/SelectInput';
import { useAddError } from '../../../context/error';
import { useFormikContext } from 'formik';
import { examineeFamilyStore } from '../../../storage';

const ExamineeFamilySelect: FunctionComponent<
  CommonExamineeSelectProps & {
    onValueSelected?: (value?: ExamineeFamily) => void;
  }
> = ({ variant, onValueSelected, editing, name }) => {
  const addError = useAddError();

  const { errors, isSubmitting } = useFormikContext<{
    family: number;
  }>();

  const [loading, setLoading] = useState(true);

  const [families, setFamilies] = useState<ExamineeFamily[]>([]);

  const loadExamineeFamilies = useCallback(async () => {
    const { items } = await ExamineeFamiliesService(addError, examineeFamilyStore).list(3000);
    setFamilies(items.sort((a, b) => a.prio - b.prio));
  }, [addError]);

  useEffect(() => {
    const loadData = async () => {
      try {
        loadExamineeFamilies();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, loadExamineeFamilies]);

  return (
    <InputGroup
      error={errors.family ? '' : undefined}
      valid={!errors.family}
      title="Prüflingsgattung"
      name={name ?? 'family'}
      loading={loading}
      variant={variant}
    >
      <SelectInput
        name={name ?? 'family'}
        disabled={!editing || isSubmitting}
        onValueSelected={(e: string | number | boolean | undefined) => {
          const f = families.find((a) => a.id === e);
          onValueSelected && onValueSelected(f);
        }}
      >
        <option disabled selected value={0} label="Prüflingsgattung auswählen" />
        {families.map((family) => (
          <option key={family.id} value={family.id}>
            {family.name}
          </option>
        ))}
      </SelectInput>
    </InputGroup>
  );
};

export default ExamineeFamilySelect;
