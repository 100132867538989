import { Button, Modal } from 'react-bootstrap';
import { FunctionComponent, useEffect, useState } from 'react';
import { ReactConfirmProps, confirmable, createConfirmation } from 'react-confirm';

import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingIndicator from '../common/LoadingIndicator';
import styled from 'styled-components';
import { BehaviorSubject, combineLatest, map, debounceTime, distinctUntilChanged } from 'rxjs';

type Pagination = {
  page: number;
  limit: number;
};

interface FilterProps extends ReactConfirmProps {
  cancelLabel: string;
  clearLabel: string;
  proceedLabel: string;
  title: string;
  show: boolean;
  preSelected: (number | string | boolean)[];
  proceed: (value?: string) => void;
  filterable: (
    limit: number,
    skip: number,
    query: string,
  ) => Promise<{ value: number | string | boolean | undefined; title: string }[]>;
}

const Content = styled(Modal.Body)`
  padding: 0;
  background: #f2f2f2;
`;

const SearchInput = styled.input`
  background-color: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255);
  color: rgb(84, 84, 84);
  padding: 0.75rem;
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  text-align: left;
  text-decoration: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  width: calc(100% - 64px);
  border: 0px;
  -webkit-appearance: none;
  outline-color: rgba(0, 0, 0, 0);
  // margin-bottom: 2rem;
  margin: 16px 32px 8px 32px;
`;

const Item = styled.div`
  display: block;
  margin: 16px 32px;
  background-color: var(--bs-light);
  border-radius: 0.3rem;
  border-color: #fff;
  padding: 8px;
  padding: 0.75rem;
  border-color: #00000000;
  align-self: auto;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  input {
    height: 16px;
    width: 16px;
  }
  label {
    text-align: left;
    font-size: 0.75rem;
    line-height: 1rem;
    text-decoration: none;
    color: rgb(84, 84, 84);
    padding: 0 8px;
  }
`;

const RelDiv = styled.div`
  position: relative;
  height: 16px;
  width: 16px;
  top: -21px;
  left: 7px;
`;

const ClearButton = styled(Button)`
  color: rgb(84, 84, 84);
`;

const LoadingItem = () => {
  return (
    <Item>
      <RelDiv>
        <LoadingIndicator size={16} />
      </RelDiv>
      <label>Mehr laden...</label>
    </Item>
  );
};

const Filter: FunctionComponent<FilterProps> = ({
  proceedLabel,
  cancelLabel,
  clearLabel,
  title,
  preSelected,
  filterable,
  show,
  proceed,
}) => {
  const [selected, setSelected] = useState<(number | string | boolean)[]>(preSelected);
  const [items, setItems] = useState<{ value: number | string | boolean | undefined; title: string }[]>([]);

  // const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  // const [query, setQuery] = useState<string>();

  const [querySubject, setQuerySubject] = useState<BehaviorSubject<string> | null>(null);
  const [paginationSubject, setPaginationSubject] = useState<BehaviorSubject<Pagination> | null>(null);
  const [params, setParams] = useState<{
    pagination: Pagination;
    query?: string;
    queryChanged?: boolean;
  } | null>(null);

  /**
   * Initialize search subject
   */
  useEffect(() => {
    if (paginationSubject === null) {
      setPaginationSubject(new BehaviorSubject({ page: 0, limit: 25 }));
    } else if (querySubject === null) {
      setQuerySubject(new BehaviorSubject(''));
    } else {
      const observable = combineLatest({
        pagination: paginationSubject,
        query: querySubject,
      })
        .pipe(
          map(({ pagination, query }) => {
            return {
              pagination,
              query,
              queryChanged: (params && params?.query !== query) ?? query.length > 0,
            };
          }),
          debounceTime(300),
          distinctUntilChanged(),
        )
        .subscribe((newState) => {
          setParams(newState);
        });

      return () => {
        if (observable) observable.unsubscribe();
        if (paginationSubject) paginationSubject.unsubscribe();
        if (querySubject) querySubject.unsubscribe();
        setPaginationSubject(null);
        setQuerySubject(null);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationSubject, querySubject]);

  useEffect(() => {
    const loadItems = async () => {
      try {
        const {
          pagination: { page, limit },
          query,
        } = params ?? { pagination: { page: 0, limit: 25 }, query: '' };
        let _items = await filterable(limit, page, query ?? '');

        _items = _items.filter((i) => i.value !== undefined);
        _items = _items.sort((a, b) => a.title.localeCompare(b.title));

        if (page === 0 || params?.queryChanged) {
          setItems(_items);
        } else {
          setItems([...items, ..._items]);
        }

        setHasMore(_items.length > 0);
      } catch (error) {}
    };
    loadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterable, params]);

  const fetch = () => {
    if (hasMore) {
      if (paginationSubject && !paginationSubject.closed) {
        const page = paginationSubject.value.page + 1;
        paginationSubject.next({ page, limit: 25 });
      }
    }
  };

  const _handleInputChange = (query: string) => {
    if (querySubject && !querySubject.closed) {
      querySubject.next(query);
    }
    if (paginationSubject && !paginationSubject.closed) {
      paginationSubject.next({ page: 0, limit: 25 });
    }
  };

  return (
    <div className="static-modal">
      <Modal show={show} onHide={() => proceed(undefined)} backdrop={true} keyboard={true}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Content>
          <SearchInput placeholder="Suchen..." onChange={(event) => _handleInputChange(event.target.value)} />
          <InfiniteScroll
            height={300}
            dataLength={items.length}
            next={fetch}
            hasMore={hasMore}
            loader={<LoadingItem />}
          >
            <>
              {items.map((item) => {
                const checked = item.value === undefined || (selected ?? []).indexOf(item.value!) > -1;
                const disabled = item.value === undefined;
                return (
                  <Item key={(item.value ?? 'undefined').toString()} aria-checked={checked} aria-disabled={disabled}>
                    <input
                      type="checkbox"
                      id={`item-${item.value}`}
                      name="selected[]"
                      checked={checked}
                      disabled={disabled}
                      onChange={(e) => {
                        if (item.value === undefined) return;
                        const checked = e.currentTarget.checked;
                        if (checked) {
                          setSelected([...(selected ?? []), item.value]);
                        } else {
                          setSelected((selected ?? []).filter((s) => s !== item.value));
                        }
                      }}
                    />
                    <label htmlFor={`item-${item.value}`}>{item.title}</label>
                  </Item>
                );
              })}
            </>
          </InfiniteScroll>
        </Content>
        <Modal.Footer>
          <ClearButton variant="light" onClick={() => proceed('[]')}>
            Zurücksetzen
          </ClearButton>
          <Button variant="light" onClick={() => proceed(undefined)}>
            Schließen
          </Button>
          <Button variant="primary" onClick={() => proceed(JSON.stringify(selected))}>
            Anwenden
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export function filter(
  title: string,
  preSelected: (number | string | boolean)[],
  filterable: (
    limit: number,
    skip: number,
    query: string,
  ) => Promise<{ value: number | string | boolean | null | undefined; title: string }[]>,
) {
  return createConfirmation(confirmable(Filter))({
    title,
    preSelected,
    filterable,
  });
}
