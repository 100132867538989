import { EmptyExamineeType, ExamineeType } from './ExamineeType';
import { EmptyInstallationSite, InstallationSite } from './InstallationSite';
import { EmptyLocation, Location } from './Location';
import { EmptyVendor, Vendor } from './Vendor';
import { NewType, UpdateType } from './removeDates';

import { ExamineeAdditionalInformationValue } from './ExamineeAdditionalInformationValue';
import { ExamineeTestTaskInterval } from './ExamineeTestTaskInterval';
import { Tester } from './Tester';
import { Due } from './Due';
import { User } from './User';

export interface Examinee {
  id: number;
  location: Location;
  tag: string;
  type: ExamineeType;
  vendor: Vendor;
  installationSite: InstallationSite;
  installationSiteDescription?: string;
  additionalInformation: ExamineeAdditionalInformationValue[];
  intervals: ExamineeTestTaskInterval[];
  lastTested?: { [key: string]: LastTest };
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export type NewExaminee = NewType<Examinee, 'tag' | 'installationSiteDescription'> & {
  installationSite: number;
  vendor: number;
  type: number;
  location: number;
  additionalInformation?: Record<number, string>;
};

export type UpdateExaminee = UpdateType<Examinee, 'tag' | 'installationSiteDescription'> & {
  installationSite: number;
  vendor: number;
  type: number;
  location: number;
  additionalInformation?: Record<number, string>;
};

export const EmptyNewExaminee: NewExaminee = {
  tag: '',
  installationSiteDescription: '',
  installationSite: 0,
  vendor: 0,
  type: 0,
  location: 0,
  additionalInformation: [],
};

export const EmptyExaminee: ExamineeWithLastTest = {
  id: 0,
  tag: '',
  installationSiteDescription: '',
  installationSite: EmptyInstallationSite,
  vendor: EmptyVendor,
  type: EmptyExamineeType,
  location: EmptyLocation,
  additionalInformation: [],
  intervals: [],
  last: undefined,
  dues: [],
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
};

export interface LastTest {
  at: string;
  tester: LastTestTester;
  result: boolean;
}

export type LastTestTester = Pick<Tester, 'id' | 'initials'> & {
  user: Pick<User, 'id' | 'name'>;
};

export type ExamineeWithLastTest = Examinee & {
  last: LastTest | undefined;
  dues: Due[];
};
