import { ThresholdType } from '../../interfaces/TestValueThreshold';

export default function formatThresholdType(type: ThresholdType) {
  switch (type) {
    case ThresholdType.LOWER:
      return 'Kleiner';
    case ThresholdType.HIHGER:
      return 'Größer';
    case ThresholdType.UNEQUAL:
      return 'Ungleich';
    case ThresholdType.EQUAL:
      return 'Gleich';
    default:
      return '';
  }
}
