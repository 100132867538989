import { EmptyTestTask, TestTask } from './TestTask';
import { NewType, UpdateType } from './removeDates';

import { ValueType } from './ValueType';
import { TestAdditionalInformationFieldOption } from './TestAdditionalInformationFieldOption';

export interface TestAdditionalInformationField {
  id: number;
  name: string;
  valueType: ValueType;
  valueOptions: TestAdditionalInformationFieldOption[];
  required: boolean;
  testTask: TestTask;
  unit?: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export type NewTestAdditionalInformationField = NewType<
  TestAdditionalInformationField,
  'name' | 'valueType' | 'required'
> & { valueOptions: string[] };

export type UpdateTestAdditionalInformationField = UpdateType<TestAdditionalInformationField, 'name'>;

export const EmptyNewTestAdditionalInformationField: NewTestAdditionalInformationField = {
  name: '',
  valueType: ValueType.BOOLEAN,
  required: false,
  valueOptions: [],
};

export const EmptyTestAdditionalInformationField: TestAdditionalInformationField = {
  id: 0,
  name: '',
  valueType: ValueType.BOOLEAN,
  valueOptions: [],
  required: false,
  testTask: EmptyTestTask,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
};
