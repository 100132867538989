import { FunctionComponent, useCallback } from 'react';

import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { columns } from '../../components/common/TestList/TestList';
import { useAuth } from '../../context/auth';
import { useAddError } from '../../context/error';
import { useMain } from '../../context/main';
import { Test } from '../../interfaces/Test';
import { TestService } from '../../services';
import { FilterType } from '../../services/Service';
import TableWithSearch, { OnDataParams, OnSearchParams } from '../../views/TableWithSearchView';

const List: FunctionComponent = () => {
  const navigate = useNavigate();
  const addError = useAddError();
  const auth = useAuth();

  const { selectedLocation } = useMain();

  const onNew = useCallback(() => {
    navigate('new');
  }, [navigate]);

  if (!auth || !auth.user) {
    return null;
  }

  return (
    <TableWithSearch<Test>
      title="Prüfungen"
      defaultLimit={get(auth, 'user.itemsPerPage', 25)}
      defaultFilter={{
        created_at: 'today',
      }}
      columns={columns(true)}
      onData={function ({ filter, limit, page }: OnDataParams<Test>) {
        const f = {
          ...(filter ?? {}),
          'examinee.location.id':
            filter['examinee.location.id'] ??
            (selectedLocation && selectedLocation.length > 0 ? selectedLocation[0].value : undefined),
        };
        return TestService(addError).list<Test>(limit, page, f);
      }}
      onSearch={function ({ limit, page, term }: OnSearchParams) {
        const f = {
          'examinee.location.id':
            selectedLocation && selectedLocation.length > 0 ? selectedLocation[0].value : undefined,
        };
        return TestService(addError).search<Test>(term, limit, page, f);
      }}
      onPrint={(filter?: FilterType<Test>, params?: Record<string, string>) => {
        const f = {
          ...(filter ?? {}),
          'examinee.location.id':
            selectedLocation && selectedLocation.length > 0 ? selectedLocation[0].value : undefined,
        };
        return TestService(addError).pdf(f, params);
      }}
      onNew={onNew}
    />
  );
};

export default List;
