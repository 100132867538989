import { ClipLoader } from 'react-spinners';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

interface LoadingIndicatorProps {
  size?: 16 | 40 | 80;
}

const ClipLoaderWrapper = styled.div<LoadingIndicatorProps>`
  position: absolute;
  left: 50%;
  width: 80px;
  margin-top: 20px;
  width: ${(props) => props.size ?? 80}px;
  margin-left: ${(props) => `${-(props.size ?? 80 / 2)}`}px;
`;

const LoadingIndicator: FunctionComponent<LoadingIndicatorProps> = ({ size }) => {
  return (
    <ClipLoaderWrapper size={size}>
      <ClipLoader size={`${size ?? 80}px`} color={'#009842'} loading={true} />
    </ClipLoaderWrapper>
  );
};
export default LoadingIndicator;
