import { EmptyVendor, Vendor } from '../../interfaces/Vendor';
import { ErrorLevel, useAddError, useClearError } from '../../context/error';
import { Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useEffect, useState } from 'react';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';
import { get, reduce } from 'lodash';

import BareInput from '../../components/form/BareInput';
import InputGroup from '../../components/form/InputGroup';
import InputSection from '../../components/form/InputSection';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { Row } from 'react-bootstrap';
import Title from '../../components/title';
import { VendorsService } from '../../services';
import { confirm } from '../../components/dialogs/Confirmation';
import qs from 'query-string';
import validateVendorName from '../../helpers/validate/validateVendorName';

const getChangedValues = (values: Vendor, initialValues: Vendor): Partial<Vendor> => {
  return reduce(
    Object.entries(values),
    (acc, [key, value]) => {
      const hasChanged = (initialValues as any)[key] !== value;
      if (hasChanged) {
        (acc as any)[key] = value;
      }
      return acc;
    },
    {},
  );
};

const shouldEditing = (location: Location) => {
  const queryParams = qs.parse(location.search, {});
  return queryParams.edit === '1' || queryParams.edit === 'true';
};

const Show: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(shouldEditing(location));

  const [item, setItem] = useState<Vendor>(EmptyVendor);

  useEffect(() => {
    const loadData = async () => {
      try {
        if (!params.id) {
          return;
        }
        const _item = await VendorsService(addError).get(params.id);
        if (!_item || !_item.id) {
          navigate('..');
        }
        setItem(_item);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, navigate, params.id]);

  const onSubmit = async (values: Vendor, { setSubmitting }: FormikHelpers<Vendor>) => {
    setSubmitting(true);
    try {
      if (params.id) {
        clearError();
        const data = getChangedValues(values, item);
        await VendorsService(addError).update(params.id, data);
        setEditing(false);
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onDelete = async (setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true);
    try {
      if (params.id) {
        if (
          await confirm(
            'Prüfart löschen?',
            'Die Prüfart wird entgültig gelöscht. Sind Sie sich sicher?',
            'Löschen',
            'Abbruch',
            {
              okColor: 'danger',
            },
          )
        ) {
          await VendorsService(addError).destroy(params.id);
          navigate('..');
        }
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isValidating, isValid, errors, resetForm, isSubmitting, setSubmitting }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Title
            onEdit={() => setEditing(true)}
            onDelete={() => onDelete(setSubmitting)}
            onCancel={() => {
              resetForm({
                values: item,
              });
              setEditing(false);
            }}
            editing={editing}
            editingValid={!isValidating && isValid}
            isSubmitting={isSubmitting}
            to="/settings/examinee-vendors"
            title={`${item.name}`}
          />
          <Row>
            <InputSection title="Stammdaten" xs={12} xl={6}>
              <InputGroup error={errors.name} valid={editing ? !errors.name : undefined} title="Name" name="name">
                <BareInput disabled={!editing || isSubmitting} type="text" name="name" validate={validateVendorName} />
              </InputGroup>
            </InputSection>
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default Show;
