import List from './List';
import MainLayout from '../../layouts/MainLayout';
import New from './New';
import React from 'react';
import RequireAuth from '../../guards/RequireAuth';
import { Route } from 'react-router';
import Show from './Show';
import ExamineeShowAdditionalInformationFields from './additional-information-fields/ExamineeShow';
import TestShowAdditionalInformationFields from './additional-information-fields/TestShow';
import ExamineeNewAdditionalInformationFields from './additional-information-fields/ExamineeNewModal';
import TestNewAdditionalInformationFields from './additional-information-fields/TestNewModal';

import ShowExamineeAdditionalInformationFieldOption from './additional-information-fields/examinee-additional-information-fields-options/Show';
import ExamineeAdditionalInformationFieldOptionNewModal from './additional-information-fields/examinee-additional-information-fields-options/NewModal';
import ShowTestAdditionalInformationFieldOption from './additional-information-fields/test-additional-information-fields-options/Show';
import TestAdditionalInformationFieldOptionNewModal from './additional-information-fields/test-additional-information-fields-options/NewModal';

const Routes = (
  <Route
    path="/settings/examinee-families"
    element={
      <RequireAuth>
        <MainLayout />
      </RequireAuth>
    }
  >
    <Route path="/settings/examinee-families/" element={<List />} />
    <Route path=":id/*" element={<Show />} />
    <Route path="new" element={<New />} />
    <Route
      path=":id/examinee-additional-information-fields/:tgid"
      element={<ExamineeShowAdditionalInformationFields />}
    />
    <Route path=":id/examinee-additional-information-fields/new" element={<ExamineeNewAdditionalInformationFields />} />
    <Route
      path=":id/examinee-additional-information-fields/:tgid/new-option"
      element={<ExamineeAdditionalInformationFieldOptionNewModal />}
    />
    <Route
      path=":id/examinee-additional-information-fields/:tgid/options/:opid"
      element={<ShowExamineeAdditionalInformationFieldOption />}
    />
    <Route path=":id/test-additional-information-fields/:tgid" element={<TestShowAdditionalInformationFields />} />
    <Route path=":id/test-additional-information-fields/new" element={<TestNewAdditionalInformationFields />} />
    <Route
      path=":id/test-additional-information-fields/:tgid/new-option"
      element={<TestAdditionalInformationFieldOptionNewModal />}
    />
    <Route
      path=":id/test-additional-information-fields/:tgid/options/:opid"
      element={<ShowTestAdditionalInformationFieldOption />}
    />
  </Route>
);

export default Routes;
