import { FunctionComponent, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import { FormikErrors } from 'formik';
import ReactTooltip from 'react-tooltip';

interface InputGroupProps {
  children?: React.ReactNode;
  name: string;
  unit?: string;
  icon?: React.ReactNode;
  title?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  valid?: boolean;
  variant?: 'light' | 'gray-200';
  longName?: boolean;
  loading?: boolean;
}

const Wrapper = styled.div<{
  variant?: 'light' | 'gray-200';
  hasError: boolean;
}>`
  margin: 9px 0;
  position: relative;
  align-items: center;
  display: flex;
  // flex-wrap: wrap;
  flex-wrap: nowrap;
  width: 100%;
  background-color: var(--bs-${(props) => props.variant ?? 'light'});
  border-radius: 0.3rem;
  border-color: #fff;
  padding: 8px;

  @media (max-width: 768px) {
    display: block;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  order: 1;
  flex: 0 1 auto;
  align-self: auto;
  svg {
    width: 1rem;
    height: 1rem;
    fill: #b3b3b3;
  }
`;

const getColor = (valid?: boolean) => {
  if (valid === false) return 'var(--bs-red)';
  if (valid === true) return 'var(--bs-primary)';
  return '#b3b3b3';
};

const breatheAnimation = keyframes`
 0% { left: 0.75rem; }
 50% { left: 1.5rem; }
 100% { left: 0.75rem; }
`;

const TitleWrapper = styled.label<{
  'data-loading'?: boolean;
  longName?: boolean;
  valid?: boolean;
}>`
  display: inline-block;
  word-break: break-word;
  overflow: hidden;
  // white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  display: flex;
  padding: 0.75rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  order: 1;
  align-self: auto;
  color: ${(props) => getColor(props.valid)};
  &:after {
    display: ${(props) => (props['data-loading'] ? 'block' : 'none')};
    position: absolute;
    bottom: 0.75rem;
    width: 4px;
    left: 0.75rem;
    right: 0.75rem;
    height: 2px;
    content: '';
    background: var(--bs-primary);
    animation: ${breatheAnimation} 1s infinite;
  }
  flex: 1 1 25%;
  width: 100%;
  @media (min-width: 1024px) {
    flex: 0 1 auto;
    width: ${(props) => (props.longName ? 222 : 132)}px;
  }
`;

const UnitWrapper = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.75rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  order: 1;
  flex: 0 0 auto;
  align-self: flex-end;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: stretch;
  order: 1;
  flex: 10 1 auto;
  align-self: auto;
  color: rgb(84, 84, 84);
  background: #00000000;
  input,
  select,
  button,
  a {
    color: rgb(84, 84, 84);
    padding: 0.75rem;
    background-color: #00000000;
    border-radius: 0.3rem;
    border-color: #00000000;
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
    text-align: left;
    font-size: 0.75rem;
    text-decoration: none;
    &:hover {
      background-color: #fff;
      border-color: #fff;
    }
    &:disabled {
      background-color: #fff;
      border-color: #fff;
    }
  }
  button,
  a {
    &:hover {
      color: var(--bs-primary);
    }
  }
  input {
    text-align: left !important;
  }
`;

const Error = styled.p`
  padding: 0 1rem 0.5rem 1rem;
  font-size: 1rem;
  margin: 0;
  line-height: 1.5;
  color: #aa0000;
  text-align: right;
`;

const InputGroup: FunctionComponent<InputGroupProps> = ({
  children,
  error,
  icon,
  title,
  valid,
  variant,
  longName,
  loading,
  unit,
}) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const parseError = (error: string | string[] | FormikErrors<any> | FormikErrors<any>[]): string => {
    if (!error) return '';
    if (typeof error === 'string') return error;
    if (typeof error === 'boolean') return '';
    if (Array.isArray(error)) {
      return error.map(parseError).join('\n');
    }
    return JSON.stringify(error);
  };

  return (
    <>
      <Wrapper variant={variant} data-valid={valid} hasError={!!error}>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        {title && (
          <TitleWrapper data-tip={title} data-loading={loading} longName={longName} valid={valid}>
            {title}
          </TitleWrapper>
        )}
        <InputWrapper>{children}</InputWrapper>
        {unit && <UnitWrapper>{unit}</UnitWrapper>}
      </Wrapper>
      {error && <Error>{parseError(error)}</Error>}
    </>
  );
};

export default InputGroup;
