import Cookies from 'js-cookie';
import { Examinee } from '../interfaces/Examinee';
import { Location } from '../interfaces/Location';

import {
  LocalExaminee,
  LocalNewExaminee,
  LocalNewTest,
  LocalTest,
  createdExamineeStore,
  createdTestsStore,
  examineeStore,
  examineeTagsStore,
  examineeTypeStore,
  installationSitesStore,
  locationStore,
  testTaskStore,
  vendorsStore,
  examineeFamilyStore,
  LocalExamineeNoLocationPossible,
  createdVendorsStore,
  createdInstallationSitesStore,
  LocalNewInstallationSite,
  LocalNewVendor,
} from '../storage';
import { API } from '../config';
import axios from 'axios';
import { Observable } from 'rxjs';
import { ImportExaminee, ImportInstallationSite, ImportTest, ImportTestGroup, ImportVendor } from './ImportService';
import { MesureGroupType } from '../interfaces/Test';
import { get, isDate, reduce } from 'lodash';

const OfflineService = () => {
  const getToken = (): string => {
    const token = Cookies.get('token');
    if (!token) {
      return '';
    } else {
      return token;
    }
  };

  const cachePolicy = () => {
    return 'no-cache, max-age=0';
  };

  const fetch = async (days: string[]) => {
    return axios
      .post(`${API}/offline`, { days }, { headers: { Authorization: getToken(), 'Cache-Control': cachePolicy() } })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        return {};
      });
  };

  const fetchOfflineSetup = async () => {
    return axios
      .get(`${API}/offline`, { headers: { Authorization: getToken(), 'Cache-Control': cachePolicy() } })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        return {};
      });
  };

  const getByTag = async (tag: string, locLock?: number): Promise<Examinee | undefined> => {
    const item = await examineeTagsStore.getItem(tag).catch((e) => {
      return undefined;
    });

    if (!item) {
      return Promise.resolve(undefined);
    }

    if (locLock && locLock !== item.location) {
      throw new Error('Another location');
    }

    return getById(item.id) ?? localExaminee(item.id);
  };

  const getById = async (id: number): Promise<Examinee | undefined> => {
    const ex = await examineeStore.getItem(id.toString());

    if (ex) return await enrichExamineeWithLocalData(ex);

    const cr = await localExaminee(id);

    if (!cr) return undefined;

    return {
      ...(cr as LocalExaminee),
      additionalInformation: [],
      intervals: [],
      updated_at: cr.created_at,
    };
  };

  const localLocation = async (id: number): Promise<Location | undefined> => {
    const cr = await locationStore.getItem(id.toString());
    return cr;
  };

  const localExaminee = async (id: number, forceLC = true): Promise<LocalExamineeNoLocationPossible | undefined> => {
    // if (id > 0) return getById(id);

    const cr = await createdExamineeStore.getItem(id.toString());

    if (!cr) return undefined;

    const lc = await locationStore.getItem(cr.location.toString());
    const vc = await vendorsStore.getItem(cr.vendor.toString());
    const tc = await examineeTypeStore.getItem(cr.type.toString());
    const ic = await installationSitesStore.getItem(cr.installationSite.toString());

    if ((forceLC && !lc) || !vc || !tc || !ic) return undefined;

    tc.family = (await examineeFamilyStore.getItem(tc.family.id.toString()))!;

    return {
      id: cr.id,
      location: lc,
      tag: cr.tag,
      type: tc,
      vendor: vc,
      installationSite: ic,
      installationSiteDescription: cr.installationSiteDescription,
      created_at: cr.created_at,
    };
  };

  const enrichExamineeWithLocalData = async (ex: Examinee): Promise<Examinee> => {
    const lc = await locationStore.getItem(ex.location.id.toString());
    const vc = await vendorsStore.getItem(ex.vendor.id.toString());
    const tc = await examineeTypeStore.getItem(ex.type.id.toString());
    const ic = await installationSitesStore.getItem(ex.installationSite.id.toString());

    if (!lc || !vc || !tc || !ic) {
      return ex;
    }

    tc.family = (await examineeFamilyStore.getItem(tc.family.id.toString()))!;

    ex.type = tc;

    return ex;
  };

  const localExaminees = async (): Promise<{
    items: LocalExamineeNoLocationPossible[];
    count: number;
  }> => {
    const all = await createdExamineeStore.list<LocalNewExaminee>();
    const examinees = (await Promise.all(all.map((e) => localExaminee(e.id, false)))).filter((e) => e !== undefined);
    return {
      items: examinees as LocalExamineeNoLocationPossible[],
      count: examinees.length,
    };
  };

  const localTest = async (id: number): Promise<LocalTest | undefined> => {
    const cr = await createdTestsStore.getItem(id.toString());

    if (!cr) return undefined;

    const ex = cr.examinee < 0 ? await localExaminee(cr.examinee) : await examineeStore.getItem(cr.examinee.toString());

    const tt = await testTaskStore.getItem(cr.testTask.toString());

    if (!ex || !tt) return undefined;

    return {
      id: cr.id,
      examinee: ex as LocalExaminee,
      testTask: tt,
      // comment: cr.comment,
      // defects: cr.defects,
      // result: cr.result,
      created_at: cr.created_at,
    };
  };

  const localTests = async (): Promise<{
    items: LocalTest[];
    count: number;
  }> => {
    const all = await createdTestsStore.list<LocalNewTest>();
    const tests = (await Promise.all(all.map((e) => localTest(e.id)))).filter((e) => e !== undefined);
    return {
      items: tests as LocalTest[],
      count: tests.length,
    };
  };

  const sync = (): Observable<string> => {
    const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

    return new Observable((observer) => {
      const run = async () => {
        await wait(250);
        observer.next('Lade Daten');

        const _examinees = await createdExamineeStore.list<LocalNewExaminee>();
        const _tests = await createdTestsStore.list<LocalNewTest>();
        const _vendors = await createdVendorsStore.list<LocalNewVendor>();
        const _installationSites = await createdInstallationSitesStore.list<LocalNewInstallationSite>();

        const vendors: ImportVendor[] = _vendors.map((v) => ({
          ...v,
          id: v.id,
        }));

        const installationSites: ImportInstallationSite[] = _installationSites.map((v) => ({
          ...v,
          id: v.id,
        }));

        const examinees: ImportExaminee[] = _examinees.map((e) => ({
          ...e,
          installationSite: get(e, 'installationSite', '266').toString(),
          vendor: get(e, 'vendor', '36').toString(),
          type: e.type.toString(),
          intervals: [],
          additionalInformation: e.additionalInformation ?? {},
        }));

        const localGroupToImportGroup = (g: MesureGroupType): ImportTestGroup => ({
          tag: g.tag,
          name: g.name,
          group: g.group,
          values: g.values ?? {},
        });

        const tests: ImportTest[] = _tests.map((e) => ({
          ...e,
          groups: e.groups.map((g) => localGroupToImportGroup(g)),
          additionalInformation: reduce(
            e.additionalInformation ?? {},
            (acc, v, k) => {
              acc[k] = isDate(v) ? v.toISOString() : v ?? '';
              return acc;
            },
            {} as Record<string, string | number | boolean | undefined | null>,
          ),
        }));

        const data: {
          examinees: ImportExaminee[];
          tests: ImportTest[];
          vendors: ImportVendor[];
          installationSites: ImportInstallationSite[];
        } = {
          examinees,
          tests,
          vendors,
          installationSites,
        };

        const blob = new Blob([JSON.stringify(data, null, 2)], {
          type: 'application/json',
        });

        const formData = new FormData();
        formData.append('file', blob);
        formData.append('source', 'offline');

        await axios.post(`${API}/tests/offline`, formData, {
          headers: { Authorization: getToken(), 'Cache-Control': cachePolicy() },
        });

        await wait(800);
        await createdExamineeStore.clear();

        await wait(800);
        await createdTestsStore.clear();

        await wait(800);
        await createdInstallationSitesStore.clear();

        await wait(800);
        await createdVendorsStore.clear();

        await wait(800);
        observer.next('Upload abgeschlossen');

        await wait(800);
        observer.complete();
      };

      run().catch((e) => {
        observer.error(e);
      });
    });
  };

  const offlineCount = async () => {
    const examinees = await createdExamineeStore.count();
    const tests = await createdTestsStore.count();
    const vendors = await createdVendorsStore.count();
    const installationSites = await createdInstallationSitesStore.count();
    return examinees + tests + vendors + installationSites;
  };

  const getTestTask = (id: number) => {
    return testTaskStore.getItem(id.toString());
  };

  return {
    fetchOfflineSetup,
    getTestTask,
    getByTag,
    getById,
    localTests,
    localExaminees,
    localExaminee,
    localLocation,
    sync,
    offlineCount,
    fetch,
  };
};

export default OfflineService;
