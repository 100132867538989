import { EmptyExaminee, Examinee } from './Examinee';
import { EmptyTestTask, TestTask } from './TestTask';
import { NewType, UpdateType } from './removeDates';

export interface ExamineeTestTaskInterval {
  id: number;
  examinee: Examinee;
  testTask: TestTask;
  interval: number;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export type NewExamineeTestTaskInterval = NewType<ExamineeTestTaskInterval, 'interval'> & {
  testTask: number;
};

export type UpdateExamineeTestTaskInterval = UpdateType<ExamineeTestTaskInterval, 'interval'>;

export const EmptyNewExamineeTestTaskInterval: NewExamineeTestTaskInterval = {
  interval: 0,
  examinee: 0,
  testTask: 0,
};

export const EmptyExamineeTestTaskInterval: ExamineeTestTaskInterval = {
  id: 0,
  interval: 0,
  examinee: EmptyExaminee,
  testTask: EmptyTestTask,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
};
