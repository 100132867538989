import { FieldAttributes, useField } from 'formik';

import { FunctionComponent } from 'react';

import styled from 'styled-components';

export const ToggleInputBooleanValueYes = 'true';
export const ToggleInputBooleanValueNo = 'false';
export const ToggleInputBooleanValueUndefined = undefined;

const YesValues = [true, ToggleInputBooleanValueYes, 'yes', '1', 1, '-1', -1];
const NoValues = [false, ToggleInputBooleanValueNo, 'no', '0', 0];

const Wrapper = styled.div<{ active?: boolean }>`
  padding: 0 0.75rem;
  flex: 1;

  > div {
    display: flex;
    min-width: 176px;
    max-width: 176px;
    width: 176px;
    > div {
      width: 50%;
      border-radius: 0.3rem;
      cursor: pointer;
      border: 1px solid var(--bs-secondary);
      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 1px solid ${(props) => (props.active ? 'var(--bs-primary)' : 'var(--bs-secondary)')};
      }
      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0 !important;
      }
    }
  }

  @media (max-width: 768px) {
    > div {
      min-width: 100%;
      max-width: 100%;
      width: 100%;
      margin-bottom: 0.5rem;
      > div {
        padding: 1rem;
      }
    }
  }
`;

const TButton = styled.div<{ active?: boolean }>`
  padding: 4px 8px;
  text-align: center;
  border-left: 0;
  font-size: 12px;
  color: ${(props) => (props.active ? 'var(--bs-primary)' : 'var(--bs-secondary)')};
  background-color: ${(props) => (props.active ? 'rgba(var(--bs-primary-rgb), 0.267)' : 'none')};
  border-color: ${(props) => (props.active ? 'var(--bs-primary)' : 'var(--bs-secondary)')} !important;
`;

const ToggleInput: FunctionComponent<
  FieldAttributes<any> & {
    disabled?: boolean;
    onValueSelected?: (value: string | number | boolean | undefined) => void;
  }
> = ({ children, valueType, disabled, onValueSelected, ...props }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(props);

  const isYes = YesValues.includes(field.value);
  const isNo = NoValues.includes(field.value);

  return (
    <Wrapper active={isYes || isNo}>
      <div>
        <TButton
          active={isYes}
          onClick={() => {
            helpers.setValue(ToggleInputBooleanValueYes, true);
            onValueSelected && onValueSelected(true);
          }}
        >
          Ja
        </TButton>
        <TButton
          active={isNo}
          onClick={() => {
            helpers.setValue(ToggleInputBooleanValueNo, true);
            onValueSelected && onValueSelected(false);
          }}
        >
          Nein
        </TButton>
      </div>
    </Wrapper>
  );
};

export default ToggleInput;
