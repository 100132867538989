import { Role } from '../interfaces/User';
import { useAuth } from '../context/auth';

export default function RequireRole({
  children,
  roles = ['ADMINISTRATOR', 'MANAGER', 'STANDARD', 'VIEWER'],
}: {
  children: JSX.Element;
  roles: Role[];
}) {
  const auth = useAuth();
  const role = auth.role();
  if (role && roles.includes(role)) {
    return children;
  } else {
    return null;
  }
}
