import { EmptyUser, User } from './User';
import { NewType, UpdateType } from './removeDates';

import { TestingDevice, DefaultTestingDevice } from './TestingDevice';

export interface Tester {
  id: number;
  sp_id: string;
  initials: string;
  testingDevicesV2: TestingDevice[];
  defaultTestingDeviceUsage: DefaultTestingDevice[];
  user: User;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  pref_show_my_week: boolean;
}

export type NewTester = NewType<
  Tester,
  'sp_id',
  {
    user?: number;
  }
>;

export type UpdateTester = UpdateType<
  Tester,
  'sp_id' | 'initials' | 'pref_show_my_week',
  {
    testingDevice: number[];
    defaultTestingDeviceUsage: number[];
  }
>;

export const EmptyNewTester: NewTester = {
  sp_id: '',
};

export const EmptyTester: Tester = {
  id: 0,
  initials: '',
  sp_id: '',
  user: EmptyUser,
  testingDevicesV2: [],
  defaultTestingDeviceUsage: [],
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  pref_show_my_week: true,
};
