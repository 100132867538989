import { Address, EmptyAddress } from './Address';
import { NewType, UpdateType } from './removeDates';

import { Examinee } from './Examinee';

export interface Location {
  id: number;
  sp_id: string;
  name: string;
  tag: string;
  address: Address;
  email: string;
  phone: string;
  fax: string;
  contact: string;
  openingHours: string;
  examinees: Examinee[];
  locked: boolean;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export type NewLocation = NewType<Location, 'sp_id'>;

export type UpdateLocation = UpdateType<Location, 'sp_id'>;

export const EmptyLocation: Location = {
  id: 0,
  sp_id: '',
  name: '',
  tag: '',
  address: EmptyAddress,
  email: '',
  phone: '',
  fax: '',
  contact: '',
  openingHours: '',
  examinees: [],
  locked: false,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
};
