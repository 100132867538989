import { EmptyUser, UpdateUser, User } from '../../interfaces/User';
import { ErrorLevel, useAddError, useClearError } from '../../context/error';
import { Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';
import { get, reduce } from 'lodash';

import { ReactComponent as ArrowIcon } from './arrow.svg';
import BareInput from '../../components/form/BareInput';
import { ButtonWithIcon } from '../../components/buttons/ButtonWithIcon';
import FormikDebug from '../../components/dev/formik-debug';
import InputGroup from '../../components/form/InputGroup';
import InputSection from '../../components/form/InputSection';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { Row } from 'react-bootstrap';
import SelectInput from '../../components/form/SelectInput';
import Title from '../../components/title';
import { TesterService, UserService } from '../../services';
import { confirm } from '../../components/dialogs/Confirmation';
import { alert } from '../../components/dialogs/Alert';
import qs from 'query-string';
import { validateEmail } from '../../helpers/validate/validateEmail';
import validateName from '../../helpers/validate/validateName';
import CustomerGroupPicker from './CustomerGroupPicker';
import RoleView from '../../components/RoleView';
import { useAuth } from '../../context/auth';

// const ButtonWithIcon = styled(Button)`
//   float: left;
//   color: #a1a1a1;
//   opacity: 1;
//   svg {
//     width: 14px;
//     height: 14px;
//     display: inline-block;
//     margin: 0 0 4px 6px;
//     &,
//     path,
//     g {
//       fill: #a1a1a1;
//       color: #a1a1a1;
//     }
//   }
//   span {
//     display: inline-block;
//     vertical-align: bottom;
//     margin-bottom: 1px;
//   }
//   &:disabled,
//   &.disabled {
//     cursor: not-allowed;
//     opacity: 0.5;
//     color: #a1a1a1 !important;
//   }
//   &:hover {
//     color: var(--bs-primary);
//     opacity: 0.8;
//     &,
//     path,
//     g {
//       fill: var(--bs-primary);
//       color: var(--bs-primary);
//     }
//   }
// `;

const getChangedValues = (values: User, initialValues: User): Partial<User> => {
  return reduce(
    Object.entries(values),
    (acc, [key, value]) => {
      const hasChanged = (initialValues as any)[key] !== value;
      if (hasChanged) {
        (acc as any)[key] = value;
      }
      return acc;
    },
    {},
  );
};

const shouldEditing = (location: Location) => {
  const queryParams = qs.parse(location.search, {});
  return queryParams.edit === '1' || queryParams.edit === 'true';
};

const Show: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const auth = useAuth();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(shouldEditing(location));

  const [item, setItem] = useState<User>(EmptyUser);

  const loadData = useCallback(async () => {
    try {
      if (!params.id) {
        return;
      }
      const _item = await UserService(addError).get(params.id);
      if (!_item || !_item.id) {
        navigate('..');
      }
      setItem(_item);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [addError, navigate, params.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onSubmit = async (values: User, { setSubmitting }: FormikHelpers<User>) => {
    setSubmitting(true);
    try {
      if (params.id) {
        clearError();
        const data = getChangedValues(values, item);
        const updateData: UpdateUser = {
          ...data,
          scopes: ((values.scopes ?? []) as any).map((tt: any) => tt.value),
        };

        if (updateData.role === 'VIEWER' && item.tester) {
          const res = await confirm(
            'Rolle ändern?',
            'Der Benutzer ist Prüfer. Wenn Sie die Rolle ändern, wird der Prüfer gelöscht. Sind Sie sich sicher?',
            'Ändern',
            'Abbruch',
            {
              okColor: 'danger',
            },
          );
          if (!res) {
            return;
          }
          await TesterService(addError).destroy(item.tester.id.toFixed());
        }

        if (item.role === 'VIEWER' && item.tester) {
          await alert(
            'Prüfer nicht möglich!',
            'Der Benutzer kann nicht ein Kunde und Prüfer sein. Bitte ändern Sie die Rolle.',
            'Schließen',
            {
              okColor: 'danger',
            },
          );
          return;
        }

        await UserService(addError).update(params.id, updateData);

        setEditing(false);
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onLock = async (setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true);
    try {
      if (params.id) {
        if (
          await confirm('Benutzer sperren?', 'Der Benutzer wird sperren. Sind Sie sich sicher?', 'Sperren', 'Abbruch', {
            okColor: 'danger',
          })
        ) {
          await UserService(addError).destroy(params.id);
          loadData();
        }
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onUnlock = async (setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true);
    try {
      if (params.id) {
        if (
          await confirm(
            'Benutzer entsperren?',
            'Der Benutzer wird entsperrt. Sind Sie sich sicher?',
            'Sperren',
            'Abbruch',
            {
              okColor: 'primary',
            },
          )
        ) {
          await UserService(addError).restore(params.id);
          loadData();
        }
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onNewTesterClick = () => {
    navigate('create-tester', {
      state: { user: item },
    });
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isValidating, isValid, values, errors, resetForm, isSubmitting, setSubmitting }) => (
        <>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Title
              onEdit={() => setEditing(true)}
              onLock={values.deleted_at === null ? () => onLock(setSubmitting) : undefined}
              onUnlock={values.deleted_at !== null ? () => onUnlock(setSubmitting) : undefined}
              onCancel={() => {
                resetForm({
                  values: item,
                });
                setEditing(false);
              }}
              onImpersonate={async () => {
                await auth.impersonate(item.id);
                navigate('/');
              }}
              editing={editing}
              editingValid={!isValidating && isValid}
              isSubmitting={isSubmitting}
              to="/settings/users"
              title={[item.name ?? item.email, item.company].filter((f) => !!f).join(' | ')}
            />
            <Row>
              <InputSection title="Stammdaten" xs={12} xl={6}>
                <InputGroup error={errors.name} valid={editing ? !errors.name : undefined} title="Name" name="name">
                  <BareInput disabled={!editing || isSubmitting} type="text" name="name" validate={validateName} />
                </InputGroup>
                <InputGroup
                  error={errors.company}
                  valid={editing ? !errors.company : undefined}
                  title="Unternehmen"
                  name="company"
                >
                  <BareInput disabled={!editing || isSubmitting} type="text" name="company" />
                </InputGroup>
                <InputGroup
                  error={errors.email}
                  valid={editing ? !errors.email : undefined}
                  title="E-Mail"
                  name="email"
                >
                  <BareInput disabled={!editing || isSubmitting} type="email" name="email" validate={validateEmail} />
                </InputGroup>
              </InputSection>
              <InputSection title="Rechte" xs={12} xl={6}>
                <InputGroup error={errors.role} valid={editing ? !errors.role : undefined} title="Rolle" name="role">
                  <SelectInput valueType="text" name="role" disabled={!editing || isSubmitting}>
                    <option value="ADMINISTRATOR">
                      <RoleView role={'ADMINISTRATOR'} />
                    </option>
                    <option value="MANAGER">
                      <RoleView role={'MANAGER'} />
                    </option>
                    <option value="STANDARD">
                      <RoleView role={'STANDARD'} />
                    </option>
                    <option value="VIEWER">
                      <RoleView role={'VIEWER'} />
                    </option>
                  </SelectInput>
                </InputGroup>
                <CustomerGroupPicker name="scopes" editing={editing} />
              </InputSection>

              <InputSection title="Voreinstellungen" xs={12} xl={6}>
                <InputGroup
                  error={errors.itemsPerPage}
                  valid={editing ? !errors.itemsPerPage : undefined}
                  title="Zeilen pro Seite"
                  name="itemsPerPage"
                >
                  <SelectInput valueType="number" name="itemsPerPage" disabled={!editing || isSubmitting}>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </SelectInput>
                </InputGroup>
              </InputSection>

              <InputSection title="Prüfer" xs={12} xl={6}>
                {values.role === 'VIEWER' && (
                  <p>Kundern können nicht als Prüfer eingesetzt werden. Bitte wählen Sie eine andere Rolle aus.</p>
                )}
                {values.role !== 'VIEWER' && (
                  <InputGroup
                    error={errors.role}
                    valid={editing ? !errors.role : undefined}
                    title="Prüfer"
                    name="tester"
                  >
                    {item && item.tester && (
                      <ButtonWithIcon variant="link" onClick={() => navigate(`/settings/testers/${item.tester!.id}`)}>
                        <span>Prüfer aufrufen</span>
                        <ArrowIcon />
                      </ButtonWithIcon>
                    )}
                    {!item.tester && (
                      <ButtonWithIcon variant="link" onClick={() => onNewTesterClick()}>
                        <span>Neuer Prüfer aus Benutzer</span>
                        <ArrowIcon />
                      </ButtonWithIcon>
                    )}
                  </InputGroup>
                )}
              </InputSection>
            </Row>
          </form>
          <FormikDebug />
        </>
      )}
    </Formik>
  );
};

export default Show;
