import Cookies from 'js-cookie';
import { TokenExpiredError } from './errorHandler/isTokenExpired';
import { AddErrorFunction, AddSafeDocError } from '../context/error';

const getToken = (addError?: AddErrorFunction, force = false): string => {
  const token = Cookies.get('token');
  const impersonateToken = Cookies.get('impersonate');
  if (!token) {
    addError && addError(TokenExpiredError as AddSafeDocError);
    return '';
  } else if (impersonateToken && force === false) {
    return impersonateToken;
  } else {
    return token;
  }
};

export default getToken;
