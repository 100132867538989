import qs from 'query-string';
import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;

const Message = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorPage: FunctionComponent = () => {
  const location = useLocation();
  const queryParams = qs.parse(location.search, {});

  const message = queryParams.message || 'An error occurred';
  const title = queryParams.title || 'Error';

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Message>{message}</Message>
    </Wrapper>
  );
};

export default ErrorPage;
