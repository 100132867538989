import { AddErrorFunction, AddSafeDocError } from '../context/error';

import axios from 'axios';
import { get as _get } from 'lodash';
import { API } from '../config';
import { ApiKey, NewApiKey } from '../interfaces/Apikey';
import _BaseService from './BaseService';
import { Service } from './Service';
import handleError from './errorHandler';
import getToken from './getToken';

export interface ExtendedService<T, UT, NT> extends Service<T, UT, NT, string> {
  create: (data: NT, suppress?: boolean) => Promise<string | undefined | AddSafeDocError>;
}

export const ApiKeyService = (addError: AddErrorFunction) =>
  _BaseService<ApiKey, null, NewApiKey, string>('api-keys', addError);

const ExtendedApiKeyService = (addError: AddErrorFunction): ExtendedService<ApiKey, null, NewApiKey> => {
  const cachePolicy = () => {
    return 'no-cache, max-age=0';
  };

  const base = ApiKeyService(addError);

  const create = (data: NewApiKey, suppress = false): Promise<string | undefined | AddSafeDocError> => {
    return axios
      .post(`${API}/api-keys`, data, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        return _get(res, 'data.data.apikey') as string;
      })
      .catch(async (e) => {
        const handledError = handleError(e);
        if (suppress) {
          return handledError;
        } else {
          addError(handledError);
          return undefined;
        }
      });
  };

  return { ...base, create };
};

export default ExtendedApiKeyService;
