import { Button as BButton, Modal } from 'react-bootstrap';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { ReactComponent as ArrowIcon } from './arrow.svg';
import { ButtonWithIcon } from '../buttons/ButtonWithIcon';
import LoadingIndicator from '../common/LoadingIndicator';
import { Tester } from '../../interfaces/Tester';
import { TestTaskService } from '../../services';
import styled from 'styled-components';
import { useAddError } from '../../context/error';
import { TestTask } from '../../interfaces/TestTask';

const P = styled.p<{ small: boolean }>`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  color: ${(props) => (props.small ? '#b3b3b3' : '#000')};
`;

const TesterButton = styled(BButton)`
  display: block;
  width: 100%;
  height: 3.125rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 9px;
  &.active,
  &:active,
  &:hover {
    p {
      color: #fff;
    }
  }
`;

const BGModalBody = styled(Modal.Body)`
  background: #f2f2f2;
  padding: 32px;
  max-height: 500px;
  min-height: 286px;
  overflow-y: scroll;
`;

const Items = styled.div`
  display: block;
  min-height: 286px;
`;

const TestingDeviceDefaultTestTasksButton: FunctionComponent<{
  editing: boolean;
  testers: Tester[];
  defaultValue: TestTask[];
  onSelectTestTasks?: (testTasks: TestTask[]) => void;
}> = ({ onSelectTestTasks, editing, testers, defaultValue }) => {
  const addError = useAddError();

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [testTasks, setTestTasks] = useState<TestTask[]>([]);
  const [selectedTestTasks, selectTestTasks] = useState<TestTask[]>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    selectTestTasks(defaultValue);
  }, [defaultValue]);

  const loadTestTasks = useCallback(async () => {
    try {
      setLoading(true);
      const { items } = await TestTaskService(addError).list();
      setTestTasks(items);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [addError]);

  const openModal = () => {
    setTestTasks([]);
    selectTestTasks(defaultValue);
    loadTestTasks();
    setModalShow(true);
  };

  const usedSelectedTestTasks = selectedTestTasks ?? [];

  if (!editing && testers.length > 0) {
    return (
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      <ButtonWithIcon variant="link" disabled>
        <span>
          {usedSelectedTestTasks.length === 0 && <P small={false}>Keine</P>}
          {usedSelectedTestTasks.length > 0 && (
            <P small={false}>{usedSelectedTestTasks.map((t) => (t ? t.initials : '')).join(', ')}</P>
          )}
        </span>
      </ButtonWithIcon>
    );
  }

  if (!editing && testers.length === 0) {
    return (
      <ButtonWithIcon disabled>
        <span>
          <P small={false}>Kein Prüfer</P>
          <P small={true}>Wählen Sie im Bearbeitungsmodus ein Prüfer für diesen Testgerät aus.</P>
        </span>
      </ButtonWithIcon>
    );
  }

  return (
    <>
      <ButtonWithIcon onClick={() => openModal()}>
        <span>
          {usedSelectedTestTasks.length === 0 && <P small={false}>Keine</P>}
          {usedSelectedTestTasks.length > 0 && (
            <P small={false}>{usedSelectedTestTasks.map((t) => (t ? t.initials : '')).join(', ')}</P>
          )}
          <P small={true}>Wählen Sie die Standardprüfungen für diesen Testgerät und den Prüfer aus.</P>
        </span>
        <ArrowIcon />
      </ButtonWithIcon>
      <Modal aria-labelledby="contained-modal-title-vcenter" centered show={modalShow}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Standardprüfungen auswählen</Modal.Title>
        </Modal.Header>
        <BGModalBody>
          {loading && <LoadingIndicator />}
          {!loading && (
            <Items>
              {testTasks.map((testTask) => {
                const active = (selectedTestTasks ?? []).some((t) => t.id === testTask.id);
                return (
                  <TesterButton
                    active={active}
                    onClick={() => {
                      const remove = (selectedTestTasks ?? []).some((t) => t.id === testTask.id);
                      if (remove) {
                        selectTestTasks(selectedTestTasks?.filter((f) => f.id !== testTask.id));
                      } else {
                        selectTestTasks([...(selectedTestTasks ?? []), testTask]);
                      }
                    }}
                  >
                    <P small={false}> {testTask.name}</P>
                    <P small={true}>{testTask.initials}</P>
                  </TesterButton>
                );
              })}
            </Items>
          )}
        </BGModalBody>
        <Modal.Footer>
          <BButton variant="light" onClick={() => setModalShow(false)}>
            Abbrechen
          </BButton>
          <BButton
            disabled={loading || !selectedTestTasks}
            variant="primary"
            onClick={() => {
              onSelectTestTasks && onSelectTestTasks && onSelectTestTasks(selectedTestTasks ?? []);
              setModalShow(false);
            }}
          >
            Auswählen
          </BButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TestingDeviceDefaultTestTasksButton;
