import { DateTime } from 'luxon';
import {
  ExTag,
  daysStore,
  examineeStore,
  examineeTagsStore,
  locationStore,
  testTaskStore,
  testingDeviceStore,
  tourStore,
  vendorsStore,
  examineeFamilyStore,
  examineeTypeStore,
  userStore,
  installationSitesStore,
} from './storage';
import Cookies from 'js-cookie';
import TourService from './services/TourService';
import {
  ExamineeFamiliesService,
  ExamineeTypesService,
  ExamineesService,
  InstallationSitesService,
  LocationService,
  TestTaskService,
  TestingDeviceService,
  UserService,
  VendorsService,
} from './services';
import { ExamineeWithLastTest } from './interfaces/Examinee';
import { first, reduce } from 'lodash';
// import OfflineService from './services/OfflineService';

// const USE_NEW_OFFLINE = false;

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const fetchMe = async () => {
  // fetch me
  try {
    const me = await UserService(noop, undefined, true).me();
    userStore.setItem('me', me);
  } catch (error) {
    if (error) {
      console.error(error);
    }
  }
};

const fetchTours = async (from: DateTime, to: DateTime) => {
  const locations: Record<string, any> = {};
  // store tours

  const f = from.toFormat('yyyy-MM-dd');
  const t = to.toFormat('yyyy-MM-dd');
  const d = `${f}_${t}`;

  const tours = await TourService(noop, false, true).atDateRange(from.toJSDate(), to.toJSDate());

  if (!tours) return {};
  await daysStore.setItem(d, tours);

  for (const tp of Object.values(tours)) {
    const tour = first(tp)?.tour;
    if (!tour || !tour.id) continue;
    await tourStore.setItem(tour.id.toString(), tour);
    for (const t of tp) locations[t.location.id] = t.testTasks;
  }

  return locations;
};

const fetchTestTasks = async () => {
  const testTasks = (
    await TestTaskService(noop, undefined, true).list(10000, 0, undefined, {
      extended: 'true',
    })
  ).items;
  for (const testTask of testTasks) {
    await testTaskStore.setItem(testTask.id.toString(), testTask);
  }
};

const fetchExamineeFamilies = async () => {
  const examineeFamilies = (await ExamineeFamiliesService(noop, undefined, true).list(10000, 0)).items;
  for (const examineeFamily of examineeFamilies) {
    await examineeFamilyStore.setItem(examineeFamily.id.toString(), examineeFamily);
  }
};

const fetchExamineeTypes = async () => {
  const examineeTypes = (await ExamineeTypesService(noop, undefined, true).list(10000, 0)).items;
  for (const examineeType of examineeTypes) {
    await examineeTypeStore.setItem(examineeType.id.toString(), examineeType);
  }
};

const fetchLocations = async (locations: string[]) => {
  for (const location of locations) {
    const loc = await LocationService(noop, true).get(location);
    if (!loc || !loc.id) continue;
    await locationStore.setItem(loc.id.toString(), loc);
    // fetch examinees at location
    const { items } = await ExamineesService(noop, true).list<ExamineeWithLastTest>(100000, 0, {
      'location.id': location,
    });
    console.log(`Fetched ${items.length} examinees at location ${location}`);
    for (const examinee of items) {
      await examineeStore.setItem(examinee.id.toString(), examinee);
    }
  }
};

const fetchTags = async () => {
  const tags = await ExamineesService(noop, true).usedTags();
  if (!tags || tags.length === 0) return;
  console.log(`Fetched ${tags.length} tags`);
  examineeTagsStore.set(
    reduce<any, { [key: string]: ExTag }>(
      tags,
      (map, { tag, ...rest }) => {
        map[tag] = rest;
        return map;
      },
      {},
    ),
  );
};

const fetchTestingDevices = async () => {
  const testingDevices = (await TestingDeviceService(noop, undefined, true).list(10000, 0)).items;
  for (const testingDevice of testingDevices) {
    await testingDeviceStore.setItem(testingDevice.id.toString(), {
      ...testingDevice,
      testers: testingDevice?.testers.map((t) => t.id),
    });
  }
};

const fetchVendors = async () => {
  const vendors = (await VendorsService(noop, undefined, true).list(10000, 0)).items;
  for (const vendor of vendors) {
    await vendorsStore.setItem(vendor.id.toString(), {
      ...vendor,
    });
  }
};

const fetchInstallationSites = async () => {
  const installationSites = (await InstallationSitesService(noop).list(10000, 0)).items;
  for (const installationSite of installationSites) {
    await installationSitesStore.setItem(installationSite.id.toString(), {
      ...installationSite,
    });
  }
};

const fetchUsers = async () => {
  const users = (await UserService(noop).list(10000, 0)).items;
  for (const user of users) {
    await userStore.setItem(user.id.toString(), {
      ...user,
    });
  }
};

// const fetchOfflineSetup = async () => {
//   const offlineSetup = await OfflineService().fetchOfflineSetup();
//   await offlineSetupStore.set(offlineSetup);
// };

export const offline = async () => {
  console.log('offline');

  const token = getToken();

  if (!token || !navigator.onLine) {
    return;
  }

  // first fetch tours from the network
  const today = DateTime.now();
  const startOfWeek = today.startOf('week').plus({ hours: 12 });
  const endOfWeek = today.endOf('week').minus({ hours: 12 });

  // const days: string[] = Array.from({ length: 7 }, (_, i) => getDay(startOfWeek, i));

  // if (USE_NEW_OFFLINE) {
  //   const {
  //     me,
  //     tours,
  //     locations,
  //     testTasks,
  //     tags,
  //     testingDevices,
  //     vendors,
  //     examineeTypes,
  //     examineeFamilies,
  //     installationSites,
  //     users,
  //     examinees,
  //   } = await OfflineService().fetch(days);

  //   // save me
  //   await userStore.setItem('me', me);

  //   // save users
  //   for (const user of users) {
  //     await userStore.setItem(user.id.toString(), user);
  //   }

  //   // save examinee families
  //   for (const examineeFamily of examineeFamilies) {
  //     await examineeFamilyStore.setItem(examineeFamily.id.toString(), examineeFamily);
  //   }

  //   // save examinee types
  //   for (const examineeType of examineeTypes) {
  //     await examineeTypeStore.setItem(examineeType.id.toString(), examineeType);
  //   }

  //   // save installation sites
  //   for (const installationSite of installationSites) {
  //     await installationSitesStore.setItem(installationSite.id.toString(), installationSite);
  //   }

  //   // save vendors
  //   for (const vendor of vendors) {
  //     await vendorsStore.setItem(vendor.id.toString(), vendor);
  //   }

  //   // save testing devices
  //   for (const testingDevice of testingDevices) {
  //     await testingDeviceStore.setItem(testingDevice.id.toString(), testingDevice);
  //   }

  //   // save tours per day
  //   for (const tour of tours) {
  //     const d = new Date(tour.date).toISOString().substring(0, 10);
  //     await daysStore.setItem(d, tour);
  //     await tourStore.setItem(tour.id.toString(), tour);
  //   }

  //   // save locations
  //   for (const location of locations) {
  //     await locationStore.setItem(location.id.toString(), location);
  //   }

  //   // save examinees at locations
  //   for (const examinee of examinees) {
  //     await examineeStore.setItem(examinee.id.toString(), examinee);
  //   }

  //   // save test tasks
  //   for (const testTask of testTasks) {
  //     await testTaskStore.setItem(testTask.id.toString(), testTask);
  //   }

  //   // save tags
  //   examineeTagsStore.set(
  //     reduce<any, { [key: string]: ExTag }>(
  //       tags,
  //       (map, { tag, ...rest }) => {
  //         map[tag] = rest;
  //         return map;
  //       },
  //       {},
  //     ),
  //   );

  //   return;
  // }

  // OLD

  await fetchMe();

  const locations = await fetchTours(startOfWeek, endOfWeek);

  // await fetchOfflineSetup();

  const prms = [
    fetchUsers(),
    fetchTestTasks(),
    fetchLocations(Object.keys(locations)),
    fetchTags(),
    fetchTestingDevices(),
    fetchVendors(),
    fetchExamineeTypes(),
    fetchExamineeFamilies(),
    fetchInstallationSites(),
  ];

  for (const p of prms) {
    await p.catch((e) => console.error(e));
  }
};

const getToken = (): string | null => {
  const token = Cookies.get('token');
  if (!token) {
    return null;
  } else {
    return token;
  }
};
