import { EmptyExaminee, Examinee } from '../../../interfaces/Examinee';
import {
  EmptyNewExamineeTestTaskInterval,
  NewExamineeTestTaskInterval,
} from '../../../interfaces/ExamineeTestTaskInterval';
import { ErrorLevel, useAddError, useClearError } from '../../../context/error';
import { Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ExamineesService } from '../../../services';
import ExtendedExamineeService from '../../../services/ExtendedExamineeService';
import FormikDebug from '../../../components/dev/formik-debug';
import InputGroup from '../../../components/form/InputGroup';
import InputSection from '../../../components/form/InputSection';
import { NumberInput } from '../../../components/form/BareInput';
import { Row } from 'react-bootstrap';
import SelectInput from '../../../components/form/SelectInput';
import Title from '../../../components/title';
import { get } from 'lodash';

const New: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const navigate = useNavigate();
  const params = useParams();

  const [item] = useState<NewExamineeTestTaskInterval>(EmptyNewExamineeTestTaskInterval);
  const [examinee, setExaminee] = useState<Examinee>(EmptyExaminee);

  const loadData = useCallback(async () => {
    if (!params.id) {
      setExaminee(EmptyExaminee);
    }
    const _examinee = await ExamineesService(addError).get(params.id ?? '');
    if (!_examinee || !_examinee.id) {
      navigate('..');
    } else {
      setExaminee(_examinee);
    }
  }, [addError, navigate, params.id]);

  useEffect(() => {
    loadData();
  }, [addError, loadData, navigate, params.id]);

  const onSubmit = async (
    values: NewExamineeTestTaskInterval,
    { setSubmitting }: FormikHelpers<NewExamineeTestTaskInterval>,
  ) => {
    setSubmitting(true);
    clearError();
    try {
      const eit = await ExtendedExamineeService(addError).addInterval(examinee.id, { ...values });
      if (eit && eit.id > 0) {
        navigate(`/examinees/${params.id}`);
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isValidating, isValid, errors, isSubmitting, touched, setFieldTouched, setFieldValue }) => (
        <>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Title
              onCancel={() => {
                navigate(`/examinees/${params.id}`);
              }}
              editing={true}
              editingValid={!isValidating && isValid && Object.keys(touched).length > 0}
              isSubmitting={isSubmitting}
              title={'Neues Intervall'}
            />
            <Row>
              <InputSection title="" xs={12} xl={6}>
                <InputGroup error={errors.vendor} valid={!errors.vendor} title="Prüfart" name="testTask">
                  <SelectInput
                    name="testTask"
                    disabled={isSubmitting}
                    onValueSelected={(e: string | number | boolean | undefined) => {
                      setFieldValue('testTask', e, true);
                      setFieldTouched('testTask');
                    }}
                  >
                    <option disabled selected value={0} label="Prüflingsgattung auswählen" />
                    {examinee.type.family.testTasks.map((testTasks) => (
                      <option key={testTasks.id} value={testTasks.id}>
                        {testTasks.name}
                      </option>
                    ))}
                  </SelectInput>
                </InputGroup>
                <InputGroup error={errors.interval} valid={!errors.interval} title="Intervall" name="interval">
                  <NumberInput disabled={isSubmitting} name="interval" float={false} />
                </InputGroup>
              </InputSection>
            </Row>
          </form>
          <FormikDebug />
        </>
      )}
    </Formik>
  );
};

export default New;
