import { IPollingConfig } from 'react-network-status/dist/types';

// Allow to override API URL in development
export const API = process.env.REACT_APP_API ?? process.env.API ?? 'http://localhost:4000/v1';

// Allow to force offline mode in development
export const FORCE_OFFLINE =
  process.env.NODE_ENV === 'production' ? false : process.env.REACT_APP_FORCE_OFFLINE === 'true' ?? false;

const u = pingUrl();

export const NETWORK_CHECK_CONFIG: IPollingConfig = {
  timeout: 5000,
  interval: process.env.NODE_ENV === 'production' ? 2000 : 10000,
  url: u,
};

console.log('NETWORK_CHECK_CONFIG', NETWORK_CHECK_CONFIG);
console.log('FORCE_OFFLINE', FORCE_OFFLINE);

function pingUrl() {
  if (FORCE_OFFLINE) return '/force-offline';
  if (process.env.NODE_ENV === 'production') return 'https://ipv4.icanhazip.com/';
  return `${API}/ping`;
}
