import React, { FunctionComponent } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

import styled from 'styled-components';
import { useError } from '../../context/error';

const TC = styled(ToastContainer)`
  .bg-danger .toast-header {
    background: var(--bs-red);
  }
  .bg-warning .toast-header {
    background: var(--bs-yellow);
  }
  .bg-info .toast-header,
  .bg-info {
    background: var(--bs-green);
  }
  .toast-header {
    color: #222 !important;
  }
`;

const Errors: FunctionComponent = () => {
  const eh = useError();
  return (
    <TC className="p-3" position="bottom-end">
      {eh.errors.map((error) => (
        <Toast key={error.id} bg="warning" onClose={() => eh.close(error)}>
          <Toast.Header closeButton={true}>
            <strong className="me-auto">{error.title ?? 'Fehler'}</strong>
          </Toast.Header>
          <Toast.Body>
            <p>
              <b>Beschreibung:</b>
              <br />
              {error.message}
            </p>
            {error.requestID && (
              <p>
                <b>Request:</b>
                <br />
                {error.requestID}
              </p>
            )}
          </Toast.Body>
        </Toast>
      ))}
    </TC>
  );
};

export default Errors;
