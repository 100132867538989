import { EmptyNewInstallationSite, NewInstallationSite } from '../../interfaces/InstallationSite';
import { ErrorLevel, useAddError, useClearError } from '../../context/error';
import { Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useState } from 'react';

import BareInput from '../../components/form/BareInput';
import InputGroup from '../../components/form/InputGroup';
import InputSection from '../../components/form/InputSection';
import { InstallationSitesService } from '../../services';
import { Row } from 'react-bootstrap';
import Title from '../../components/title';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import validateInstallationSiteName from '../../helpers/validate/validateInstallationSiteName';

const New: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const navigate = useNavigate();

  const [item] = useState<NewInstallationSite>(EmptyNewInstallationSite);

  const onSubmit = async (values: NewInstallationSite, { setSubmitting }: FormikHelpers<NewInstallationSite>) => {
    setSubmitting(true);
    clearError();
    try {
      const id = await InstallationSitesService(addError).create(values);
      if (id && id > 0) {
        navigate(`/settings/examinee-installation-sites/${id}`);
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isValidating, isValid, errors, isSubmitting, touched }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Title
            onCancel={() => {
              navigate('..');
            }}
            editing={true}
            editingValid={!isValidating && isValid && Object.keys(touched).length > 0}
            isSubmitting={isSubmitting}
            title={'Neuer Aufstellort'}
          />
          <Row>
            <InputSection title="Stammdaten" xs={12} xl={6}>
              <InputGroup error={errors.name} valid={!errors.name} title="Name" name="name">
                <BareInput disabled={isSubmitting} type="text" name="name" validate={validateInstallationSiteName} />
              </InputGroup>
            </InputSection>
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default New;
