import React, { FunctionComponent } from 'react';

interface RoleViewProps {
  role: string;
}

const RoleMap = {
  ADMINISTRATOR: 'Administrator',
  MANAGER: 'Manager',
  STANDARD: 'Mitarbeiter',
  VIEWER: 'Kunde',
  API_KEY: 'API Key',
};

const RoleView: FunctionComponent<RoleViewProps> = ({ role }) => {
  const r = (RoleMap as any)[role.toUpperCase()];
  return <>{r ?? 'Unbekannt'}</>;
};

export default RoleView;
