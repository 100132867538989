import { Button as BCButton } from 'react-bootstrap';
import { FunctionComponent } from 'react';
import React from 'react';
import styled from 'styled-components';

export declare type ButtonType = 'button' | 'reset' | 'submit';

const getColor = (colored?: 'true' | 'danger') => {
  if (colored === 'danger') return 'var(--bs-red)';
  if (colored === 'true') return 'var(--bs-primary)';
  return '#a1a1a1';
};

const B = styled(BCButton)<{
  colored?: 'true' | 'danger';
  hovercolored?: 'true' | 'danger';
  float?: 'left' | 'right';
  block?: boolean;
  breakat?: number;
  // onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void;
}>`
  background: transparent;
  float: ${(props) => props.float ?? 'left'};
  flex-wrap: nowrap;
  color: ${(props) => getColor(props.colored)};
  opacity: 1;
  svg {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 0 6px 4px 0;
    &,
    path,
    g {
      fill: ${(props) => getColor(props.colored)};
      color: ${(props) => getColor(props.colored)};
    }
  }
  span {
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 1px;
  }
  &:disabled,
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: #a1a1a1 !important;
    svg {
      &,
      path,
      g {
        fill: #a1a1a1 !important;
        color: #a1a1a1 !important;
      }
    }
  }
  &:hover {
    color: ${(props) => getColor(props.hovercolored ?? props.colored)};
    opacity: 0.8;
    &,
    path,
    g {
      fill: ${(props) => getColor(props.hovercolored ?? props.colored)};
      color: ${(props) => getColor(props.hovercolored ?? props.colored)};
    }
  }

  @media (max-width: ${(props) => (props.breakat ? props.breakat : 767)}px) {
    width: ${(props) => (props.block ? 100 : 50)}%;
    padding: 1rem;
    margin-top: 1rem;
    background: #efefef;
    min-width: 140px;
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  @media (max-width: 620px) {
    width: 100%;
  }
`;

const SubmitButton: FunctionComponent<{
  children?: React.ReactNode;
  disabled?: boolean;
  colored?: 'true' | 'danger';
  hovercolored?: 'true' | 'danger';
  type?: ButtonType;
  float?: 'left' | 'right';
  block?: boolean;
  breakat?: number;
  onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void;
  successredirect?: string;
}> = ({ block, breakat, children, colored, hovercolored, disabled, float, onClick, type, successredirect }) => {
  return (
    <B
      variant="link"
      colored={colored}
      hovercolored={hovercolored}
      disabled={disabled}
      type={type}
      float={float}
      block={block}
      breakat={breakat}
      onClick={onClick}
      successredirect={successredirect}
    >
      {children}
    </B>
  );
};

export default SubmitButton;
