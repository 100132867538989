import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';

import { Formik } from 'formik';
import { get } from 'lodash';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import BareInput from '../../components/form/BareInput';
import { useAuth } from '../../context/auth';
import InputGroup from './InputGroup';
import { ReactComponent as LockIcon } from './LockIcon.svg';
import { ReactComponent as UserIcon } from './UserIcon.svg';

const LoginContent = styled.div`
  width: 80%;
  @media (min-width: 767px) {
    width: 60%;
  }
  @media (min-width: 1200px) {
    width: 35%;
  }
  @media (min-width: 1600px) {
    width: 25%;
  }
`;
const Logo = styled.h1`
  color: #009842;
  text-align: center;
  font-size: 3rem;
  @media (min-width: 441px) {
    font-size: 5rem;
  }
`;

const FullWidthButton = styled.button`
  color: #fff;
  background-color: #009842;
  border-color: #009842;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  display: block;
  width: 100%;
  height: auto;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border: 1px solid transparent;
  user-select: none;
  text-align: center;
  vertical-align: middle;
  font-weight: 400;
  &:not(:disabled) {
    user-select: none;
cursor: pointer;
  }
  &[disabled], &:disabled, .disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
}
`;

const Link = styled(NavLink)`
  padding: 0 0 0.75rem 0.5rem;
  float: right;
  margin: 0;
  height: 100%;
  display: block;
  font-weight: 700;
  font-size: 1rem;
  color: #86cba4;
  padding-bottom: 16px;
  border-bottom: 2px solid rgba(1, 1, 1, 0);
  transition: color 0.22s ease 0s;
  text-decoration: none;
  background-color: initial;
  svg {
    fill: #86cba4;
  }
  &.active,
  &:hover {
    // border-bottom: 2px solid #009842;
    color: #009842;
    svg {
      fill: #009842;
    }
  }
`;

const Login: FunctionComponent = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [search] = useSearchParams();

  return (
    <LoginContent>
      <Logo>safeDOC</Logo>
      <Formik
        initialValues={{ email: search.get('email') ?? '', password: '' }}
        validate={(values) => {
          const errors: any = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Bitte geben Sie eine gültige E-Mail-Adresse ein';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          setSubmitting(true);
          auth
            .signin(values.email, values.password)
            .then(() => {
              setSubmitting(false);
              let path = search.get('redirect');
              if (path?.length === 0) path = null;
              if (path === null) path = '/';
              navigate(path);
            })
            .catch((e) => {
              const status = get(e, 'response.status');
              const message = get(e, 'message');
              if (status === 404) {
                setFieldError('password', 'Benutzer oder Passwort falsch');
              } else if (status === 400) {
                const validationErrors = get(e, 'response.data.errorsValidation[0]', 'Unbekannter Fehler');
                for (const key in validationErrors) {
                  setFieldError(key, validationErrors[key]);
                }
              } else {
                setFieldError('password', message);
              }
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <InputGroup icon={<UserIcon />} error={errors.email && touched.email ? errors.email : undefined}>
              <BareInput
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                autoComplete="username"
              />
            </InputGroup>
            <InputGroup icon={<LockIcon />} error={errors.password && touched.password ? errors.password : undefined}>
              <BareInput
                type="password"
                name="password"
                autoComplete="current-password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
            </InputGroup>
            <Link to={`/auth/reset?$email=${values.email}`}>Passwort vergessen?</Link>
            <FullWidthButton type="submit" disabled={isSubmitting}>
              Anmelden
            </FullWidthButton>
          </form>
        )}
      </Formik>
    </LoginContent>
  );
};

export default Login;
