import { reduce } from 'lodash';

export function cleanUp(f: Record<string, (number | string | boolean | [Date, Date])[]>) {
  const a = reduce<
    Record<string, (number | string | boolean | [Date, Date])[]>,
    Record<string, (number | string | boolean | [Date, Date])[]>
  >(
    f,
    (prev, curr, key) => {
      if (curr && !(Array.isArray(curr) && curr.length === 0)) {
        prev[key] = curr;
      }
      return prev;
    },
    {},
  );
  if (Object.keys(a).length === 0) {
    return undefined;
  } else {
    return a;
  }
}

export const removeKeysWithUndefindedValue = (data: Record<string, any>) => {
  return reduce(
    data,
    (prev, curr, key) => {
      if (curr) {
        prev[key] = curr;
      }
      return prev;
    },
    {} as Record<string, any>,
  ) as URLSearchParams;
};
