import { FieldAttributes, useField } from 'formik';

import { FunctionComponent, createRef, useMemo } from 'react';
import styled from 'styled-components';

const Image = styled.label<{
  src: string;
  disabled?: boolean;
}>`
  width: 100%;
  height: 109px;
  background-image: url(${(props) => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: ${(props) => (props.disabled ? 'arrow' : 'pointer')};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ImageInput: FunctionComponent<
  FieldAttributes<any> & {
    disabled?: boolean;
  }
> = ({ children, valueType, disabled, ...props }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fileInputRef = createRef<HTMLInputElement>();

  const imgSrc = useMemo(() => {
    if (field.value === undefined) {
      return '';
    }

    if (typeof field.value === 'string') {
      return field.value;
    }

    if (field.value instanceof File) {
      return URL.createObjectURL(field.value);
    }

    return `${field.value}`;
  }, [field.value]);

  const handleChange = (e: any) => {
    console.log(e.target.files);
    helpers.setValue(e.target.files[0]);
    helpers.setTouched(true);
  };

  return (
    <Wrapper
      onClick={() => {
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
      }}
    >
      <Image src={imgSrc} disabled={disabled} />
      <input hidden ref={fileInputRef} accept="image/*" disabled={disabled} type="file" onChange={handleChange} />
    </Wrapper>
  );
};

export default ImageInput;
