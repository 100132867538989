import { AddSafeDocError, ErrorLevel, useAddError, useClearError } from '../../../../context/error';
import BareInput from '../../../../components/form/BareInput';
import { Modal } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useEffect, useState } from 'react';
import { Step, Wizard } from '../../../../components/wizard';
import {
  ExamineeAdditionalInformationFieldService,
  ExamineeAdditionalInformationFieldOptionService,
} from '../../../../services';
import { useNavigate, useParams } from 'react-router-dom';

import InputGroup from '../../../../components/form/InputGroup';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import { NewExamineeAdditionalInformationFieldOption } from '../../../../interfaces/ExamineeAdditionalInformationFieldOption';
import { ExamineeAdditionalInformationField } from '../../../../interfaces/ExamineeAdditionalInformationField';

import { get } from 'lodash';
import validateMinLength from '../../../../helpers/validate/validateMinLength';

type VT = NewExamineeAdditionalInformationFieldOption;

const getTitleForSavingStep = (step: number, hasError?: boolean) => {
  if (hasError) return 'Es ist ein Fehler aufgetreten!';
  if (step === 0) return 'Auswahlmöglichkeit wird gespeichert...';
  if (step === 1) return 'Fertig!';
  return '';
};

const ExamineeAdditionalInformationFieldOptionNewModal: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const navigate = useNavigate();
  const params = useParams();

  const [examineeAdditionalInformationField, setExamineeAdditionalInformationField] =
    useState<ExamineeAdditionalInformationField>();

  const [savingStep, setSavingStep] = useState(-1);
  const [savingResult, setSavingResult] = useState<number | AddSafeDocError>();

  useEffect(() => {
    const loadExamineeAdditionalInformationField = async () => {
      if (params.tgid) {
        const _tv = await ExamineeAdditionalInformationFieldService(addError).get(params.tgid);
        setExamineeAdditionalInformationField(_tv);
      }
    };
    loadExamineeAdditionalInformationField();
  }, [addError, params.tgid]);

  const [item] = useState<VT>({
    option: '',
    field: 0,
  });

  const [step, setStep] = useState(0);

  const onSubmit = async (values: VT, { setSubmitting }: FormikHelpers<VT>) => {
    setSubmitting(true);
    clearError();
    try {
      setSavingStep(0);

      const newExamineeAdditionalInformationFieldOption: NewExamineeAdditionalInformationFieldOption = {
        option: values.option,
        field: examineeAdditionalInformationField?.id ?? 0,
      };

      const res = await ExamineeAdditionalInformationFieldOptionService(addError).create(
        newExamineeAdditionalInformationFieldOption,
        true,
      );
      setSavingResult(res);
      setSavingStep(1);
      navigate(`/settings/examinee-families/${params.id}/examinee-additional-information-fields/${params.tgid}`);
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal size="lg" centered show={true}>
      <Formik validateOnChange enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
        {({ setFieldTouched, handleChange, errors, isSubmitting, touched, values, setFieldValue, submitForm }) => (
          <form>
            <Wizard
              step={step}
              preparing={!examineeAdditionalInformationField}
              onChange={(s) => {
                setStep(s);
              }}
              onClose={() =>
                navigate(
                  `/settings/examinee-families/${params.id}/examinee-additional-information-fields/${params.tgid}`,
                )
              }
              onComplete={() => {
                submitForm();
              }}
            >
              <Step
                title={`Neue Auswahlmöglichkeit für ${examineeAdditionalInformationField?.name}`}
                description="Start"
                valid={true}
              >
                <p>Dieser Assistent unterstütz Sie bei der Erstellung einer neuen Auswahlmöglichkeit.</p>
              </Step>
              <Step
                title="Auswahlmöglichkeit"
                description="Auswahlmöglichkeit"
                valid={!errors.option && !!touched.option}
              >
                <p>Bitte geben Sie die neu Auswahlmöglichkeit ein.</p>
                <InputGroup
                  error={errors.option}
                  valid={!errors.option}
                  title="Nachricht"
                  name="option"
                  variant="gray-200"
                >
                  <BareInput
                    disabled={isSubmitting}
                    type="text"
                    name="option"
                    validate={(v: any) => validateMinLength(1, v)}
                    onChange={(e: any) => {
                      setFieldTouched('option');
                      handleChange(e);
                    }}
                  />
                </InputGroup>
              </Step>
              <Step
                title={getTitleForSavingStep(
                  savingStep,
                  savingResult !== undefined && typeof savingResult !== 'number',
                )}
                description="Abschluss"
                notCloseable={savingStep !== 1}
              >
                {savingStep < 1 && <LoadingIndicator size={40} />}
                {savingStep === 1 && typeof savingResult === 'number' && (
                  <p>Die Auswahlmöglichkeit {values?.name} wurde erfolgreich erstellt.</p>
                )}
                {savingStep === 1 && typeof savingResult !== 'number' && <p>{savingResult?.message}</p>}
              </Step>
            </Wizard>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default ExamineeAdditionalInformationFieldOptionNewModal;
