import Table, { TableColProps } from '../../table';

import { Examinee } from '../../../interfaces/Examinee';
import { ExamineeTestTaskInterval } from '../../../interfaces/ExamineeTestTaskInterval';
import { FunctionComponent } from 'react';

interface ExamineeTestTaskIntervalListProps {
  examinee: Pick<Examinee, 'id' | 'intervals'>;
  loading: boolean;
}

const columns: TableColProps<ExamineeTestTaskInterval>[] = [
  { title: 'Prüfart', width: 2, values: 'testTask.initials' },
  {
    title: 'Monate',
    width: 4,
    values: (item: ExamineeTestTaskInterval) => {
      return item.interval;
    },
  },
];

const ExamineeTestTaskIntervalList: FunctionComponent<ExamineeTestTaskIntervalListProps> = ({ loading, examinee }) => {
  return (
    <Table
      navigatePrefix={`/examinees/${examinee.id}/intervals/`}
      loading={loading}
      items={examinee.intervals}
      columns={columns}
    />
  );
};

export default ExamineeTestTaskIntervalList;
