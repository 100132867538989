import Table, { TableColProps } from '../../../../components/table';

import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { TestAdditionalInformationField } from '../../../../interfaces/TestAdditionalInformationField';
import { TestAdditionalInformationFieldOption } from '../../../../interfaces/TestAdditionalInformationFieldOption';

interface TestAdditionalInformationFieldOptionListProps {
  item: TestAdditionalInformationField;
  loading: boolean;
}

const columns: TableColProps<TestAdditionalInformationFieldOption>[] = [
  { title: 'Auswahlmöglichkeit', values: 'option' },
];

const TestAdditionalInformationFieldOptionList: FunctionComponent<TestAdditionalInformationFieldOptionListProps> = ({
  loading,
  item,
}) => {
  const params = useParams();
  return (
    <Table
      loading={loading}
      items={item.valueOptions.sort((a, b) => a.option.localeCompare(b.option))}
      columns={columns}
      navigatePrefix={`/settings/examinee-families/${params.id}/test-additional-information-fields/${params.tgid}/options/`}
    />
  );
};

export default TestAdditionalInformationFieldOptionList;
