import { Container, Navbar } from 'react-bootstrap';

import { FunctionComponent } from 'react';
import { ReactComponent as MenuIcon } from './menu.svg';
import { NavLink } from 'react-router-dom';
import { ReactComponent as _Logo } from './logo.svg';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

const Logo = styled(_Logo)`
  height: 28px;
  margin: 10px auto;
`;

const WhiteNavbar = styled(Navbar)`
  background: #fff;
`;

const Link = styled(NavLink)`
  padding: 0.75rem 0.5rem;
  margin: 0;
  @media (min-width: 1200px) {
    margin: 0 28px -0.5rem 28px;
    padding: 0.5rem 1rem;
  }
  height: 100%;
  display: block;
  font-weight: 700;
  font-size: 1rem;
  color: #86cba4;
  padding-bottom: 16px;
  border-bottom: 2px solid rgba(1, 1, 1, 0);
  transition: color 0.22s ease 0s;
  text-decoration: none;
  background-color: initial;
  svg {
    fill: #86cba4;
  }
  &.active,
  &:hover {
    // border-bottom: 2px solid #009842;
    color: #009842;
    svg {
      fill: #009842;
    }
  }
`;

const Header: FunctionComponent = () => {
  const location = useLocation();
  const redirect = encodeURI(location.pathname);
  const loginPath = `/auth/login?redirect=${redirect}`;

  return (
    <WhiteNavbar variant="light" fixed="top" expand="xl">
      <Container fluid>
        <Navbar.Brand href="/">
          <Logo />
        </Navbar.Brand>

        <Navbar.Text className="justify-content-end">
          <Link to={loginPath}>
            <MenuIcon />
          </Link>
        </Navbar.Text>
      </Container>
    </WhiteNavbar>
  );
};

export default Header;
