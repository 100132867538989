import { EmptyNewExaminee, NewExaminee } from '../../interfaces/Examinee';
import { ErrorLevel, useAddError, useClearError } from '../../context/error';
import { Formik, FormikHelpers } from 'formik';
import { get, reduce } from 'lodash';

import BareInput from '../../components/form/BareInput';
import ExamineeAdditionalInformationSection from '../../components/common/examinee/ExamineeAdditionalInformationSection';
import ExamineeFamilySelect from '../../components/common/examinee/ExamineeFamilySelect';
import ExamineeTypeSelect from '../../components/common/examinee/ExamineeTypeSelect';
import { ExamineesService, UserService } from '../../services';
import FormikDebug from '../../components/dev/formik-debug';
import { FunctionComponent, useEffect, useState } from 'react';
import InputGroup from '../../components/form/InputGroup';
import InputSection from '../../components/form/InputSection';
import InstallationSiteSelect from '../../components/common/examinee/InstallationSiteSelect';
import { PersistFormikValues } from '../../helpers/PersistFormikValues';
import { Row } from 'react-bootstrap';
import Title from '../../components/title';
import VendorSelect from '../../components/common/examinee/VendorSelect';
import { useMain } from '../../context/main';
import { useNavigate } from 'react-router-dom';
import validateExamineeTagFree from '../../helpers/validate/validateExamineeTagFree';
import LoadingIndicator from '../../components/common/LoadingIndicator';

type FormType = NewExaminee & {
  family: number;
  additionalInformation?: Record<string, any>;
};

const New: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const navigate = useNavigate();
  const { selectedLocation } = useMain();

  const onSubmit = async (
    values: NewExaminee & { family: number },
    { setSubmitting }: FormikHelpers<NewExaminee & { family: number }>,
  ) => {
    console.log('setSubmitting');

    setSubmitting(true);
    clearError();
    try {
      const additionalInformation = reduce<Record<number, string>, Record<string, string>>(
        values.additionalInformation,
        (prev, acc, key) => {
          if (acc) {
            prev[key.toString()] = acc;
          }
          return prev;
        },
        {},
      );
      const id = await ExamineesService(addError).create({
        ...values,
        additionalInformation,
      });
      if (id && id > 0) {
        navigate(`/examinees/${id}`);
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const validate = (values: FormType) => {
    return validateExamineeTagFree(values.tag)
      .then((tag) => {
        const errors: any = {};

        if (tag) {
          errors.tag = tag;
        }

        if (values.installationSite < 1) {
          errors['installationSite'] = true;
        }

        if (values.vendor < 1) {
          errors['vendor'] = true;
        }

        if (!values.family || values.family === 0) {
          errors['family'] = true;
        }

        if (values.type < 1) {
          errors['type'] = true;
        }

        if (values.location < 1) {
          errors['location'] = true;
        }

        return errors;
      })
      .catch((e) => {
        return {
          tag: 'Die ID konnte nicht überprüft werden.',
        };
      });
  };

  const [initialValues, setInitialValues] = useState<FormType | undefined>(undefined);

  useEffect(() => {
    const load = async () => {
      const [family, intervals, installationSiteDescription, installationSite] = await Promise.all([
        UserService(addError).getPreference<number>('examinee.create.family'),
        UserService(addError).getPreference<any[]>('examinee.create.intervals'),
        UserService(addError).getPreference<string>('examinee.create.installationSiteDescription'),
        UserService(addError).getPreference<number>('examinee.create.installationSite'),
      ]);

      setInitialValues({
        ...EmptyNewExaminee,
        location: selectedLocation && selectedLocation.length > 0 ? selectedLocation[0].value : 0,
        family: family ?? 0,
        installationSite: installationSite ?? 0,
        intervals: intervals ?? [],
        installationSiteDescription,
      });
    };
    load();
  }, [addError, selectedLocation]);

  if (!initialValues) {
    return <LoadingIndicator size={40} />;
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...initialValues,
      }}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({ handleSubmit, isValidating, isValid, errors, isSubmitting, setFieldValue }) => (
        <>
          <form
            autoComplete="off"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Title
              onCancel={() => {
                navigate('..');
              }}
              editing={true}
              editingValid={!isValidating && isValid}
              isSubmitting={isSubmitting}
              title={'Neuer Prüfling'}
            />
            <Row>
              <InputSection title="Stammdaten" xs={12} xl={6}>
                <InputGroup
                  error={errors.tag ? errors.tag : undefined}
                  valid={!errors.tag}
                  title="Eindeutige ID"
                  name="tag"
                >
                  <BareInput disabled={isSubmitting} type="text" name="tag" />
                </InputGroup>
                <ExamineeFamilySelect
                  onValueSelected={(f) => {
                    setFieldValue('type', 0);
                  }}
                  editing={true}
                />
                <ExamineeTypeSelect editing={true} />
                <VendorSelect editing={true} />
              </InputSection>
              <InputSection title="Aufstellort" xs={12} xl={6}>
                <InstallationSiteSelect editing={true} />
                <InputGroup
                  valid={!errors.installationSiteDescription}
                  title="Bezeichnung"
                  name="installationSiteDescription"
                >
                  <BareInput disabled={isSubmitting} type="text" name="installationSiteDescription" />
                </InputGroup>
              </InputSection>
              <InputSection title="zusätzliche Felder" xs={12} xl={6}>
                <ExamineeAdditionalInformationSection editing={true} />
              </InputSection>
            </Row>
            <PersistFormikValues name="examinees-new-form" persistInvalid={true} debounce={50} shouldValidate={true} />
          </form>
          <FormikDebug />
        </>
      )}
    </Formik>
  );
};

export default New;
