import List from './List';
import MainLayout from '../../layouts/MainLayout';
import New from './New';
import NewInterval from './intervals/New';
import React from 'react';
import RequireAuth from '../../guards/RequireAuth';
import RequireLocation from '../../guards/RequireLocation';
import { Route } from 'react-router';
import SelectLocationModal from './SelectLocationModal';
import Show from './Show';
import ShowInterval from './intervals/Show';
import ExtendedExamineeService from '../../services/ExtendedExamineeService';

// this is a helper function to auto load the location
const auto = async (path: string) => {
  const id = path.split('/')[2];
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const examinee = await ExtendedExamineeService(() => {})
    .get(id)
    .catch(() => {
      return undefined;
    });
  return examinee?.location;
};

const Routes = (
  <Route
    path="/examinees"
    element={
      <RequireAuth>
        <MainLayout />
      </RequireAuth>
    }
  >
    <Route path="/examinees/" element={<List />} />
    <Route
      path=":id/intervals/new"
      element={
        <RequireLocation auto={auto}>
          <NewInterval />
        </RequireLocation>
      }
    />
    <Route
      path=":id/intervals/:iid"
      element={
        <RequireLocation auto={auto}>
          <ShowInterval />
        </RequireLocation>
      }
    />
    <Route
      path=":id/*"
      element={
        <RequireLocation auto={auto}>
          <Show />
        </RequireLocation>
      }
    />
    <Route
      path="new/*"
      element={
        <RequireLocation auto={auto}>
          <New />
        </RequireLocation>
      }
    />
    <Route path="new/location" element={<SelectLocationModal />} />
  </Route>
);

export default Routes;
