import { Nav } from 'react-bootstrap';

import React, { FunctionComponent } from 'react';
import { Link as NavLink } from 'react-router-dom';
import styled from 'styled-components';
import OfflineService from '../../services/OfflineService';

interface OfflineButtonProps {
  className?: string;
}

const Link = styled(NavLink)`
  padding: 0.75rem 0.5rem;
  margin: 0;
  @media (min-width: 1200px) {
    margin: 0 28px -0.5rem 28px;
    padding: 0.5rem 1rem;
  }
  height: 100%;
  display: block;
  font-weight: 700;
  font-size: 1rem;
  color: #86cba4;
  padding-bottom: 16px;
  border-bottom: 2px solid rgba(1, 1, 1, 0);
  transition: color 0.22s ease 0s;
  text-decoration: none;
  background-color: initial;
  svg {
    fill: #86cba4;
  }
  &.active,
  &:hover {
    // border-bottom: 2px solid #009842;
    color: #009842;
    svg {
      fill: #009842;
    }
  }
`;

const OfflineButton: FunctionComponent<OfflineButtonProps> = ({ className }) => {
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      OfflineService()
        .offlineCount()
        .then((count) => setCount(count));
    }, 1000);
    return () => clearInterval(interval);
  }, [count]);

  return (
    <Nav className={className}>
      <Link to="/offline" reloadDocument={true}>
        Offline {count > 0 ? `(${count})` : ''}
      </Link>
    </Nav>
  );
};

export default OfflineButton;
