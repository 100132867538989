import { Html5QrcodeScanner, Html5QrcodeSupportedFormats } from 'html5-qrcode';
import { QrcodeErrorCallback, QrcodeSuccessCallback, QrDimensionFunction, QrDimensions } from 'html5-qrcode/esm/core';
import React from 'react';

const qrcodeRegionId = 'html5qr-code-full-region';

interface Html5QrcodePluginProps {
  verbose: boolean;
  fps: number | undefined;
  qrbox?: number | QrDimensions | QrDimensionFunction | undefined;
  aspectRatio?: number | undefined;
  disableFlip?: boolean | undefined;
  qrCodeSuccessCallback: (decodedText: string) => Promise<boolean>;
  qrCodeErrorCallback: QrcodeErrorCallback;
}

class Html5QrcodePlugin extends React.Component<Html5QrcodePluginProps> {
  html5QrcodeScanner: Html5QrcodeScanner | undefined;

  render() {
    return <div id={qrcodeRegionId} />;
  }

  componentWillUnmount() {
    this.html5QrcodeScanner &&
      this.html5QrcodeScanner.clear().catch((error) => {
        console.error('Failed to clear html5QrcodeScanner. ', error);
      });
  }

  componentDidMount() {
    // Creates the configuration object for Html5QrcodeScanner.
    function createConfig(
      props: Pick<Html5QrcodePluginProps, 'fps' | 'verbose' | 'qrbox' | 'disableFlip' | 'aspectRatio'>,
    ) {
      const config: any = {
        rememberLastUsedCamera: true,
        showTorchButtonIfSupported: true,
        showZoomSliderIfSupported: false,
        formatsToSupport: [
          Html5QrcodeSupportedFormats.QR_CODE,
          Html5QrcodeSupportedFormats.DATA_MATRIX,
          Html5QrcodeSupportedFormats.CODE_128,
        ],
      };
      if (props.fps) {
        config.fps = props.fps;
      }
      if (props.qrbox) {
        config.qrbox = props.qrbox;
      }
      if (props.aspectRatio) {
        config.aspectRatio = props.aspectRatio;
      }
      if (props.disableFlip !== undefined) {
        config.disableFlip = props.disableFlip;
      }
      return config;
    }

    const config = createConfig(this.props);
    const verbose = this.props.verbose === true;

    // Suceess callback is required.
    if (!this.props.qrCodeSuccessCallback) {
      throw new Error('qrCodeSuccessCallback is required callback.');
    }

    const callback: QrcodeSuccessCallback = async (code) => {
      const res = await this.props.qrCodeSuccessCallback(code);
      try {
        if (res) this.html5QrcodeScanner?.clear();
      } catch (error) {}
    };

    this.html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose);
    this.html5QrcodeScanner.render(callback, this.props.qrCodeErrorCallback);
  }
}

export default Html5QrcodePlugin;
