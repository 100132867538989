import { Tester } from './Tester';
import { NewType, UpdateType } from './removeDates';
import { TestTask } from './TestTask';

export interface TestingDevice {
  id: number;
  serial: string;
  name: string;
  vendor: string;
  testers: Tester[];
  defaultTestingDeviceUsage: DefaultTestingDevice[];
  defaultTestTasks: TestTask[];
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export type NewTestingDevice = NewType<TestingDevice, 'serial' | 'name' | 'vendor'>;

export type UpdateTestingDevice = UpdateType<TestingDevice, 'serial' | 'name' | 'vendor'>;

export const EmptyNewTestingDevice: NewTestingDevice = {
  name: '',
  vendor: '',
  serial: '',
};

export const EmptyTestingDevice: TestingDevice = {
  id: 0,
  name: '',
  vendor: '',
  serial: '',
  testers: [],
  defaultTestTasks: [],
  defaultTestingDeviceUsage: [],
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
};

export interface DefaultTestingDevice {
  id: number;
  tester: Tester;
  testTask: TestTask;
  testingDevice: TestingDevice;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}
