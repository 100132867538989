import { Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useEffect, useState } from 'react';
import { Col, Modal, Row, ToggleButton } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import BareInput, { NumberInput } from '../../../../components/form/BareInput';
import { Step, Wizard } from '../../../../components/wizard';
import { AddSafeDocError, ErrorLevel, useAddError, useClearError } from '../../../../context/error';
import {
  TestTaskService,
  TestValueGroupService,
  TestValueService,
  TestValueThresholdService,
} from '../../../../services';
import { SELECTABLE_BOOLEAN, SELECTABLE_THRESHOLD_TYPE, SELECTABLE_THRESHOLD_TYPE_NUM } from '../statics';

import { get } from 'lodash';
import styled from 'styled-components';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import InputGroup from '../../../../components/form/InputGroup';
import ToggleInput from '../../../../components/form/ToggleInput';
import validateMinLength from '../../../../helpers/validate/validateMinLength';
import { TestTask } from '../../../../interfaces/TestTask';
import { TestValue } from '../../../../interfaces/TestValue';
import { TestValueGroup } from '../../../../interfaces/TestValueGroup';
import { NewTestValueThreshold } from '../../../../interfaces/TestValueThreshold';
import { ValueType } from '../../../../interfaces/ValueType';

type VT = Omit<NewTestValueThreshold, 'testTask' | 'testValue' | 'prio'> & {
  testTask?: TestTask;
  testValue?: TestValue;
};

const P = styled.p<{ small: boolean }>`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  color: ${(props) => (props.small ? '#b3b3b3' : '#000')};
`;

const B = styled.div`
  min-width: 75px;
  p {
    text-align: center;
  }
  // height: 25px;
`;

const ValueTypeButton: FunctionComponent<{ name: string }> = ({ name }) => (
  <B>
    <P small={false}>{name}</P>
    <P small={true}></P>
  </B>
);

const WD = styled.div`
  width: 80%;
`;

const getTitleForSavingStep = (step: number, hasError?: boolean) => {
  if (hasError) return 'Es ist ein Fehler aufgetreten!';
  if (step === 0) return 'Prüfwert wird gespeichert...';
  if (step === 1) return 'Fertig!';
  return '';
};

const TestValeThresholdNewModal: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const navigate = useNavigate();
  const params = useParams();

  const [task, setTask] = useState<TestTask>();
  const [testValue, setTestValue] = useState<TestValue>();
  const [group, setGroup] = useState<TestValueGroup>();

  const [savingStep, setSavingStep] = useState(-1);
  const [savingResult, setSavingResult] = useState<number | AddSafeDocError>();

  useEffect(() => {
    const loadTask = async () => {
      if (params.id) {
        const _task = await TestTaskService(addError).get(params.id);
        setTask(_task);
      }
    };
    loadTask();
  }, [addError, params.id]);

  useEffect(() => {
    const loadTestValue = async () => {
      if (params.tvid) {
        const _tv = await TestValueService(addError).get(params.tvid);
        setTestValue(_tv);
      }
    };
    loadTestValue();
  }, [addError, params.tvid]);

  useEffect(() => {
    const loadTask = async () => {
      if (params.tgid) {
        const _group = await TestValueGroupService(addError).get(params.tgid);
        setGroup(_group);
      }
    };
    loadTask();
  }, [addError, params.tgid]);

  const [item] = useState<VT>({
    description: '',
    triggersFailure: undefined,
    thresholdType: undefined,
    thresholdValue: '',
  });

  const [step, setStep] = useState(0);

  const onSubmit = async (values: VT, { setSubmitting }: FormikHelpers<VT>) => {
    setSubmitting(true);
    clearError();
    try {
      setSavingStep(0);

      const newTestValueThreshold: NewTestValueThreshold = {
        description: values.description,
        triggersFailure: values.triggersFailure,
        thresholdType: values.thresholdType,
        thresholdValue: values.thresholdValue,
        testValue: testValue!.id,
        testTask: task!.id,
        group: group!.id,
        prio: 0,
      };

      const res = await TestValueThresholdService(addError).create(newTestValueThreshold, true);
      setSavingResult(res);
      setSavingStep(1);
      navigate(`/settings/tasks/${params.id}/test-value-groups/${params.tgid}/test-values/${params.tvid}`);
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal size="lg" centered show={true}>
      <Formik validateOnChange enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
        {({ setFieldTouched, handleChange, errors, isSubmitting, touched, values, setFieldValue, submitForm }) => (
          <form>
            <Wizard
              step={step}
              preparing={!task || !group}
              onChange={(s) => {
                setStep(s);
              }}
              onClose={() =>
                navigate(`/settings/tasks/${params.id}/test-value-groups/${params.tgid}/test-values/${params.tvid}`)
              }
              onComplete={() => {
                submitForm();
              }}
            >
              <Step
                title={`Neuer Schwellwert für ${testValue?.name} bei ${task?.initials}-Prüfungen in der Gruppe ${group?.name}`}
                description="Start"
                valid={true}
              >
                <p>Dieser Assistent unterstütz Sie bei der Erstellung eines neuen Schwellwert.</p>
              </Step>
              <Step title="Nachricht" description="Nachricht" valid={!errors.description && !!touched.description}>
                <p>Bitte geben Sie eine Nachricht ein, die dem Tester später angezeigt wird.</p>
                <InputGroup
                  error={errors.description}
                  valid={!errors.description}
                  title="Nachricht"
                  name="description"
                  variant="gray-200"
                >
                  <BareInput
                    disabled={isSubmitting}
                    type="text"
                    name="description"
                    validate={(v: any) => validateMinLength(1, v)}
                    onChange={(e: any) => {
                      setFieldTouched('description');
                      handleChange(e);
                    }}
                  />
                </InputGroup>
              </Step>
              <Step
                title="in Ordnung?"
                description="Pflichtfeld"
                valid={!errors.triggersFailure && !!touched.triggersFailure}
              >
                <WD>
                  <Row>
                    <Col>
                      <p>Scheitert die Prüfungen, wenn der Schwellwert nicht eingehalten wird?</p>
                    </Col>
                  </Row>
                  <Row>
                    {SELECTABLE_BOOLEAN.map(({ value, title }, idx) => (
                      <Col>
                        <ToggleButton
                          className="m-2"
                          key={idx}
                          id={`radio-${idx}`}
                          type="radio"
                          variant="outline-dark"
                          name="radio"
                          value={value ? 'true' : 'false'}
                          checked={values.triggersFailure === value}
                          onChange={() => {
                            setFieldTouched('triggersFailure');
                            setFieldValue('triggersFailure', value, true);
                          }}
                        >
                          <ValueTypeButton name={title} />
                        </ToggleButton>
                      </Col>
                    ))}
                  </Row>
                </WD>
              </Step>

              <Step
                title="Schwellwert"
                description="Wert"
                valid={
                  !errors.thresholdType && !errors.thresholdValue && !!touched.thresholdType && !!touched.thresholdValue
                }
              >
                <p>Bitte geben Sie den Schwellwert und die Art der Schwelle ein.</p>
                <Row>
                  <Col>
                    <Row>
                      {((testValue && testValue.valueType === ValueType.NUMBER) ||
                      (testValue && testValue.valueType === ValueType.FLOAT)
                        ? SELECTABLE_THRESHOLD_TYPE_NUM
                        : SELECTABLE_THRESHOLD_TYPE
                      ).map(({ value, title }, idx) => (
                        <Col xs={12}>
                          <ToggleButton
                            className="m-2"
                            key={idx}
                            id={`radio-${idx}`}
                            type="radio"
                            variant="outline-dark"
                            name="radio"
                            value={value ? 'true' : 'false'}
                            checked={values.thresholdType === value}
                            onChange={() => {
                              setFieldTouched('thresholdType');
                              setFieldValue('thresholdType', value, true);
                            }}
                          >
                            <ValueTypeButton name={title} />
                          </ToggleButton>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                  <Col>
                    <InputGroup
                      error={errors.thresholdValue}
                      valid={!errors.thresholdValue}
                      title="Wert"
                      name="thresholdValue"
                      variant="gray-200"
                    >
                      {testValue && testValue.valueType === ValueType.STRING && (
                        <BareInput type="text" name="thresholdValue" />
                      )}
                      {testValue &&
                        (testValue.valueType === ValueType.NUMBER || testValue.valueType === ValueType.FLOAT) && (
                          <NumberInput name="thresholdValue" float={testValue.valueType === ValueType.FLOAT} />
                        )}
                      {testValue && testValue.valueType === ValueType.BOOLEAN && <ToggleInput name="thresholdValue" />}
                    </InputGroup>
                  </Col>
                </Row>
              </Step>
              <Step
                title={getTitleForSavingStep(
                  savingStep,
                  savingResult !== undefined && typeof savingResult !== 'number',
                )}
                description="Abschluss"
                notCloseable={savingStep !== 1}
              >
                {savingStep < 1 && <LoadingIndicator size={40} />}
                {savingStep === 1 && typeof savingResult === 'number' && (
                  <p>Der Prüfwert {values?.name} wurde erfolgreich erstellt.</p>
                )}
                {savingStep === 1 && typeof savingResult !== 'number' && <p>{savingResult?.message}</p>}
              </Step>
            </Wizard>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default TestValeThresholdNewModal;
