import { AddErrorFunction, AddSafeDocError } from '../context/error';
import { FilterType, Service } from './Service';

import axios from 'axios';
import { get as _get } from 'lodash';
import queryString from 'query-string';
import { API } from '../config';
import { NewExtension, OfflineStore } from '../storage';
import handleError from './errorHandler';
import getToken from './getToken';

export class OfflineError extends Error {
  constructor() {
    super('Offline');
  }
}

const BaseService = <T, UT, NT, CR = number>(
  path: string,
  addError: AddErrorFunction,
  offline?: OfflineStore<T>,
  createdStore?: OfflineStore<NT & NewExtension>,
  noCache = true,
): Service<T, UT, NT, CR> => {
  const cachePolicy = () => {
    if (noCache) {
      return 'no-cache, max-age=0';
    } else {
      return 'max-age=120';
    }
  };

  const list = <ST = T>(limit = 25, page = 0, filter?: FilterType<T>, params?: Record<string, string>) => {
    const query = queryString.stringify({
      limit,
      page,
      filter: Object.keys(filter ?? {}).length > 0 ? JSON.stringify(filter) : undefined,
      ...(params ?? {}),
    });
    return axios
      .get(`${API}/${path}/?${query}`, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        return { items: _get(res, 'data.data.items') as ST[], count: _get(res, 'data.data.count') as number };
      })
      .catch(async (e) => {
        if (e.code === 'ERR_NETWORK' && offline) {
          // try to get from offline store
          const items = await offline.list<ST>(filter);
          return { items, count: -1 };
        }

        addError(handleError(e));
        return { items: [] as ST[], count: 0 };
      });
  };

  const search = <ST = T>(
    term: string,
    limit = 25,
    page = 0,
    filter?: FilterType<T>,
    params?: Record<string, string>,
  ) => {
    const q = term.trim();
    const query = queryString.stringify({
      q,
      page,
      limit,
      filter: Object.keys(filter ?? {}).length > 0 ? JSON.stringify(filter) : undefined,
      ...(params ?? {}),
    });
    return axios
      .get(`${API}/${path}/?${query}`, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        return { items: _get(res, 'data.data.items') as ST[], count: _get(res, 'data.data.count') as number };
      })
      .catch((e) => {
        addError(handleError(e));
        return { items: [] as ST[], count: 0 };
      });
  };

  const get = <ST = T>(id: string, params?: Record<string, string>) => {
    const query = queryString.stringify({
      ...(params ?? {}),
    });
    return axios
      .get(`${API}/${path}/${id}?${query}`, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        return _get(res, 'data.data') as ST;
      })
      .catch(async (e) => {
        if (e.code === 'ERR_NETWORK') {
          // try to get from offline store
          const item = await offline?.getItem(id);
          return item as ST;
        }
        addError(handleError(e));
        return {} as ST;
      });
  };

  const destroy = (id: string, suppress = false) => {
    return axios
      .delete(`${API}/${path}/${id}`, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        return true;
      })
      .catch((e) => {
        const handledError = handleError(e);
        if (suppress) {
          return handledError;
        } else {
          addError(handledError);
          return false;
        }
      });
  };

  const create = (data: NT, suppress = false): Promise<CR | AddSafeDocError | undefined> => {
    return axios
      .post(`${API}/${path}`, data, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        return _get(res, 'data.data.id') as CR;
      })
      .catch(async (e) => {
        if (e.code === 'ERR_NETWORK') {
          if (!createdStore) {
            throw new OfflineError();
          }

          const id = -new Date().getTime();
          await createdStore.setItem(id.toString(), {
            ...data,
            id: id.toString(),
            created_at: new Date().toISOString(),
          });
          return id as CR;
        }

        const handledError = handleError(e);
        if (suppress) {
          return handledError;
        } else {
          addError(handledError);
          return undefined;
        }
      });
  };

  const update = (id: string, data: UT, suppress = false) => {
    return axios
      .put(`${API}/${path}/${id}`, data, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then(() => {
        return true;
      })
      .catch((e) => {
        if (e.code === 'ERR_NETWORK') {
          throw new OfflineError();
        }

        const handledError = handleError(e);
        if (suppress) {
          return handledError;
        } else {
          addError(handledError);
          return false;
        }
      });
  };

  return { list, get, update, destroy, create, search };
};

export default BaseService;
