import DoTest from './DoTest';
import List from './List';
import MainLayout from '../../layouts/MainLayout';
import NoTester from './NoTester';
import RequireAuth from '../../guards/RequireAuth';
import RequireLocation from '../../guards/RequireLocation';
import RequireTester from '../../guards/RequireTester';
import { Route } from 'react-router';
import Show from './Show';
import Start from './Start';
import TestOverview from './TestOverview';

const Routes = (
  <Route
    path="/tests"
    element={
      <RequireAuth>
        <MainLayout />
      </RequireAuth>
    }
  >
    <Route path="/tests" element={<List />} />
    <Route
      path="/tests/new"
      element={
        <RequireLocation showAlert>
          <RequireTester noTesterChildren={<NoTester />}>
            <Start />
          </RequireTester>
        </RequireLocation>
      }
    />
    <Route path="/tests/:id" element={<Show />} />
    <Route
      path="/tests/new/:examinee"
      element={
        <RequireLocation>
          <RequireTester>
            <TestOverview />
          </RequireTester>
        </RequireLocation>
      }
    />
    <Route
      path="/tests/new/:examinee/:task"
      element={
        <RequireLocation>
          <RequireTester>
            <DoTest />
          </RequireTester>
        </RequireLocation>
      }
    />
  </Route>
);

export default Routes;
