import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { CommonExamineeSelectProps } from '.';
import InputGroup from '../../form/InputGroup';
import { TestingDevice } from '../../../interfaces/TestingDevice';
import TypeaheadInput from '../../form/TypeaheadInput';
import { UserService } from '../../../services';
import { useAddError } from '../../../context/error';
import { useAuth } from '../../../context/auth';
import { useField, useFormikContext } from 'formik';
import { Tester } from '../../../interfaces/Tester';
import { userStore } from '../../../storage';

const TestingDeviceSelect: FunctionComponent<
  CommonExamineeSelectProps & {
    onValueSelected?: (value?: TestingDevice) => void;
    testTasks: number;
  }
> = ({ testTasks, variant, name, editing }) => {
  const addError = useAddError();
  const auth = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, __, helpers] = useField(name ?? 'testingDevice');
  const [tester, setTester] = useState<Tester | undefined>();

  const setTouched = useCallback((value: boolean) => helpers.setTouched(value), [helpers]);
  const setValue = useCallback(
    (value: any, shouldValidate?: boolean | undefined) => helpers.setValue(value, shouldValidate),
    [helpers],
  );
  const setError = useCallback((value: string | undefined) => helpers.setError(value), [helpers]);

  const { errors, isSubmitting } = useFormikContext<{
    testingDevice: number;
  }>();

  const [loading, setLoading] = useState(true);

  const [testingDevices, setTestingDevices] = useState<TestingDevice[]>([]);

  useEffect(() => {
    const loadData = async () => {
      const user = await UserService(addError, userStore).get(auth.user.id);
      setTester(user.tester);
    };
    loadData();
  }, [addError, auth.user.id]);

  useEffect(() => {
    if (tester) {
      setTestingDevices(tester?.testingDevicesV2 ?? []);
      const defaultTestingDevice = tester?.testingDevicesV2?.find((dtdu) =>
        dtdu?.defaultTestTasks?.some((dtt) => dtt.id === testTasks),
      );
      if (defaultTestingDevice) {
        setTouched(true);
        setValue(defaultTestingDevice.id, true);
        setError(undefined);
      }
      setLoading(false);
    } else {
      setTouched(false);
      setValue(undefined, true);
      setError(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testTasks, tester]);

  return (
    <InputGroup
      error={errors.testingDevice ? '' : undefined}
      valid={!errors.testingDevice}
      title="Prüfmittel"
      name={name ?? 'testingDevice'}
      variant={variant}
      loading={loading}
    >
      <TypeaheadInput
        name={name ?? 'testingDevice'}
        options={[
          ...testingDevices.map((is) => ({
            label: `${is.vendor} ${is.name} (${is.serial})`,
            value: is.id,
          })),
          // {
          //   label: "Kein Prüfmittel benötigt",
          //   value: 0,
          // },
        ]}
        disabled={!editing || isSubmitting}
        emptyLabel="Prüfmittel auswählen"
      />
    </InputGroup>
  );
};

export default TestingDeviceSelect;
