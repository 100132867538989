import { forwardRef, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { DateTime } from 'luxon';
import styled from 'styled-components';
import Title from '../../components/title';
import { TourStop } from '../../interfaces/TourStop';
import DayStop from './DayStop';
import NoStop from './NoStop';

const TourTag = styled.p`
  @media (min-width: 768px) {
    float: right;
  }

  font-size: 18px;
  letter-spacing: 0;
  line-height: 25px;
  font-weight: 400;
  padding: 6px 0;
  margin: 0;
  color: #a1a1a1 !important;
`;

interface DayProps {
  baseDate: Date;
  tourstops: TourStop[];
  title: string;
  id: string | undefined;
}

const Wrapper = styled.div`
  margin-bottom: 2rem;
  min-height: 135px;
`;

const Day = forwardRef<HTMLDivElement, DayProps>((props, ref) => {
  const { title, baseDate, tourstops, id } = props;

  const tour = useMemo(() => {
    return tourstops && tourstops.length > 0 ? tourstops[0].tour.tag : undefined;
  }, [tourstops]);

  const [formattedDate] = useState(
    `${title} - ${DateTime.fromJSDate(baseDate).setLocale('de-de').toFormat('EEE, dd.MM.yyyy')}`,
  );

  return (
    <Wrapper ref={ref} id={id}>
      <Title isSubmitting={false} title={formattedDate}>
        <TourTag>{tour ? `Tour ${tour}` : ''}</TourTag>
      </Title>
      <Row>
        {tourstops.length === 0 && (
          <Col xs={12}>
            <NoStop />
          </Col>
        )}
        {tourstops.map((stop, index) => (
          <Col xs={12} key={stop.id}>
            <DayStop stopNumber={index + 1} stop={stop} />
          </Col>
        ))}
      </Row>
    </Wrapper>
  );
});
export default Day;
