import { AddSafeDocError, ErrorLevel } from '../../context/error';

import { get } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isValidationError = (error: any): null | AddSafeDocError => {
  const errorName = get(error, 'response.data.errorType');
  if (errorName === 'Validation') {
    const errorsValidation = get(error, 'response.data.errorsValidation[0]');
    const message: string | undefined = Object.values(errorsValidation)[0] as string;
    return {
      level: ErrorLevel.danger,
      title: 'Fehler',
      requestID: get(error, 'response.headers["x-request-id"]'),
      message: message ?? 'Unbekannter Fehler während der Validierung',
    };
  }
  return null;
};

export default isValidationError;
