import { Formik, FormikHelpers } from 'formik';
import { get, reduce } from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';
import BareInput, { NumberInput } from '../../../components/form/BareInput';
import SelectInput, { ToggleInputBooleanValueUndefined } from '../../../components/form/ToggleInput';
import { ErrorLevel, useAddError, useClearError } from '../../../context/error';
import { EmptyTestValue, TestValue } from '../../../interfaces/TestValue';

import qs from 'query-string';
import { Row } from 'react-bootstrap';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import FormikDebug from '../../../components/dev/formik-debug';
import { confirm } from '../../../components/dialogs/Confirmation';
import InputGroup from '../../../components/form/InputGroup';
import InputSection from '../../../components/form/InputSection';
import ToggleInput from '../../../components/form/ToggleInput';
import Title from '../../../components/title';
import validateTestValueName from '../../../helpers/validate/validateTestValueName';
import { ValueType } from '../../../interfaces/ValueType';
import { TestValueService } from '../../../services';
import TestValeOptionList from './test-value-options';
import TestValeThresholdList from './test-value-thresholds';

const getChangedValues = (values: TestValue, initialValues: TestValue): Partial<TestValue> => {
  return reduce(
    Object.entries(values),
    (acc, [key, value]) => {
      const hasChanged = (initialValues as any)[key] !== value;
      if (hasChanged) {
        (acc as any)[key] = value;
      }
      return acc;
    },
    {},
  );
};

const shouldEditing = (location: Location) => {
  const queryParams = qs.parse(location.search, {});
  return queryParams.edit === '1' || queryParams.edit === 'true';
};

const Show: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(shouldEditing(location));

  const [item, setItem] = useState<TestValue>(EmptyTestValue);

  useEffect(() => {
    const loadData = async () => {
      try {
        if (!params.id || !params.tvid) {
          return;
        }
        const _item = await TestValueService(addError).get(params.tvid);
        if (!_item || !_item.id) {
          navigate(`/settings/tasks/${params.id}`);
        }
        setItem(_item);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, navigate, params.id, params.tvid]);

  const onSubmit = async (values: TestValue, { setSubmitting }: FormikHelpers<TestValue>) => {
    setSubmitting(true);
    try {
      if (params.tvid) {
        clearError();
        const data = getChangedValues(values, item);
        await TestValueService(addError).update(params.tvid, data);
        setEditing(false);
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onDelete = async (setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true);
    try {
      if (params.tvid) {
        if (
          await confirm(
            'Prüfwert löschen?',
            'Der Prüfwert wird entgültig gelöscht. Sind Sie sich sicher?',
            'Löschen',
            'Abbruch',
            {
              okColor: 'danger',
            },
          )
        ) {
          await TestValueService(addError).destroy(params.tvid);
          navigate(`/settings/tasks/${params.id}`);
        }
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onNewThreshold = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/settings/tasks/${params.id}/test-value-groups/${params.tgid}/test-values/${params.tvid}/new-threshold`);
  };

  const onNewOption = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/settings/tasks/${params.id}/test-value-groups/${params.tgid}/test-values/${params.tvid}/new-option`);
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isValidating, isValid, errors, resetForm, isSubmitting, setSubmitting, values }) => (
        <>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Title
              onEdit={() => setEditing(true)}
              onDelete={() => onDelete(setSubmitting)}
              onCancel={() => {
                resetForm({
                  values: item,
                });
                setEditing(false);
              }}
              editing={editing}
              editingValid={!isValidating && isValid}
              isSubmitting={isSubmitting}
              to={`/settings/tasks/${params.id}`}
              title={`${item?.testTask?.initials} - ${item?.group?.name} - ${item.name}`}
            />
            <Row>
              <InputSection title="Stammdaten" xs={12} xl={6}>
                <InputGroup error={errors.name} valid={editing ? !errors.name : undefined} title="Name" name="name">
                  <BareInput
                    disabled={!editing || isSubmitting}
                    type="text"
                    name="name"
                    validate={validateTestValueName}
                  />
                </InputGroup>
                <InputGroup
                  error={errors.description}
                  valid={editing ? !errors.description : undefined}
                  title="Beschreibung"
                  name="description"
                >
                  <BareInput disabled={!editing || isSubmitting} type="text" name="description" />
                </InputGroup>
                <InputGroup error={errors.unit} valid={editing ? !errors.unit : undefined} title="Einheit" name="unit">
                  <BareInput placeholder="ohne Einheit" disabled={!editing || isSubmitting} type="text" name="unit" />
                </InputGroup>
                <InputGroup title="Pflichtfeld" name="required" valid={true}>
                  <ToggleInput
                    validate={(value: any) => value === ToggleInputBooleanValueUndefined}
                    name="required"
                    disabled={true}
                  />
                </InputGroup>
              </InputSection>
              <InputSection title="Sonstiges" xs={12} xl={6}>
                <InputGroup title="Format" name="valueType" valid={true}>
                  <BareInput
                    disabled={true}
                    type="text"
                    name="valueType"
                    value={
                      values.valueType === ValueType.STRING
                        ? 'Text'
                        : values.valueType === ValueType.NUMBER
                        ? 'Ganzzahl'
                        : values.valueType === ValueType.FLOAT
                        ? 'Dezimalzahl'
                        : values.valueType === ValueType.BOOLEAN
                        ? 'Ja / Nein'
                        : values.valueType === ValueType.OPTION
                        ? 'Auswahl'
                        : ''
                    }
                  />
                </InputGroup>
                <InputGroup
                  error={errors.prio}
                  valid={editing ? !errors.prio : undefined}
                  title="Priorität"
                  name="prio"
                >
                  <NumberInput required={true} name="prio" disabled={!editing || isSubmitting} float={false} />
                </InputGroup>
                <InputGroup title="Standardwert" name="defaultValue" valid={true}>
                  {values.valueType === ValueType.STRING && (
                    <BareInput disabled={!editing || isSubmitting} type="text" name="defaultValue" />
                  )}
                  {(values.valueType === ValueType.NUMBER || values.valueType === ValueType.FLOAT) && (
                    <NumberInput
                      name="defaultValue"
                      disabled={!editing || isSubmitting}
                      float={values.valueType === ValueType.FLOAT}
                    />
                  )}
                  {values.valueType === ValueType.BOOLEAN && (
                    <ToggleInput name="defaultValue" disabled={!editing || isSubmitting} />
                  )}
                  {values.valueType === ValueType.OPTION && (
                    <SelectInput valueType="text" name="defaultValue" disabled={!editing || isSubmitting}>
                      <option value="">Kein Standardwert</option>
                      {values.valueOptions.map((o) => (
                        <option value={o.option}>{o.option}</option>
                      ))}
                    </SelectInput>
                  )}
                </InputGroup>
              </InputSection>
              {values.valueType === ValueType.OPTION && (
                <InputSection
                  title="Auswahlmöglichkeiten"
                  xs={12}
                  deleteText="Neue Auswahlmöglichkeit"
                  onDelete={(e) => onNewOption(e)}
                >
                  <TestValeOptionList item={values} loading={loading} />
                </InputSection>
              )}
              <InputSection
                title="Schwellwerte (absteigend)"
                xs={12}
                deleteText="Neuer Schwellenwert"
                onDelete={(e) => onNewThreshold(e)}
              >
                <TestValeThresholdList item={values} loading={loading} />
              </InputSection>
            </Row>
          </form>
          <FormikDebug />
        </>
      )}
    </Formik>
  );
};

export default Show;
