import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { first, last } from 'lodash';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import { useAddError } from '../../context/error';
import TourService, { DateTourStopsMap } from '../../services/TourService';
import Day from './Day';
import LoadingIndicator from '../../components/common/LoadingIndicator';

const Title = styled.h2`
  margin-top: 1.5rem;
  color: var(--bs-primary);
`;

const dayPlusDays = (date: DateTime, days: number) => date.plus({ days, hours: 12 });

const Show: FunctionComponent = () => {
  const addError = useAddError();
  const [loading, setLoading] = useState(true);

  const [today] = useState(DateTime.now());

  const startOfWeek = today.startOf('week');
  const startOfNextWeek = today.plus({ week: 1 }).startOf('week');

  const [days] = useState(Array.from({ length: 7 }, (_, i) => dayPlusDays(startOfWeek, i)));
  const [daysNextWeek] = useState(Array.from({ length: 7 }, (_, i) => dayPlusDays(startOfNextWeek, i)));

  const [tourStops, setTourStops] = useState<DateTourStopsMap>({});
  const [nextWeekTourStops, setNextWeekTourStops] = useState<DateTourStopsMap>({});

  const todayRef = useRef<HTMLDivElement>(null);
  const isToday = (date: DateTime) => date.hasSame(today, 'day');

  useEffect(() => {
    const loadData = async () => {
      try {
        const tours = await TourService(addError).atDateRange(
          first(days)?.toJSDate() ?? new Date(),
          last(days)?.toJSDate() ?? new Date(),
        );
        if (!tours) return;
        setTourStops(tours);
        setTimeout(() => {
          // if screen is to wide, we don't need to scroll
          if (window.innerWidth > 768) return;

          const element = todayRef.current;
          if (!element) return;

          // ew need some offset to account for the fixed header
          const headerOffset = 90;
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.scrollY - headerOffset;

          // scroll to the element
          window.scrollTo({
            top: offsetPosition,
            behavior: 'auto',
            // behavior: ScrollBehavior.instant,
          });
        }, 50);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, days]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const tours = await TourService(addError).atDateRange(
          first(daysNextWeek)?.toJSDate() ?? new Date(),
          last(daysNextWeek)?.toJSDate() ?? new Date(),
        );
        if (!tours) return;
        setNextWeekTourStops(tours);
      } catch (error) {
      } finally {
      }
    };
    loadData();
  }, [addError, daysNextWeek]);

  const getTourStopsforDay = (date: DateTime) => {
    return { ...tourStops, ...nextWeekTourStops }[date.toFormat('yyyy-MM-dd')] ?? [];
  };

  if (loading)
    return (
      <div className="mt-0">
        <Row>
          <Col xs={12}>
            <LoadingIndicator />
          </Col>
        </Row>
      </div>
    );

  return (
    <div className="mt-0">
      <Row>
        <Col xs={12}>
          <Title>Diese Woche {startOfWeek.toFormat(" - 'KW'WW")}</Title>
        </Col>
        {days.map((day, key) => (
          <Col key={key} xs={12} xl={6}>
            <Day
              id={isToday(day) ? 'today' : undefined}
              ref={isToday(day) ? todayRef : undefined}
              title={
                day.toRelativeCalendar({
                  unit: 'days',
                }) ?? ''
              }
              baseDate={day.toJSDate()}
              tourstops={getTourStopsforDay(day)}
            />
          </Col>
        ))}
      </Row>
      <Row>
        <Col xs={12}>
          <Title>Nächste Woche {startOfNextWeek.toFormat(" - 'KW'WW")}</Title>
        </Col>
        {daysNextWeek.map((day, key) => (
          <Col key={key} xs={12} xl={6}>
            <Day
              id={isToday(day) ? 'today' : undefined}
              title={
                day.toRelativeCalendar({
                  unit: 'days',
                }) ?? ''
              }
              baseDate={day.toJSDate()}
              tourstops={getTourStopsforDay(day)}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Show;
