import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FilterableTableColProps, TableColProps } from '../../components/table';
import {
  ExamineeFamiliesService,
  ExamineeTypesService,
  ExamineesService,
  LocationService,
  VendorsService,
} from '../../services';

import { get } from 'lodash';
import { useCallback } from 'react';
import styled from 'styled-components';
import { download } from '../../components/dialogs/Download';
import { useAuth } from '../../context/auth';
import { useAddError } from '../../context/error';
import { useMain } from '../../context/main';
import { Examinee, ExamineeWithLastTest } from '../../interfaces/Examinee';
import { FilterType } from '../../services/Service';
import TableWithSearch, { OnDataParams, OnSearchParams } from '../../views/TableWithSearchView';

const P = styled.p<{ small: boolean }>`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  color: ${(props) => (props.small ? '#b3b3b3' : '#000')};
`;

const columns = (withTest: boolean): TableColProps<ExamineeWithLastTest>[] => [
  {
    title: 'Eindeutige Nummer',
    values: 'tag',
    key: 'tag',
    type: 'string',
    filterable: (addError, limit, page, query) =>
      (query && query.length > 0
        ? ExamineesService(addError)
            .searchTag(query)
            .then((res) => res.items)
        : ExamineesService(addError)
            .tags(limit, page)
            .then((res) => res.items)
      ).then((i) => {
        return i.map((v) => ({ value: v, title: v }));
      }),
  },
  {
    title: 'Standort',
    values: (item: Examinee) => {
      return (
        <span>
          <P small={false}>
            [{item.location.tag}] {item.location.name}
          </P>
          <P small={true}>
            {item.location.address.street}, {item.location.address.postalCode} {item.location.address.city}
          </P>
        </span>
      );
    },
    key: 'location.id',
    type: 'number',
    filterable: (addError, limit, page, query) =>
      (query && query.length > 0
        ? LocationService(addError)
            .search(query)
            .then((res) => res.items)
        : LocationService(addError)
            .list(limit, page)
            .then((res) => res.items)
      ).then((i) => i.map((v) => ({ value: v.id, title: v.name }))),
  },
  {
    title: 'Hersteller',
    key: 'vendor.id',
    values: 'vendor.name',
    type: 'number',
    filterable: (addError, limit, page, query) => {
      return (
        query && query.length > 0
          ? VendorsService(addError)
              .search(query)
              .then((res) => res.items)
          : VendorsService(addError)
              .list(limit, page)
              .then((res) => res.items)
      ).then((i) => i.map((v) => ({ value: v.id, title: v.name })));
    },
  },
  {
    title: 'Prüflingsart',
    values: 'type.name',
    key: 'type.id',
    type: 'number',
    filterable: (addError, limit, page, query) =>
      (query && query.length > 0
        ? ExamineeTypesService(addError)
            .search(query)
            .then((res) => res.items)
        : ExamineeTypesService(addError)
            .list(limit, page)
            .then((res) => res.items)
      ).then((i) => i.map((v) => ({ value: v.id, title: v.name }))),
  },
  {
    title: 'Prüflingsgattung',
    values: 'type.family.name',
    key: 'type.family.id',
    type: 'number',
    filterable: (addError, limit, page, query) =>
      (query && query.length > 0
        ? ExamineeFamiliesService(addError)
            .search(query)
            .then((res) => res.items)
        : ExamineeFamiliesService(addError)
            .list(limit, page)
            .then((res) => res.items)
      ).then((i) => i.map((v) => ({ value: v.id, title: v.name }))),
  },
  {
    title: 'Erstellt am',
    key: 'created_at',
    type: 'date',
    filterable: true,
    values: (item: ExamineeWithLastTest) => {
      return (
        <span>
          {new Date(item.created_at).toLocaleString('de-DE', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })}
        </span>
      );
    },
  },

  ...((withTest === true
    ? [
        {
          title: 'letzte Prüfung am',
          key: 'last.at',
          type: 'date',
          filterable: true,
          values: (item: ExamineeWithLastTest) => {
            const data = get(item, 'last.at');
            if (data === undefined || data === null || data === false) return <span></span>;
            return (
              <span>
                {new Date(data).toLocaleString('de-DE', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </span>
            );
          },
        },
        {
          title: 'letzte Prüfung i.O.',
          key: 'last.result',
          type: 'boolean',
          filterable: true,
          values: (item: ExamineeWithLastTest) => {
            const data = get(item, 'last.result');
            if (data === false || data === 'false') return <span style={{ color: 'red' }}>nein</span>;
            if (data === true || data === 'true') return <span style={{ color: 'green' }}>ja</span>;
            return <span style={{ color: 'gray' }}>keine Prüfung</span>;
          },
        },
      ]
    : []) as FilterableTableColProps<ExamineeWithLastTest>[]),
];

const List: FunctionComponent = () => {
  const navigate = useNavigate();
  const addError = useAddError();
  const auth = useAuth();

  const { selectedLocation } = useMain();

  const [cols, setCols] = useState<TableColProps<ExamineeWithLastTest>[]>(columns(true));

  const onNew = useCallback(() => {
    window.localStorage.removeItem('examinees-new-form');
    navigate('new');
  }, [navigate]);

  const onXLSX = useCallback(
    (filter?: FilterType<{ id: number }> | undefined, params?: Record<string, string> | undefined) => {
      const f = {
        ...(filter ?? {}),
        'location.id': selectedLocation && selectedLocation.length > 0 ? selectedLocation[0].value : undefined,
      };
      download(addError, 'examinees/xlsx', f, params);
    },
    [addError, selectedLocation],
  );

  return (
    <TableWithSearch<ExamineeWithLastTest>
      tabs={['Alle', 'Mit Prüfung', 'Ohne Prüfung']}
      title="Prüflinge"
      columns={cols}
      defaultFilter={{
        created_at: 'today',
      }}
      defaultLimit={get(auth, 'user.itemsPerPage', 25)}
      onSearch={function ({ term, page, limit }: OnSearchParams) {
        const f = {
          'location.id': selectedLocation && selectedLocation.length > 0 ? selectedLocation[0].value : undefined,
        };
        return ExamineesService(addError).search<ExamineeWithLastTest>(term, limit, page, f);
      }}
      onData={function ({ filter, limit, page, tab }: OnDataParams<ExamineeWithLastTest>) {
        setCols(columns(tab === 0 || tab === 1));

        const f = {
          ...(filter ?? {}),
          'location.id': selectedLocation && selectedLocation.length > 0 ? selectedLocation[0].value : undefined,
        };
        return ExamineesService(addError).list<ExamineeWithLastTest>(limit, page, f, {
          test: tab === 0 ? '' : tab === 1 ? 'true' : 'false',
        });
      }}
      onNew={onNew}
      onXLSX={onXLSX}
      filter={{ 'location.id': selectedLocation && selectedLocation.length > 0 ? selectedLocation[0].value : 0 }}
    />
  );
};

export default List;
