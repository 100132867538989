import List from './List';
import MainLayout from '../../layouts/MainLayout';
import New from './New';
import NewTesterModal from '../testers/NewModal';
import React from 'react';
import RequireAuth from '../../guards/RequireAuth';
import { Route } from 'react-router';
import Show from './Show';

const Routes = (
  <Route
    path="/settings/users"
    element={
      <RequireAuth>
        <MainLayout />
      </RequireAuth>
    }
  >
    <Route path="/settings/users" element={<List />} />
    <Route path=":id/*" element={<Show />} />
    <Route path="new" element={<New />} />
    {/* Modal */}
    <Route path="/settings/users/:id/create-tester" element={<NewTesterModal />} />
  </Route>
);

export default Routes;
