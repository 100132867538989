import { Formik, FormikHelpers } from 'formik';
import { get, reduce } from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ErrorLevel, useAddError, useClearError } from '../../../context/error';

import qs from 'query-string';
import { Row } from 'react-bootstrap';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { confirm } from '../../../components/dialogs/Confirmation';
import BareInput from '../../../components/form/BareInput';
import InputGroup from '../../../components/form/InputGroup';
import InputSection from '../../../components/form/InputSection';
import ToggleInput, { ToggleInputBooleanValueUndefined } from '../../../components/form/ToggleInput';
import Title from '../../../components/title';
import validateTestValueGroupName from '../../../helpers/validate/validateTestValueGroupName';
import {
  EmptyTestAdditionalInformationField,
  TestAdditionalInformationField,
} from '../../../interfaces/TestAdditionalInformationField';
import { ValueType } from '../../../interfaces/ValueType';
import { TestAdditionalInformationFieldService } from '../../../services';
import TestAdditionalInformationFieldOptionList from './test-additional-information-fields-options';

const getChangedValues = (
  values: TestAdditionalInformationField,
  initialValues: TestAdditionalInformationField,
): Partial<TestAdditionalInformationField> => {
  return reduce(
    Object.entries(values),
    (acc, [key, value]) => {
      const hasChanged = (initialValues as any)[key] !== value;
      if (hasChanged) {
        (acc as any)[key] = value;
      }
      return acc;
    },
    {},
  );
};

const shouldEditing = (location: Location) => {
  const queryParams = qs.parse(location.search, {});
  return queryParams.edit === '1' || queryParams.edit === 'true';
};

const Show: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(shouldEditing(location));

  const [item, setItem] = useState<TestAdditionalInformationField>(EmptyTestAdditionalInformationField);

  useEffect(() => {
    const loadData = async () => {
      try {
        if (!params.id || !params.tgid) {
          return;
        }
        const _item = await TestAdditionalInformationFieldService(addError).get(params.tgid);
        if (!_item || !_item.id) {
          navigate(`/settings/examinee-families/${params.id}`);
        }
        setItem(_item);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, navigate, params.id, params.tgid]);

  const onSubmit = async (
    values: TestAdditionalInformationField,
    { setSubmitting }: FormikHelpers<TestAdditionalInformationField>,
  ) => {
    setSubmitting(true);
    try {
      if (params.tgid) {
        clearError();
        const data = getChangedValues(values, item);
        await TestAdditionalInformationFieldService(addError).update(params.tgid, data);
        setEditing(false);
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onDelete = async (setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true);
    try {
      if (params.tgid) {
        if (
          await confirm(
            'Prüfwertgruppe löschen?',
            'Der Prüfwertgruppe  wird entgültig gelöscht. Sind Sie sich sicher?',
            'Löschen',
            'Abbruch',
            {
              okColor: 'danger',
            },
          )
        ) {
          await TestAdditionalInformationFieldService(addError).destroy(params.tgid);
          navigate(`/settings/examinee-families/${params.id}`);
        }
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onNewOption = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/settings/examinee-families/${params.id}/test-additional-information-fields/${params.tgid}/new-option`);
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isValidating, isValid, errors, resetForm, isSubmitting, setSubmitting, values }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Title
            onEdit={() => setEditing(true)}
            onDelete={() => onDelete(setSubmitting)}
            onCancel={() => {
              resetForm({
                values: item,
              });
              setEditing(false);
            }}
            editing={editing}
            editingValid={!isValidating && isValid}
            isSubmitting={isSubmitting}
            to={`/settings/examinee-families/${params.id}`}
            title={`${item.name}`}
          />
          <Row>
            <InputSection title="Stammdaten" xs={12} xl={6}>
              <InputGroup error={errors.name} valid={editing ? !errors.name : undefined} title="Name" name="name">
                <BareInput
                  disabled={!editing || isSubmitting}
                  type="text"
                  name="name"
                  validate={validateTestValueGroupName}
                />
              </InputGroup>
            </InputSection>
            <InputSection title="Sonstiges" xs={12} xl={6}>
              <InputGroup title="Format" name="valueType">
                <BareInput disabled={true} type="text" name="valueType" />
              </InputGroup>
              <InputGroup error={errors.unit} valid={editing ? !errors.unit : undefined} title="Einheit" name="unit">
                <BareInput placeholder="ohne Einheit" disabled={!editing || isSubmitting} type="text" name="unit" />
              </InputGroup>
              <InputGroup title="Pflichtfeld" name="required">
                <ToggleInput
                  validate={(value: any) => value === ToggleInputBooleanValueUndefined}
                  name="required"
                  disabled={!editing || isSubmitting}
                />
              </InputGroup>
            </InputSection>
            {item.valueType === ValueType.OPTION && (
              <InputSection
                title="Auswahlmöglichkeiten"
                xs={12}
                deleteText="Neue Auswahlmöglichkeit"
                onDelete={(e) => onNewOption(e)}
              >
                <TestAdditionalInformationFieldOptionList item={item} loading={loading} />
              </InputSection>
            )}
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default Show;
