import { AddSafeDocError, ErrorLevel, useAddError, useClearError } from '../../../context/error';
import { FieldArray, Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useEffect, useState } from 'react';
import { Step, Wizard } from '../../../components/wizard';
import { useNavigate, useParams } from 'react-router-dom';
import { Button as BCButton } from 'react-bootstrap';

import BareInput from '../../../components/form/BareInput';
import InputGroup from '../../../components/form/InputGroup';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { ValueType } from '../../../interfaces/ValueType';
import { get } from 'lodash';
import validateMinLength from '../../../helpers/validate/validateMinLength';
import { Col, Modal, Row, ToggleButton } from 'react-bootstrap';
import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from './trash.svg';
import { ReactComponent as PlusIcon } from './plus.svg';
import { NewExamineeAdditionalInformationField } from '../../../interfaces/ExamineeAdditionalInformationField';
import { ExamineeAdditionalInformationFieldService, ExamineeFamiliesService } from '../../../services';
import { ExamineeFamily } from '../../../interfaces/ExamineeFamily';
import { SELECTABLE_BOOLEAN, SELECTABLE_VALUE_TYPES } from '../../tasks/test-values/statics';

type VT = Omit<NewExamineeAdditionalInformationField, 'family' | 'valueType' | 'required'> & {
  valueType?: ValueType;
  required?: boolean;
};

const getTitleForSavingStep = (step: number, hasError?: boolean) => {
  if (hasError) return 'Es ist ein Fehler aufgetreten!';
  if (step === 0) return 'Zusätzliches Feld wird gespeichert...';
  if (step === 1) return 'Fertig!';
  return '';
};

const getColor = (colored?: 'true' | 'danger') => {
  if (colored === 'danger') return 'var(--bs-red)';
  if (colored === 'true') return 'var(--bs-primary)';
  return '#a1a1a1';
};

const B = styled(BCButton)<{
  colored?: 'true' | 'danger';
  hovercolored?: 'true' | 'danger';
  float?: 'left' | 'right';
  block?: boolean;
  onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void;
}>`
  float: ${(props) => props.float ?? 'left'};
  color: ${(props) => getColor(props.colored)};
  opacity: 1;
  cursour: pointer;
  svg {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 0 6px 4px 0;
    cursour: pointer;
    &,
    path,
    g {
      fill: ${(props) => getColor(props.colored)};
      color: ${(props) => getColor(props.colored)};
    }
  }
  span {
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 1px;
  }
  &:disabled,
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: #a1a1a1 !important;
    svg {
      &,
      path,
      g {
        fill: #a1a1a1 !important;
        color: #a1a1a1 !important;
      }
    }
  }
  &:hover {
    color: ${(props) => getColor(props.hovercolored ?? props.colored)};
    opacity: 0.8;
    &,
    path,
    g {
      fill: ${(props) => getColor(props.hovercolored ?? props.colored)};
      color: ${(props) => getColor(props.hovercolored ?? props.colored)};
    }
  }
  @media (max-width: 767px) {
    width: ${(props) => (props.block ? 100 : 50)}%;
    padding: 1rem;
    margin-top: 1rem;
    // color: #a1a1a1;
    background: #efefef;
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

const AR = styled.div`
  width: 100%;
  display: flex;
  button {
  }
`;

const WD = styled.div`
  width: 80%;
`;

const P = styled.p<{ small: boolean }>`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  color: ${(props) => (props.small ? '#b3b3b3' : '#000')};
`;

const B2 = styled.div`
  min-width: 75px;
  p {
    text-align: center;
  }
  // height: 25px;
`;

const ValueTypeButton: FunctionComponent<{ name: string }> = ({ name }) => (
  <B2>
    <P small={false}>{name}</P>
    <P small={true}></P>
  </B2>
);

const NewModal: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const navigate = useNavigate();
  const params = useParams();

  const [family, setFamily] = useState<ExamineeFamily>();

  const [savingStep, setSavingStep] = useState(-1);
  const [savingResult, setSavingResult] = useState<number | AddSafeDocError>();

  useEffect(() => {
    const loadFamily = async () => {
      if (params.id) {
        const _family = await ExamineeFamiliesService(addError).get(params.id);
        setFamily(_family);
      }
    };
    loadFamily();
  }, [addError, params.id]);

  const [item] = useState<VT>({
    name: '',
    unit: '',
    valueType: undefined,
    required: undefined,
    valueOptions: [],
  });

  const [step, setStep] = useState(0);

  const onSubmit = async (values: VT, { setSubmitting }: FormikHelpers<VT>) => {
    setSubmitting(true);
    clearError();
    try {
      setSavingStep(0);

      const newTestValueGroup: NewExamineeAdditionalInformationField = {
        name: values.name,
        family: family!.id,
        unit: values.unit,
        required: values.required ?? false,
        valueType: values.valueType!,
        valueOptions: (values.valueOptions ?? []).filter((f: string | undefined) => f && f.trim().length > 0),
      };

      const res = await ExamineeAdditionalInformationFieldService(addError).create(newTestValueGroup, true);
      setSavingResult(res);
      setSavingStep(1);
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal size="lg" centered show={true}>
      <Formik validateOnChange enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
        {({ setFieldTouched, handleChange, errors, isSubmitting, touched, values, setFieldValue, submitForm }) => (
          <form>
            <Wizard
              step={step}
              preparing={!family}
              onChange={(s) => {
                setStep(s);
              }}
              onClose={() => navigate(`/settings/examinee-families/${family?.id}`)}
              onComplete={() => {
                submitForm();
              }}
            >
              <Step title={`Neues zusätzliches Feld bei ${family?.name}`} description="Start" valid={true}>
                <p>
                  Dieser Assistent unterstütz Sie bei der Erstellung eines neuen Feldes für Prüflinge bei {family?.name}
                </p>
              </Step>

              <Step title="Name des zusätzliches Feldes" description="Name" valid={!errors.name && !!touched.name}>
                <p>Bitte geben Sie den Namen des zusätzliches Feldes ein.</p>
                <InputGroup error={errors.name} valid={!errors.name} title="Name" name="name" variant="gray-200">
                  <BareInput
                    disabled={isSubmitting}
                    type="text"
                    name="name"
                    minLength={1}
                    validate={(v: any) => validateMinLength(1, v)}
                    onChange={(e: any) => {
                      setFieldTouched('name');
                      handleChange(e);
                    }}
                  />
                </InputGroup>
              </Step>

              <Step title="Format des Wertes" description="Format" valid={!errors.valueType && touched.valueType}>
                <WD>
                  <Row>
                    <Col>
                      <p>Bitte wählen Sie in passendes Eingabeformat.</p>
                    </Col>
                  </Row>
                  <Row>
                    {SELECTABLE_VALUE_TYPES.map(({ value, title }, idx) => (
                      <Col>
                        <ToggleButton
                          className="m-2"
                          key={idx}
                          id={`radio-${idx}`}
                          type="radio"
                          variant="outline-dark"
                          name="radio"
                          value={value}
                          checked={values.valueType === value}
                          onChange={() => {
                            setFieldTouched('valueType');
                            setFieldValue('valueType', value, true);
                          }}
                        >
                          <ValueTypeButton name={title} />
                        </ToggleButton>
                      </Col>
                    ))}
                  </Row>
                </WD>
              </Step>

              {values.valueType === ValueType.NUMBER && (
                <Step title="Einheit des Wertes" description="Einheit" valid={true}>
                  <p>Bitte geben Sie die Einheit ein, in der der Wert gemessen wird.</p>
                  <InputGroup error={errors.unit} valid={!errors.unit} title="Einheit" name="unit" variant="gray-200">
                    <BareInput
                      disabled={isSubmitting}
                      type="text"
                      name="unit"
                      placeholder="ohne Einheit"
                      onChange={(e: any) => {
                        setFieldTouched('unit');
                        handleChange(e);
                      }}
                    />
                  </InputGroup>
                </Step>
              )}

              {values.valueType === ValueType.OPTION && (
                <Step
                  title="Auswahlmöglichkeiten"
                  description="Auswahlmöglichkeiten"
                  valid={
                    (values.valueOptions ?? []).filter((f: string | undefined) => f && f.trim().length > 0).length > 0
                  }
                >
                  <p>Bitte geben Sie die Auswahlmöglichkeiten ein, die der Wert annehmen kann.</p>
                  <FieldArray
                    name="valueOptions"
                    render={(arrayHelpers) => (
                      <>
                        {values.valueOptions && values.valueOptions.length > 0
                          ? values.valueOptions.map((_: string, index: number) => (
                              <AR key={index}>
                                <InputGroup
                                  error={errors.unit}
                                  valid={!errors.unit}
                                  title="Auswahl"
                                  name={`valueOptions.${index}`}
                                  variant="gray-200"
                                >
                                  <BareInput
                                    disabled={isSubmitting}
                                    type="text"
                                    name={`valueOptions.${index}`}
                                    placeholder=""
                                    onChange={(e: any) => {
                                      setFieldTouched(`valueOptions.${index}`);
                                      handleChange(e);
                                    }}
                                  />
                                </InputGroup>
                                <B variant="link" hovercolored="danger" onClick={() => arrayHelpers.remove(index)}>
                                  <DeleteIcon />
                                </B>
                              </AR>
                            ))
                          : null}
                        <B variant="link" hovercolored="true" onClick={() => arrayHelpers.push('')}>
                          <PlusIcon />
                        </B>
                      </>
                    )}
                  />
                </Step>
              )}

              <Step title="Pflichtfeld" description="Pflichtfeld" valid={!errors.required && touched.required}>
                <WD>
                  <Row>
                    <Col>
                      <p>Muss der Wert beim Anlegen des Prüfling vom Prüfer erfasst werden?</p>
                    </Col>
                  </Row>
                  <Row>
                    {SELECTABLE_BOOLEAN.map(({ value, title }, idx) => (
                      <Col>
                        <ToggleButton
                          className="m-2"
                          key={idx}
                          id={`radio-${idx}`}
                          type="radio"
                          variant="outline-dark"
                          name="radio"
                          value={value ? 'true' : 'false'}
                          checked={values.required === value}
                          onChange={() => {
                            setFieldTouched('required');
                            setFieldValue('required', value, true);
                          }}
                        >
                          <ValueTypeButton name={title} />
                        </ToggleButton>
                      </Col>
                    ))}
                  </Row>
                </WD>
              </Step>

              <Step
                title={getTitleForSavingStep(savingStep, typeof savingResult !== 'number')}
                description="Abschluss"
                notCloseable={savingStep !== 1}
              >
                {savingStep < 1 && <LoadingIndicator size={40} />}
                {savingStep === 1 && typeof savingResult === 'number' && (
                  <p>Das zusätzliches Feld {values?.name} wurde erfolgreich erstellt.</p>
                )}
                {savingStep === 1 && typeof savingResult !== 'number' && <p>{savingResult?.message}</p>}
              </Step>
            </Wizard>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default NewModal;
