import React, { FunctionComponent } from 'react';
import { useAuth } from '../../context/auth';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Bar = styled.div`
  background-color: #009842;
  color: white;
  padding: 0.5rem;
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  cursor: pointer;
  p {
    display: inline-block;
    margin: 0 1rem;
    padding: 0;
  }
`;

const Impersonate: FunctionComponent = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const imo = auth.isImpersonating();
  if (imo) {
    return (
      <Bar>
        <div
          onClick={async () => {
            await auth.stopImpersonate();
            navigate('/');
          }}
        >
          <p>Impersonating {imo.name}</p>
          <p>Click to stop impersonating</p>
        </div>
      </Bar>
    );
  }
  return <></>;
};

export default Impersonate;
