import { TestValueType } from '../../interfaces/TestValue';

export default function formatMeasureType(type: TestValueType) {
  switch (type) {
    case TestValueType.FUNKTIONSPRÜFUNG:
      return 'Funktionsprüfung';
    case TestValueType.MESSPRÜFUNG:
      return 'Messprüfung';
    case TestValueType.SICHTPRÜFUNG:
      return 'Sichtprüfung';
    default:
      return '';
  }
}
