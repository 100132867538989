import { FieldAttributes } from 'formik';
import { DateTime } from 'luxon';
import React from 'react';

export interface DateTimeFieldProps extends React.InputHTMLAttributes<Date>, FieldAttributes<any> {
  field: any;
  form: any;
  timezone: string;
  format?: string;
}

export const DateTimeField = ({ field, form }: DateTimeFieldProps) => {
  const formatted = field.value ? DateTime.fromISO(field.value).toLocaleString() : '';
  return (
    <React.Fragment>
      <input disabled className="form-control" {...{ ...field }} value={formatted} />
      {form.errors.date ? <div className="text-danger">{form.errors.date}</div> : null}
    </React.Fragment>
  );
};
