import { ExamineeFamily, NewExamineeFamily, UpdateExamineeFamily } from '../interfaces/ExamineeFamily';
import { ExamineeType, NewExamineeType, UpdateExamineeType } from '../interfaces/ExamineeType';
import { InstallationSite, NewInstallationSite, UpdateInstallationSite } from '../interfaces/InstallationSite';
import { Location, NewLocation, UpdateLocation } from '../interfaces/Location';
import {
  DefaultMeasureGroups,
  NewDefaultMeasureGroups,
  NewTestTask,
  TestTask,
  UpdateDefaultMeasureGroups,
  UpdateTestTask,
} from '../interfaces/TestTask';
import { NewTestValue, TestValue, UpdateTestValue } from '../interfaces/TestValue';
import { NewTestValueGroup, TestValueGroup, UpdateTestValueGroup } from '../interfaces/TestValueGroup';
import { NewTestValueThreshold, TestValueThreshold, UpdateTestValueThreshold } from '../interfaces/TestValueThreshold';
import { NewTester, Tester, UpdateTester } from '../interfaces/Tester';
import { NewVendor, UpdateVendor, Vendor } from '../interfaces/Vendor';

import { AddErrorFunction } from '../context/error';
import {
  ExamineeAdditionalInformationField,
  NewExamineeAdditionalInformationField,
  UpdateExamineeAdditionalInformationField,
} from '../interfaces/ExamineeAdditionalInformationField';
import {
  ExamineeAdditionalInformationFieldOption,
  NewExamineeAdditionalInformationFieldOption,
  UpdateExamineeAdditionalInformationFieldOption,
} from '../interfaces/ExamineeAdditionalInformationFieldOption';
import { Job } from '../interfaces/Job';
import { Preference, UpdatePreference } from '../interfaces/Preference';
import {
  NewTestAdditionalInformationField,
  TestAdditionalInformationField,
  UpdateTestAdditionalInformationField,
} from '../interfaces/TestAdditionalInformationField';
import {
  NewTestAdditionalInformationFieldOption,
  TestAdditionalInformationFieldOption,
  UpdateTestAdditionalInformationFieldOption,
} from '../interfaces/TestAdditionalInformationFieldOption';
import { NewTestValueOption, TestValueOption, UpdateTestValueOption } from '../interfaces/TestValueOption';
import { TestingDevice } from '../interfaces/TestingDevice';
import { User } from '../interfaces/User';
import { OfflineStore, createdInstallationSitesStore, createdVendorsStore } from '../storage';
import ExtendedApiKeyService from './ApiKeysService';
import _BaseService from './BaseService';
import _ExamineesUtils from './ExamineesUtils';
import ExtendedExamineeService from './ExtendedExamineeService';
import ExtendedTestService from './ExtendedTestService';
import _ExtendedTestingDeviceService from './ExtendedTestingDeviceService';
import _PaperService from './PaperService';
import _SafeplanAdapterService from './SafeplanAdapterService';
import _UserService from './UserService';
import _AuthService from './auth';
import { Customer } from '../interfaces/Customer';

export const AuthService = (addError: AddErrorFunction) => _AuthService(addError);

export const PaperService = (addError: AddErrorFunction) => _PaperService(addError);

export const ExamineesUtils = (addError?: AddErrorFunction, noCache = false) => _ExamineesUtils(addError, noCache);

export const UserService = (addError: AddErrorFunction, offline?: OfflineStore<User>, noCache = false) =>
  _UserService(addError, offline, noCache);

export const TesterService = (addError: AddErrorFunction, noCache = false) =>
  _BaseService<Tester, UpdateTester, NewTester>('testers', addError, undefined, undefined, noCache);

export const TestingDeviceService = (
  addError: AddErrorFunction,
  offline?: OfflineStore<TestingDevice>,
  noCache = true,
) => _ExtendedTestingDeviceService(addError, offline, noCache);

export const TestTaskService = (addError: AddErrorFunction, offline?: OfflineStore<TestTask>, noCache = false) =>
  _BaseService<TestTask, UpdateTestTask, NewTestTask>('test-tasks', addError, offline, undefined, noCache);

export const TestValueService = (addError: AddErrorFunction, noCache = false) =>
  _BaseService<TestValue, UpdateTestValue, NewTestValue>('test-values', addError, undefined, undefined, noCache);

export const TestValueThresholdService = (addError: AddErrorFunction, noCache = false) =>
  _BaseService<TestValueThreshold, UpdateTestValueThreshold, NewTestValueThreshold>(
    'test-value-thresholds',
    addError,
    undefined,
    undefined,
    noCache,
  );

export const TestValueOptionService = (addError: AddErrorFunction, noCache = false) =>
  _BaseService<TestValueOption, UpdateTestValueOption, NewTestValueOption>('test-value-options', addError);

export const ExamineeAdditionalInformationFieldOptionService = (addError: AddErrorFunction, noCache = false) =>
  _BaseService<
    ExamineeAdditionalInformationFieldOption,
    UpdateExamineeAdditionalInformationFieldOption,
    NewExamineeAdditionalInformationFieldOption
  >('examinee-additional-information-field-options', addError, undefined, undefined, noCache);

export const TestAdditionalInformationFieldOptionService = (addError: AddErrorFunction, noCache = false) =>
  _BaseService<
    TestAdditionalInformationFieldOption,
    UpdateTestAdditionalInformationFieldOption,
    NewTestAdditionalInformationFieldOption
  >('test-additional-information-field-options', addError, undefined, undefined, noCache);

export const TestValueGroupService = (addError: AddErrorFunction, noCache = false) =>
  _BaseService<TestValueGroup, UpdateTestValueGroup, NewTestValueGroup>(
    'test-value-groups',
    addError,
    undefined,
    undefined,
    noCache,
  );

export const DefaultMeasureGroupsService = (addError: AddErrorFunction, noCache = false) =>
  _BaseService<DefaultMeasureGroups, UpdateDefaultMeasureGroups, NewDefaultMeasureGroups>(
    'default-measure-groups',
    addError,
    undefined,
    undefined,
    noCache,
  );

export const ExamineeFamiliesService = (
  addError: AddErrorFunction,
  offline?: OfflineStore<ExamineeFamily>,
  noCache = true,
) =>
  _BaseService<ExamineeFamily, UpdateExamineeFamily, NewExamineeFamily>(
    'examinee-families',
    addError,
    offline,
    undefined,
    noCache,
  );

export const ExamineeTypesService = (
  addError: AddErrorFunction,
  offline?: OfflineStore<ExamineeType>,
  noCache = true,
) =>
  _BaseService<ExamineeType, UpdateExamineeType, NewExamineeType>(
    'examinee-types',
    addError,
    offline,
    undefined,
    noCache,
  );

export const ExamineeAdditionalInformationFieldService = (addError: AddErrorFunction, noCache = false) =>
  _BaseService<
    ExamineeAdditionalInformationField,
    UpdateExamineeAdditionalInformationField,
    NewExamineeAdditionalInformationField
  >('examinee-additional-information-fields', addError, undefined, undefined, noCache);

export const TestAdditionalInformationFieldService = (addError: AddErrorFunction, noCache = false) =>
  _BaseService<TestAdditionalInformationField, UpdateTestAdditionalInformationField, NewTestAdditionalInformationField>(
    'test-additional-information-fields',
    addError,
    undefined,
    undefined,
    noCache,
  );

export const VendorsService = (addError: AddErrorFunction, offline?: OfflineStore<Vendor>, noCache = false) =>
  _BaseService<Vendor, UpdateVendor, NewVendor>('vendors', addError, offline, createdVendorsStore, noCache);

export const LocationService = (addError: AddErrorFunction, noCache = false) =>
  _BaseService<Location, UpdateLocation, NewLocation>('locations', addError, undefined, undefined, noCache);

export const CustomerService = (addError: AddErrorFunction, noCache = false) =>
  _BaseService<Customer, null, null>('customers', addError, undefined, undefined, noCache);

export const InstallationSitesService = (
  addError: AddErrorFunction,
  offline?: OfflineStore<InstallationSite>,
  noCache = true,
) =>
  _BaseService<InstallationSite, UpdateInstallationSite, NewInstallationSite>(
    'installation-sites',
    addError,
    offline,
    createdInstallationSitesStore,
    noCache,
  );

export const ExamineesService = (addError: AddErrorFunction, noCache = false) =>
  ExtendedExamineeService(addError, noCache);

export const TestService = (addError: AddErrorFunction, noCache = false) => ExtendedTestService(addError, noCache);

export const SafeplanAdapterService = (addError: AddErrorFunction, noCache = false) =>
  _SafeplanAdapterService(addError);

export const PreferencesService = (addError: AddErrorFunction, noCache = false) =>
  _BaseService<Preference, UpdatePreference, null>('preferences', addError, undefined, undefined, noCache);

export const ApiKeyService = (addError: AddErrorFunction, noCache = false) => ExtendedApiKeyService(addError);

export const JobsService = (addError: AddErrorFunction) => _BaseService<Job, null, null>('jobs', addError);
