import { FunctionComponent, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import TableWithSearch, { OnDataParams, OnSearchParams } from '../../views/TableWithSearchView';
import { useAddError } from '../../context/error';
import { TableColProps } from '../../components/table';
import { TestingDevice } from '../../interfaces/TestingDevice';
import { TesterService, TestingDeviceService } from '../../services';
import { download } from '../../components/dialogs/Download';
import { useAuth } from '../../context/auth';
import { get } from 'lodash';

const columns: TableColProps<TestingDevice>[] = [
  {
    title: 'Hersteller',
    values: 'vendor',
    key: 'vendor',
  },
  { title: 'Name', values: 'name', key: 'name' },
  { title: 'Seriennummer', values: 'serial' },
  {
    title: 'Prüfer',
    values: (item: TestingDevice) => {
      try {
        return item.testers
          .map((t) => `${t.user.name} ${t.user.deleted_at ? ' (Gesperrt)' : ''}`)
          .sort((a, b) => a.localeCompare(b))
          .join(', ');
      } catch (error) {
        return '';
      }
    },
    key: 'testers.id',
    type: 'number',
    filterable: (addError, limit, page, query) =>
      (query && query.length > 0
        ? TesterService(addError)
            .search(query)
            .then((res) => res.items)
        : TesterService(addError)
            .list(limit, page)
            .then((res) => res.items)
      ).then((i) => i.map((v) => ({ value: v.id, title: v.user.name }))),
  },
  {
    title: 'Standardprüfungen',
    values: (item: TestingDevice) => {
      try {
        return item.defaultTestTasks
          .map((t) => `${t.initials}`)
          .sort((a, b) => a.localeCompare(b))
          .join(', ');
      } catch (error) {
        return '';
      }
    },
  },
];

const List: FunctionComponent = () => {
  const navigate = useNavigate();
  const addError = useAddError();
  const auth = useAuth();

  const onNew = useCallback(() => {
    navigate('new');
  }, [navigate]);

  const onXLSX = useCallback(
    (filter: any, params: Record<string, string> | undefined) => {
      download(addError, 'testing-devices/xlsx', filter, params);
    },
    [addError],
  );

  if (!auth || !auth.user) {
    return null;
  }

  return (
    <TableWithSearch
      title="Prüfmittel"
      to="/settings"
      defaultLimit={get(auth, 'user.itemsPerPage', 25)}
      columns={columns}
      onData={function ({ filter, limit, page }: OnDataParams<TestingDevice>) {
        return TestingDeviceService(addError).list<TestingDevice>(limit, page, filter);
      }}
      onSearch={function ({ limit, page, term }: OnSearchParams) {
        return TestingDeviceService(addError).search<TestingDevice>(term, limit, page);
      }}
      onNew={onNew}
      onXLSX={onXLSX}
    />
  );
};

export default List;
