/* eslint-disable no-restricted-globals */
import { Field, useField } from 'formik';

import { FunctionComponent } from 'react';
import { TestValueThreshold } from '../../interfaces/TestValueThreshold';
import styled from 'styled-components';
import { DateTimeField } from './DateTimeField';

const BareInput = styled(Field)`
  border: 0;
  background: transparent;
  font-size: 0.75rem;
  -webkit-appearance: none;
  outline-color: rgba(0, 0, 0, 0);
`;

export const NumberInput: FunctionComponent<{
  required?: boolean;
  disabled?: boolean;
  min?: number;
  max?: number;
  float: boolean;
  name: string;
  thresholds?: TestValueThreshold[];
}> = (props) => {
  const { required, float = [] } = props;
  const [field] = useField(props);

  return (
    <BareInput
      validate={(value: any) => {
        const empty = value === undefined || value === null || value.toString().trim() === '';

        if (required && empty) {
          return true;
        }

        if (!empty && Number.isNaN(Number(value))) {
          return true;
        }

        if (value !== -1 && value < 0) {
          return false;
        }

        // if (thresholds.length > 0) {
        //   const thresholdsDesc = orderBy(thresholds, 'prio', 'desc');
        //   for (let i = 0; i < thresholdsDesc.length; i++) {
        //     const threshold = thresholdsDesc[i];
        //     const thv = Number(threshold.thresholdValue);
        //     const v = Number(value);

        //     if (threshold.thresholdType === ThresholdType.LOWER) {
        //       if (thv >= v) {
        //         return threshold.description;
        //       }
        //     }

        //     if (threshold.thresholdType === ThresholdType.HIHGER) {
        //       if (thv <= v) {
        //         return threshold.description;
        //       }
        //     }

        //     if (threshold.thresholdType === ThresholdType.EQUAL) {
        //       if (thv !== v) {
        //         return threshold.description;
        //       }
        //     }

        //     if (threshold.thresholdType === ThresholdType.UNEQUAL) {
        //       if (thv === v) {
        //         return threshold.description;
        //       }
        //     }
        //   }
        // }

        return false;
      }}
      type="number"
      inputMode={float ? 'decimal' : 'numeric'}
      pattern={float ? '[0-9]+([.,][0-9]+)?' : '[0-9]'}
      step={float ? 0.01 : 1}
      min={props.min ?? '-1'}
      max={props.max}
      disabled={props.disabled}
      {...field}
      {...props}
    />
  );
};

export const DateTimeInput: FunctionComponent<{
  required?: boolean;
  disabled?: boolean;
  name: string;
}> = (props) => {
  const { required } = props;
  const [field] = useField(props);

  return (
    <>
      <BareInput
        validate={(value: any) => {
          const empty = value === undefined || value === null || value.toString().trim() === '';

          if (required && empty) {
            return true;
          }

          if (!empty && Number.isNaN(Number(value))) {
            return true;
          }

          return false;
        }}
        component={DateTimeField}
        disabled={props.disabled}
        {...field}
        {...props}
      />
    </>
  );
};

export default BareInput;
