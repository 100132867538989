import localforage from 'localforage';
import { Examinee, ExamineeWithLastTest, NewExaminee } from './interfaces/Examinee';
import { ExamineeFamily } from './interfaces/ExamineeFamily';
import { ExamineeType } from './interfaces/ExamineeType';
import { InstallationSite, NewInstallationSite } from './interfaces/InstallationSite';
import { FilterType } from './services/Service';
import { NewVendor, Vendor } from './interfaces/Vendor';
import { PreferenceValue, User } from './interfaces/User';
import { Location } from './interfaces/Location';
import { NewTest, Test } from './interfaces/Test';
import { TestTask } from './interfaces/TestTask';

type Func = (instance: LocalForage, ...args: any[]) => Promise<any>;
type Funcs = { [key: string]: Func };

export interface OfflineStore<T> {
  instance: LocalForage;
  getItem: (key: string) => Promise<T | undefined>;
  list: <ST>(filter?: FilterType<T>) => Promise<ST[]>;
  setItem: (key: string, value: any) => Promise<void>;
  removeItem: (key: string) => Promise<void>;
  clear: () => Promise<void>;
  count: () => Promise<number>;
}

export const initStorage = <T, A = Funcs>(storeName: string, a?: A): OfflineStore<T> & A => {
  const instance = localforage.createInstance({
    storeName,
    name: 'safedoc',
    driver: [localforage.INDEXEDDB],
  });
  return {
    instance,
    count: async () => {
      const r = await instance.keys();
      return r.length;
    },
    getItem: async (key: string): Promise<T | undefined> => {
      const r = await instance.getItem(key);
      if (r === null || r === undefined) return undefined;
      return r as T;
    },
    list: async <ST>(filter?: FilterType<T>): Promise<ST[]> => {
      const r = await instance.keys();
      const items: ST[] = [];
      for (const key of r) {
        const item = await instance.getItem(key);
        if (!item) continue;

        if (filter) {
          let ok = true;
          for (const k of Object.keys(filter)) {
            if (
              // check if the value is the same
              (item as any)[k] !== (filter as any)[k] &&
              // check if the value is an object and the id is the same
              typeof (item as any)[k] === 'object' &&
              (item as any)[k].id !== (filter as any)[k]
            ) {
              ok = false;
              break;
            }
          }
          if (!ok) continue;
        }

        items.push(item as ST);
      }
      return items;
    },
    setItem: async (key: string, value: any) => {
      return await instance.setItem(key, value);
    },
    removeItem: async (key: string) => {
      return await instance.removeItem(key);
    },
    clear: async () => {
      return await instance.clear();
    },
    ...(a ?? {}),
  } as OfflineStore<T> & A;
};

export type NewExtension = { id: number; created_at: string };

export type LocalNewVendor = NewVendor & NewExtension;
export type LocalNewInstallationSite = NewInstallationSite & NewExtension;

export type LocalNewTest = NewTest & NewExtension;
export type LocalTest = Pick<Test, 'id' | 'testTask' | 'created_at'> & {
  examinee: LocalExaminee;
};

export type LocalNewExaminee = NewExaminee & NewExtension;
export type LocalExaminee = Pick<
  Examinee,
  | 'id'
  | 'tag'
  | 'installationSiteDescription'
  | 'installationSite'
  | 'created_at'
  | 'type'
  | 'vendor'
  | 'lastTested'
  | 'location'
>;

export type LocalExamineeNoLocationPossible = Omit<LocalExaminee, 'location'> & {
  location: undefined | Location;
};

export const examineeFamilyStore = initStorage<ExamineeFamily>('examineeFamily');
export const examineeTypeStore = initStorage<ExamineeType>('examineeType');
export const daysStore = initStorage('days');
export const tourStore = initStorage('tours');
export const vendorsStore = initStorage<Vendor>('vendors');
export const installationSitesStore = initStorage<InstallationSite>('installation-sites');
export const locationStore = initStorage<Location>('locations');
export const examineeStore = initStorage<ExamineeWithLastTest>('examinees');
export const testTaskStore = initStorage<TestTask>('test-tasks');
export const userStore = initStorage<User>('users');
export const testingDeviceStore = initStorage('testing-devices');

export const preferencesStore = initStorage<
  PreferenceValue,
  {
    keyed: (instance: LocalForage) => Promise<{ [key: string]: PreferenceValue }>;
  }
>('preferences', {
  keyed: async (instance: LocalForage) => {
    const rs: { [key: string]: PreferenceValue } = {};
    await instance.iterate(async (value: PreferenceValue, key: string) => {
      rs[key] = value;
    });
    return rs;
  },
});

export const createdExamineeStore = initStorage<LocalNewExaminee>('created-examinees');
export const createdTestsStore = initStorage<LocalNewTest>('created-tests');
export const createdVendorsStore = initStorage<LocalNewVendor>('created-vendors');
export const createdInstallationSitesStore = initStorage<LocalNewInstallationSite>('created-installation-sites');

// const setupStore = initStorage('setup');
// export const offlineSetupStore = {
//   get: () => {
//     return setupStore.getItem('setup');
//   },
//   set: (setup: any) => {
//     return setupStore.setItem('setup', setup);
//   },
//   clear: () => {
//     return setupStore.clear();
//   },
// };

export const clearOffline = async () => {
  await examineeFamilyStore.clear();
  await examineeTypeStore.clear();
  await daysStore.clear();
  await tourStore.clear();
  await vendorsStore.clear();
  await installationSitesStore.clear();
  await locationStore.clear();
  await examineeStore.clear();
  await testTaskStore.clear();
  await userStore.clear();
  await testingDeviceStore.clear();
  await preferencesStore.clear();
  await createdExamineeStore.clear();
  await createdTestsStore.clear();
  await createdVendorsStore.clear();
  await createdInstallationSitesStore.clear();
};

export interface ExTag {
  id: number;
  location: number;
}
type ExTags = { [key: string]: ExTag };
const tagsStore = initStorage<ExTags>('tags');

export const examineeTagsStore = {
  getItem: async (key: string): Promise<ExTag | undefined> => {
    const tags = await tagsStore.getItem('tags');
    if (!tags) return undefined;
    return tags[key];
  },
  set: async (t: { [key: string]: ExTag }) => {
    return await tagsStore.setItem('tags', t);
  },
  list: async (): Promise<{ [key: string]: ExTag }> => {
    const tags = await tagsStore.getItem('tags');
    if (!tags) return {};
    return tags as { [key: string]: ExTag };
  },
  add: async (key: string, value: ExTag) => {
    const tags = await tagsStore.getItem('tags');
    if (!tags) return await tagsStore.setItem('tags', { [key]: value });
    return await tagsStore.setItem('tags', { ...tags, [key]: value });
  },
  removeItem: async (key: string) => {
    return await tagsStore.removeItem(key);
  },
  clear: async () => {
    return await tagsStore.clear();
  },
};
