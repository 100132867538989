import { FunctionComponent } from 'react';
import { TableColProps } from '../../components/table';

import { Tester } from '../../interfaces/Tester';
import { TesterService } from '../../services';
import { useAddError } from '../../context/error';
import TableWithSearch, { OnDataParams, OnSearchParams } from '../../views/TableWithSearchView';
import { useAuth } from '../../context/auth';
import { get } from 'lodash';

const columns: TableColProps<Tester>[] = [
  { title: 'Kürzel', values: 'initials' },
  { title: 'Name', values: 'user.name' },
  {
    title: 'Testgerät',
    values: (tester) => {
      if (!tester.testingDevicesV2) return '';
      return tester.testingDevicesV2
        .map((testingDevice) => `${testingDevice.vendor} ${testingDevice.name} (${testingDevice.serial})`)
        .join(', ');
    },
  },
  {
    title: 'Status',
    values: (item: Tester) => {
      return <span>{!item.user || item.deleted_at || item.user.deleted_at ? 'Gesperrt' : 'Aktiv'}</span>;
    },
    key: 'status',
    showActive: true,
    filterable: (addError, limit, page, query) =>
      Promise.resolve([
        {
          value: undefined,
          title: 'Aktiv',
        },
        {
          value: 'deleted',
          title: 'Gesperrt',
        },
      ]),
  },
];

const List: FunctionComponent = () => {
  const addError = useAddError();
  const auth = useAuth();

  if (!auth || !auth.user) {
    return null;
  }

  return (
    <TableWithSearch
      title="Prüfer"
      to="/settings"
      defaultLimit={get(auth, 'user.itemsPerPage', 25)}
      columns={columns}
      onData={function ({ filter, limit, page }: OnDataParams<Tester>) {
        return TesterService(addError).list<Tester>(limit, page, filter);
      }}
      onSearch={function ({ limit, page, term }: OnSearchParams) {
        return TesterService(addError).search<Tester>(term, limit, page);
      }}
    />
  );
};

export default List;
