import MainLayout from '../../layouts/MainLayout';
import React from 'react';

import { Route } from 'react-router';
import ErrorPage from './Error';

const Routes = (
  <Route path="/error" element={<MainLayout />}>
    <Route path="/error" element={<ErrorPage />} />
  </Route>
);

export default Routes;
