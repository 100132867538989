import { Formik } from 'formik';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useAddError } from '../../context/error';
import { EmptyExaminee, ExamineeWithLastTest } from '../../interfaces/Examinee';

import { get, reduce } from 'lodash';
import styled from 'styled-components';
import DueList from '../../components/common/DueList/DueList';
import ExamineeTestTaskIntervalList from '../../components/common/ExamineeTestTaskIntervalList';
import TestList from '../../components/common/TestList/TestList';
import FormikDebug from '../../components/dev/formik-debug';
import { alert } from '../../components/dialogs/Alert';
import BareInput from '../../components/form/BareInput';
import InputGroup from '../../components/form/InputGroup';
import InputSection from '../../components/form/InputSection';
import Title from '../../components/title';
import { useAuth } from '../../context/auth';
import { ExamineeAdditionalInformationValue } from '../../interfaces/ExamineeAdditionalInformationValue';
import { Test } from '../../interfaces/Test';
import { ExamineesUtils } from '../../services';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const P = styled.p<{ small: boolean }>`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  color: ${(props) => (props.small ? '#b3b3b3' : '#000')};
`;

const L = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: start;
  align-content: start;
  align-items: flex-start;
  flex: 1;
  color: #a1a1a1;
  opacity: 1;
  padding: 0.75rem;
`;

type Ex = Omit<ExamineeWithLastTest, 'additionalInformation'> & {
  family?: number;
  additionalInformation?: Record<string, any>;
  tests: Test[];
};

const Show: FunctionComponent = () => {
  const addError = useAddError();
  const auth = useAuth();

  const navigate = useNavigate();
  const params = useParams();

  const [item, setItem] = useState<Ex>({ ...EmptyExaminee, tests: [] });
  const [notFound, setNotFound] = useState(false);

  const loadData = useCallback(async () => {
    if (!params.id) {
      setItem({ ...EmptyExaminee, tests: [] });
    }

    const tag = params.tag ?? '';
    const _item = await ExamineesUtils(addError).getByTagPublic(tag);
    const hasExaminee = _item !== null && _item !== undefined && _item.id;
    const isAuth = auth.token !== undefined;

    // Prüfling existiert nicht, aber Benutzer ist angemeldet -> Neuen Test starten
    if (!hasExaminee && isAuth) {
      navigate(`/tests/new?tag=${tag}`);
      return;
    }

    // Prüfling existiert nicht, Benutzer ist nicht angemeldet -> Fehlermeldung
    if (!hasExaminee && !isAuth) {
      setNotFound(true);
      return;
    }

    // Prüfling existiert, Benutzer ist angemeldet -> Prüfling anzeigen
    if (hasExaminee && isAuth) {
      navigate(`/examinees/${_item.id}`);
      return;
    }

    // Prüfling existiert, Benutzer ist nicht angemeldet -> Prüfling anzeigen
    setItem({
      ..._item,
      additionalInformation: reduce<ExamineeAdditionalInformationValue, Record<string, any>>(
        _item.additionalInformation ?? [],
        (prev, v: ExamineeAdditionalInformationValue, k) => {
          if (v.field) {
            const d = v.field.id.toString();
            prev[d] = v.value;
          }
          return prev;
        },
        {},
      ),
    });
  }, [addError, auth.token, navigate, params.id, params.tag]);

  useEffect(() => {
    loadData();
  }, [addError, loadData, navigate, params.id]);

  if (notFound) {
    return (
      <Row>
        <Col xs={12}>
          <Alert variant={'danger'}>
            <b>Prüfling nicht gefunden</b> Der Prüfling wurde nicht gefunden. Bitte überprüfen Sie die eingegebene ID.
          </Alert>
        </Col>
      </Row>
    );
  }

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={noop}>
      {({ values }) => (
        <>
          <form autoComplete="off" onSubmit={noop}>
            <Title title={`Prüfling ${item.tag}`} />
            {item.last?.result === false && (
              <Row>
                <Col xs={12}>
                  <Alert variant={'danger'}>
                    <b>[Prüfling gesperrt]</b> Der Prüfling ist bei der letzten Prüfung durchgefallen und darf nicht
                    weiter verwendet werden.
                  </Alert>
                </Col>
              </Row>
            )}
            <Row>
              <InputSection title="Stammdaten" xs={12} xl={6}>
                <InputGroup title="Eindeutige ID" name="tag">
                  <BareInput disabled={true} type="text" name="tag" />
                </InputGroup>
                <InputGroup title="Prüflingsgattung" name="type.family.name">
                  <BareInput disabled={true} type="text" name="type.family.name" />
                </InputGroup>
                <InputGroup title="Prüflingsart" name="type.name">
                  <BareInput disabled={true} type="text" name="type.name" />
                </InputGroup>
                <InputGroup title="Hersteller" name="vendor.name">
                  <BareInput disabled={true} type="text" name="vendor.name" />
                </InputGroup>
              </InputSection>
              <InputSection title="Aufstellort" xs={12} xl={6}>
                <InputGroup title="Standort" name="location">
                  <L>
                    <P small={false}>
                      {get(values, 'location.tag') ? `[${values.location.tag}]` : ''} {values.location.name}
                    </P>
                    <P small={true}>
                      {values.location.address.street}, {values.location.address.postalCode}{' '}
                      {values.location.address.city}
                    </P>
                  </L>
                </InputGroup>
                <InputGroup title="Aufstellort" name="installationSite.name">
                  <BareInput disabled={true} type="text" name="installationSite.name" />
                </InputGroup>
                <InputGroup title="Bezeichnung" name="installationSiteDescription">
                  <BareInput disabled={true} type="text" name="installationSiteDescription" />
                </InputGroup>
              </InputSection>
              {values.additionalInformation && values.additionalInformation.length > 0 && (
                <InputSection title="zusätzliche Felder" xs={12} xl={6}>
                  {values.additionalInformation.map((ai: any) => (
                    <InputGroup title={ai.field?.name} name={`additionalInformation.${ai.field?.id}`}>
                      <BareInput disabled={true} type="text" name={`additionalInformation.${ai.field?.id}`} />
                    </InputGroup>
                  ))}
                </InputSection>
              )}
              {!(values.additionalInformation && values.additionalInformation.length > 0) && (
                <InputSection title="" xs={12} xl={6}></InputSection>
              )}
              <InputSection title="" xs={12} xl={6}></InputSection>
              {values.last?.result !== false && (
                <>
                  <InputSection title="Prüfintervalle" xs={12} xl={6}>
                    <ExamineeTestTaskIntervalList loading={false} examinee={values} />
                  </InputSection>
                  <InputSection title="nächste Prüfungen" xs={12} xl={6}>
                    <DueList items={values.dues} />
                  </InputSection>
                </>
              )}
              <InputSection title="Prüfungen" xs={12}>
                <TestList
                  onClick={(i) => {
                    if (auth.user) {
                      navigate(`${i.id}`);
                    } else {
                      alert('Anmeldung erforderlich', 'Sie müssen sich anmelden, um die Prüfungsdetails zu sehen.');
                    }
                  }}
                  showExamineeTag={false}
                  loading={false}
                  items={values.tests}
                />
              </InputSection>
            </Row>
          </form>
          <FormikDebug />
        </>
      )}
    </Formik>
  );
};

export default Show;
