import { API } from '../config';
import { AddErrorFunction } from '../context/error';
import axios from 'axios';
import { get } from 'lodash';

const AuthService = (addError: AddErrorFunction) => {
  const signin = (email: string, password: string): Promise<string> => {
    return axios.post(`${API}/auth/login`, { email, password }).then((res) => {
      return get(res, 'data.data', undefined);
    });
  };

  const changePassword = (password: string, passwordNew: string, token: string): Promise<boolean> => {
    return axios
      .post(
        `${API}/auth/change-password`,
        { password, passwordNew, passwordConfirm: passwordNew },
        { headers: { Authorization: token } },
      )
      .then((res) => {
        return get(res, 'data.message', undefined) === 'Password successfully changed.';
      });
  };

  const resetPassword = (email: string, token: string): Promise<boolean> => {
    return axios.post(`${API}/auth/reset-password`, { email }, { headers: { Authorization: token } }).then((res) => {
      return get(res, 'data.message', undefined) === 'Password successfully changed.';
    });
  };

  return { signin, changePassword, resetPassword };
};

export default AuthService;
