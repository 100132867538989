import { AddErrorFunction } from '../context/error';
import { NewTest, Test } from '../interfaces/Test';

import axios from 'axios';
import { get as _get } from 'lodash';
import queryString from 'query-string';
import { API } from '../config';
import { createdTestsStore } from '../storage';
import _BaseService from './BaseService';
import { FilterType, Service } from './Service';
import handleError from './errorHandler';
import getToken from './getToken';

export interface ExtendedService<T, UT, NT> extends Service<T, UT, NT> {
  pdf: (filter?: FilterType<T>, params?: Record<string, string>) => Promise<boolean>;
}

const TestService = (addError: AddErrorFunction, noCache = false) =>
  _BaseService<Test, never, NewTest>('tests', addError, undefined, undefined, noCache);

const ExtendedTestService = (addError: AddErrorFunction, noCache = false): ExtendedService<Test, never, NewTest> => {
  const cachePolicy = () => {
    if (noCache) {
      return 'no-cache, max-age=0';
    } else {
      return 'max-age=120';
    }
  };

  const base = TestService(addError);

  const create = (data: NewTest, suppress = false) => {
    return axios
      .post(`${API}/tests`, data, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        return _get(res, 'data.data.id') as number;
      })
      .catch(async (e) => {
        if (e.code === 'ERR_NETWORK') {
          const id = -new Date().getTime();
          await createdTestsStore.setItem(id.toString(), {
            ...data,
            id: id.toString(),
            created_at: new Date().toISOString(),
          });
          return id;
        }

        const handledError = handleError(e);
        if (suppress) {
          return handledError;
        } else {
          addError(handledError);
          return undefined;
        }
      });
  };

  const pdf = (filter?: FilterType<Test>, params?: Record<string, string>): Promise<boolean> => {
    const query = queryString.stringify({
      filter: Object.keys(filter ?? {}).length > 0 ? JSON.stringify(filter) : undefined,
      ...(params ?? {}),
    });

    return axios
      .post(
        `${API}/tests/pdf?${query}`,
        {},
        {
          headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
        },
      )
      .then((res) => {
        return true;
      })
      .catch(async (e) => {
        addError(handleError(e));
        return false;
      });
  };

  return { ...base, create, pdf };
};

export default ExtendedTestService;
