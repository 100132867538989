import { EmptyExaminee, Examinee } from '../../../interfaces/Examinee';
import { EmptyExamineeTestTaskInterval, ExamineeTestTaskInterval } from '../../../interfaces/ExamineeTestTaskInterval';
import { ErrorLevel, useAddError, useClearError } from '../../../context/error';
import { Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useEffect, useState } from 'react';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';

import { ExamineesService } from '../../../services';
import ExtendedExamineeService from '../../../services/ExtendedExamineeService';
import FormikDebug from '../../../components/dev/formik-debug';
import InputGroup from '../../../components/form/InputGroup';
import InputSection from '../../../components/form/InputSection';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { NumberInput } from '../../../components/form/BareInput';
import { Row } from 'react-bootstrap';
import Title from '../../../components/title';
import { get } from 'lodash';
import qs from 'query-string';

const shouldEditing = (location: Location) => {
  const queryParams = qs.parse(location.search, {});
  return queryParams.edit === '1' || queryParams.edit === 'true';
};

const Show: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(shouldEditing(location));

  const [examinee, setExaminee] = useState<Examinee>(EmptyExaminee);
  const [item, setItem] = useState<ExamineeTestTaskInterval>(EmptyExamineeTestTaskInterval);

  useEffect(() => {
    const loadData = async () => {
      try {
        if (!params.id) {
          return;
        }
        const _item = await ExamineesService(addError).get(params.id);
        if (!_item || !_item.id) {
          navigate('..');
        }
        setExaminee(_item);
        const _interval = _item.intervals.find((i) => i.id.toString() === params.iid);
        if (!_interval) {
          navigate('..');
        }
        setItem(_interval!);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, navigate, params.id, params.iid]);

  const onSubmit = async (
    values: ExamineeTestTaskInterval,
    { setSubmitting }: FormikHelpers<ExamineeTestTaskInterval>,
  ) => {
    setSubmitting(true);
    try {
      if (params.id) {
        clearError();
        await ExtendedExamineeService(addError).updateInterval(examinee.id, item.id, { ...values });
        setEditing(false);
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  // const onDelete = async (setSubmitting: (isSubmitting: boolean) => void) => {
  //   setSubmitting(true);
  //   try {
  //     if (params.id) {
  //       if (
  //         await confirm(
  //           'Prüfmittel löschen?',
  //           'Das Prüfmittel wird entgültig gelöscht. Sind Sie sich sicher?',
  //           'Löschen',
  //           'Abbruch',
  //           {
  //             okColor: 'danger',
  //           },
  //         )
  //       ) {
  //         await ExamineeTestTaskIntervalService(addError).destroy(params.id);
  //         navigate('..');
  //       }
  //     }
  //   } catch (error) {
  //     addError({
  //       level: ErrorLevel.danger,
  //       message: get(error, 'message', 'Unbekannter Fehler'),
  //     });
  //   } finally {
  //     setSubmitting(false);
  //   }
  // };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
      {({
        handleSubmit,
        isValidating,
        setFieldValue,
        isValid,
        errors,
        values,
        resetForm,
        isSubmitting,
        setSubmitting,
      }) => (
        <>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Title
              onEdit={() => setEditing(true)}
              // onDelete={() => onDelete(setSubmitting)}
              onCancel={() => {
                resetForm({
                  values: item,
                });
                setEditing(false);
              }}
              editing={editing}
              editingValid={!isValidating && isValid}
              isSubmitting={isSubmitting}
              to={`/examinees/${params.id}`}
              title={`${examinee.tag} - ${item.testTask.initials} - Intervall`}
            />
            <Row>
              <InputSection title="Stammdaten" xs={12} xl={6}>
                <InputGroup error={errors.interval} valid={!errors.interval} title="Intervall" name="interval">
                  <NumberInput disabled={isSubmitting || !editing} name="interval" float={false} />
                </InputGroup>
              </InputSection>
            </Row>
          </form>
          <FormikDebug />
        </>
      )}
    </Formik>
  );
};

export default Show;
