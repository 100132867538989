import queryString from 'query-string';
import { AddErrorFunction } from '../context/error';
import { Examinee, NewExaminee, UpdateExaminee } from '../interfaces/Examinee';
import {
  ExamineeTestTaskInterval,
  NewExamineeTestTaskInterval,
  UpdateExamineeTestTaskInterval,
} from '../interfaces/ExamineeTestTaskInterval';

import axios from 'axios';
import { get as _get } from 'lodash';
import { API } from '../config';
import { createdExamineeStore, examineeTagsStore } from '../storage';
import _BaseService from './BaseService';
import { Service } from './Service';
import handleError from './errorHandler';
import getToken from './getToken';

export interface ExtendedService<T, UT, NT> extends Service<T, UT, NT> {
  addInterval: (id: number, interval: NewExamineeTestTaskInterval) => Promise<ExamineeTestTaskInterval>;
  updateInterval: (
    id: number,
    intervalId: number,
    interval: UpdateExamineeTestTaskInterval,
  ) => Promise<ExamineeTestTaskInterval>;
  usedTags: () => Promise<{ id: string; location: string; tag: string }[]>;
  tags: (limit?: number, page?: number) => Promise<{ items: string[]; count: number }>;
  searchTag: (term: string) => Promise<{ items: string[]; count: number }>;
}

export const ExamineeService = (addError: AddErrorFunction, noCache = false) =>
  _BaseService<Examinee, UpdateExaminee, NewExaminee>('examinees', addError, undefined, undefined, noCache);

const ExtendedExamineeService = (
  addError: AddErrorFunction,
  noCache = true,
): ExtendedService<Examinee, UpdateExaminee, NewExaminee> => {
  const cachePolicy = () => {
    if (noCache) {
      return 'no-cache, max-age=0';
    } else {
      return 'max-age=120';
    }
  };

  const base = ExamineeService(addError);

  const tags = (limit = 25, page = 0) => {
    const query = queryString.stringify({
      limit,
      page,
    });
    return axios
      .get(`${API}/examinees/tags?${query}`, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        const items = _get(res, 'data.data.items');
        return { items: items.map((item: any) => item.tag) as string[], count: _get(res, 'data.data.count') as number };
      })
      .catch(async (e) => {
        addError(handleError(e));
        return { items: [] as string[], count: 0 };
      });
  };

  const searchTag = (term: string) => {
    const q = term.trim();
    const query = queryString.stringify({
      q,
    });
    return axios
      .get(`${API}/examinees/tags?${query}`, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        const items = _get(res, 'data.data.items');
        return { items: items.map((item: any) => item.tag) as string[], count: _get(res, 'data.data.count') as number };
      })
      .catch((e) => {
        addError(handleError(e));
        return { items: [] as string[], count: 0 };
      });
  };

  const addInterval = (id: number, interval: NewExamineeTestTaskInterval) => {
    return axios
      .post(
        `${API}/examinees/${id}/intervals`,
        { interval: interval.interval, testTask: interval.testTask },
        {
          headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
        },
      )
      .then((res) => {
        return _get(res, 'data.data') as ExamineeTestTaskInterval;
      })
      .catch((e) => {
        addError(handleError(e));
        return {} as ExamineeTestTaskInterval;
      });
  };

  const updateInterval = (id: number, intervalId: number, data: UpdateExamineeTestTaskInterval) => {
    return axios
      .put(`${API}/examinees/${id}/intervals/${intervalId}`, data, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        return _get(res, 'data.data') as ExamineeTestTaskInterval;
      })
      .catch((e) => {
        addError(handleError(e));
        return {} as ExamineeTestTaskInterval;
      });
  };

  const usedTags = () => {
    return axios
      .get(`${API}/examinees/tags`, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        return _get(res, 'data.data.items');
      })
      .catch((e) => {
        addError(handleError(e));
        return [];
      });
  };

  const create = (data: NewExaminee, suppress = false) => {
    return axios
      .post(`${API}/examinees`, data, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        return _get(res, 'data.data.id') as number;
      })
      .catch(async (e) => {
        if (e.code === 'ERR_NETWORK') {
          const id = -new Date().getTime();
          await examineeTagsStore.add(data.tag, { id, location: data.location });
          await createdExamineeStore.setItem(id.toString(), {
            ...data,
            id: id.toString(),
            created_at: new Date().toISOString(),
          });
          return id;
        }

        const handledError = handleError(e);
        if (suppress) {
          return handledError;
        } else {
          addError(handledError);
          return -1;
        }
      });
  };

  return { ...base, tags, searchTag, create, addInterval, updateInterval, usedTags };
};

export default ExtendedExamineeService;
