import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const ButtonWithIcon = styled(Button)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: flex-start;
  float: left;
  color: #a1a1a1;
  opacity: 1;
  svg {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 11px 0 11px 11px;
    &,
    path,
    g {
      fill: #a1a1a1;
      color: #a1a1a1;
    }
  }
  // span {
  //   display: inline-block;
  //   vertical-align: bottom;
  //   margin-bottom: 1px;
  // }
  span {
    margin: auto 0;
  }
  &:disabled,
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: #a1a1a1 !important;
  }
  &:hover {
    color: var(--bs-primary);
    opacity: 0.8;
    &,
    path,
    g {
      fill: var(--bs-primary);
      color: var(--bs-primary);
    }
  }
`;
