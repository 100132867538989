import { EmptyExamineeFamily, ExamineeFamily } from './ExamineeFamily';
import { NewType, UpdateType } from './removeDates';

export interface ExamineeType {
  id: number;
  name: string;
  prio: number;
  family: ExamineeFamily;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export type NewExamineeType = NewType<
  ExamineeType,
  'name',
  {
    family: number;
  }
>;

export type UpdateExamineeType = UpdateType<
  ExamineeType,
  'name' | 'prio',
  {
    family: number;
  }
>;

export const EmptyNewExamineeType: NewExamineeType = {
  name: '',
  family: 0,
};

export const EmptyExamineeType: ExamineeType = {
  id: 0,
  name: '',
  prio: 0,
  family: EmptyExamineeFamily,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
};
