import { Col, ColProps } from 'react-bootstrap';
import React, { FunctionComponent } from 'react';

import styled from 'styled-components';

const Title = styled.h3`
  padding: 36px 0 14px;
  font-size: 11px;
  letter-spacing: 0;
  text-transform: uppercase;
  line-height: 22px;
  font-weight: 700;
  color: #009842;
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  display: block;
`;

const Describtion = styled.h6`
  padding: 36px 0 14px;
  font-size: 11px;
  letter-spacing: 0;
  text-transform: uppercase;
  line-height: 22px;
  font-weight: 700;
  color: #009842;
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  display: block;
`;

const DeleteButton = styled.button`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  border: 0;
  color: #a1a1a1;
  &:hover {
    color: var(--bs-red);
    opacity: 1;
    &,
    path,
    g {
      fill: var(--bs-red);
      color: var(--bs-red);
    }
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
  width: 100%;
`;

interface InputSectionProps {
  title?: string;
  describtion?: string;
  onDelete?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  deleteText?: string;
}

const InputSection: FunctionComponent<InputSectionProps & ColProps> = ({
  children,
  title,
  describtion,
  onDelete,
  deleteText,
  ...rest
}) => {
  return (
    <Col {...rest}>
      <Header>
        <Title>{title ?? <br />}</Title>
        {onDelete && <DeleteButton onClick={(e) => onDelete(e)}>{deleteText ?? 'Löschen'}</DeleteButton>}
      </Header>
      {describtion && <Describtion>{describtion}</Describtion>}
      {children}
    </Col>
  );
};

export default InputSection;
