import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map } from 'rxjs';
// import { DTP, DateRangePicker } from '../components/common/DateRangePicker';
import { alert } from '../components/dialogs/Alert';
import { confirm } from '../components/dialogs/Confirmation';
import Table, { TableColProps } from '../components/table';
import Title, { SearchState, TitleHeader, TitleTo } from '../components/title';
import { useAddError } from '../context/error';
import { FilterType } from '../services/Service';

// type DateRange = [Date | null, Date | null];

export type OnDataParams<T> = {
  limit: number;
  page: number;
  filter: FilterType<T> & { range?: string };
  tab: number;
};

export type OnSearchParams = {
  term: string;
  limit: number;
  page: number;
};

interface TableWithSearchProps<T extends { id: number }> {
  tabs?: string[];
  columns: TableColProps<T>[];
  onData: (options: OnDataParams<T>) => Promise<{ items: T[]; count: number }>;
  onSearch?: (options: OnSearchParams) => PromiseLike<{ items: T[]; count: number }>;
  onNew?: () => void;
  onXLSX?: (filter?: FilterType<T>, params?: Record<string, string>) => void;
  onPrint?: (filter?: FilterType<T>, params?: Record<string, string>) => Promise<boolean>;
  filter?: FilterType<T>;
  title: TitleHeader;
  to?: TitleTo;
  onClickItem?: (item: T) => void;
  defaultFilter?: Record<string, 'today' | 'this-week' | 'this-month' | 'this-year' | (number | string | boolean)[]>; // FilterType<T>;
  defaultLimit?: number;
}

type Pagination = {
  page: number;
  limit: number;
};

type ColumnFilters = Record<string, (number | string | boolean | [Date, Date] | undefined)[]>;

function TableWithSearch<T extends { id: number }>({
  tabs,
  columns,
  onSearch,
  onData,
  onNew,
  onXLSX,
  onPrint,
  onClickItem,
  title,
  to,
  defaultLimit,
  defaultFilter,
}: TableWithSearchProps<T>) {
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(false);

  const [items, setItems] = useState<T[] | null>(null);
  const [count, setCount] = useState<number>(0);
  const addError = useAddError();

  const [search, setSearch] = useSearchParams();

  const [tabSubject, setTabSubject] = useState<BehaviorSubject<number> | null>(null);

  const [paginationSubject, setPaginationSubject] = useState<BehaviorSubject<Pagination> | null>(null);

  const [columnFiltersSubject, setColumnFiltersSubject] = useState<BehaviorSubject<ColumnFilters> | null>(null);

  // const [dateRangeSubject, setdateRangeSubject] = useState<BehaviorSubject<DateRange> | null>(null);

  const [params, setParams] = useState<{
    pagination: Pagination;
    filter: ColumnFilters;
    // dateRange: DateRange;
    tab: number;
  } | null>(null);

  const intFromSearch = (key: string, defaultValue: number) => {
    const v = search.get(key);
    if (v === undefined || v === null) return defaultValue;
    const paresed = parseInt(v);
    return isNaN(paresed) ? defaultValue : paresed;
  };

  const queryTab: number = intFromSearch('tab', 0);
  const queryPage: number = intFromSearch('page', 0);
  const queryLimit: number = intFromSearch('limit', defaultLimit ?? 25);

  /**
   * Initialize search subject
   */
  useEffect(() => {
    if (tabSubject === null) {
      // load from search
      setTabSubject(new BehaviorSubject(queryTab));
    } else if (paginationSubject === null) {
      // load from search
      setPaginationSubject(
        new BehaviorSubject({
          page: queryPage,
          limit: queryLimit,
        }),
      );
      // } else if (dateRangeSubject === null) {
      //   const d: DateRange = [DateTime.now().startOf('week').toJSDate(), DateTime.now().endOf('day').toJSDate()];
      //   setdateRangeSubject(new BehaviorSubject(d));
    } else if (columnFiltersSubject === null) {
      // load from search
      const hasQueryFilter =
        search.get('filter') !== undefined &&
        search.get('filter') !== null &&
        search.get('filter') !== '' &&
        search.get('filter') !== '{}';
      let f: Record<string, 'today' | 'this-week' | 'this-month' | 'this-year' | (number | string | boolean)[]> =
        defaultFilter ?? {};

      if (hasQueryFilter) {
        f = JSON.parse(
          search.get('filter') &&
            search.get('filter') !== undefined &&
            search.get('filter') !== null &&
            search.get('filter') !== 'undefined' &&
            search.get('filter') !== 'null'
            ? (search.get('filter') as string)
            : '{}',
        );
      }

      for (const key in f) {
        if (f[key] === undefined || f[key] === null) {
          delete f[key];
        }

        if (typeof f[key] === 'string' && f[key] === 'today') {
          f[key] = [DateTime.now().startOf('day').toISO(), DateTime.now().endOf('day').toISO()];
        }

        if (typeof f[key] === 'string' && f[key] === 'this-week') {
          f[key] = [DateTime.now().startOf('week').toISO(), DateTime.now().endOf('day').toISO()];
        }

        if (typeof f[key] === 'string' && f[key] === 'this-month') {
          f[key] = [DateTime.now().startOf('month').toISO(), DateTime.now().endOf('day').toISO()];
        }

        if (typeof f[key] === 'string' && f[key] === 'this-year') {
          f[key] = [DateTime.now().startOf('year').toISO(), DateTime.now().endOf('day').toISO()];
        }
      }

      setColumnFiltersSubject(new BehaviorSubject(f as any));
    } else {
      const observable = combineLatest({
        pagination: paginationSubject,
        filter: columnFiltersSubject,
        // dateRange: dateRangeSubject,
        tab: tabSubject,
      })
        .pipe(
          map(
            ({
              pagination,
              filter,
              // dateRange,
              tab,
            }) => {
              return {
                pagination,
                filter,
                // dateRange,
                tab,
              };
            },
          ),
          debounceTime(300),
          distinctUntilChanged(),
        )
        .subscribe((newState) => {
          setSearch({
            ...search,
            tab: newState.tab.toString(),
            page: newState.pagination.page.toString(),
            limit: newState.pagination.limit.toString(),
            filter: JSON.stringify(newState.filter),
          });
          setParams(newState);
        });

      return () => {
        if (observable) observable.unsubscribe();
        if (paginationSubject) paginationSubject.unsubscribe();
        if (columnFiltersSubject) columnFiltersSubject.unsubscribe();
        setPaginationSubject(null);
        setColumnFiltersSubject(null);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paginationSubject,
    columnFiltersSubject,
    //  dateRangeSubject,
    tabSubject,
  ]);

  const _onXLSX = useCallback(() => {
    if (!params) return;
    onXLSX && onXLSX(params.filter as any);
  }, [params, onXLSX]);

  const _onPrint = useCallback(async () => {
    if (!params) return;
    // if (params.dateRange[0] === null || params.dateRange[1] === null) return;
    if (onPrint) {
      const q = await confirm(
        'PDF(s) erstellen',
        'Der Export wird im Hintergrund erstellt. Sie erhalten nach Fertigstellung eine E-Mail an Ihre hinterlegte E-Mail-Adresse.',
        'Auftrag ausführen',
        'Abbrechen',
      );
      if (!q) return;

      const res = await onPrint({
        ...(params.filter as any),
        // ...(dateRange === true ? { range: JSON.stringify(params.dateRange) } : {}),
      });
      if (!res) {
        alert('PDF erstellen', 'Druckauftrag konnte nicht gestartet werden.');
      } else {
        alert(
          'PDF erstellen',
          'Der Druckauftrag wurde gestartet. Sie erhalten nach Abschluss eine E-Mail mit dem Download-Link.',
        );
      }
    }
  }, [params, onPrint]);
  // }, [count]);

  useEffect(() => {
    const loadData = async () => {
      try {
        if (!params || !onData) return;
        // if (params.dateRange[0] === null || params.dateRange[1] === null) return;
        setLoading(true);
        const { items, count } = await onData({
          tab: params.tab,
          limit: params.pagination.limit,
          page: params.pagination.page,
          filter: {
            ...(params.filter ?? ({} as any)),
            // ...(dateRange === true ? { range: JSON.stringify(params.dateRange) } : {}),
          } as any,
        });
        setItems(items);
        setCount(count);
        // }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addError, params]);

  const [searchState, setSearchState] = useState<SearchState<T>>({
    items: [],
    loading: false,
    errorMessage: undefined,
    noResults: false,
    active: false,
  });

  const usedColumns = useMemo(() => {
    // make all columns not filterable if searching

    return columns.map((c: TableColProps<T>) => {
      const showFilter = !searchState.active && (!!(c as any).filterable || !!(c as any).filterKey);
      return {
        ...c,
        filterable: showFilter ? (c as any).filterable : undefined,
      };
    });
  }, [columns, searchState.active]);

  const usedTitle = useMemo(
    () => ((tabs && tabs.length > 0) || count === 0 ? title : `${title} (${count})`),
    [count, tabs, title],
  );

  return (
    <>
      <Title<T>
        tabs={
          !searching && tabs && tabs.length > 0
            ? tabs.map((title, i) => {
                if ((i === params?.tab ?? 0) && count > 0 && !loading) return `${title} (${count})`;
                return title;
              })
            : undefined
        }
        tab={params?.tab ?? 0}
        title={usedTitle}
        to={to}
        onNew={onNew}
        onXLSX={onXLSX ? _onXLSX : undefined}
        onPrint={onPrint ? _onPrint : undefined}
        onTab={(tab) => {
          if (tabSubject && !tabSubject.closed) {
            tabSubject.next(tab);
          }
          if (paginationSubject && !paginationSubject.closed) {
            paginationSubject.next({
              limit: params?.pagination.limit ?? 25,
              page: 0,
            });
          }
        }}
        search={
          onSearch
            ? {
                onSearch: (term: string) => {
                  return onSearch({
                    term,
                    limit: params?.pagination.limit ?? 25,
                    page: params?.pagination.page ?? 0,
                  });
                },
                onChanged(state) {
                  setSearching(state.active);
                  setSearchState(state);
                },
              }
            : undefined
        }
      >
        {/* {dateRange === true ? (
          <DateRangePicker>
            <DTP
              locale="de"
              selectsRange={true}
              startDate={params?.dateRange[0] ?? null}
              endDate={params?.dateRange[1] ?? null}
              maxDate={new Date()}
              todayButton="Heute"
              dateFormat="dd.MM.yyyy"
              showWeekNumbers
              showMonthDropdown
              shouldCloseOnSelect
              onChange={(update: [Date | null, Date | null]) => {
                if (dateRangeSubject && !dateRangeSubject.closed) {
                  return dateRangeSubject.next([
                    update[0] ? DateTime.fromJSDate(update[0]).startOf('day').toJSDate() : null,
                    update[1] ? DateTime.fromJSDate(update[1]).endOf('day').toJSDate() : null,
                  ]);
                }
              }}
              withPortal
            />
          </DateRangePicker>
        ) : null} */}
      </Title>
      <Table
        loading={searchState.active ? searchState.loading : loading || items === null}
        items={searchState.active ? searchState.items : items ?? []}
        columns={usedColumns}
        defaultLimit={queryLimit}
        defaultPage={queryPage}
        columnFilters={
          searchState.active || params?.filter === undefined || params?.filter === null ? {} : params.filter
        }
        onFilterChanged={(f) => {
          if (columnFiltersSubject && !columnFiltersSubject.closed) {
            return columnFiltersSubject.next(f);
          }
        }}
        onPagination={
          !searching
            ? (page: number, limit: number) => {
                if (paginationSubject && !paginationSubject.closed) {
                  console.log('pagination', page, limit);
                  return paginationSubject.next({
                    limit,
                    page,
                  });
                }
              }
            : undefined
        }
        pages={
          searchState.active || params?.pagination.limit === undefined || params?.pagination.limit === null
            ? 1
            : Math.ceil(count / params.pagination.limit)
        }
        onClick={onClickItem}
      />
    </>
  );
}

export default TableWithSearch;
