import { TestValueType } from '../../../interfaces/TestValue';
import { ThresholdType } from '../../../interfaces/TestValueThreshold';
import { ValueType } from '../../../interfaces/ValueType';
import formatMeasureType from '../../../helpers/format/measureType';
import formatThresholdType from '../../../helpers/format/thresholdType';
import formatValueType from '../../../helpers/format/valueType';

export const SELECTABLE_VALUE_TYPES = [
  { value: ValueType.BOOLEAN, title: formatValueType(ValueType.BOOLEAN) },
  { value: ValueType.NUMBER, title: formatValueType(ValueType.NUMBER) },
  { value: ValueType.FLOAT, title: formatValueType(ValueType.FLOAT) },
  { value: ValueType.STRING, title: formatValueType(ValueType.STRING) },
  { value: ValueType.OPTION, title: formatValueType(ValueType.OPTION) },
];

export const SELECTABLE_BOOLEAN = [
  { value: false, title: 'Nein' },
  { value: true, title: 'Ja' },
];

export const SELECTABLE_TYPES = [
  { value: TestValueType.FUNKTIONSPRÜFUNG, title: formatMeasureType(TestValueType.FUNKTIONSPRÜFUNG) },
  { value: TestValueType.MESSPRÜFUNG, title: formatMeasureType(TestValueType.MESSPRÜFUNG) },
  { value: TestValueType.SICHTPRÜFUNG, title: formatMeasureType(TestValueType.SICHTPRÜFUNG) },
];

export const SELECTABLE_THRESHOLD_TYPE = [
  { value: ThresholdType.EQUAL, title: formatThresholdType(ThresholdType.EQUAL) },
  { value: ThresholdType.UNEQUAL, title: formatThresholdType(ThresholdType.UNEQUAL) },
];

export const SELECTABLE_THRESHOLD_TYPE_NUM = [
  { value: ThresholdType.HIHGER, title: formatThresholdType(ThresholdType.HIHGER) },
  { value: ThresholdType.LOWER, title: formatThresholdType(ThresholdType.LOWER) },
  { value: ThresholdType.EQUAL, title: formatThresholdType(ThresholdType.EQUAL) },
  { value: ThresholdType.UNEQUAL, title: formatThresholdType(ThresholdType.UNEQUAL) },
];
