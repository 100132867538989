import { AddErrorFunction } from '../context/error';

import axios from 'axios';
import download from 'downloadjs';
import { get as _get } from 'lodash';
import queryString from 'query-string';
import { API } from '../config';
import handleError from './errorHandler';
import getToken from './getToken';

const DownloadService = (addError: AddErrorFunction) => {
  const cachePolicy = () => {
    return 'no-cache, max-age=0';
  };

  const downloadFile = (path: string, filter?: any, params?: Record<string, string>) => {
    const query = queryString.stringify({
      filter: Object.keys(filter ?? {}).length > 0 ? JSON.stringify(filter) : undefined,
      ...(params ?? {}),
    });

    return axios
      .post(
        `${API}/${path}/?${query}`,
        {},
        {
          responseType: 'blob',
          headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
        },
      )
      .then((res) => {
        const data = _get(res, 'data');
        const mime = _get(res, 'headers.content-type');
        // extract filename from header
        const _filename = _get(res, 'headers.content-disposition');
        const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = regex.exec(_filename);
        let filename = 'export.xlsx';
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
        // start download
        download(data, filename, mime);
      })
      .catch((e) => {
        addError(handleError(e));
        return;
      });
  };

  return { downloadFile };
};

export default DownloadService;
