import { FunctionComponent } from 'react';
import { TableColProps } from '../../components/table';

import { LocationService } from '../../services';
import { useAddError } from '../../context/error';
import { Location } from '../../interfaces/Location';
import TableWithSearch, { OnDataParams, OnSearchParams } from '../../views/TableWithSearchView';
import { useAuth } from '../../context/auth';
import { get } from 'lodash';
import { useMain } from '../../context/main';

const columns: TableColProps<Location>[] = [
  { title: 'Standortnummer', values: 'tag' },
  { title: 'Standort', values: 'name' },
  { title: 'Straße', values: 'address.street' },
  { title: 'Postleitzahl', values: 'address.postalCode' },
  { title: 'Stadt', values: 'address.city' },
];

const List: FunctionComponent = () => {
  const addError = useAddError();
  const auth = useAuth();
  const { selectLocation } = useMain();

  if (!auth || !auth.user) {
    return null;
  }

  return (
    <TableWithSearch
      title="Standorte"
      onClickItem={(location) => {
        selectLocation([
          {
            label1: `[${location.tag}] ${location.name}`,
            label2: `${location.address.street}, ${location.address.postalCode} ${location.address.city}`,
            value: location.id,
            locked: location.locked,
          },
        ]);
      }}
      columns={columns}
      defaultLimit={get(auth, 'user.itemsPerPage', 25)}
      onData={function ({ filter, limit, page }: OnDataParams<Location>) {
        return LocationService(addError).list<Location>(limit, page, filter);
      }}
      onSearch={function ({ limit, page, term }: OnSearchParams) {
        return LocationService(addError).search<Location>(term, limit, page);
      }}
    />
  );
};

export default List;
