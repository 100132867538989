import { AddSafeDocError, ErrorLevel } from '../../context/error';
import Cookies from 'js-cookie';
import { get } from 'lodash';

export const TokenExpiredError = {
  level: ErrorLevel.danger,
  title: 'Sitzung abgelaugen',
  message: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich neu an.',
  redirect: '/auth/login',
};

const isTokenExpired = (error: Error): null | AddSafeDocError => {
  const errorName = get(error, 'response.data.errorRaw.name');
  if (errorName === 'TokenExpiredError') {
    Cookies.remove('token');
    setTimeout(() => {
      return TokenExpiredError;
    }, 35);
  }
  return null;
};

export default isTokenExpired;
