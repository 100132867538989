import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { InstallationSite, NewInstallationSite } from '../../../interfaces/InstallationSite';

import { CommonExamineeSelectProps } from '.';
import InputGroup from '../../form/InputGroup';
import { InstallationSitesService } from '../../../services';
import TypeaheadInput from '../../form/TypeaheadInput';
import { useAddError } from '../../../context/error';
import { useFormikContext } from 'formik';
import { installationSitesStore } from '../../../storage';

const InstallationSiteSelect: FunctionComponent<CommonExamineeSelectProps> = ({ variant, editing, name }) => {
  const addError = useAddError();

  const { errors, isSubmitting } = useFormikContext<{
    installationSite: number;
  }>();

  const [loading, setLoading] = useState(true);

  const [installationSites, setInstallationSites] = useState<InstallationSite[]>([]);

  const loadInstallationSites = useCallback(async () => {
    const { items } = await InstallationSitesService(addError, installationSitesStore).list(3000);

    setInstallationSites(items);
  }, [addError]);

  useEffect(() => {
    const loadData = async () => {
      try {
        loadInstallationSites();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, loadInstallationSites]);

  return (
    <InputGroup
      valid={!errors.installationSite}
      error={errors.installationSite ? '' : undefined}
      title="Aufstellort"
      name={name ?? 'installationSite'}
      loading={loading}
      variant={variant}
    >
      <TypeaheadInput
        name={name ?? 'installationSite'}
        options={installationSites.map((is) => ({
          label: is.name,
          value: is.id,
        }))}
        disabled={!editing || isSubmitting}
        emptyLabel="Aufstellort auswählen"
        newSelectionPrefix="neuer Aufstellort: "
        onNewOption={(name: string) => {
          const values: NewInstallationSite = {
            name,
          };
          return InstallationSitesService(addError, installationSitesStore)
            .create(values)
            .then(async (id) => {
              await loadInstallationSites();
              return id;
            });
        }}
      />
    </InputGroup>
  );
};

export default InstallationSiteSelect;
