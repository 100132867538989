import { FunctionComponent, useCallback } from 'react';
import { TableColProps } from '../../components/table';

import { User } from '../../interfaces/User';
import { UserService } from '../../services';
import { useAddError } from '../../context/error';
import { useNavigate } from 'react-router-dom';
import TableWithSearch, { OnDataParams, OnSearchParams } from '../../views/TableWithSearchView';
import RoleView from '../../components/RoleView';
import { useAuth } from '../../context/auth';
import { get } from 'lodash';

const columns: TableColProps<User>[] = [
  { title: 'Name', values: 'name' },
  { title: 'Unternehmen', values: 'company', key: 'company' },
  { title: 'E-Mail', values: 'email' },
  { title: 'Rolle', values: (item: User) => <RoleView role={item.role} /> },
  { title: 'Prüfer', values: (item: User) => <span>{item.tester ? 'Ja' : 'Nein'}</span> },
  {
    title: 'Status',
    values: (item: User) => {
      return <span>{item.deleted_at ? 'Gesperrt' : 'Aktiv'}</span>;
    },
    key: 'status',
    showActive: true,
    filterable: (addError, limit, page, query) =>
      Promise.resolve([
        {
          value: undefined,
          title: 'Aktiv',
        },
        {
          value: 'deleted',
          title: 'Gesperrt',
        },
      ]),
  },
];

const List: FunctionComponent = () => {
  const navigate = useNavigate();
  const addError = useAddError();
  const auth = useAuth();

  const onNew = useCallback(() => {
    navigate('new');
  }, [navigate]);

  if (!auth || !auth.user) {
    return null;
  }

  return (
    <TableWithSearch<User>
      title="Benutzer"
      to="/settings"
      defaultLimit={get(auth, 'user.itemsPerPage', 25)}
      columns={columns}
      onData={function ({ filter, limit, page }: OnDataParams<User>) {
        return UserService(addError).list<User>(limit, page, filter);
      }}
      onSearch={function ({ limit, page, term }: OnSearchParams) {
        return UserService(addError).search<User>(term, limit, page);
      }}
      onNew={onNew}
    />
  );
};

export default List;
