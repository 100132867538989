import { NewType, UpdateType } from './removeDates';
import {
  EmptyExamineeAdditionalInformationField,
  ExamineeAdditionalInformationField,
} from './ExamineeAdditionalInformationField';

export interface ExamineeAdditionalInformationFieldOption {
  id: number;
  option: string;
  field: ExamineeAdditionalInformationField;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export type NewExamineeAdditionalInformationFieldOption = NewType<
  ExamineeAdditionalInformationFieldOption,
  'option'
> & { field: number };

export type UpdateExamineeAdditionalInformationFieldOption = UpdateType<
  ExamineeAdditionalInformationFieldOption,
  'option'
>;

export const EmptyNewExamineeAdditionalInformationFieldOption: NewExamineeAdditionalInformationFieldOption = {
  option: '',
  field: 0,
};

export const EmptyExamineeAdditionalInformationFieldOption: ExamineeAdditionalInformationFieldOption = {
  id: 0,
  option: '',
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  field: EmptyExamineeAdditionalInformationField,
};
