import { EmptyNewApiKey, NewApiKey } from '../../interfaces/Apikey';
import { ErrorLevel, useAddError, useClearError } from '../../context/error';
import { Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useState } from 'react';

import BareInput from '../../components/form/BareInput';
import InputGroup from '../../components/form/InputGroup';
import InputSection from '../../components/form/InputSection';
import { Row } from 'react-bootstrap';
import Title from '../../components/title';
import { ApiKeyService } from '../../services';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import validateMinLength from '../../helpers/validate/validateMinLength';
import { alert } from '../../components/dialogs/Alert';

const New: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const navigate = useNavigate();

  const [item] = useState<NewApiKey>(EmptyNewApiKey);

  const onSubmit = async (values: NewApiKey, { setSubmitting }: FormikHelpers<NewApiKey>) => {
    setSubmitting(true);
    clearError();
    try {
      const token = await ApiKeyService(addError).create(values);
      if (token !== undefined && typeof token === 'string') {
        await alert('Token', token);
        navigate(`/settings/api-keys`);
      } else {
        addError({
          level: ErrorLevel.danger,
          message: 'Es ist ein Fehler aufgetreten',
        });
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isValidating, isValid, errors, isSubmitting, touched }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Title
            onCancel={() => {
              navigate('..');
            }}
            editing={true}
            editingValid={!isValidating && isValid && Object.keys(touched).length > 0}
            isSubmitting={isSubmitting}
            title={'Neuen API Keys erzeugen'}
          />
          <Row>
            <InputSection title="Stammdaten" xs={12} xl={6}>
              <InputGroup error={errors.name} valid={!errors.name} title="Name" name="name">
                <BareInput
                  disabled={isSubmitting}
                  type="text"
                  name="name"
                  validate={(v: any) => validateMinLength(3, v)}
                />
              </InputGroup>
            </InputSection>
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default New;
