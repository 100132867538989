import { FunctionComponent, useEffect, useState } from 'react';

import { Location } from '../../../interfaces/Location';
import { LocationService } from '../../../services';
import { Typeahead } from 'react-bootstrap-typeahead';
import { TypeaheadPropsAndState } from 'react-bootstrap-typeahead/types/types';
import styled from 'styled-components';
import { useAddError } from '../../../context/error';
import { useMain } from '../../../context/main';
import { useNavigate } from 'react-router-dom';
import { BehaviorSubject, of, merge, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { TypeaheadMenuProps } from 'react-bootstrap-typeahead/types/components/TypeaheadMenu';

const CustomInput = styled.input`
  display: block;
  width: 100%;
  // max-width: 22rem;
  height: 3.125rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const TH = styled(Typeahead)`
   {
    width: 100%;
    // padding: 1rem;
    #location-selector,
    .location-selector {
      width: 100%;
      transform: translate(0px, 50px);
    }
  }
`;

const P = styled.p<{ small: boolean }>`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  color: ${(props) => (props.small ? '#b3b3b3' : '#000')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
`;

const P2 = styled.p`
  height: 3.125rem;
  padding: 0.375rem 0.75rem 0.375rem 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ced4da;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

function filterBy(option: any, state: TypeaheadPropsAndState): boolean {
  return true;
}

const CurrentItemSpan = styled.span`
  display: block;
  width: 100%;
  // max-width: 22rem;
  height: 3.125rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const CurrentItem: FunctionComponent<{
  options: Record<string, any>;
  onClick?: React.MouseEventHandler<HTMLSpanElement> | undefined;
  onClear?: React.MouseEventHandler<HTMLSpanElement> | undefined;
}> = ({ options, onClick, onClear }) => {
  const option = options && options.length > 0 ? options[0] : undefined;
  if (option && option.value !== 'all') {
    return (
      <div>
        <CurrentItemSpan onClick={onClick}>
          <P small={false}>{option.label1}</P>
          <P small={true}>{option.label2}</P>
        </CurrentItemSpan>
        {/* <button onClick={(r) => onClear && onClear(r)}>X</button> */}
      </div>
    );
  } else {
    return (
      <CurrentItemSpan onClick={onClick}>
        <P2>Alle Standort ausgewählt</P2>
      </CurrentItemSpan>
    );
  }
};

const SpacerTop = styled.span`
  padding: 0.75rem 0 0.5rem 0;
  margin-top: 0.25rem;
  border-top: 1px solid #ced4da;
  text-align: left;
  font-size: 0.75rem;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
  width: 100%;
  display: block;
`;

const Item: FunctionComponent<
  {
    option: Record<string, any>;
  } & TypeaheadMenuProps
> = ({ option, ...menuProps }) => {
  if (option.value === 'all') {
    return <SpacerTop>Alle Standorte</SpacerTop>;
  } else {
    return (
      <span>
        <P small={false}>{option.label1}</P>
        <P small={true}>{option.label2}</P>
      </span>
    );
  }
};

export const itemToOption = (location: Location) => {
  return {
    label1: `[${location.tag}] ${location.name}`,
    label2: `${location.address.street}, ${location.address.postalCode} ${location.address.city}`,
    value: location.id,
    locked: location.locked,
  };
};

const LocationSelect: FunctionComponent = () => {
  const addError = useAddError();
  const navigate = useNavigate();

  const [options, setOptions] = useState<Record<string, any>[]>([]);

  const [query, setQuery] = useState<string>('');

  const { selectLocation, selectedLocation } = useMain();

  const [inputEleRef, setInputEleRef] = useState<HTMLInputElement | null>();

  const [state, setState] = useState({
    locations: [],
    loading: false,
    errorMessage: '',
    noResults: false,
  });

  useEffect(() => {
    if (state.locations && state.locations.length > 0) {
      setOptions([...(state.locations ?? []).slice(0, 5).map((location) => itemToOption(location))]);
    } else {
      setOptions([]);
    }
  }, [state.locations]);

  const [subject, setSubject] = useState<BehaviorSubject<string> | null>(null);

  useEffect(() => {
    if (subject === null) {
      const sub = new BehaviorSubject('');
      setSubject(sub);
    } else {
      // console.log(subject.observed, subject.closed);
      let observable: Subscription;
      if (subject.closed) {
        const sub = new BehaviorSubject('');
        setSubject(sub);
      } else {
        observable = subject
          .pipe(
            map((s) => s.trim()),
            distinctUntilChanged(),
            filter((s) => {
              return s.length >= 2;
            }),
            debounceTime(250),
            switchMap((term) =>
              merge(
                of({ loading: true, errorMessage: '', noResults: false }),
                LocationService(addError)
                  .search(term, 5, 0)
                  .then(({ items }) => {
                    return { locations: items, loading: false, noResults: items.length === 0 };
                  }),
              ),
            ),
          )
          .subscribe((newState) => {
            setState((s) => Object.assign({}, s, newState));
          });
      }
      return () => {
        observable?.unsubscribe();
        subject.unsubscribe();
        setSubject(null);
      };
    }
  }, [addError, subject]);

  const _handleInputChange = (query: string) => {
    setQuery(query);
    // search(query);
    if (subject) {
      return subject.next(query);
    }
  };

  return (
    <TH
      id="location-selector"
      labelKey={(option: any) => option.value.toString()}
      onInputChange={_handleInputChange}
      isLoading={state.loading}
      paginate={false}
      options={[...options, { label1: 'Alle Standorte', value: 'all', label2: '', locked: false }]}
      // maxResults={5}
      multiple={false}
      placeholder="Standort suchen"
      emptyLabel={
        inputEleRef && inputEleRef.value && inputEleRef.value.trim() !== ''
          ? 'Keine Standorte gefunden'
          : 'Nach Name, Ort, Objektnummer suchen...'
      }
      filterBy={filterBy}
      selected={selectedLocation}
      renderMenuItemChildren={(_option: Record<string, any> | string, menuProps: TypeaheadMenuProps, idx: number) => {
        const option = _option as Record<string, any>;
        return <Item option={option} {...menuProps} />;
      }}
      renderInput={({ inputRef, referenceElementRef, value, ...inputProps }, { selected, toggleMenu, isMenuShown }) => {
        return isMenuShown ? (
          <CustomInput
            value={query}
            autoFocus={true}
            {...inputProps}
            ref={(input) => {
              setInputEleRef(input);
              inputRef(input);
              referenceElementRef(input);
            }}
          />
        ) : (
          <CurrentItem
            options={selected}
            onClear={() => {
              toggleMenu();
              selectLocation(undefined);
            }}
            onClick={() => {
              toggleMenu();
            }}
          />
        );
      }}
      allowNew={false}
      onChange={async (_selected: any[]) => {
        if (_selected && _selected.length > 0) {
          selectLocation(_selected);
          navigate('/');
        }
      }}
    />
  );
};

export default LocationSelect;
