import React, { memo, useEffect, useRef, useState } from 'react';
import { Button as BCButton, Col, Row } from 'react-bootstrap';
import { Location, useLocation, useNavigate } from 'react-router-dom';

import { isEqual } from 'lodash';
import qs from 'query-string';
import styled from 'styled-components';
import HideOffline from '../NetworkStatus/HideOffline';
import SubmitButton from '../SubmitButton';
import { ReactComponent as ArrowIcon } from './arrow.svg';
import { ReactComponent as CheckIcon } from './check.svg';
import { ReactComponent as CloseIcon } from './close.svg';
import { ReactComponent as PencilIcon } from './edit.svg';
import { ReactComponent as ImpersonateIcon } from './impersonate.svg';
import { ReactComponent as LockIcon } from './lock.svg';
import { ReactComponent as NewIcon } from './plus.svg';
import { ReactComponent as PrintIcon } from './printer.svg';
import { ReactComponent as SearchIcon } from './search.svg';
import { ReactComponent as DeleteIcon } from './trash.svg';
import { ReactComponent as UnlockIcon } from './unlock.svg';
import { ReactComponent as UploadIcon } from './upload.svg';
import { ReactComponent as DownloadIcon } from './xlsx.svg';

import { BehaviorSubject, merge, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import Tabs from './tabs';

const isPopup = (location: Location) => {
  const queryParams = qs.parse(location.search, {});
  return queryParams.p === '1' || queryParams.p === 'true';
};

export interface FilterItem {
  key: string;
  title: string;
  value: boolean;
}

export interface SearchState<T> {
  items: T[];
  loading: boolean;
  errorMessage: string | undefined;
  noResults: boolean;
  active: boolean;
}

export type TitleHeader = string | React.ReactNode;
export type TitleTo = string | undefined;

export interface TitleProps<T> {
  tab?: number;
  tabs?: string[];
  onTab?: (tab: number) => void;
  to?: TitleTo;
  saveAndNewLink?: string;
  title: TitleHeader;
  loading?: boolean;
  editing?: boolean;
  editingValid?: boolean;
  onPrint?: () => void;
  onXLSX?: () => void;
  onEdit?: () => void;
  onCancel?: () => void;
  onSave?: () => void;
  onNew?: () => void;
  onDelete?: () => void;
  onLock?: () => void;
  onUnlock?: () => void;
  onSync?: () => void;
  onImpersonate?: () => void;
  isSubmitting?: boolean;
  isSyncing?: boolean;
  children?: React.ReactNode;
  search?: {
    onSearch: (term: string) => PromiseLike<{ items: T[]; count: number }>;
    onChanged: (state: SearchState<T>) => void;
  };
  // Filter
  filter?: FilterItem[];
  onFilterChanged?: (value: FilterItem[]) => void;
  //
  allowOffline?: 'save'[];
}

const Wrapper = styled.div`
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 0px;
  min-height: 51px;
`;

const Search = styled.div`
  color: #a1a1a1;
  float: left;
  opacity: 1;

  padding: 0.8rem 1rem 1rem 1rem;
  margin-top: 1rem;
  background: #efefef;
  width: 100%;
  display: flex;
  align-items: center;

  input {
    flex: 1;
    margin: 0 0.5rem;
    border: 0 !important;
  }

  @media (min-width: 768px) {
    margin-left: 2rem;
    margin-top: 0;
    padding: 4px;
    background: transparent;
    display: inline-block;
  }

  svg {
    user-select: none;
    cursor: pointer;
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 0 6px 4px 0;
    &,
    path,
    g {
      fill: #a1a1a1;
      color: #a1a1a1;
    }
  }
`;
const SearchInput = styled.input`
  background: transparent;
  border: 0;
  outline: none;
  color: #000;
  height: 26px;
  transform: translateY(2px);
  ::placeholder {
    color: #a1a1a1;
  }
  &:focus {
    border-bottom 1px solid #a1a1a1;
  }
`;

const BackButton = styled(BCButton)`
  float:left;
  color: #a1a1a1;
  opacity: 1;
  transform: translate(-1.25rem,0px);
  svg {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 0 6px 4px 0;
    &,
    path,
    g {
      fill: #a1a1a1;
      color:  #a1a1a1;
    }
  }
  span {
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 1px;
  }
  &:disabled,
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: #a1a1a1 !important;
  }
  &:hover {
    color: var(--bs-primary);
    opacity: 0.8;
    &,
    path,
    g {
      fill: var(--bs-primary)
      color:var(--bs-primary);
    }
  }
`;

const MRow = styled(Row)`
  margin-bottom: 11px;
  justify-content: space-between;
  align-items: center;
`;

const H = styled.h1`
  float: left;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 25px;
  font-weight: 700;
  padding: 6px 0;
  margin: 0;
`;

const ColFloatRight = styled(Col)`
  float: right;
  min-height: 39px;
  @media (max-width: 1200px) and (min-width: 620px) {
    display: flex;
  }
`;

function Title<T>({
  onTab,
  onEdit,
  onNew,
  onDelete,
  onLock,
  onUnlock,
  onCancel,
  onPrint,
  onXLSX,
  onSync,
  onImpersonate,
  loading,
  editing,
  editingValid,
  isSubmitting,
  isSyncing,
  children,
  title,
  to,
  search,
  filter,
  onFilterChanged,
  tabs,
  tab,
  allowOffline,
  saveAndNewLink,
}: TitleProps<T>) {
  const location = useLocation();
  const navigate = useNavigate();

  const [popup] = useState(isPopup(location));

  /**
   * Search
   */
  const searchEnabled = search !== undefined;
  const [query, setQuery] = useState<string>('');
  const [searchSubject, setSearchSubject] = useState<BehaviorSubject<string> | null>(null);
  const [searchState, setSearchState] = useState<SearchState<T>>({
    items: [],
    loading: false,
    errorMessage: undefined,
    noResults: false,
    active: false,
  });

  /**
   * Initialize search subject
   */
  useEffect(() => {
    if (!search) return;
    if (searchSubject === null) {
      const sub = new BehaviorSubject('');
      setSearchSubject(sub);
    } else {
      const observable = searchSubject
        .pipe(
          map((s) => s.toLowerCase()),
          map((s) => {
            console.log(s);
            return s;
          }),
          map((s) => s.trim()),
          distinctUntilChanged(),
          debounceTime(250),
          switchMap((term) => {
            if (!term || term.length === 0) {
              return of({ items: [], loading: false, errorMessage: undefined, noResults: false, active: false });
            }

            return merge(
              of({ items: [], loading: true, errorMessage: undefined, noResults: false, active: true }),
              search!.onSearch(term).then(({ items }) => ({
                items,
                loading: false,
                errorMessage: undefined,
                noResults: true,
                active: true,
              })),
            );
          }),
          catchError((e: any) =>
            of({
              items: [],
              loading: false,
              errorMessage: e.message,
              noResults: true,
              active: true,
            }),
          ),
        )
        .subscribe((newState) => {
          console.log(newState);

          setSearchState((s) => Object.assign({}, s, newState));
        });

      return () => {
        if (observable) observable.unsubscribe();
        if (searchSubject) searchSubject.unsubscribe();
        setSearchSubject(null);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchSubject]);

  /**
   * Update search state
   */
  useEffect(() => {
    if (search) {
      search.onChanged(searchState);
    }
  }, [search, searchState]);

  /**
   *
   */
  const close = () => {
    window.close();
  };

  const renderEditButton = () => {
    return (
      <SubmitButton
        key="edit"
        breakat={1200}
        type="button"
        float="right"
        hovercolored="true"
        onClick={() => onEdit && onEdit()}
      >
        <PencilIcon />
        <span>Bearbeiten</span>
      </SubmitButton>
    );
  };

  const renderSaveButton = (successredirect?: string) => {
    return (
      <SubmitButton
        key={successredirect ? 'saveAndNew' : 'save'}
        breakat={1200}
        colored="true"
        type="submit"
        float="right"
        disabled={isSubmitting || editingValid === false}
        successredirect={successredirect}
      >
        <CheckIcon />
        {!isSubmitting && !successredirect && <span>Speichern</span>}
        {!isSubmitting && successredirect && <span>Speichern & Neu</span>}
        {isSubmitting && <span>Speichert...</span>}
      </SubmitButton>
    );
  };

  const renderCancelButton = () => {
    return (
      <SubmitButton
        key="cancel"
        breakat={1200}
        type="button"
        float="right"
        colored="danger"
        disabled={isSubmitting}
        onClick={() => onCancel && onCancel()}
      >
        <CloseIcon />
        <span>Abbruch</span>
      </SubmitButton>
    );
  };

  const renderLockButton = () => {
    return (
      <SubmitButton
        key="lock"
        breakat={1200}
        type="button"
        float="right"
        hovercolored="danger"
        disabled={isSubmitting}
        onClick={() => onLock && onLock()}
      >
        <LockIcon />
        <span>Sperren</span>
      </SubmitButton>
    );
  };

  const renderUnlockButton = () => {
    return (
      <SubmitButton
        key="unlock"
        breakat={1200}
        type="button"
        float="right"
        hovercolored="true"
        disabled={isSubmitting}
        onClick={() => onUnlock && onUnlock()}
      >
        <UnlockIcon />
        <span>Aktivieren</span>
      </SubmitButton>
    );
  };

  const renderDeleteButton = () => {
    return (
      <SubmitButton
        key="delete"
        breakat={1200}
        type="button"
        float="right"
        hovercolored="danger"
        disabled={isSubmitting}
        onClick={() => onDelete && onDelete()}
      >
        <DeleteIcon />
        <span>Löschen</span>
      </SubmitButton>
    );
  };

  const renderImpersionate = () => {
    return (
      <SubmitButton
        key="impersonate"
        breakat={1200}
        type="button"
        float="right"
        hovercolored="true"
        disabled={isSubmitting}
        onClick={() => onImpersonate && onImpersonate()}
      >
        <ImpersonateIcon />
        <span>Impersonate</span>
      </SubmitButton>
    );
  };

  const renderNewButton = () => {
    return (
      <SubmitButton
        key="new"
        breakat={1200}
        type="button"
        float="right"
        hovercolored="true"
        disabled={isSubmitting}
        onClick={() => onNew && onNew()}
      >
        <NewIcon />
        <span>Hinzufügen</span>
      </SubmitButton>
    );
  };

  const renderPrintButton = () => {
    return (
      <SubmitButton
        key={'print'}
        breakat={1200}
        type="button"
        float="right"
        hovercolored="true"
        disabled={isSubmitting}
        onClick={() => onPrint && onPrint()}
      >
        <PrintIcon />
        <span>PDF erstellen</span>
      </SubmitButton>
    );
  };

  const renderXLSXButton = () => {
    return (
      <SubmitButton
        key="xlsx"
        breakat={1200}
        type="button"
        float="right"
        hovercolored="true"
        disabled={isSubmitting}
        onClick={() => onXLSX && onXLSX()}
      >
        <DownloadIcon />
        <span>Exportieren</span>
      </SubmitButton>
    );
  };

  const renderSyncButton = () => {
    return (
      <SubmitButton
        key="sync"
        breakat={1200}
        type="button"
        float="right"
        hovercolored="true"
        disabled={isSubmitting}
        onClick={() => onSync && onSync()}
      >
        <UploadIcon />
        <span>Hochladen</span>
      </SubmitButton>
    );
  };

  const isSearching = searchSubject && !searchSubject.closed && searchSubject.value.length > 0;
  const ref = useRef<HTMLInputElement>(null);
  const renderSearch = () => {
    return (
      <HideOffline>
        <Col xs={12} md={'auto'}>
          <Search>
            <SearchIcon onClick={() => ref && ref.current && ref.current.focus()} />
            <SearchInput
              ref={ref}
              placeholder="Suche"
              value={query}
              onChange={(e) => {
                setQuery(e.currentTarget.value);
                if (searchSubject && !searchSubject.closed) {
                  return searchSubject.next(e.currentTarget.value);
                }
              }}
            />
            {isSearching ? (
              <CloseIcon
                onClick={() => {
                  setQuery('');
                  if (searchSubject && !searchSubject.closed) {
                    searchSubject.next('');
                  }
                }}
              />
            ) : (
              false
            )}
          </Search>
        </Col>
      </HideOffline>
    );
  };

  const buttonsOnline = [
    !loading && editing && !isSearching && onCancel ? renderSaveButton() : null,
    !loading && editing && !isSearching && onCancel && saveAndNewLink !== undefined
      ? renderSaveButton(saveAndNewLink)
      : null,
    !loading && editing && !isSearching && onCancel ? renderCancelButton() : null,
  ].filter((f) => !!f);

  const buttonsOffline = [
    !loading && !editing && !isSearching && onEdit ? renderEditButton() : null,
    !loading && !editing && !isSearching && onNew ? renderNewButton() : null,
    !loading && !editing && !isSearching && onDelete ? renderDeleteButton() : null,
    !loading && !editing && !isSearching && onLock ? renderLockButton() : null,
    !loading && !editing && !isSearching && onUnlock ? renderUnlockButton() : null,
    !loading && !editing && !isSearching && onPrint ? renderPrintButton() : null,
    !loading && !editing && !isSearching && onXLSX ? renderXLSXButton() : null,
    !loading && !editing && !isSearching && onImpersonate ? renderImpersionate() : null,
  ].filter((f) => !!f);

  const buttons = [!loading && !editing && !isSearching && onSync ? renderSyncButton() : null].filter((f) => !!f);

  const hasAction = buttonsOnline.length > 0 || buttonsOffline.length > 0 || buttons.length > 0;

  const hasChildren = children !== undefined && children !== null && children !== '' && children !== false;
  // const hasAction =
  //   [onCancel, onEdit, onNew, onDelete, onPrint, onLock, onUnlock, onXLSX, onImpersonate, onSync].filter((f) => !!f)
  //     .length > 0;
  const colW = 12 / ([hasChildren, hasAction].filter((f) => f).length + 1);

  return (
    <Wrapper>
      {to && (
        <MRow>
          <Col>
            <BackButton
              variant="link"
              disabled={editing}
              onClick={() => {
                if (popup) {
                  close();
                } else {
                  navigate(to);
                }
              }}
            >
              <ArrowIcon />
              <span>{popup ? 'Schließen' : 'Zurück'}</span>
            </BackButton>
          </Col>
        </MRow>
      )}
      <MRow>
        <Col xs={12} xl={colW}>
          <Row>
            <Col xs={12}>
              <H>{title}</H>
            </Col>
            {!editing && searchEnabled ? renderSearch() : null}
          </Row>
        </Col>
        {hasChildren && (
          <Col xs={12} xl={colW}>
            {!editing ? <>{children}</> : null}
          </Col>
        )}

        {hasAction ? (
          <ColFloatRight xs={12} xl={colW}>
            <HideOffline allowOffline={allowOffline && allowOffline.includes('save')}>{buttonsOnline}</HideOffline>
            <HideOffline>{buttonsOffline}</HideOffline>
            {buttons}
          </ColFloatRight>
        ) : null}
      </MRow>
      <Tabs tabs={tabs} tab={tab} onTab={onTab} />
    </Wrapper>
  );
}

export default memo(Title, (prev, next) => {
  return isEqual(prev, next);
}) as typeof Title;

// export const GenericTitle = memo(Title, (prev, next) => {
//   return isEqual(prev, next);
// });

// export default memo<T>(Title<T>, (prev, next) => {
//   return isEqual(prev, next);
// });
