import { FunctionComponent, useState } from 'react';
import { useNetworkStatus } from 'react-network-status';
import styled from 'styled-components';
import { FORCE_OFFLINE, NETWORK_CHECK_CONFIG } from '../../config';

const Wrapper = styled.div`
  position: fixed;
  top: 66px;
  left: 0;
  right: 0;
  color: #fff;
  background-color: #009842;
  font-size: 0.75rem;
  padding: 0.25rem;
  text-align: center;
`;

const NetworkStatus: FunctionComponent = () => {
  const [networkStatus, setNetworkStatus] = useState(true);

  useNetworkStatus((networkStatusUpdate) => {
    setNetworkStatus(networkStatusUpdate);
  }, NETWORK_CHECK_CONFIG);

  if (FORCE_OFFLINE === true) {
    return <Wrapper>Offline-Modus</Wrapper>;
  }

  if (networkStatus) {
    return null;
  }

  return <Wrapper>Offline-Modus</Wrapper>;
};

export default NetworkStatus;
