import './import.scss';

import MainLayout from '../../layouts/MainLayout';
import React from 'react';
import RequireAuth from '../../guards/RequireAuth';
import { Route } from 'react-router';

const Rel = React.lazy(() => import('./import-rel'));

const Routes = (
  <Route
    path="/import"
    element={
      <RequireAuth>
        <MainLayout />
      </RequireAuth>
    }
  >
    <Route
      path="/import/rel"
      element={
        <React.Suspense fallback={<>...</>}>
          <Rel />
        </React.Suspense>
      }
    />
  </Route>
);

export default Routes;
