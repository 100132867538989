import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  max-width: 80px;
  height: 1rem;
  background: #00e55f;
  background: -moz-linear-gradient(left, #00e55f 0%, #009842 51%, #ce0000 100%);
  background: -webkit-linear-gradient(left, #00e55f 0%, #009842 51%, #ce0000 100%);
  background: linear-gradient(to right, #00e55f 0%, #009842 51%, #ce0000 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e55f', endColorstr='#CE0000',GradientType=1 );
  position: relative;
`;

const Line = styled.div<{ width: number }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: ${(props) => props.width}%;
  border-right: 2px solid #000;
`;

interface TargetBarProps {
  target: number;
  range: number;
  value: number;
}

const TargetBar: FunctionComponent<TargetBarProps> = ({ value, range, target }) => {
  const totalRange = [target - range, target + range];
  // Calcualte the position in totalRange as a percentage with 0 being the left side and 100 being the right side
  const deviation = Math.max(0, Math.min(100, ((value - totalRange[0]) / (totalRange[1] - totalRange[0])) * 100));
  console.log(target, range, totalRange, value, deviation);
  return (
    <Wrapper>
      <Line width={deviation} />
    </Wrapper>
  );
};

export default TargetBar;
