import './formik-debug.scss';
import 'rc-drawer/assets/index.css';

import { Col, Container, Row } from 'react-bootstrap';

import Drawer from 'rc-drawer';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { Disclosure } from '@headlessui/react';

const A = styled.p`
  display: block;
`;

const Toggle = styled(Disclosure.Button)`
  position: fixed;
  left: 0;
  bottom: 0;
`;

const Mapped: FunctionComponent<{
  name: string;
  item: Record<string, any>;
}> = ({ name, item }) => {
  return (
    <>
      <Row>
        <Col>
          <h5>{name}</h5>
          {item &&
            Object.keys(item).map((key) => {
              return (
                <div key={key}>
                  <h6>{key}</h6>
                  <A>{JSON.stringify(item[key])}</A>
                </div>
              );
            })}
        </Col>
      </Row>
      <hr />
    </>
  );
};

const FormikDebug: FunctionComponent = () => {
  const formik = useFormikContext<any>();
  const { errors, touched, values, isValidating, isValid } = formik;

  if (process.env.NODE_ENV !== 'development') {
    return null;
  }

  return (
    <Disclosure>
      <Toggle>Open Form Debug</Toggle>
      <Disclosure.Panel>
        <Drawer placement="right" width="340px" open={true} mask={false}>
          <Container>
            <Mapped name="Values" item={values} />
            <Mapped name="Touched" item={touched} />
            <Mapped name="Errors" item={errors} />
            <Row>
              <Col>
                <h5>isValidating</h5>
                <A>{isValidating ? 'true' : 'false'}</A>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <h5>isValid</h5>
                <A>{isValid ? 'true' : 'false'}</A>
              </Col>
            </Row>
          </Container>
        </Drawer>
      </Disclosure.Panel>
    </Disclosure>
  );
};

export default FormikDebug;
