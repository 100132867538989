import { Formik, FormikHelpers } from 'formik';
import { get, reduce } from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ErrorLevel, useAddError, useClearError } from '../../context/error';
import { EmptyTester, Tester, UpdateTester } from '../../interfaces/Tester';

import qs from 'query-string';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import SelectSafeplanTechnicians from '../../components/SelectSafeplanTechnicians';
import { ButtonWithIcon } from '../../components/buttons/ButtonWithIcon';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import TestingDeviceList from '../../components/common/TestingDeviceList';
import FormikDebug from '../../components/dev/formik-debug';
import { alert } from '../../components/dialogs/Alert';
import BareInput from '../../components/form/BareInput';
import InputGroup from '../../components/form/InputGroup';
import InputSection from '../../components/form/InputSection';
import ToggleInput from '../../components/form/ToggleInput';
import Title from '../../components/title';
import { useAuth } from '../../context/auth';
import validateInitials from '../../helpers/validate/validateInitials';
import { validateSafePlanID } from '../../helpers/validate/validateSafePlanID';
import { TesterService } from '../../services';
import { ReactComponent as ArrowIcon } from './arrow.svg';

const P = styled.p<{ small: boolean }>`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  color: ${(props) => (props.small ? '#b3b3b3' : '#000')};
`;

// const ButtonWithIcon = styled(Button)`
//   float: left;
//   color: #a1a1a1;
//   opacity: 1;
//   svg {
//     width: 14px;
//     height: 14px;
//     display: inline-block;
//     margin: 11px 0 11px 11px;
//     &,
//     path,
//     g {
//       fill: #a1a1a1;
//       color: #a1a1a1;
//     }
//   }
//   span {
//     display: inline-block;
//     vertical-align: bottom;
//     margin-bottom: 1px;
//   }
//   &:disabled,
//   &.disabled {
//     cursor: not-allowed;
//     opacity: 0.5;
//     color: #a1a1a1 !important;
//   }
//   &:hover {
//     color: var(--bs-primary);
//     opacity: 0.8;
//     &,
//     path,
//     g {
//       fill: var(--bs-primary);
//       color: var(--bs-primary);
//     }
//   }
// `;

const getChangedValues = (values: Tester, initialValues: Tester): Partial<UpdateTester> => {
  return reduce(
    Object.entries(values),
    (acc, [key, value]) => {
      const hasChanged = (initialValues as any)[key] !== value;
      if (hasChanged) {
        (acc as any)[key] = value;
      }
      return acc;
    },
    {},
  );
};

const shouldEditing = (location: Location) => {
  const queryParams = qs.parse(location.search, {});
  return queryParams.edit === '1' || queryParams.edit === 'true';
};

const Show: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(shouldEditing(location));

  const [item, setItem] = useState<Tester>(EmptyTester);

  const auth = useAuth();
  const [isAdmin, setIsAdmin] = useState(get(auth, 'user.role', '') === 'ADMINISTRATOR');
  useEffect(() => {
    setIsAdmin(get(auth, 'user.role', '') === 'ADMINISTRATOR');
  }, [auth]);

  useEffect(() => {
    const loadData = async () => {
      try {
        if (!params.id) {
          return;
        }
        const _item = await TesterService(addError).get(params.id);
        if (!_item || !_item.id) {
          navigate('..');
        }
        setItem(_item);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, navigate, params.id]);

  const onSubmit = async (values: Tester, { setSubmitting }: FormikHelpers<Tester>) => {
    setSubmitting(true);
    try {
      if (params.id) {
        clearError();
        const data = getChangedValues(values, item);
        await TesterService(addError).update(params.id, data);
        setEditing(false);
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onDelete = async (setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true);
    try {
      if (params.id) {
        await alert(
          'Prüfer löschen',
          'Prüfer können aus Aufbewahrungspflichten nicht gelöscht werden. Bitte deaktiveren Sie den Benutzer.',
          'Schließen',
          {
            okColor: 'primary',
          },
        );
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  const locked = item.deleted_at || item.user.deleted_at;

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isValidating, isValid, errors, resetForm, isSubmitting, setSubmitting }) => (
        <>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Title
              onEdit={locked ? undefined : () => setEditing(true)}
              onDelete={locked ? undefined : () => onDelete(setSubmitting)}
              onCancel={() => {
                resetForm({
                  values: item,
                });
                setEditing(false);
              }}
              editing={editing}
              editingValid={!isValidating && isValid}
              isSubmitting={isSubmitting}
              to="/settings/testers"
              title={`${item.user?.name ?? item.user?.email ?? ''}${locked ? ' (Gesperrt)' : ''}`}
            />
            <Row>
              <InputSection title="Stammdaten" xs={12} xl={6}>
                <InputGroup title="Prüfer" name="initials">
                  <ButtonWithIcon
                    variant="link"
                    disabled={editing}
                    onClick={() => navigate(`/settings/users/${item.user?.id}`)}
                  >
                    <span>
                      <P small={false}>{item.user?.name}</P>
                      <P small={true}>{item.user?.email}</P>
                    </span>
                    <ArrowIcon />
                  </ButtonWithIcon>
                </InputGroup>
                <InputGroup
                  error={errors.initials}
                  valid={editing ? !errors.initials : undefined}
                  title="Kürzel"
                  name="initials"
                >
                  <BareInput
                    disabled={!editing || isSubmitting}
                    type="text"
                    name="initials"
                    validate={validateInitials}
                  />
                </InputGroup>
                <InputGroup error={errors.sp_id} title="safePlan ID" name="sp_id">
                  <SelectSafeplanTechnicians name="sp_id" validate={validateSafePlanID} editing={editing} />
                </InputGroup>
              </InputSection>
              <InputSection title="Einstellungen" xs={12} xl={6}>
                <InputGroup
                  error={errors.pref_show_my_week}
                  valid={editing ? !errors.pref_show_my_week : undefined}
                  title="Meine Woche anzeigen"
                  name="pref_show_my_week"
                >
                  <ToggleInput name="pref_show_my_week" disabled={!editing || isSubmitting || !isAdmin} />
                </InputGroup>
              </InputSection>
              <InputSection title="Prüfmittel" xs={12}>
                <>{item && <TestingDeviceList tester={item} />}</>
              </InputSection>
            </Row>
          </form>
          <FormikDebug />
        </>
      )}
    </Formik>
  );
};

export default Show;
