import { AddSafeDocError, ErrorLevel } from '../../context/error';

import { get } from 'lodash';

export const UnauthorizedError = {
  level: ErrorLevel.danger,
  title: 'Zugriff nicht erlaubt',
  message: 'Sie haben auf diesen Bereich keinen Zugriff.',
  redirect: '/error',
};

const isUnauthorized = (error: Error): null | AddSafeDocError => {
  const errorName = get(error, 'response.statusText');
  if (errorName === 'Unauthorized') {
    return UnauthorizedError;
  }
  return null;
};

export default isUnauthorized;
