import { NavDropdown } from 'react-bootstrap';

import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { ClipLoader, DotLoader } from 'react-spinners';
import styled from 'styled-components';
import { Job, JobState } from '../../interfaces/Job';
import { JobsService } from '../../services';
import { ReactComponent as MenuIcon } from './stack.svg';

const JobTitleMap: Record<string, string> = {
  'import-tests': 'Prüfungen importieren',
  'export-tests-pdf': 'Prüfungen exportieren',
};

const JobStateMap = {
  [JobState.Activating]: 'Activating',
  [JobState.Completed]: 'Abgeschlossen',
  [JobState.Failed]: 'Fehlgeschlagen',
  [JobState.NotStarted]: 'Warten auf Ausführung',
  [JobState.Provisioning]: 'Vorbereiten',
  [JobState.Running]: 'Läuft',
};

const Dropdown = styled(NavDropdown)`
  color: #86cba4;
  svg {
    fill: #86cba4;
    height: 16px;
    width: 16px;
  }
  &.active,
  &:hover {
    color: #009842;
    svg {
      fill: #009842;
    }
  }
  .dropdown-toggle {
    &:after {
      display: none;
    }
  }
  .dropdown-menu {
    min-width: 232px;
    border: 0;
    padding: 0;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgb(136 152 170 / 10%), 0 15px 35px 0 rgb(49 49 93 / 10%), 0 5px 15px 0 rgb(0 0 0 / 8%);
    .dropdown-item {
      a,
      button {
        font-size: 0.875rem;
        margin: 0px;
        padding: 0px;
      }
    }
    &:after {
      content: '';
      z-index: 1;
      width: 21px;
      height: 21px;
      margin: -7px;
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSI5Ij48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGZpbGw9IiM4ODk4QUEiIGZpbGwtb3BhY2l0eT0iLjEiIGQ9Ik0xIDkuMDkyaDE5bC02LjQwMi02Ljc0Yy0xLjcxNy0xLjgwNi00LjQ4NS0xLjgtNi4xOTYgMEwxIDkuMDkzek0yMC4zNDIgOGwtNi4wMi02LjMzNmMtMi4xMDgtMi4yMi01LjUzOC0yLjIxOC03LjY0NSAwTC42NTggOGgxOS42ODR6Ii8+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTcuNDAyIDIuMzUzYzEuNzExLTEuODAxIDQuNDgtMS44MDcgNi4xOTYgMEwyMCA5LjA5M0gxbDYuNDAyLTYuNzR6Ii8+PC9nPjwvc3ZnPg==);
      background-repeat: no-repeat;
      background-position: 50%;
      position: absolute;
      top: -8px;
      right: 12px;
    }
  }
`;

const JobItem = styled(NavDropdown.Item)`
  border-bottom: 1px solid #e5e5e5;
  cursor: default;
  &:last-child {
    border-bottom: 0;
  }
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 8px 16px;
  div:first-child {
    min-width: 18px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  div:last-child {
    margin-left: 16px;
    line-height: 1.25rem;
    p:first-child {
      font-size: 0.875rem;
      font-weight: 700;
      margin: 0;
    }
    p:last-child {
      color: #6b7280;
      font-size: 0.75rem;
      margin: 0;
    }
  }
`;

const HeaderItem = styled(NavDropdown.Item)`
  background: #86cba4;
  border-bottom: 1px solid #e5e5e5;
  padding: 8px 16px;
  text-align: center;
  cursor: default;
  color: #fff;
  &:hover {
    background: #86cba4;
    color: #fff;
  }
`;

const Badge = styled.span`
  position: absolute;
  bottom: 0px;
  right: 0px;
  border-radius: 50%;
  background: #fff;
  color: #86cba4;
  font-size: 11px;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  font-weight: 700;
`;

const Icon: FunctionComponent<{
  count: number;
}> = ({ count }) => {
  return (
    <span className="relative inline-block">
      <MenuIcon />
      {count > 0 && <Badge>{count > 9 ? '9+' : count}</Badge>}
    </span>
  );
};

const NoJobsWrapper = styled.div`
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p  {
    color: #6b7280;
    font-size: 0.75rem;
    margin: 0;
    padding: 0;
  }
`;

const JobsDropDown: FunctionComponent = () => {
  const [intervalId, setIntervalId] = useState<any | null>(null);

  const [jobs, setJobs] = useState<Job[]>([]);

  // load jobs from JobsService and retry this every 30 seconds
  const loadJobs = useCallback(async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      const _jobs = await JobsService((error) => {}).list();
      setJobs(_jobs.items);
    } catch (error) {}
  }, []);

  useEffect(() => {
    loadJobs();
    intervalId && clearInterval(intervalId);
    setIntervalId(setInterval(loadJobs, 15000));
    return () => {
      // clear interval
      intervalId && clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadJobs]);

  return (
    <Dropdown title={<Icon count={jobs.length} />} id="menu" aria-labelledby="menu-button" role="menu" align="end">
      <HeaderItem>System-Jobs</HeaderItem>
      {jobs.length === 0 && (
        <NoJobsWrapper>
          <p>Keine offenen Jobs</p>
        </NoJobsWrapper>
      )}
      {jobs.map((job) => (
        <JobItem key={job.id}>
          <div>
            {job.status === JobState.Running && (
              <ClipLoader speedMultiplier={0.75} size={`18px`} color={'#009842'} loading={true} />
            )}
            {(job.status === JobState.NotStarted || job.status === JobState.Provisioning) && (
              <DotLoader speedMultiplier={0.5} size={`12px`} color={'#6b7280'} loading={true} />
            )}
          </div>
          <div>
            <p>{JobTitleMap[job.name]}</p>
            <p>
              {JobStateMap[job.status]}
              {job.progress && job.progress > 0 ? ` (${job.progress}%)` : ''}
            </p>
          </div>
        </JobItem>
      ))}
    </Dropdown>
  );
};

export default JobsDropDown;
