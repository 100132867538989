import { EmptyPreference, Preference } from '../../interfaces/Preference';
import { ErrorLevel, useAddError, useClearError } from '../../context/error';
import { PreferencesService } from '../../services';
import { Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useEffect, useState } from 'react';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';
import { get, reduce } from 'lodash';

import BareInput, { NumberInput } from '../../components/form/BareInput';
import InputGroup from '../../components/form/InputGroup';
import InputSection from '../../components/form/InputSection';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { Row } from 'react-bootstrap';
import Title from '../../components/title';
import qs from 'query-string';

const getChangedValues = (values: Preference, initialValues: Preference): Partial<Preference> => {
  return reduce(
    Object.entries(values),
    (acc, [key, value]) => {
      const hasChanged = (initialValues as any)[key] !== value;
      if (hasChanged) {
        (acc as any)[key] = value;
      }
      return acc;
    },
    {},
  );
};

const shouldEditing = (location: Location) => {
  const queryParams = qs.parse(location.search, {});
  return queryParams.edit === '1' || queryParams.edit === 'true';
};

const Show: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(shouldEditing(location));

  const [item, setItem] = useState<Preference>(EmptyPreference);

  useEffect(() => {
    const loadData = async () => {
      try {
        if (!params.id) {
          return;
        }
        const _item = await PreferencesService(addError).get(params.id);
        if (!_item || !_item.id) {
          navigate('..');
        }
        setItem(_item);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, navigate, params.id]);

  const onSubmit = async (values: Preference, { setSubmitting }: FormikHelpers<Preference>) => {
    setSubmitting(true);
    try {
      if (params.id) {
        clearError();
        const data = getChangedValues(values, item);
        await PreferencesService(addError).update(item.key, data);
        setEditing(false);
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isValidating, isValid, errors, values, resetForm, isSubmitting, setSubmitting }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Title
            onEdit={() => setEditing(true)}
            onCancel={() => {
              resetForm({
                values: item,
              });
              setEditing(false);
            }}
            editing={editing}
            editingValid={!isValidating && isValid}
            isSubmitting={isSubmitting}
            to="/settings/preferences"
            title={item.key}
          />
          <Row>
            <InputSection title="Stammdaten" xs={12} xl={6}>
              <InputGroup title="Key" name="key">
                <BareInput disabled={true} type="text" name="key" />
              </InputGroup>
              <InputGroup title="Key" name="description">
                <BareInput disabled={true} type="text" name="description" />
              </InputGroup>
              <InputGroup error={errors.value} valid={editing ? !errors.value : undefined} title="Wert" name="value">
                <NumberInput required={true} name="value" disabled={!editing || isSubmitting} float={true} />
              </InputGroup>
            </InputSection>
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default Show;
