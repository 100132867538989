import { Navigate, useLocation } from 'react-router';

import { useAuth } from '../context/auth';

export default function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();

  const location = useLocation();
  const redirect = encodeURI(location.pathname);
  const loginPath = `/auth/login?redirect=${redirect}`;

  if (!auth.token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={loginPath} />;
  }

  return children;
}
