import { useField } from 'formik';

import { FunctionComponent } from 'react';
import styled from 'styled-components';

export const StyledTextarea = styled.textarea`
  border: 0;
  background: transparent;
  font-size: 0.75rem;
  -webkit-appearance: none;
  outline-color: rgba(0, 0, 0, 0);
  height: 109px;
  min-height: 109px;
  max-height: 109px;
  width: 100%;
  resize: none;
  padding: 0.75rem;
`;

interface TextAreaInputProps {
  disabled?: boolean;
  name: string;
}

const TextAreaInput: FunctionComponent<TextAreaInputProps> = ({ name, disabled }) => {
  const [field, meta, helpers] = useField(name);
  return <StyledTextarea disabled={disabled} {...field} {...meta} {...helpers} />;
};

export default TextAreaInput;
