import React from 'react';

type useLocalStorageStateFunc<T> = [
  localStorageState: T | undefined,
  handleUpdateLocalStorageState: (x: T | undefined) => void,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useLocalStorageState = <T>(key: string, value: any): useLocalStorageStateFunc<T> => {
  const parsedLocalStorage = JSON.parse(localStorage.getItem(key) || '{}');
  const initialValue = Object.keys(parsedLocalStorage).length > 0 ? parsedLocalStorage : value;
  const [localStorageState, setLocalStorageState] = React.useState<T | undefined>(initialValue);
  const handleUpdateLocalStorageState = React.useCallback(
    (x: T | undefined) => {
      setLocalStorageState(x);
      localStorage.setItem(key, JSON.stringify(x ?? {}));
    },
    [key],
  );
  return [localStorageState, handleUpdateLocalStorageState];
};

export default useLocalStorageState;
