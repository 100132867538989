import MainLayout from '../../layouts/MainLayout';
import React from 'react';
import RequireAuth from '../../guards/RequireAuth';
import { Route } from 'react-router';
import Show from './this-week';

const Routes = (
  <Route
    path="/tours/this-week"
    element={
      <RequireAuth>
        <MainLayout />
      </RequireAuth>
    }
  >
    <Route path="/tours/this-week" element={<Show />} />
  </Route>
);

export default Routes;
