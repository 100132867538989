import { ExamineeAdditionalInformationField } from './ExamineeAdditionalInformationField';
import { NewType, UpdateType } from './removeDates';

import { TestAdditionalInformationField } from './TestAdditionalInformationField';
import { TestTask } from './TestTask';

export interface ExamineeFamily {
  id: number;
  name: string;
  initials?: string;
  testTasks: TestTask[];
  prio: number;
  types: [];
  examineeAdditionalInformation: ExamineeAdditionalInformationField[];
  testAdditionalInformation: TestAdditionalInformationField[];
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export type NewExamineeFamily = NewType<ExamineeFamily, 'name'>;

export type UpdateExamineeFamily = UpdateType<
  ExamineeFamily,
  'name' | 'prio',
  {
    testTasks: number[];
  }
>;

export const EmptyNewExamineeFamily: NewExamineeFamily = {
  name: '',
};

export const EmptyExamineeFamily: ExamineeFamily = {
  name: '',
  id: 0,
  testTasks: [],
  types: [],
  prio: 0,
  examineeAdditionalInformation: [],
  testAdditionalInformation: [],
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
};
