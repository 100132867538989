import { EmptyNewExamineeType, NewExamineeType } from '../../interfaces/ExamineeType';
import { ErrorLevel, useAddError, useClearError } from '../../context/error';
import { ExamineeFamiliesService, ExamineeTypesService } from '../../services';
import { Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useEffect, useState } from 'react';

import BareInput from '../../components/form/BareInput';
import { ExamineeFamily } from '../../interfaces/ExamineeFamily';
import InputGroup from '../../components/form/InputGroup';
import InputSection from '../../components/form/InputSection';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { Row } from 'react-bootstrap';
import SelectInput from '../../components/form/SelectInput';
import Title from '../../components/title';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import validateMinLength from '../../helpers/validate/validateMinLength';

const New: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const navigate = useNavigate();

  const [item] = useState<NewExamineeType>(EmptyNewExamineeType);

  const [loading, setLoading] = useState(true);
  const [families, setFamilies] = useState<ExamineeFamily[]>([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const { items } = await ExamineeFamiliesService(addError).list();
        setFamilies(items);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError]);

  const onSubmit = async (values: NewExamineeType, { setSubmitting }: FormikHelpers<NewExamineeType>) => {
    setSubmitting(true);
    clearError();
    try {
      const id = await ExamineeTypesService(addError).create(values);
      if (id && id > 0) {
        navigate(`/settings/examinee-types/${id}`);
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isValidating, isValid, errors, values, isSubmitting, touched }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Title
            onCancel={() => {
              navigate('..');
            }}
            editing={true}
            editingValid={!isValidating && isValid && Object.keys(touched).length === Object.keys(values).length}
            isSubmitting={isSubmitting}
            title={'Neue Prüflingsart'}
          />
          <Row>
            <InputSection title="Stammdaten" xs={12} xl={6}>
              <InputGroup error={errors.name} valid={!errors.name} title="Name" name="name">
                <BareInput
                  disabled={isSubmitting}
                  type="text"
                  name="name"
                  validate={(v: any) => validateMinLength(2, v)}
                />
              </InputGroup>
              <InputGroup error={errors.family ? '' : undefined} valid={!errors.family} title="Gattung" name="family">
                <SelectInput name="family" disabled={isSubmitting}>
                  <option disabled selected value={0} label="Prüflingsgattung auswählen" />
                  {families.map((family) => (
                    <option key={family.id} value={family.id}>
                      {family.name}
                    </option>
                  ))}
                </SelectInput>
              </InputGroup>
            </InputSection>
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default New;
