import { Button, Col, Row } from 'react-bootstrap';
import { ErrorLevel, useAddError } from '../../context/error';

import { FunctionComponent, ReactNode, useState } from 'react';
import InputSection from '../../components/form/InputSection';
import SystemService from '../../services/SystemService';
import Title from '../../components/title';
import { get } from 'lodash';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';

const ClipLoaderWrapper = styled.div`
  display: inline-block;
  width: 14px;
  height: 14px;
  margin: 0px 7px 0px 0;
  position: relative;
  top: 4px;
`;

const RButton = styled(Button)`
  float: left;
  color: #a1a1a1;
  opacity: 1;
  svg {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 0 0 4px 6px;
    &,
    path,
    g {
      fill: #a1a1a1;
      color: #a1a1a1;
    }
  }
  span {
    display: inline-block;
    vertical-align: top;
  }
  &:disabled,
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: #a1a1a1 !important;
  }
  &:hover {
    color: var(--bs-primary);
    opacity: 0.8;
    &,
    path,
    g {
      fill: var(--bs-primary);
      color: var(--bs-primary);
    }
  }
`;

interface RunButtonProps {
  job: string;
  children: ReactNode;
}

const RunButton: FunctionComponent<RunButtonProps> = ({ children, job }) => {
  const addError = useAddError();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const runTasks = async () => {
    try {
      setLoading(true);
      await SystemService(addError).runTask(job);
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setLoading(false);
      setDisabled(true);
    }
  };

  return (
    <RButton disabled={disabled} onClick={() => !disabled && runTasks()}>
      {loading && (
        <ClipLoaderWrapper>
          <ClipLoader size={16} color={'#fff'} loading={true} />
        </ClipLoaderWrapper>
      )}
      <span>{children}</span>
    </RButton>
  );
};

const Tasks: FunctionComponent = () => {
  return (
    <>
      <Title title="System Tasks" />
      <div>
        <Row>
          <InputSection title="SafePlan" xs={12}>
            <Row>
              <Col xs={12} md={4} lg={3} xl={2}>
                <RunButton job="pull-locations">Standorte importieren</RunButton>
              </Col>
              <Col xs={12} md={4} lg={3} xl={2}>
                <RunButton job="pull-tours">Touren importieren</RunButton>
              </Col>
            </Row>
          </InputSection>
        </Row>
      </div>
    </>
  );
};

export default Tasks;
