import { Formik, FormikHelpers } from 'formik';
import { get, reduce } from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';
import BareInput, { NumberInput } from '../../components/form/BareInput';
import { ErrorLevel, useAddError, useClearError } from '../../context/error';
import { EmptyTestTask, TestTask } from '../../interfaces/TestTask';

import qs from 'query-string';
import styled from 'styled-components';
import { ButtonWithIcon } from '../../components/buttons/ButtonWithIcon';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { confirm } from '../../components/dialogs/Confirmation';
import InputGroup from '../../components/form/InputGroup';
import InputSection from '../../components/form/InputSection';
import TextAreaInput from '../../components/form/TextAreaInput';
import ToggleInput, { ToggleInputBooleanValueUndefined } from '../../components/form/ToggleInput';
import Title from '../../components/title';
import formatValueType from '../../helpers/format/valueType';
import validateTestTaskInitials from '../../helpers/validate/validateTestTaskInitials';
import validateTestTaskName from '../../helpers/validate/validateTestTaskName';
import { TestTaskService } from '../../services';
import { ReactComponent as ArrowIcon } from './arrow.svg';

const P = styled.p<{ small: boolean }>`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  color: ${(props) => (props.small ? '#b3b3b3' : '#000')};
`;

const PG = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: flex-start;
  align-items: stretch;
  float: left;
  height: 100%;
`;

const RowWithBorder = styled(Row)`
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
`;

const getChangedValues = (values: TestTask, initialValues: TestTask): Partial<TestTask> => {
  return reduce(
    Object.entries(values),
    (acc, [key, value]) => {
      const hasChanged = (initialValues as any)[key] !== value;
      if (hasChanged) {
        (acc as any)[key] = value;
      }
      return acc;
    },
    {},
  );
};

const shouldEditing = (location: Location) => {
  const queryParams = qs.parse(location.search, {});
  return queryParams.edit === '1' || queryParams.edit === 'true';
};

const Show: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(shouldEditing(location));

  const [item, setItem] = useState<TestTask>(EmptyTestTask);

  useEffect(() => {
    const loadData = async () => {
      try {
        if (!params.id) {
          return;
        }
        const _item = await TestTaskService(addError).get(params.id);
        if (!_item || !_item.id) {
          navigate('..');
        }
        setItem(_item);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, navigate, params.id]);

  const onSubmit = async (values: TestTask, { setSubmitting }: FormikHelpers<TestTask>) => {
    setSubmitting(true);
    try {
      if (params.id) {
        clearError();
        const data = getChangedValues(values, item);
        await TestTaskService(addError).update(params.id, data);
        setEditing(false);
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onDelete = async (setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true);
    try {
      if (params.id) {
        if (
          await confirm(
            'Prüfart löschen?',
            'Die Prüfart wird entgültig gelöscht. Sind Sie sich sicher?',
            'Löschen',
            'Abbruch',
            {
              okColor: 'danger',
            },
          )
        ) {
          await TestTaskService(addError).destroy(params.id);
          navigate('..');
        }
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isValidating, isValid, errors, resetForm, isSubmitting, setSubmitting }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Title
            onEdit={() => setEditing(true)}
            onDelete={() => onDelete(setSubmitting)}
            onCancel={() => {
              resetForm({
                values: item,
              });
              setEditing(false);
            }}
            editing={editing}
            editingValid={!isValidating && isValid}
            isSubmitting={isSubmitting}
            to="/settings/tasks"
            title={`${item.initials} - ${item.name}`}
          />
          <Row>
            <InputSection title="Stammdaten" xs={12} xl={6}>
              <InputGroup error={errors.name} valid={editing ? !errors.name : undefined} title="Name" name="name">
                <BareInput
                  disabled={!editing || isSubmitting}
                  type="text"
                  name="name"
                  validate={validateTestTaskName}
                />
              </InputGroup>
              <InputGroup
                error={errors.initials}
                valid={editing ? !errors.initials : undefined}
                title="Kürzel"
                name="initials"
              >
                <BareInput
                  disabled={!editing || isSubmitting}
                  type="text"
                  name="initials"
                  validate={validateTestTaskInitials}
                />
              </InputGroup>
              <InputGroup error={errors.norm} valid={editing ? !errors.norm : undefined} title="Norm" name="norm">
                <BareInput disabled={!editing || isSubmitting} type="text" name="norm" />
              </InputGroup>
            </InputSection>
            <InputSection xs={12} xl={6}>
              <InputGroup error={errors.prio} valid={editing ? !errors.prio : undefined} title="Priorität" name="prio">
                <NumberInput required={true} name="prio" disabled={!editing || isSubmitting} float={false} />
              </InputGroup>
              <InputGroup
                error={errors.defaultInterval}
                valid={editing ? !errors.defaultInterval : undefined}
                title="Intervall (Monate)"
                name="defaultInterval"
              >
                <NumberInput required={true} name="defaultInterval" disabled={!editing || isSubmitting} float={false} />
              </InputGroup>
              <InputGroup
                error={errors.withoutTestingDevice}
                valid={editing ? !errors.withoutTestingDevice : undefined}
                title="Ohne Prüfmittel"
                name="withoutTestingDevice"
              >
                <ToggleInput
                  validate={(value: any) => value === ToggleInputBooleanValueUndefined}
                  name="withoutTestingDevice"
                  disabled={!editing || isSubmitting}
                />
              </InputGroup>
            </InputSection>
            <InputSection title="Prüfwerte" xs={12}>
              {editing && (
                <Row>
                  <Col xs={12} lg={4}>
                    <InputGroup valid={true} title="Neu" name="new">
                      <ButtonWithIcon variant="link" onClick={() => navigate(`test-value-groups/new`)}>
                        <PG>
                          <P small={false}>neuer Unterprüfart</P>
                          <P small={true}>Fügen Sie einen neuen Unterprüftart zur Prüfart hinzu.</P>
                        </PG>
                        <ArrowIcon />
                      </ButtonWithIcon>
                    </InputGroup>
                  </Col>
                </Row>
              )}
              {(item.testValueGroups ?? []).map((tv) => (
                <RowWithBorder>
                  <Col xs={12} lg={4}>
                    {/* <Sticky enabled={true} top={80}> */}
                    <InputGroup
                      key={tv.id}
                      error={errors.name}
                      valid={editing ? !errors.name : undefined}
                      // title={tv.name}
                      name="name"
                    >
                      <ButtonWithIcon
                        variant="link"
                        disabled={false}
                        onClick={() => navigate(`test-value-groups/${tv.id}`)}
                      >
                        <PG>
                          <P small={false}>{tv.name}</P>
                          <P small={true}>
                            {tv.testValues && tv.testValues.length > 0 ? tv.testValues.length : 'keine'} Prüfwerte
                          </P>
                        </PG>
                        <ArrowIcon />
                      </ButtonWithIcon>
                    </InputGroup>
                    {/* </Sticky> */}
                  </Col>
                  <Col xs={12} lg={8}>
                    {editing && (
                      <InputGroup valid={true} title="Neu" name="new">
                        <ButtonWithIcon
                          variant="link"
                          onClick={() => navigate(`test-value-groups/${tv.id}/test-values/new`)}
                        >
                          <PG>
                            <P small={false}>neuer Prüfwert</P>
                            <P small={true}>Fügen Sie einen neuen Prüfwert zur Prüfart hinzu.</P>
                          </PG>
                          <ArrowIcon />
                        </ButtonWithIcon>
                      </InputGroup>
                    )}
                    {(tv.testValues ?? []).map((ts) => (
                      <InputGroup
                        key={ts.id}
                        error={errors.name}
                        valid={editing ? !errors.name : undefined}
                        title={formatValueType(ts.valueType)}
                        name="name"
                      >
                        <ButtonWithIcon
                          variant="link"
                          disabled={false}
                          onClick={() => navigate(`test-value-groups/${tv.id}/test-values/${ts.id}`)}
                        >
                          <PG>
                            <P small={false}>
                              {ts.name}
                              {ts.required ? '(erforderlich)' : ''}
                            </P>
                            <P small={true}>{ts.unit && ts.unit.length > 0 ? ts.unit : 'ohne Einheit'}</P>
                          </PG>
                          <ArrowIcon />
                        </ButtonWithIcon>
                      </InputGroup>
                    ))}
                  </Col>
                </RowWithBorder>
              ))}
            </InputSection>
            <InputSection title="Standardmessgruppen" xs={12} xl={6}>
              {editing && (
                <Row>
                  <Col xs={12}>
                    <InputGroup valid={true} title="Neu" name="new">
                      <ButtonWithIcon variant="link" onClick={() => navigate(`default-measure-groups/new`)}>
                        <PG>
                          <P small={false}>neuer Standardmessgruppen</P>
                          <P small={true}>Fügen Sie eine neue Standardmessgruppen zur Prüfart hinzu.</P>
                        </PG>
                        <ArrowIcon />
                      </ButtonWithIcon>
                    </InputGroup>
                  </Col>
                </Row>
              )}
              {(item.defaultMeasureGroups ?? []).map((tv) => (
                <Row>
                  <Col xs={12}>
                    <InputGroup key={tv.id} error={errors.name} valid={editing ? !errors.name : undefined} name="name">
                      <ButtonWithIcon
                        variant="link"
                        disabled={false}
                        onClick={() => navigate(`default-measure-groups/${tv.id}`)}
                      >
                        <PG>
                          <P small={false}>{tv.name}</P>
                        </PG>
                        <ArrowIcon />
                      </ButtonWithIcon>
                    </InputGroup>
                    {/* </Sticky> */}
                  </Col>
                </Row>
              ))}
            </InputSection>
            <InputSection title="Messgruppen" xs={12} xl={6}>
              <InputGroup
                error={errors.maxMeasureGroups}
                valid={editing ? !errors.maxMeasureGroups : undefined}
                title="Maximale Anzahl"
                name="maxMeasureGroups"
              >
                <NumberInput
                  required={true}
                  min={1}
                  max={100}
                  name="maxMeasureGroups"
                  disabled={!editing || isSubmitting}
                  float={false}
                />
              </InputGroup>
            </InputSection>
            <InputSection title="Prüfbericht" xs={12} xl={6}>
              <InputGroup
                error={errors.reportFooter}
                valid={editing ? !errors.reportFooter : undefined}
                title="Fußzeile"
                name="reportFooter"
              >
                <TextAreaInput name="reportFooter" disabled={!editing || isSubmitting} />
              </InputGroup>
            </InputSection>
            <InputSection title="" xs={0} xl={6}></InputSection>
            <InputSection title="zusätzliche Felder" xs={12} xl={6}>
              {editing && (
                <InputGroup valid={true} title="Neu" name="new">
                  <ButtonWithIcon variant="link" onClick={() => navigate(`test-additional-information-fields/new`)}>
                    <PG>
                      <P small={false}>neues zusätzliches Feld</P>
                      <P small={true}>Fügen Sie ein zusätzliches Feld zur Prüfart hinzu.</P>
                    </PG>
                    <ArrowIcon />
                  </ButtonWithIcon>
                </InputGroup>
              )}
              {(item.testAdditionalInformationFields ?? []).map((tv) => (
                <InputGroup
                  key={tv.id}
                  error={errors.name}
                  valid={editing ? !errors.name : undefined}
                  title={formatValueType(tv.valueType)}
                  name="name"
                >
                  <ButtonWithIcon
                    variant="link"
                    disabled={false}
                    onClick={() => navigate(`test-additional-information-fields/${tv.id}`)}
                  >
                    <PG>
                      <P small={false}>
                        {tv.name}
                        {tv.required ? '(erforderlich)' : ''}
                      </P>
                    </PG>
                    <ArrowIcon />
                  </ButtonWithIcon>
                </InputGroup>
              ))}
            </InputSection>
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default Show;
