import { AddSafeDocError, ErrorLevel, useAddError, useClearError } from '../../context/error';
import { Button, Modal } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useEffect, useState } from 'react';
import { Step, Wizard } from '../../components/wizard';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import FormikDebug from '../../components/dev/formik-debug';
import InputGroup from '../../components/form/InputGroup';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { NewTester } from '../../interfaces/Tester';
import SelectSafeplanTechnicians from '../../components/SelectSafeplanTechnicians';
import { TesterService } from '../../services';
import { User } from '../../interfaces/User';
import UserService from '../../services/UserService';
import { get } from 'lodash';
import { validateSafePlanID } from '../../helpers/validate/validateSafePlanID';

const getTitleForSavingStep = (isSubmitting: boolean, hasError: boolean) => {
  if (!isSubmitting && !hasError) return 'Fertig!';
  if (!isSubmitting && hasError) return 'Es ist ein Fehler aufgetreten!';
  if (isSubmitting) return 'Prüfer wird gespeichert...';
  return '';
};

const NewModal: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useParams();

  const [user, setUser] = useState<User>();
  const [savingResult, setSavingResult] = useState<number | AddSafeDocError>();

  useEffect(() => {
    const loadUser = async () => {
      if (params.id) {
        const _user = await UserService(addError).get(params.id);
        if (_user.tester) {
          navigate(`/settings/testers/${_user.tester.id}`, { replace: true });
        } else {
          setUser(_user);
        }
      }
    };
    loadUser();
  }, [addError, navigate, params.id]);

  const [item] = useState<NewTester>({
    sp_id: '',
    user: user?.id,
  });

  const [step, setStep] = useState(0);

  const onSubmit = async (values: NewTester, { setSubmitting }: FormikHelpers<NewTester>) => {
    setSubmitting(true);
    clearError();
    try {
      const newTester = {
        sp_id: values.sp_id,
        user: user!.id,
      };
      const res = await TesterService(addError).create(newTester, true);
      setSavingResult(res);
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal size="lg" centered show={true}>
      <Formik validateOnChange enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
        {({ errors, touched, submitForm, isSubmitting }) => (
          <>
            <form>
              <Wizard
                step={step}
                preparing={!user}
                onChange={(s) => {
                  setStep(s);
                }}
                onClose={() => navigate(pathname.split('/').slice(0, -1).join('/'))}
                onComplete={() => {
                  submitForm();
                }}
              >
                <Step title={`${user?.name} as Prüfer anlegen`} description="Start" valid={true}>
                  <p>
                    Dieser Assistent unterstütz Sie bei der Erstellung eines neuen Prüfers und wir die erforderlichen
                    Schritte mit Ihnen durchgehen.
                  </p>
                </Step>
                <Step title="mit SafePLAN verbinden" description="SafePLAN" valid={!errors.sp_id && touched.sp_id}>
                  <p>
                    Bitte geben Sie die ID des Techniker aus safePLAN an, um den Prüfer mit einem Techniker zu
                    verknüpfen.
                  </p>
                  <InputGroup
                    error={errors.sp_id}
                    valid={!errors.sp_id}
                    title="Techniker-ID"
                    name="sp_id"
                    variant="gray-200"
                  >
                    <SelectSafeplanTechnicians name="sp_id" validate={validateSafePlanID} editing={true} />
                  </InputGroup>
                </Step>
                <Step
                  title={getTitleForSavingStep(isSubmitting, typeof savingResult !== 'number')}
                  description="SafePLAN"
                  notCloseable={isSubmitting}
                >
                  {isSubmitting && <LoadingIndicator size={40} />}
                  {!isSubmitting && typeof savingResult === 'number' && (
                    <p>
                      Der Prüfer {user?.name} wurde erfolgreich erstellt.
                      <br />
                      <Button
                        onClick={() => {
                          navigate(`/settings/testers/${savingResult}`);
                        }}
                        variant="link"
                      >
                        Zum Prüfer
                      </Button>
                    </p>
                  )}
                  {!isSubmitting && typeof savingResult !== 'number' && <p>{savingResult?.message}</p>}
                </Step>
              </Wizard>
            </form>
            <FormikDebug />
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default NewModal;
