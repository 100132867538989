import { FunctionComponent, memo } from 'react';

import { NavDropdown } from 'react-bootstrap';
import { User } from '../../interfaces/User';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import RoleView from '../RoleView';

interface DropdownUsernameHeaderProps {
  user?: User;
}

const P = styled.p<{ small: boolean }>`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: ${(props) => (props.small ? 0.75 : 1.0)}rem;
  color: ${(props) => (props.small ? '#b3b3b3' : '#000')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const DropdownUsernameHeader: FunctionComponent<DropdownUsernameHeaderProps> = ({ user }) => {
  if (!user) {
    return null;
  }

  return (
    <>
      <NavDropdown.Header>
        <P small={false}> {user.name}</P>
        <P small={true}>
          <RoleView role={user.role} />
        </P>
      </NavDropdown.Header>
      <NavDropdown.Divider />
    </>
  );
};

export default memo(DropdownUsernameHeader, (prev, next) => {
  const eq = isEqual(prev.user, next.user);
  return eq;
});
