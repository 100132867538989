import { Navigate, useLocation } from 'react-router';
import { alert } from '../components/dialogs/Alert';

import { useMain } from '../context/main';
import { Location } from '../interfaces/Location';
import { useEffect, useState } from 'react';

type RequireLocationAuto = (path: string) => Promise<Location | undefined>;

export default function RequireLocation({
  children,
  showAlert,
  auto,
}: {
  children: JSX.Element;
  showAlert?: boolean;
  auto?: RequireLocationAuto;
}) {
  const location = useLocation();
  const { selectedLocation, selectLocationByObject } = useMain();
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);

  const hasLocation = selectedLocation && selectedLocation.length > 0 && selectedLocation[0].value !== 'all';

  useEffect(() => {
    if (hasLocation) {
      // If we already have a location, we don't need to do anything.
      setLoading(false);
      setInit(true);
    } else if (!auto) {
      // If we don't have a location and no auto function is provided, we can't do anything.
      setLoading(false);
      setInit(true);
    } else {
      // If we don't have a location and an auto function is provided, we try to get the location
      setLoading(true);
      setInit(true);
      auto(location.pathname).then((location) => {
        if (location) {
          // If we got a location, we select it.
          selectLocationByObject(location);
        }
        // We're done.
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // If we're still initializing, we don't know if we have a location or not.
  if (!init) {
    return <></>;
  }

  // If we're still loading, wait for it.
  if (loading) {
    return <>Standort wird gewechselt...</>;
  }

  // If we don't have a location, we can't continue.
  if (!selectedLocation || selectedLocation.length === 0) {
    // If we should show an alert, we do so.
    if (showAlert) {
      alert('Kein Standort ausgewählt', 'Bitte wählen Sie einen anderen Standort aus.');
      return <> </>;
    }
    // Otherwise, we redirect to the home page, but save the current location they were
    return <Navigate to="/" state={{ from: location, showNoLocationAlert: true }} />;
  }

  // If the location is locked, we can't continue.
  if (selectedLocation[0]['locked']) {
    alert('Standort gesperrt', 'Bitte wählen Sie einen anderen Standort aus.');
    return <></>;
  }

  // If we have a location and it's not locked, we can continue.
  return children;
}
