import { AddErrorFunction } from '../context/error';

import axios from 'axios';
import { API } from '../config';
import handleError from './errorHandler';
import getToken from './getToken';

const SystemService = (addError: AddErrorFunction, noCache = false) => {
  const cachePolicy = () => {
    if (noCache) {
      return 'no-cache, max-age=0';
    } else {
      return 'max-age=120';
    }
  };

  const runTask = (name: string) => {
    return axios
      .post(
        `${API}/tasks`,
        { name },
        {
          headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
        },
      )
      .then((res) => {
        return true;
      })
      .catch((e) => {
        const handledError = handleError(e);
        addError(handledError);
        return false;
      });
  };

  return { runTask };
};

export default SystemService;
