import { Col, Row } from 'react-bootstrap';
import { EmptyExaminee, ExamineeWithLastTest, LastTest } from '../../interfaces/Examinee';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as CheckNowIcon } from './a.svg';

import { ReactComponent as ArrowIcon } from './check.svg';
import { ExamineesService } from '../../services';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import SquareButton from '../../components/buttons/SquareButton';
import styled from 'styled-components';
import { useAddError } from '../../context/error';
import OfflineService from '../../services/OfflineService';
import { LocalExaminee } from '../../storage';
import { DateTime } from 'luxon';
import RequireTester from '../../guards/RequireTester';

const ColAligned = styled(Col)`
  text-align: center;
  padding: 1rem 0;
`;

const Continue = styled.span`
  display: block;
  margin: 0 !important;src/routes/dashboard/a.svg
  color: #aaa;
  min-height: 1.5rem;
  line-height: 1.5rem;
  font-size: 1rem;
`;

const TestOverview: FunctionComponent = () => {
  const addError = useAddError();
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [item, setItem] = useState<ExamineeWithLastTest | LocalExaminee>(EmptyExaminee);
  const [storedTask, setStoredTask] = useState<number>();

  const loadData = useCallback(async () => {
    if (!params.examinee) {
      setItem(EmptyExaminee);
    }
    setLoading(true);

    const id = Number(params.examinee);

    let _item = undefined;

    if (id < 0) {
      _item = (await OfflineService().localExaminee(id)) as LocalExaminee;
    } else {
      _item = await ExamineesService(addError)
        .get(params.examinee ?? '')
        .catch(async (e) => {
          if (e.message === 'Offline') {
            if (id < 0) {
              // if id is negative, it local temp id
              return (await OfflineService().localExaminee(id)) as LocalExaminee;
            } else {
              return await OfflineService().getById(id);
            }
          }
        });
    }

    if (!_item || !_item.id) {
      navigate('/');
    } else {
      setItem(_item);
      setLoading(false);
    }
  }, [addError, navigate, params.examinee]);

  useEffect(() => {
    loadData();
  }, [loadData, params.id]);

  useEffect(() => {
    const raw = window.localStorage.getItem('examinees-do-test-form');
    if (!raw || raw.trim() === '') return;
    const data = JSON.parse(raw);
    if (data && data.testTask && data.testTask > 0) {
      setStoredTask(data.testTask);
    }
  }, []);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <Row className="h-100 align-items-center justify-content-center">
        {item.type.family.testTasks
          .sort((a, b) => a.prio - b.prio)
          .map((testTask) => {
            const lastTested: LastTest | undefined = (item.lastTested ?? {})[testTask.id];
            const lastTestedThisWeek =
              lastTested && lastTested.at && DateTime.fromISO(lastTested.at) > DateTime.now().minus({ days: 7 });

            return (
              <ColAligned key={testTask.id} xs={12} md={6} lg={3}>
                <SquareButton
                  highlight={!lastTestedThisWeek}
                  onClick={() => {
                    if (!storedTask || storedTask !== testTask.id) {
                      window.localStorage.removeItem('examinees-do-test-form');
                    }
                  }}
                  to={`/tests/new/${item.id}/${testTask.id}`}
                >
                  <div>
                    <ArrowIcon />
                    <span>
                      {testTask.name}
                      {storedTask && storedTask === testTask.id ? (
                        <Continue>fortsetzen</Continue>
                      ) : (
                        <Continue></Continue>
                      )}
                      {lastTested && lastTested.at && <span>Letzte Prüfung: {lastTested.at}</span>}
                    </span>
                  </div>
                </SquareButton>
              </ColAligned>
            );
          })}
      </Row>
      <RequireTester>
        <Row className="h-100 align-items-center justify-content-center">
          <ColAligned xs={12} md={6} lg={4}>
            <SquareButton to="/tests/new">
              <div>
                <CheckNowIcon />
                <span>Nächste Prüfung</span>
              </div>
            </SquareButton>
          </ColAligned>
        </Row>
      </RequireTester>
    </div>
  );
};
export default TestOverview;
