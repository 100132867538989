import { FunctionComponent } from 'react';

import styled from 'styled-components';
import { useMain } from '../../../context/main';

const P = styled.p<{ small: boolean }>`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  color: ${(props) => (props.small ? '#000' : '#000')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: not-allowed;
`;

const CustomInput = styled.div`
  display: block;
  width: 100%;
  height: 3.125rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  background-image: linear-gradient(
    135deg,
    #b2a2cd33 25%,
    #5f537033 25%,
    #5f537033 50%,
    #b2a2cd33 50%,
    #b2a2cd33 75%,
    #5f537033 75%,
    #5f537033 100%
  );
  background-size: 56.57px 56.57px;
`;

const LocationShow: FunctionComponent = () => {
  const { selectedLocation } = useMain();
  if (!selectedLocation || selectedLocation.length === 0) return <></>;
  const location = selectedLocation[0];
  return (
    <CustomInput>
      <P small={false}>{location.label1}</P>
      <P small={true}>{location.label2}</P>
    </CustomInput>
  );
};

export default LocationShow;
