import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Footer from '../components/footer';

const Main = styled.main`
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
`;

const Wrapper = styled(Outlet)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
`;

const CenterLayout: FunctionComponent = () => {
  return (
    <>
      <Main>
        <Wrapper />
      </Main>
      <Footer />
    </>
  );
};

export default CenterLayout;
