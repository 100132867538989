import { AddErrorFunction } from '../context/error';
import { NewTestingDevice, TestingDevice, UpdateTestingDevice } from '../interfaces/TestingDevice';

import axios from 'axios';
import { get as _get } from 'lodash';
import queryString from 'query-string';
import { API } from '../config';
import { OfflineStore } from '../storage';
import _BaseService from './BaseService';
import { Service } from './Service';
import handleError from './errorHandler';
import getToken from './getToken';

export interface ExtendedService<T, UT, NT> extends Service<T, UT, NT> {
  free: (limit?: number, page?: number) => Promise<T[]>;
  setAsDefault: (testingDevice: string, testTasks: number[]) => Promise<boolean>;
}

export const TestingDeviceService = (
  addError: AddErrorFunction,
  offline?: OfflineStore<TestingDevice>,
  noCache = true,
) =>
  _BaseService<TestingDevice, UpdateTestingDevice, NewTestingDevice>(
    'testing-devices',
    addError,
    offline,
    undefined,
    noCache,
  );

const ExtendedTestingDeviceService = (
  addError: AddErrorFunction,
  offline?: OfflineStore<TestingDevice>,
  noCache = true,
): ExtendedService<TestingDevice, UpdateTestingDevice, NewTestingDevice> => {
  const cachePolicy = () => {
    if (noCache) {
      return 'no-cache, max-age=0';
    } else {
      return 'max-age=120';
    }
  };

  const base = TestingDeviceService(addError, offline);

  const free = (limit = 25, page = 0) => {
    const query = queryString.stringify({ limit, page });
    return axios
      .get(`${API}/testing-devices/free?${query}`, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        return _get(res, 'data.data') as TestingDevice[];
      })
      .catch((e) => {
        addError(handleError(e));
        return {} as TestingDevice[];
      });
  };

  const setAsDefault = (testingDevice: string, testTasks: number[]) => {
    return axios
      .post(
        `${API}/testing-devices/${testingDevice}/default`,
        { testTasks },
        {
          headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
        },
      )
      .then(() => {
        return true;
      })
      .catch((e) => {
        addError(handleError(e));
        return false;
      });
  };

  return { ...base, free, setAsDefault };
};

export default ExtendedTestingDeviceService;
