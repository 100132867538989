import Table, { TableColProps } from '../../../../components/table';

import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { ExamineeAdditionalInformationField } from '../../../../interfaces/ExamineeAdditionalInformationField';
import { ExamineeAdditionalInformationFieldOption } from '../../../../interfaces/ExamineeAdditionalInformationFieldOption';

interface ExamineeAdditionalInformationFieldOptionListProps {
  item: ExamineeAdditionalInformationField;
  loading: boolean;
}

const columns: TableColProps<ExamineeAdditionalInformationFieldOption>[] = [
  { title: 'Auswahlmöglichkeit', values: 'option' },
];

const ExamineeAdditionalInformationFieldOptionList: FunctionComponent<
  ExamineeAdditionalInformationFieldOptionListProps
> = ({ loading, item }) => {
  const params = useParams();
  return (
    <Table
      loading={loading}
      items={item.valueOptions.sort((a, b) => a.option.localeCompare(b.option))}
      columns={columns}
      navigatePrefix={`/settings/examinee-families/${params.id}/examinee-additional-information-fields/${params.tgid}/options/`}
    />
  );
};

export default ExamineeAdditionalInformationFieldOptionList;
