import React, { FunctionComponent } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { Listbox, Transition } from '@headlessui/react';
import styled from 'styled-components';

interface PerPageSelectorProps {
  sizes?: number[];
  pageSize: number;
  setPageSize: (value: number) => void;
}
const Button = styled(Listbox.Button)`
  border: 0;
  user-select: none;
  cursor: pointer;
  background: transparent;
  svg,
  span {
    margin: 0 0.4rem;
  }
  svg {
    width: 1.4rem;
  }
`;

const R = styled.div`
  position: relative;
`;

const Options = styled(Listbox.Options)`
  padding: 0;
  border-radius: 3px;
  background: #fff;
  user-select: none;
  cursor: pointer;
  list-style-type: none;
  position: absolute;
  bottom: 0;
  li {
    padding: 4px 16px;
    &:hover {
      background: #eee;
    }
  }
`;

const PerPageSelector: FunctionComponent<PerPageSelectorProps> = ({ sizes, pageSize, setPageSize }) => {
  const usedSizes = sizes ?? [5, 10, 25, 50];
  return (
    <Listbox
      value={pageSize}
      onChange={(value) => {
        setPageSize(value);
      }}
    >
      <R className="relative">
        <Button>
          <span className="font-semibold">{pageSize}</span>&nbsp; pro Seite
          <ChevronDownIcon />
        </Button>
        <Transition
          as={React.Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Options>
            {usedSizes.map((p) => (
              <Listbox.Option key={p} value={p}>
                <span className={`leading-none`}>
                  <span className="font-semibold">{p}</span> pro Seite
                </span>
              </Listbox.Option>
            ))}
          </Options>
        </Transition>
      </R>
    </Listbox>
  );
};

export default PerPageSelector;
