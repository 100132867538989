export interface Address {
  street: string;
  postalCode: string;
  city: string;
  regionCode?: string;
  countryCode?: string;
}

export const EmptyAddress: Address = {
  street: '',
  postalCode: '',
  city: '',
};
