import { FunctionComponent } from 'react';
import { TableColProps } from '../../components/table';

import { PreferencesService } from '../../services';
import { useAddError } from '../../context/error';
import { Preference } from '../../interfaces/Preference';
import { useNavigate } from 'react-router-dom';
import TableWithSearch, { OnDataParams, OnSearchParams } from '../../views/TableWithSearchView';
import { useAuth } from '../../context/auth';
import { get } from 'lodash';

const columns: TableColProps<Preference>[] = [
  {
    title: 'Name',
    values: 'key',
    key: 'key',
  },
  {
    title: 'Beschreibung',
    values: 'description',
    key: 'description',
  },
  {
    title: 'Wert',
    values: 'value',
    key: 'value',
  },
];

const List: FunctionComponent = () => {
  const addError = useAddError();
  const navigate = useNavigate();
  const auth = useAuth();

  if (!auth || !auth.user) {
    return null;
  }

  return (
    <TableWithSearch
      title="Einstellungen"
      to="/settings"
      defaultLimit={get(auth, 'user.itemsPerPage', 25)}
      columns={columns}
      onData={function ({ filter, limit, page }: OnDataParams<Preference>) {
        return PreferencesService(addError).list<Preference>(limit, page, filter);
      }}
      onSearch={function ({ limit, page, term }: OnSearchParams) {
        return PreferencesService(addError).search<Preference>(term, limit, page);
      }}
      onClickItem={(pref: Preference) => {
        navigate(`/settings/preferences/${pref.key}`);
      }}
    />
  );

  // const navigate = useNavigate();
  // const [loading, setLoading] = useState(true);
  // const [items, setItems] = useState<Preference[]>([]);
  // const [pagination, setPagination] = useState({ page: 0, limit: 25 });
  // const [count, setCount] = useState<number>(0);

  // const addError = useAddError();

  // useEffect(() => {
  //   const loadData = async () => {
  //     try {
  //       const { items, count } = await PreferencesService(addError).list(pagination.limit, pagination.page);
  //       setItems(items);
  //       setCount(count);
  //     } catch (error) {
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   loadData();
  // }, [addError, count, pagination.limit, pagination.page]);

  // return (
  //   <>
  //     <Title title="Einstellungen" to="/settings" />
  //     <Table
  //       loading={loading}
  //       items={items}
  //       columns={columns}
  //       onPagination={(page, limit) => setPagination({ page, limit })}
  //       pages={Math.ceil(count / pagination.limit)}
  //       onClick={({ key }) => {
  //         navigate(`/settings/preferences/${key}`);
  //       }}
  //     />
  //   </>
  // );
};

export default List;
