import axios from 'axios';
import { get, reduce, values } from 'lodash';
import { DateTime } from 'luxon';
import queryString from 'query-string';
import { API } from '../config';
import { AddErrorFunction } from '../context/error';
import { TesterPerformance } from '../interfaces/PerformanceToday';
import handleError from './errorHandler';
import getToken from './getToken';

const AnalyticsService = (addError: AddErrorFunction) => {
  const cachePolicy = () => {
    return 'max-age=120';
  };

  const gdt = (date: Date | null | undefined, p: 'start' | 'end') => {
    const _base = DateTime.fromJSDate(date ?? new Date());
    if (p === 'start') {
      return _base.startOf('day').toUTC().toISO();
    } else {
      return _base.endOf('day').toUTC().toISO();
    }
  };

  const performanceTarget = async (): Promise<{ target: number; range: number }> => {
    return {
      target: await axios
        .get(`${API}/preferences/time-per-test-target`, {
          headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
        })
        .then((res) => {
          return Number(get(res, 'data.data.value', 0));
        })
        .catch((e) => {
          addError(handleError(e));
          return 0;
        }),
      range: await axios
        .get(`${API}/preferences/time-per-test-target-range`, {
          headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
        })
        .then((res) => {
          return Number(get(res, 'data.data.value', 0));
        })
        .catch((e) => {
          addError(handleError(e));
          return 0;
        }),
    };
  };

  const testerPerformance = ([from, until]: [Date | null, Date | null]): Promise<TesterPerformance[]> => {
    const query = queryString.stringify({
      from: gdt(from, 'start'),
      until: gdt(until, 'end'),
    });

    return axios
      .get(`${API}/analytics/performance?${query}`, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        const data = get(res, 'data.data', []);
        const a = reduce(
          data,
          (acc: { [key: string]: TesterPerformance }, item) => {
            const { tester, initials, name, ...rest } = item;
            if (acc[item.tester]) {
              acc[item.tester].locations.push(rest);
            } else {
              acc[item.tester] = {
                tester,
                initials,
                name,
                locations: [rest],
              };
            }
            return acc;
          },
          {},
        );
        return values(a);
      })
      .catch((e) => {
        addError(handleError(e));
        return [];
      });
  };

  return { testerPerformance, performanceTarget };
};

export default AnalyticsService;
