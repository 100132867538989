import { FunctionComponent, useEffect, useState } from 'react';

import { TourStop } from '../../interfaces/TourStop';
import { confirm } from '../../components/dialogs/Confirmation';
import styled from 'styled-components';
import { useMain } from '../../context/main';
import OfflineService from '../../services/OfflineService';

import { ReactComponent as PhoneIcon } from './phone.svg';
import { ReactComponent as PinIcon } from './pin.svg';

interface DayStopProps {
  stop: TourStop;
  stopNumber: number;
}

const Wrapper = styled.div<{ active?: boolean; offline?: boolean }>`
  margin: 1rem 0 0 0;
  padding: 1rem 1rem 1rem 0;
  display: block;
  width: 100%;
  background-color: var(--bs-light);
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 0.3rem;
  border-color: #fff;
  border: ${(props) => getBorder(props.active ?? false, props.offline ?? false)};
  cursor: ${(props) => (props.active ? 'default' : 'pointer')};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

const InfoCol = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  flex: 1 1 auto;
  max-width: calc(100% - 4.5rem);
  padding: 0 1rem 0 0;
  p {
    width: 100%;
    overflow-wrap: break-word;
    text-wrap: balance;
  }

  /* Safari 11+ */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      p {
        text-wrap: wrap !important;
      }
    }
  }
  /* Test website on real Safari 11+ */

  /* Safari 10.1 */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (not (stroke-color: transparent)) {
      p {
        text-wrap: wrap !important;
      }
    }
  }

  /* Safari 6.1-10.0 (but not 10.1) */
  @media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
    @media {
      p {
        text-wrap: wrap !important;
      }
    }
  }
`;
const ActionCol = styled.div`
  padding: 0 1rem 0 0;
  a {
    height: 100%;
    display: block;
    line-height: 100%;
    vertical-align: middle;
    svg {
      fill: var(--bs-primary);
      height: 1.5rem;
      width: 1.5rem;
      g,
      path {
        fill: var(--bs-primary);
      }
    }
  }
`;

const TaskWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
`;

const P = styled.p<{ color: string }>`
  padding: 0;
  margin: 0 0 0.25rem 0;
  text-align: left;
  font-size: 0.75rem;
  color: ${(props) => `var(--bs-${props.color})`};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
`;

const StopNumber = styled.div`
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 1.5rem;
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  min-width: 36px;
  padding: 0 1rem;
  color: #86cba4;
`;

const Content = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
`;

const getBorder = (active: boolean, offline: boolean) => {
  if (active) {
    return 'var(--bs-primary) 1px solid';
  }
  if (offline) {
    return 'var(--bs-info) 1px solid';
  }
  return '#00000000 1px solid';
};

const TaskContent = styled.div<{ active?: boolean; offline?: boolean }>`
  padding: 0;
  margin: 0 0.25rem 0 0;
  text-align: center;
  font-size: 1rem;
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  min-width: 36px;
  padding: 0 1rem;
  color: #b3b3b3;
  background-color: var(--bs-light);
  border-radius: 0.3rem;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  z-index: 99;
  position: relative;
  top: -1px;
  border-left: ${(props) => getBorder(props.active ?? false, props.offline ?? false)};
  border-bottom: ${(props) => getBorder(props.active ?? false, props.offline ?? false)};
  border-right: ${(props) => getBorder(props.active ?? false, props.offline ?? false)};
`;

const DayStop: FunctionComponent<DayStopProps> = ({ stop, stopNumber }) => {
  const { selectedLocation, selectLocationByObject } = useMain();

  const [active, setActive] = useState(false);
  const [offlineAvailable, setOfflineAvailable] = useState(false);

  useEffect(() => {
    const load = async () => {
      const ol = await OfflineService().localLocation(stop.location.id);
      setOfflineAvailable(ol !== undefined);
    };
    load();
  }, [stop.location.id]);

  useEffect(() => {
    const a = (selectedLocation ? selectedLocation.findIndex((l) => stop.location.id === l.value) : -1) > -1;
    setActive(a);
  }, [selectedLocation, stop.location.id]);

  const selectLocation = async () => {
    const res = await confirm('Standort wechseln', 'Möchten Sie den Standort wechseln?', 'Ja', 'Nein');
    if (res) {
      selectLocationByObject(stop.location);
    }
  };

  const Contact = () => {
    const c = [stop.location.contact, stop.location.phone].filter((s) => s && s.length > 0);
    if (c.length === 0) return <div></div>;
    return <P color="secondary">{c.join(' | ')}</P>;
  };

  const Comment = () => {
    if (!stop.comment) return <div></div>;
    return <P color="info">{stop.comment}</P>;
  };

  const Phone = () => {
    if (!stop.location.phone) return <div></div>;
    const isMobile = /Mobile|iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (!isMobile) return <div></div>;
    return (
      <a href={`tel:${stop.location.phone}`}>
        <PhoneIcon />
      </a>
    );
  };

  const Map = () => {
    const ios = /iPad|iPhone|iPod/.test(navigator.userAgent);

    const url = ios
      ? `maps://?q=${stop.location.address.street}, ${stop.location.address.postalCode} ${stop.location.address.city}`
      : `http://maps.google.com/?q=${stop.location.address.street}, ${stop.location.address.postalCode} ${stop.location.address.city}`;

    return (
      <a href={url} target="blank">
        <PinIcon />
      </a>
    );
  };

  return (
    <>
      <Wrapper active={active} offline={offlineAvailable}>
        <InfoCol onClick={() => !active && selectLocation()}>
          <StopNumber>{stopNumber}</StopNumber>
          <Content>
            <P color="primary">{`[${stop.location.tag}] ${stop.location.name}`}</P>
            <P color="dark">{`${stop.location.address.street}, ${stop.location.address.postalCode} ${stop.location.address.city}`}</P>
            <Contact />
            <Comment />
          </Content>
        </InfoCol>
        <ActionCol>
          <Phone />
        </ActionCol>
        <ActionCol>
          <Map />
        </ActionCol>
      </Wrapper>
      <TaskWrapper>
        {stop.testTasks.map((tt) => (
          <TaskContent active={active} offline={offlineAvailable} key={tt.id}>
            {tt.initials}
          </TaskContent>
        ))}
      </TaskWrapper>
    </>
  );
};

export default DayStop;
