import { ValueType } from '../../interfaces/ValueType';

export default function formatValueType(type: ValueType) {
  switch (type) {
    case ValueType.BOOLEAN:
      return 'Ja / Nein';
    case ValueType.NUMBER:
      return 'Zahl (ganzzahlig)';
    case ValueType.FLOAT:
      return 'Zahl (zwei Nachkommastelle)';
    case ValueType.STRING:
      return 'Text';
    case ValueType.OPTION:
      return 'Auswahl';
    default:
      return '';
  }
}
