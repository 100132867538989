import { NewType, UpdateType } from './removeDates';

import { Tester } from './Tester';

export type Role = 'ADMINISTRATOR' | 'MANAGER' | 'STANDARD' | 'VIEWER';
export type Language = 'en-US' | 'de-DE';

export type PreferenceValue =
  | string
  | number
  | boolean
  | string[]
  | number[]
  | boolean[]
  | Record<string, string | number | boolean | string[] | number[] | boolean[]>
  | undefined;
export type Preferences = { [key: string]: PreferenceValue };

export interface Preference {
  // id: number;
  key: string;
  value?: string;
  user: User;
}

export interface User {
  id: number;
  email: string;
  name: string;
  company?: string;
  role: string;
  language: string;
  tester?: Tester;
  scopes: UserScope[];
  itemsPerPage?: number;
  // preferences: Preference[];
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export interface UserScope {
  id: number;
  user: User;
  type: string;
  sp_id: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export type NewUser = NewType<User, 'email' | 'name' | 'role' | 'language'>;

export type UpdateUser = UpdateType<User, 'email' | 'name' | 'role' | 'language'>;

export const EmptyNewUser: NewUser = {
  email: '',
  name: '',
  language: 'de-DE',
  role: 'VIEWER',
};

export const EmptyUser: User = {
  id: 0,
  email: '',
  name: '',
  role: '',
  language: '',
  scopes: [],
  // preferences: [],
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
};
