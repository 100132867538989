import { Button, Col, Row, Stack } from 'react-bootstrap';

import { FunctionComponent } from 'react';
import Title from '../../components/title';
import { chunk } from 'lodash';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

interface SettingsGroup {
  title: string;
  items: SettingItem[];
}

interface SettingItem {
  title: string;
  link: string;
}

const groups: SettingsGroup[] = [
  {
    title: 'Prüfungen',
    items: [
      { title: 'Prüfarten', link: '/settings/tasks' },
      { title: 'Prüfer', link: '/settings/testers' },
    ],
  },
  {
    title: 'Prüflinge',
    items: [
      { title: 'Prüflingsgattungen', link: '/settings/examinee-families' },
      { title: 'Prüflingsarten', link: '/settings/examinee-types' },
      { title: 'Hersteller', link: '/settings/examinee-vendors' },
      { title: 'Aufstellorte', link: '/settings/examinee-installation-sites' },
    ],
  },
  {
    title: 'Werkzeug und Geräte',
    items: [{ title: 'Prüfmittel', link: '/settings/testing-devices' }],
  },
  {
    title: 'System',
    items: [
      { title: 'Einstellungen', link: '/settings/preferences' },
      { title: 'Briefpaper', link: '/settings/paper' },
      { title: 'Benutzer', link: '/settings/users' },
      { title: 'API Keys', link: '/settings/api-keys' },
    ],
  },
];

const LinkButton = styled(Button)`
  float: left;
  text-align: left;
  color: #a1a1a1;
  opacity: 1;
  padding: 1px 0;
  margin: 0;
  font-size: 14px;
  text-decoration: none;
  color: var(--bs-primary);
  &:hover {
    color: #a1a1a1;
    opacity: 0.8;
  }
`;

const H = styled.h1`
  font-size: 18px;
  letter-spacing: 0;
  line-height: 25px;
  font-weight: 700;
  padding: 6px 0;
  margin: 0;
  display: block;
`;

const HR = styled.hr`
  background-color: #e6e6e6;
  opacity: 1;
`;

const List: FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <>
      <Title title="Einstellungen" />
      <br></br>
      {chunk(groups, 5).map((c) => (
        <>
          <Row>
            {c.map((g) => (
              <Col key={g.title}>
                <H>{g.title}</H>
                <Stack gap={0}>
                  {g.items.map((i) => (
                    <LinkButton key={i.title} variant="link" onClick={() => navigate(i.link)}>
                      {i.title}
                    </LinkButton>
                  ))}
                </Stack>
              </Col>
            ))}
          </Row>
          <HR />
        </>
      ))}
    </>
  );
};

export default List;
