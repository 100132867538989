import { FunctionComponent, useEffect, useState } from 'react';
import Table, { TableColProps } from '../../components/table';

import { useAddError } from '../../context/error';
import OfflineService from '../../services/OfflineService';
import { LocalTest } from '../../storage';

const columns: TableColProps<LocalTest>[] = [
  { title: 'Prüfling', width: 2, values: 'examinee.tag' },
  {
    title: 'Prüfart',
    width: 2,
    values: 'testTask.initials',
    key: 'testTask.id',
  },
  {
    title: 'Datum',
    width: 4,
    values: (item: LocalTest) => {
      return new Date(item.created_at).toLocaleDateString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },
  },
  // {
  //   title: 'Bemerkung',
  //   width: 2,
  //   values: 'comment',
  // },
  // {
  //   title: 'Bestanden',
  //   width: 1,
  //   values: (item: LocalTest) => {
  //     return <ResultColored result={item.result}>{item.result ? 'Ja' : 'Nein'}</ResultColored>;
  //   },
  //   key: 'result',
  //   filterable: () =>
  //     Promise.resolve([
  //       { value: true, title: 'Ja' },
  //       { value: false, title: 'Nein' },
  //     ]),
  // },
];
const TestsList: FunctionComponent = () => {
  const [loading, setLoading] = useState(true);

  const [items, setItems] = useState<LocalTest[]>([]);
  const addError = useAddError();

  const loadData = async () => {
    try {
      const { items } = await OfflineService().localTests();
      setItems(items);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [addError]);

  return <Table loading={loading} items={items} columns={columns} />;
};

export default TestsList;
