import { FunctionComponent } from 'react';
import styled from 'styled-components';

interface InputGroupProps {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  error?: string;
}

const Wrapper = styled.div<{ hasError: boolean }>`
  margin: 9px 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 0.3rem;
  border-color: #f0f0f0;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  order: 1;
  flex: 0 1 auto;
  align-self: auto;
  svg {
    width: 1.75rem;
    height: 1.75rem;
    fill: #b3b3b3;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: stretch;
  order: 1;
  flex: 1 1 auto;
  align-self: auto;
  input {
    padding: 0.75rem;
    background-color: #f0f0f0;
    border-radius: 0.3rem;
    border-color: #f0f0f0;
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
  }
`;

const Error = styled.p`
  padding: 0 1rem 0.5rem 1rem;
  font-size: 1rem;
  margin: 0;
  line-height: 1.5;
  color: #aa0000;
  text-align: right;
`;

const InputGroup: FunctionComponent<InputGroupProps> = ({ children, error, icon }) => {
  return (
    <>
      <Wrapper hasError={!!error}>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <InputWrapper>{children}</InputWrapper>
      </Wrapper>
      {error && <Error>{error}</Error>}
    </>
  );
};

export default InputGroup;
