import { AddErrorFunction, AddSafeDocError } from '../context/error';

import axios from 'axios';
import { get as _get } from 'lodash';
import { API } from '../config';
import { Paper, UpdatePaper } from '../interfaces/Paper';
import handleError from './errorHandler';
import getToken from './getToken';

export type PaperServiceType = {
  get: (suppress?: boolean) => Promise<Paper | undefined | AddSafeDocError>;
  update: (data: UpdatePaper, suppress?: boolean) => Promise<void | undefined | AddSafeDocError>;
};

const PaperService = (addError: AddErrorFunction): PaperServiceType => {
  const cachePolicy = () => {
    return 'no-cache, max-age=0';
  };

  const get = (suppress = false): Promise<Paper | undefined | AddSafeDocError> => {
    return axios
      .get(`${API}/paper`, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        return _get(res, 'data.data') as Paper;
      })
      .catch(async (e) => {
        const handledError = handleError(e);
        if (suppress) {
          return handledError;
        } else {
          addError(handledError);
          return undefined;
        }
      });
  };

  const update = (data: UpdatePaper, suppress = false): Promise<void | AddSafeDocError> => {
    const _data = {
      ...data,
      footer: data.footer ? JSON.stringify(data.footer) : undefined,
      logo: data.logo instanceof File ? data.logo : undefined,
    };
    return axios
      .postForm(`${API}/paper`, _data, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then(() => {
        return;
      })
      .catch(async (e) => {
        const handledError = handleError(e);
        if (suppress) {
          return handledError;
        } else {
          addError(handledError);
          return undefined;
        }
      });
  };

  return { get, update };
};

export default PaperService;
