import { NewType } from './removeDates';

export interface ApiKey {
  id: number;
  apikey: string;
  name: string;
  created_at: string;
  deleted_at?: string;
}

export type NewApiKey = NewType<ApiKey, 'name'>;

export const EmptyNewApiKey: NewApiKey = {
  name: '',
};

export const EmptyApiKey: ApiKey = {
  id: 0,
  apikey: '',
  name: '',
  created_at: new Date().toISOString(),
};
