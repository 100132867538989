import { Field, FieldArray, Formik, FormikHelpers, useFormikContext } from 'formik';
import { get, orderBy } from 'lodash';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorLevel, useAddError, useClearError } from '../../context/error';
import { EmptyExaminee, Examinee, LastTest } from '../../interfaces/Examinee';
import { NewTest, NewThresholdDefect } from '../../interfaces/Test';
import { EmptyTestTask, TestTask } from '../../interfaces/TestTask';
import { TestValueThreshold, ThresholdType } from '../../interfaces/TestValueThreshold';
import { ExamineesService, TestService, TestTaskService, UserService } from '../../services';

import styled from 'styled-components';
import SubmitButton from '../../components/SubmitButton';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import TestingDeviceSelect from '../../components/common/examinee/TestingDeviceSelect';
import InputGroup from '../../components/form/InputGroup';
import InputSection from '../../components/form/InputSection';
import TextAreaInput from '../../components/form/TextAreaInput';
import Title from '../../components/title';
import { PersistFormikValues } from '../../helpers/PersistFormikValues';
import OfflineService from '../../services/OfflineService';
import { LocalExaminee, userStore } from '../../storage';
import AdditionalInformation from './AdditionalInformation';
import MesureGroup from './MesureGroup';
import { ReactComponent as CheckIcon } from './check.svg';
import { ReactComponent as NewIcon } from './plus.svg';

type FormType = Omit<NewTest, 'removeMe' | 'tester' | 'thresholdDefects'> & {
  successredirect?: string;
};

const Hidden = styled.input`
  display: none;
`;

const Notifications: FunctionComponent<{ testTask: TestTask; onChange: (defects: NewThresholdDefect[]) => void }> = ({
  testTask,
  onChange,
}) => {
  const [notifications, setNotifications] = useState<NewThresholdDefect[]>([]);
  const { values } = useFormikContext<FormType>();

  useEffect(() => {
    const clear = () => {
      setNotifications([]);
      return;
    };

    if (!values) {
      clear();
    }

    // if (!values.group) {
    //   clear();
    // }

    let n: NewThresholdDefect[] = [];

    const add = (name: string, th: TestValueThreshold) => {
      n = [...n, { name, description: th.description, threshold: th.id, triggersFailure: th.triggersFailure }];
    };

    for (const group of values.groups) {
      for (const [key, value] of Object.entries(group.values)) {
        const tvID = Number(key.substring(3));
        if (Number.isNaN(tvID)) {
          clear();
        }
        const testValueGroup = testTask.testValueGroups.find((e) => e.id === group.group);
        if (!testValueGroup) {
          clear();
        }
        const testValue = testValueGroup?.testValues.find((e) => e.id === tvID);

        if (!testValue) {
          clear();
        }

        if (testValue) {
          const { thresholds } = testValue;
          if (thresholds.length > 0) {
            const thresholdsDesc = orderBy(thresholds, 'prio', 'desc');
            for (let i = 0; i < thresholdsDesc.length; i++) {
              const threshold = thresholdsDesc[i];
              const thv = Number(threshold.thresholdValue);
              const v = Number(value);
              if (!Number.isNaN(thv) && !Number.isNaN(v)) {
                if (threshold.thresholdType === ThresholdType.LOWER) {
                  if (thv >= v) {
                    add(testValue!.name, threshold);
                    break;
                  }
                } else if (threshold.thresholdType === ThresholdType.HIHGER) {
                  if (thv <= v) {
                    add(testValue!.name, threshold);
                    break;
                  }
                } else if (threshold.thresholdType === ThresholdType.EQUAL) {
                  if (thv !== v) {
                    add(testValue!.name, threshold);
                    break;
                  }
                } else if (threshold.thresholdType === ThresholdType.UNEQUAL) {
                  if (thv === v) {
                    add(testValue!.name, threshold);
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }

    setNotifications(n);
    onChange(n);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testTask.testValueGroups, values]);

  return (
    <div>
      {notifications.map(({ name, description, triggersFailure }, i) => (
        <Alert key={i} variant={triggersFailure ? 'danger' : 'warning'}>
          {`[${name}] ${description}`}
        </Alert>
      ))}
    </div>
  );
};

const AddMesureGroupButton = styled.button`
  margin: 36px 0 14px;
  font-size: 11px;
  border: 0;
  letter-spacing: 0;
  text-transform: uppercase;
  line-height: 22px;
  font-weight: 700;
  color: #009842;
  float: left;
  opacity: 1;
  svg {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 0 6px 4px 0;
    &,
    path,
    g {
      fill: #009842;
      color: #009842;
    }
  }
  span {
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 1px;
  }
  &:disabled,
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: #a1a1a1 !important;
  }
  &:hover {
    color: #009842;
    opacity: 0.8;
    &,
    path,
    g {
      fill: #009842;
      color: #009842;
    }
  }
`;

const Value = styled.input`
  border: 0;
  background: transparent;
  font-size: 0.75rem;
  -webkit-appearance: none;
  outline-color: rgba(0, 0, 0, 0);
`;

const DoTest: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [examinee, setExaminee] = useState<Examinee | LocalExaminee>(EmptyExaminee);
  const [testTask, setTeskTast] = useState<TestTask>(EmptyTestTask);
  const [thresholdDefects, setThresholdDefects] = useState<NewThresholdDefect[]>([]);

  // const [testValueGroup, setTestValueGroup] = useState<TestValueGroup>(EmptyTestValueGroup);

  // const [initialGroupValues, setInitialGroupValues] =
  //   useState<Record<string, undefined | null | string | Array<string>>>();
  const [initialValues, setInitialValues] = useState<FormType>();

  const loadData = useCallback(async () => {
    if (!params.examinee || !params.task) {
      navigate('/');
    }
    setLoading(true);

    const id = Number(params.examinee);

    const loadExaminee = async () => {
      let _item = undefined;
      if (id < 0) {
        _item = (await OfflineService().localExaminee(id)) as LocalExaminee;
      } else {
        _item = await ExamineesService(addError)
          .get(params.examinee ?? '')
          .catch(async (e) => {
            if (e.message === 'Offline') {
              if (id < 0) {
                // if id is negative, it local temp id
                return (await OfflineService().localExaminee(id)) as LocalExaminee;
              } else {
                return await OfflineService().getById(id);
              }
            }
          });
      }
      return _item;
    };

    const [_examinee, _testTask] = await Promise.all([
      loadExaminee(),
      TestTaskService(addError)
        .get(params.task ?? '')
        .then((tt) => {
          if (!tt) {
            const id = Number(params.task);
            return OfflineService().getTestTask(id);
          }
          return tt;
        })
        .catch((e) => {
          if (e.message === 'Offline') {
            const id = Number(params.task);
            return OfflineService().getTestTask(id);
          }
        }),
    ]);

    if (!_examinee || !_examinee.id) {
      navigate('/');
    } else {
      setExaminee(_examinee!);
      setTeskTast(_testTask!);

      // if (_testTask.testValueGroups.length > 0) {
      //   setTestValueGroup(_testTask.testValueGroups[0]);
      // }

      setLoading(false);
    }
  }, [addError, navigate, params.examinee, params.task]);

  useEffect(() => {
    loadData();
  }, [loadData, params.id]);

  useEffect(() => {
    // const tvd: TestValue[] = testValueGroup.testValues.filter((tv) => tv.defaultValue);

    // const _dfv = reduce(
    //   tvd,
    //   (prev, curr) => {
    //     if (curr.valueType === 'boolean' && curr.defaultValue !== undefined) {
    //       prev[`mgv${curr.id}`] = curr.defaultValue === 'true';
    //     }

    //     const nv = Number(curr.defaultValue);
    //     if (curr.valueType === 'number' && !isNaN(nv)) {
    //       prev[`mgv${curr.id}`] = nv;
    //     }

    //     if (curr.valueType === 'string' && curr.defaultValue && curr.defaultValue.length > 0) {
    //       prev[`mgv${curr.id}`] = curr.defaultValue;
    //     }

    //     if (curr.valueType === 'option' && curr.defaultValue && curr.defaultValue.length > 0) {
    //       prev[`mgv${curr.id}`] = curr.defaultValue;
    //     }

    //     return prev;
    //   },
    //   {} as Record<string, any>,
    // );

    // setInitialGroupValues(_dfv);

    console.log('testTask', testTask);

    if (!testTask) return;

    const defaultMeasureGroups = (testTask.defaultMeasureGroups ?? []).map(({ tag, name }) => ({
      tag,
      name,
      values: {},
      group: 0,
    }));

    const _initialValues: FormType = {
      testTask: params.task ? Number(params.task) : 0,
      examinee: params.examinee ? Number(params.examinee) : 0,
      testingDevice: -1,
      groups: defaultMeasureGroups,
      additionalInformation: {},
    };

    setInitialValues(_initialValues);
  }, [params.examinee, params.task, testTask]);

  const onSubmit = async (values: FormType, { setSubmitting }: FormikHelpers<FormType>) => {
    setSubmitting(true);
    clearError();

    try {
      const user = await UserService(addError, userStore).me();
      const tester = Number(get(user, 'tester.id', 0));
      const data: NewTest = {
        ...values,
        thresholdDefects,
        tester,
      };

      const id = await TestService(addError).create(data);

      if (!(id && typeof id === 'number' && (id > 0 || id < 0))) {
        return;
      }

      if (values.successredirect) {
        window.localStorage.removeItem('examinees-do-test-form');
        navigate(values.successredirect);
        return;
      }

      window.localStorage.removeItem('examinees-do-test-form');
      navigate(`/tests/new/${params.examinee}`);
    } catch (error) {
      console.error(error);
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div>
        <LoadingIndicator size={40} />
      </div>
    );
  }

  if (loading || !testTask || !initialValues) {
    return (
      <div>
        <LoadingIndicator size={40} />
      </div>
    );
  }

  const lastTested: LastTest | undefined = (examinee.lastTested ?? {})[testTask.id];

  return (
    <Formik
      validateOnMount={true}
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={(v) => {
        const errors: Record<string, string | undefined> = {};

        for (const groupKey in v.groups) {
          const group = v.groups[groupKey];
          for (const valueKey in group.values) {
            if (
              typeof group.values[valueKey] === 'number' &&
              Number(group.values[valueKey]) < 0 &&
              Number(group.values[valueKey]) !== -1
            ) {
              const name = `groups[${groupKey}].values.${valueKey}`;
              errors[name] = 'Wert muss größer oder gleich 0 sein bzw. -1 sein.';
            }
          }
        }

        return errors;
      }}
    >
      {({ handleSubmit, isValidating, isValid, errors, values, isSubmitting, setFieldValue }) => (
        <form
          autoComplete="off"
          onSubmit={(e) => {
            const successredirect = get(e, 'nativeEvent.submitter.attributes.successredirect.nodeValue');
            setFieldValue('successredirect', successredirect);
            setTimeout(() => handleSubmit(e), 50);
            e.preventDefault();
          }}
        >
          <Title
            onCancel={() => {
              navigate(`/tests/new/${params.examinee}`);
            }}
            editing={true}
            editingValid={!isValidating && isValid}
            isSubmitting={isSubmitting}
            title={`${testTask.name} - Prüfung`}
            allowOffline={['save']}
            saveAndNewLink={`/tests/new`}
          />
          <Notifications
            testTask={testTask}
            onChange={(_thresholdDefects) => {
              setThresholdDefects(_thresholdDefects);
            }}
          />
          {lastTested && lastTested.at && (
            <Row>
              <InputSection title="Prüfling" xs={12}>
                <Row>
                  <Col xs={12} md={6} lg={6} xl={3}>
                    <InputGroup title="Prüfling-ID" name="tag">
                      <Value name="tag" disabled={true} value={examinee.tag} />
                    </InputGroup>
                  </Col>
                  <Col xs={12} md={6} lg={6} xl={3}>
                    <InputGroup title="Prüflingsart" name="type">
                      <Value name="type" disabled={true} value={examinee.type.name} />
                    </InputGroup>
                  </Col>
                  <Col xs={12} md={6} lg={6} xl={3}>
                    <InputGroup title="Hersteller" name="vendor">
                      <Value name="vendor" disabled={true} value={examinee.vendor.name} />
                    </InputGroup>
                  </Col>
                  <Col xs={12} md={6} lg={6} xl={3}>
                    <InputGroup title="Letzte Prüfung" name="lastTested">
                      <Value name="lastTested" disabled={true} value={lastTested.at} />
                    </InputGroup>
                  </Col>
                </Row>
              </InputSection>
            </Row>
          )}
          <Row>
            {!testTask.withoutTestingDevice && (
              <InputSection title="Prüfmittel" xs={12} md={6}>
                <TestingDeviceSelect editing={true} testTasks={testTask.id} />
              </InputSection>
            )}
          </Row>
          {get(examinee, 'type.family.testAdditionalInformation', []).length > 0 && (
            <Row>
              <InputSection title="Zusätzliche Informationen" xs={12}>
                <Row>
                  {examinee.type.family.testAdditionalInformation.map((af) => (
                    <AdditionalInformation
                      name={`additionalInformation.${af.id}`}
                      isSubmitting={isSubmitting}
                      f={af}
                      errors={errors}
                      key={af.id}
                    />
                  ))}
                </Row>
              </InputSection>
              <InputSection title="" xs={0} xl={4}></InputSection>
            </Row>
          )}
          <Field
            as={Hidden}
            name="groups-count"
            validate={() => {
              return values.groups.length === 0;
            }}
          ></Field>
          <FieldArray
            name="groups"
            validateOnChange={true}
            render={(arrayHelpers) => (
              <div>
                {values.groups &&
                  values.groups.map((group: { name: any }, index: number) => (
                    <Row data-index={index} key={index}>
                      <MesureGroup
                        testTask={testTask}
                        name={`${group.name} - Stromkreis / Messgruppe`}
                        onRemove={(index: number) => {
                          arrayHelpers.remove(index);
                        }}
                        group={values.groups[index].group}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        index={index}
                        isSubmitting={isSubmitting}
                      />
                    </Row>
                  ))}
                <Row>
                  <Col xs={12}>
                    {values.groups.length < (testTask.maxMeasureGroups ?? 100) && (
                      <AddMesureGroupButton
                        type="button"
                        onClick={() => {
                          const name = prompt('Bitte geben Sie die Bezeichnung der Stromkreis / Messgruppe ein.');
                          if (name) {
                            arrayHelpers.push({
                              values: {},
                              name: name,
                            });
                          }
                        }}
                      >
                        <NewIcon />
                        Weiter Messegruppe anlegen
                      </AddMesureGroupButton>
                    )}
                  </Col>
                </Row>
              </div>
            )}
          />
          <Row>
            <InputSection title="ergänzende informationen" xs={12} md={6} xl={6}>
              <InputGroup title="Bemerkung" name="comment">
                <TextAreaInput name="comment" />
              </InputGroup>
            </InputSection>
            <InputSection title="Ergebniss" xs={12} md={6}>
              <InputGroup title="Mängel" name="defects">
                <TextAreaInput name="defects" />
              </InputGroup>
            </InputSection>
          </Row>
          <Row className="mt-3 d-xs-block d-xl-none">
            <Col xs={12} lg={6}>
              <SubmitButton
                breakat={1200}
                block={true}
                colored="true"
                type="submit"
                float="right"
                disabled={isSubmitting || !isValid}
              >
                <CheckIcon />
                {!isSubmitting && <span>Speichern</span>}
                {isSubmitting && <span>Speichert...</span>}
              </SubmitButton>
            </Col>
            <Col xs={12} lg={6}>
              <SubmitButton
                breakat={1200}
                block={true}
                colored="true"
                type="submit"
                float="right"
                disabled={isSubmitting || !isValid}
                successredirect={'/tests/new'}
              >
                <CheckIcon />
                {!isSubmitting && <span>Speichern & Neu</span>}
                {isSubmitting && <span>Speichert...</span>}
              </SubmitButton>
            </Col>
          </Row>
          <PersistFormikValues
            name="examinees-do-test-form"
            persistInvalid={true}
            debounce={50}
            shouldValidate={true}
          />
        </form>
      )}
    </Formik>
  );
};
export default DoTest;
