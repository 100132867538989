import './index.scss';
import './print.scss';

import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import info from './build';
import reportWebVitals from './reportWebVitals';

import { init as initApm } from '@elastic/apm-rum';

import withClearCache from './App/ClearCache';

if (process.env.REACT_APP_RUM && process.env.NODE_ENV === 'production') {
  initApm({
    serviceName: 'safedoc',
    serverUrl: process.env.REACT_APP_RUM,
    serviceVersion: info.build,
    environment: info.env,
    distributedTracing: true,
    distributedTracingOrigins: ['https://allessafe.notion.cloud/safedoc/live'],
  });
}

if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: '36a1053a-e971-4b6e-a8f6-20b84e968540',
    clientToken: 'pub60c059810f163e02b63dd8f94ddf2824',
    site: 'datadoghq.eu',
    service: 'safedoc',
    version: info.build,
    sampleRate: 50,
    premiumSampleRate: 50,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    env: info.env,
  });

  datadogRum.onReady(() => {
    console.log('Datadog RUM is ready');

    try {
      datadogRum.setGlobalContextProperty('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);
      datadogRum.setGlobalContextProperty('language', navigator.language);
    } catch (error) {}
  });
}

const ClearCacheComponent = withClearCache(App);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    <ClearCacheComponent />
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (process.env.NODE_ENV === 'production') {
  datadogRum.startSessionReplayRecording();
}
