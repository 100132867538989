// import ExamineeFamilies from "../examinee-families";
// import ExamineeTypes from "./examinee-types";
// import InstallationSitesRoutes from "./installation-sites";
import List from './List';
import MainLayout from '../../layouts/MainLayout';
// import NewTestValueModal from "./tasks/test-values/NewModal";
import RequireAuth from '../../guards/RequireAuth';
import { Route } from 'react-router';

// import Tasks from "./tasks";
// import VendorsRoutes from "./vendors";

const Routes = (
  <Route
    path="/settings"
    element={
      <RequireAuth>
        <MainLayout />
      </RequireAuth>
    }
  >
    <Route path="/settings" element={<List />} />
  </Route>
);

export default Routes;

// export default function SettingsRoute() {
//   // let location = useLocation();
//   // let state = location.state as { modal?: Location };
//   return (
//     <>
//       <Routes>
//         <Route
//           path="/settings"
//           element={
//             <RequireAuth>
//               <MainLayout />
//             </RequireAuth>
//           }
//         >
//           <Route path="/settings/" element={<List />} />
//           {/* <Route path="/settings/tasks/*" element={<Tasks />} />
//           <Route
//             path="/settings/examinee-families/*"
//             element={<ExamineeFamilies />}
//           />
//           <Route
//             path="/settings/examinee-types/*"
//             element={<ExamineeTypes />}
//           />
//           <Route
//             path="/settings/examinee-vendors/*"
//             element={<VendorsRoutes />}
//           />
//           <Route
//             path="/settings/examinee-installation-sites/*"
//             element={<InstallationSitesRoutes />}
//           /> */}
//         </Route>
//       </Routes>
//       {/* Modals */}
//       {/* <Routes>
//         <Route
//           path="/settings/tasks/:id/test-values/new"
//           element={<NewTestValueModal />}
//         />
//       </Routes> */}
//     </>
//   );
// }
