import { UpdateType } from './removeDates';

export type ColPaperFooter = string[];
export type PaperFooter = ColPaperFooter[];

export interface Paper {
  id: number;
  footer: PaperFooter;
  primary: string;
  secondary: string;
  logo?: string;
  author: string;
  updated_at: string;
}

export type UpdatePaper = UpdateType<Paper, 'footer' | 'primary' | 'secondary' | 'author'> & {
  logo?: File;
};

export const EmptyPaper: Paper = {
  id: 0,
  updated_at: new Date().toISOString(),
  footer: [],
  primary: '',
  secondary: '',
  author: '',
};
