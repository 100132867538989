import { NewType, UpdateType } from './removeDates';

export interface Vendor {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export type NewVendor = NewType<Vendor, 'name'>;

export type UpdateVendor = UpdateType<Vendor, 'name'>;

export const EmptyNewVendor: NewVendor = {
  name: '',
};

export const EmptyVendor: Vendor = {
  id: 0,
  name: '',
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
};
