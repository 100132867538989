import { useAuth } from '../context/auth';

export default function RequireTester({
  children,
  noTesterChildren = null,
}: {
  children: JSX.Element;
  noTesterChildren?: JSX.Element | null;
}) {
  const auth = useAuth();

  if (!auth.user) {
    return <></>;
  }

  if (auth.user && auth.user.tester) {
    return children;
  } else {
    return noTesterChildren;
  }
}
