import BareInput from '../../../../components/form/BareInput';
import { Row } from 'react-bootstrap';
import {
  EmptyTestAdditionalInformationFieldOption,
  TestAdditionalInformationFieldOption,
} from '../../../../interfaces/TestAdditionalInformationFieldOption';
import { ErrorLevel, useAddError, useClearError } from '../../../../context/error';
import { Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useEffect, useState } from 'react';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';
import { get, reduce } from 'lodash';

import InputGroup from '../../../../components/form/InputGroup';
import InputSection from '../../../../components/form/InputSection';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import { TestAdditionalInformationFieldOptionService } from '../../../../services';
import Title from '../../../../components/title';
import { confirm } from '../../../../components/dialogs/Confirmation';
import qs from 'query-string';
import validateTestAdditionalInformationFieldOptionName from '../../../../helpers/validate/validateTestAdditionalInformationFieldOptionName';

const getChangedValues = (
  values: TestAdditionalInformationFieldOption,
  initialValues: TestAdditionalInformationFieldOption,
): Partial<TestAdditionalInformationFieldOption> => {
  return reduce(
    Object.entries(values),
    (acc, [key, value]) => {
      const hasChanged = (initialValues as any)[key] !== value;
      if (hasChanged) {
        (acc as any)[key] = value;
      }
      return acc;
    },
    {},
  );
};

const shouldEditing = (location: Location) => {
  const queryParams = qs.parse(location.search, {});
  return queryParams.edit === '1' || queryParams.edit === 'true';
};

const Show: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(shouldEditing(location));

  const [item, setItem] = useState<TestAdditionalInformationFieldOption>(EmptyTestAdditionalInformationFieldOption);

  useEffect(() => {
    const loadData = async () => {
      try {
        if (!params.opid) {
          return;
        }
        const _item = await TestAdditionalInformationFieldOptionService(addError).get(params.opid);
        if (!_item || !_item.id) {
          navigate(`/settings/examinee-families/${params.id}/test-additional-information-fields/${params.tgid}`);
        }
        setItem(_item);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, navigate, params.id, params.opid, params.tgid, params.tvid]);

  const onSubmit = async (
    values: TestAdditionalInformationFieldOption,
    { setSubmitting }: FormikHelpers<TestAdditionalInformationFieldOption>,
  ) => {
    setSubmitting(true);
    try {
      if (params.opid) {
        clearError();
        const data = getChangedValues(values, item);
        await TestAdditionalInformationFieldOptionService(addError).update(params.opid, data);
        setEditing(false);
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onDelete = async (setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true);
    try {
      if (params.opid) {
        if (
          await confirm(
            'Auswahlmöglichkeit löschen?',
            'Die Auswahlmöglichkeit wird entgültig gelöscht. Sind Sie sich sicher?',
            'Löschen',
            'Abbruch',
            {
              okColor: 'danger',
            },
          )
        ) {
          await TestAdditionalInformationFieldOptionService(addError).destroy(params.opid);
          navigate(`/settings/examinee-families/${params.id}/test-additional-information-fields/${params.tgid}`);
        }
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isValidating, isValid, errors, resetForm, isSubmitting, setSubmitting }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Title
            onEdit={() => setEditing(true)}
            onDelete={() => onDelete(setSubmitting)}
            onCancel={() => {
              resetForm({
                values: item,
              });
              setEditing(false);
            }}
            editing={editing}
            editingValid={!isValidating && isValid}
            isSubmitting={isSubmitting}
            to={`/settings/examinee-families/${params.id}/test-additional-information-fields/${params.tgid}`}
            title={`Auswahlmöglichkeit ${item.option}`}
          />
          <Row>
            <InputSection title="Stammdaten" xs={12} xl={6}>
              <InputGroup
                error={errors.option}
                valid={editing ? !errors.option : undefined}
                title="Auswahlmöglichkeit"
                name="option"
              >
                <BareInput
                  disabled={!editing || isSubmitting}
                  type="text"
                  name="option"
                  validate={validateTestAdditionalInformationFieldOptionName}
                />
              </InputGroup>
            </InputSection>
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default Show;
