import { Container } from 'react-bootstrap';
import { FunctionComponent } from 'react';
import info from '../../build';
import styled from 'styled-components';

const Version = styled.span`
  font-size: 0.8rem;
  color: #aaa;
`;

const Footer: FunctionComponent = () => {
  return (
    <footer className="footer mt-auto py-3 text-center">
      <Container fluid={true}>
        <Version>
          v{info.version}({info.build})
        </Version>
      </Container>
    </footer>
  );
};

export default Footer;
