import { NewType, UpdateType } from './removeDates';
import { EmptyTestAdditionalInformationField, TestAdditionalInformationField } from './TestAdditionalInformationField';

export interface TestAdditionalInformationFieldOption {
  id: number;
  option: string;
  field: TestAdditionalInformationField;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export type NewTestAdditionalInformationFieldOption = NewType<TestAdditionalInformationFieldOption, 'option'> & {
  field: number;
};

export type UpdateTestAdditionalInformationFieldOption = UpdateType<TestAdditionalInformationFieldOption, 'option'>;

export const EmptyNewTestAdditionalInformationFieldOption: NewTestAdditionalInformationFieldOption = {
  option: '',
  field: 0,
};

export const EmptyTestAdditionalInformationFieldOption: TestAdditionalInformationFieldOption = {
  id: 0,
  option: '',
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  field: EmptyTestAdditionalInformationField,
};
