import { AddSafeDocError, ErrorLevel } from '../../context/error';

import isGeneralError from './isGeneralError';
import isNetworkError from './isNetworkError';
import isTokenExpired from './isTokenExpired';
import isUnauthorized from './isUnauthorized';
import isValidationError from './isValidationError';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ErrorHandler = (error: any) => null | AddSafeDocError;

const handlers: ErrorHandler[] = [isTokenExpired, isUnauthorized, isValidationError, isGeneralError, isNetworkError];

const handleError = (error: Error) => {
  let preparedError: AddSafeDocError = {
    title: 'Unbekannter Fehler',
    level: ErrorLevel.danger,
    message: error.message,
  };

  handlers.forEach((handler) => {
    const eh = handler(error);
    if (eh !== null) {
      preparedError = eh;
      return;
    }
  });

  return preparedError;
};

export default handleError;
