import { ExamineeType, NewExamineeType } from '../../../interfaces/ExamineeType';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { CommonExamineeSelectProps } from '.';
import { ExamineeTypesService } from '../../../services';
import InputGroup from '../../form/InputGroup';
import TypeaheadInput from '../../form/TypeaheadInput';
import { useAddError } from '../../../context/error';
import { useFormikContext } from 'formik';
import { examineeTypeStore } from '../../../storage';

const ExamineeTypeSelect: FunctionComponent<
  CommonExamineeSelectProps & {
    onValueSelected?: (value?: ExamineeType) => void;
  }
> = ({ variant, name, editing }) => {
  const addError = useAddError();

  const { values, errors, isSubmitting } = useFormikContext<{
    type: number;
    family: number;
  }>();

  const [loading, setLoading] = useState(true);

  const [types, setTypes] = useState<ExamineeType[]>([]);

  const getFamily = useCallback((): number | undefined => {
    if (typeof values.type === 'number') {
      return values.family;
    }
    if (typeof values.type === 'object') {
      return (values as any).type.family.id;
    }
    return undefined;
  }, [values]);

  const loadExamineeTypes = useCallback(async () => {
    const family = getFamily();
    const { items } = await ExamineeTypesService(addError, examineeTypeStore).list(3000, 0, {
      family,
    });
    setTypes(items.sort((a, b) => a.prio - b.prio));
  }, [addError, getFamily]);

  useEffect(() => {
    const loadData = async () => {
      try {
        loadExamineeTypes();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, loadExamineeTypes]);
  return (
    <InputGroup
      error={errors.type ? '' : undefined}
      valid={!errors.type}
      title="Prüflingsart"
      name={name ?? 'type'}
      variant={variant}
      loading={loading}
    >
      <TypeaheadInput
        name={name ?? 'type'}
        options={types.map((is) => ({
          label: is.name,
          value: is.id,
        }))}
        disabled={!editing || isSubmitting || !getFamily()}
        emptyLabel="Prüflingsart auswählen"
        newSelectionPrefix="neue Prüflingsart: "
        onNewOption={(name: string) => {
          const newExamineeType: NewExamineeType = {
            name,
            family: values.family,
          };
          return ExamineeTypesService(addError)
            .create(newExamineeType)
            .then(async (id) => {
              await loadExamineeTypes();
              return id;
            });
        }}
      />
    </InputGroup>
  );
};

export default ExamineeTypeSelect;
