import './tabs_style.scss';

import { Listbox } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { FunctionComponent } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { useWindowSize } from 'react-use';

interface TabsProps {
  tab?: number;
  tabs?: string[];
  onTab?: (tab: number) => void;
}

const TabsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    display: inline-block;
    margin-right: 2rem;
    padding: 0 0 11px 0;
    color: #a1a1a1;
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 19px;
    text-transform: uppercase;
    user-select: none;
    cursor: pointer;
    border-bottom: 1px solid #00000000;
    &.active {
      color: #009842;
      border-bottom: 1px solid #009842;
    }
  }
`;

const Tabs: FunctionComponent<TabsProps> = ({ tab, tabs, onTab }) => {
  const hasTabs = tabs && tabs.length > 0;
  const { width } = useWindowSize();

  if (!hasTabs) return null;

  const onChange = (i: number) => {
    if (onTab) onTab(i);
  };

  // render a Listbox with the tabs for small screens
  if (width < 1200) {
    return (
      <div className="tabs-mobile">
        <Listbox value={tab} onChange={onChange}>
          <Listbox.Button>
            <span className="text">{tab ? tabs[tab] : 'Alle'}</span>
            <span className="icon">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Listbox.Options>
            {tabs.map((t, i) => (
              <Listbox.Option key={i} value={i}>
                {t}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>
      </div>
    );
  }

  return (
    <Row>
      <Col>
        <TabsList>
          {tabs &&
            tabs.map((t, i) => (
              <li key={i} className={i === tab ? 'active' : ''}>
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    if (onTab) onTab(i);
                  }}
                >
                  {t}
                </span>
              </li>
            ))}
        </TabsList>
      </Col>
    </Row>
  );
};

export default Tabs;
