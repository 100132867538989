import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { alert } from '../../components/dialogs/Alert';
import { confirm } from '../../components/dialogs/Confirmation';
import BareInput, { DateTimeInput, NumberInput } from '../../components/form/BareInput';
import { EmptyTest, Test } from '../../interfaces/Test';

import { Formik } from 'formik';
import { get } from 'lodash';
import styled from 'styled-components';
import LocationSelectButton from '../../components/common/LocationSelectButton';
import InstallationSiteSelect from '../../components/common/examinee/InstallationSiteSelect';
import InputGroup from '../../components/form/InputGroup';
import InputSection from '../../components/form/InputSection';
import TextAreaInput from '../../components/form/TextAreaInput';
import ToggleInput from '../../components/form/ToggleInput';
import Title from '../../components/title';
import { useAddError } from '../../context/error';
import { MeasuredValue } from '../../interfaces/MeasuredValue';
import { ValueType } from '../../interfaces/ValueType';
import { TestService } from '../../services';

const Good = styled.p`
  color: rgb(84, 84, 84);
  align-self: auto;
  text-align: left;
  font-size: 0.75rem;
  line-height: 0.75rem;
  margin: auto 0;
  width: 100%;
`;

const Show: FunctionComponent = () => {
  const addError = useAddError();

  const navigate = useNavigate();
  const params = useParams();

  const [item, setItem] = useState<Test>(EmptyTest);

  const loadData = useCallback(async () => {
    if (!params.id) {
      navigate('..');
    }

    const _item = await TestService(addError).get(params.id ?? '');
    if (!_item || !_item.id) {
      navigate('..');
    } else {
      setItem(_item);
    }
  }, [addError, navigate, params.id]);

  useEffect(() => {
    loadData();
  }, [addError, loadData, navigate, params.id]);

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={() => console.log('')}>
      {({ handleSubmit, values }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Title
            to="/tests"
            loading={item.id === 0}
            onPrint={async () => {
              const q = await confirm(
                'PDF(s) erstellen',
                'Der Export wird im Hintergrund erstellt. Sie erhalten nach Fertigstellung eine E-Mail an Ihre hinterlegte E-Mail-Adresse.',
                'Auftrag ausführen',
                'Abbrechen',
              );
              if (!q) return;

              const f = {
                id: values.id,
              };
              const res = await TestService(addError).pdf(f, {});

              if (!res) {
                alert('PDF erstellen', 'Druckauftrag konnte nicht gestartet werden.');
              } else {
                alert(
                  'PDF erstellen',
                  'Der Druckauftrag wurde gestartet. Sie erhalten nach Abschluss eine E-Mail mit dem Download-Link.',
                );
              }
            }}
            title={`Prüfbericht ${item.testTask.name} ${item.testTask.norm ? `nach ${item.testTask.norm}` : ''}`}
          />
          <Row>
            <InputSection title="Prüfung" xs={12} xl={4}>
              <InputGroup title="Prüfer" name="tester.user.name">
                <BareInput disabled={true} type="text" name="tester.user.name" />
              </InputGroup>
              <InputGroup title="Datum" name="created_at">
                <DateTimeInput disabled={true} name="created_at" />
              </InputGroup>
              <InputGroup title="Norm" name="testTask.norm">
                <BareInput disabled={true} type="text" name="testTask.norm" />
              </InputGroup>
              {(values.additionalInformation ?? []).map((ai, i) => (
                <InputGroup
                  key={`additionalInformation.${i}.id`}
                  title={ai.field.name}
                  unit={ai.field.unit}
                  name={`additionalInformation.${i}.value`}
                >
                  {ai.field.valueType === ValueType.STRING && (
                    <BareInput disabled={true} type="text" name={`additionalInformation.${i}.value`} />
                  )}
                  {ai.field.valueType === ValueType.NUMBER && (
                    <BareInput
                      disabled={true}
                      type="number"
                      inputMode="decimal"
                      pattern="[0-9]+([\.,][0-9]+)?"
                      step="0.01"
                      min="0"
                      name={`additionalInformation.${i}.value`}
                    />
                  )}
                  {ai.field.valueType === ValueType.BOOLEAN && (
                    <ToggleInput name={`additionalInformation.${i}.value`} disabled={true} />
                  )}
                </InputGroup>
              ))}
            </InputSection>
            <InputSection xs={12} xl={4}>
              <InputGroup title="Bestanden" name="result">
                <ToggleInput name="result" disabled={true} />
              </InputGroup>
              <InputGroup title="Mängel" name="defects">
                <TextAreaInput disabled={true} name="defects" />
              </InputGroup>
            </InputSection>
            <InputSection xs={12} xl={4}>
              <InputGroup title="Bemerkung" name="comment">
                <TextAreaInput disabled={true} name="comment" />
              </InputGroup>
            </InputSection>
            <InputSection title="Prüfling" xs={12} md={6} xl={4}>
              <InputGroup title="Inventar-Nr." name="examinee.tag">
                <BareInput disabled={true} type="text" name="examinee.tag" />
              </InputGroup>
              <InputGroup title="Geräteart" name="examinee.type.family.name">
                <BareInput disabled={true} type="text" name="examinee.type.family.name" />
              </InputGroup>
              <InputGroup title="Gerätegattung" name="examinee.type.name">
                <BareInput disabled={true} type="text" name="examinee.type.name" />
              </InputGroup>
              <InputGroup title="Hersteller" name="examinee.vendor.name">
                <BareInput disabled={true} type="text" name="examinee.vendor.name" />
              </InputGroup>
              {(values.examinee.additionalInformation ?? []).map((ai, i) => (
                <InputGroup
                  key={`examinee.additionalInformation.${i}.id`}
                  title={ai.field.name}
                  unit={ai.field.unit}
                  name={`examinee.additionalInformation.${i}.value`}
                >
                  <BareInput disabled={true} type="text" name={`examinee.additionalInformation.${i}.value`} />
                </InputGroup>
              ))}
            </InputSection>
            <InputSection title="Standort" xs={12} md={6} xl={4}>
              <InputGroup title="Standort" name="location">
                <LocationSelectButton
                  value={get(values, 'examinee.location.id')}
                  data={get(values, 'examinee.location')}
                />
              </InputGroup>
              <InstallationSiteSelect editing={true} name="examinee.installationSite" />
              <InputGroup title="Bezeichnung" name="examinee.installationSiteDescription">
                <BareInput disabled={true} type="text" name="examinee.installationSiteDescription" />
              </InputGroup>
            </InputSection>
            {values.testingDevice && (
              <InputSection title="Prüfmittel" xs={12} md={6} xl={4}>
                <InputGroup title="Hersteller" name="testingDevice.vendor">
                  <BareInput disabled={true} type="text" name="testingDevice.vendor" />
                </InputGroup>
                <InputGroup title="Prüfmittel" name="testingDevice.name">
                  <BareInput disabled={true} type="text" name="testingDevice.name" />
                </InputGroup>
                <InputGroup title="Seriennummer" name="testingDevice.serial">
                  <BareInput disabled={true} type="text" name="testingDevice.serial" />
                </InputGroup>
              </InputSection>
            )}
            {(values.measureGroups ?? []).map((mg, i) => (
              <InputSection key={i} title={`Prüfgruppe ${mg.name ?? mg.tag}`} xs={12}>
                <Row>
                  {(mg.measuredValues ?? []).map((mv: MeasuredValue, mi: number) => {
                    const isIEA = mv.testValue.name.toLowerCase() === 'iea';
                    return (
                      <Col xs={12} md={6} xl={4}>
                        <InputGroup
                          title={mv.testValue.name}
                          unit={mv.testValue.unit}
                          name={`measureGroups.${i}.measuredValues.${mi}.value`}
                        >
                          {mv.testValue.valueType === ValueType.STRING && (
                            <BareInput
                              disabled={true}
                              type="text"
                              name={`measureGroups.${i}.measuredValues.${mi}.value`}
                            />
                          )}
                          {mv.testValue.valueType === ValueType.OPTION && (
                            <BareInput
                              disabled={true}
                              type="text"
                              name={`measureGroups.${i}.measuredValues.${mi}.value`}
                            />
                          )}
                          {isIEA && mv.value === '-1' && <Good>Good</Good>}
                          {(!isIEA || mv.value !== '-1') &&
                            (mv.testValue.valueType === ValueType.NUMBER ||
                              mv.testValue.valueType === ValueType.FLOAT) && (
                              <NumberInput
                                name={`measureGroups.${i}.measuredValues.${mi}.value`}
                                disabled={true}
                                float={mv.testValue.valueType === ValueType.FLOAT}
                              />
                            )}
                          {mv.testValue.valueType === ValueType.BOOLEAN && (
                            <ToggleInput name={`measureGroups.${i}.measuredValues.${mi}.value`} disabled={true} />
                          )}
                        </InputGroup>
                      </Col>
                    );
                  })}
                </Row>
              </InputSection>
            ))}
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default Show;
