import { FunctionComponent, useCallback } from 'react';
import { TableColProps } from '../../components/table';

import { ApiKey } from '../../interfaces/Apikey';
import { ApiKeyService } from '../../services';
import { useAddError } from '../../context/error';
import { useNavigate } from 'react-router-dom';
import TableWithSearch, { OnDataParams, OnSearchParams } from '../../views/TableWithSearchView';
import { useAuth } from '../../context/auth';
import { get } from 'lodash';

const columns: TableColProps<ApiKey>[] = [
  {
    title: 'Name',
    values: 'name',
    key: 'name',
  },
  {
    title: 'API Key',
    values: 'apikey',
    key: 'apikey',
  },
];

const List: FunctionComponent = () => {
  const navigate = useNavigate();
  const addError = useAddError();
  const auth = useAuth();

  const onNew = useCallback(() => {
    navigate('new');
  }, [navigate]);

  if (!auth || !auth.user) {
    return null;
  }

  return (
    <TableWithSearch
      title="API Keys"
      to="/settings"
      columns={columns}
      defaultLimit={get(auth, 'user.itemsPerPage', 25)}
      onData={function ({ filter, limit, page }: OnDataParams<ApiKey>) {
        return ApiKeyService(addError).list<ApiKey>(limit, page, filter);
      }}
      onSearch={function ({ limit, page, term }: OnSearchParams) {
        return ApiKeyService(addError).search<ApiKey>(term, limit, page);
      }}
      onNew={onNew}
    />
  );
};

export default List;
