import { FunctionComponent, useCallback } from 'react';
import { TableColProps } from '../../components/table';

import { ExamineeFamiliesService, TestTaskService } from '../../services';
import { ExamineeFamily } from '../../interfaces/ExamineeFamily';
import { useAddError } from '../../context/error';
import { useNavigate } from 'react-router-dom';
import TableWithSearch, { OnDataParams, OnSearchParams } from '../../views/TableWithSearchView';
import { useAuth } from '../../context/auth';
import { get } from 'lodash';

const columns: TableColProps<ExamineeFamily>[] = [
  {
    title: 'Name',
    values: 'name',
    key: 'name',
    type: 'string',
    filterable: (addError, limit, page, query) =>
      (query && query.length > 0
        ? ExamineeFamiliesService(addError)
            .search(query)
            .then((res) => res.items)
        : ExamineeFamiliesService(addError)
            .list(limit, page)
            .then((res) => res.items)
      ).then((i) => i.map((v) => ({ value: v.name, title: v.name }))),
  },
  {
    title: 'Prüfarten',
    values: (item) =>
      item.testTasks
        .map((tt) => tt.initials)
        .sort((a, b) => a.localeCompare(b))
        .join(', '),
    key: 'testTasks',
    type: 'number',
    filterable: (addError, limit, page, query) =>
      (query && query.length > 0
        ? TestTaskService(addError)
            .search(query)
            .then((res) => res.items)
        : TestTaskService(addError)
            .list(limit, page)
            .then((res) => res.items)
      ).then((i) => i.map((v) => ({ value: v.id, title: v.initials }))),
  },
];

const List: FunctionComponent = () => {
  const navigate = useNavigate();
  const addError = useAddError();
  const auth = useAuth();

  const onNew = useCallback(() => {
    navigate('new');
  }, [navigate]);

  if (!auth || !auth.user) {
    return null;
  }

  return (
    <TableWithSearch
      title="Prüflingsgattungen"
      to="/settings"
      defaultLimit={get(auth, 'user.itemsPerPage', 25)}
      columns={columns}
      onData={function ({ filter, limit, page }: OnDataParams<ExamineeFamily>) {
        return ExamineeFamiliesService(addError).list<ExamineeFamily>(limit, page, filter);
      }}
      onSearch={function ({ term, limit, page }: OnSearchParams) {
        return ExamineeFamiliesService(addError).search<ExamineeFamily>(term, limit, page);
      }}
      onNew={onNew}
    />
  );
};

export default List;
