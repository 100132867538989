import { AddErrorFunction, AddSafeDocError } from '../context/error';
import { FilterType, Service } from './Service';
import { NewUser, Preferences, PreferenceValue, UpdateUser, User } from '../interfaces/User';

import { API } from '../config';
import { get as _get } from 'lodash';
import axios from 'axios';
import handleError from './errorHandler';
import queryString from 'query-string';
import { OfflineStore, userStore, preferencesStore } from '../storage';
import getToken from './getToken';

export interface ExtendedService<T, UT, NT> extends Service<T, UT, NT> {
  me: () => Promise<T>;
  restore: (id: string, suppress?: boolean) => Promise<boolean | AddSafeDocError>;
  syncPreferences: () => Promise<void>;
  getPreference: <R extends PreferenceValue>(key: string) => Promise<R | undefined>;
  setPreference: (key: string, value: PreferenceValue) => Promise<void>;
  impersonate: (id: number) => Promise<string | undefined>;
}

const UserService = (
  addError: AddErrorFunction,
  offline?: OfflineStore<User>,
  noCache = true,
): ExtendedService<User, UpdateUser, NewUser> => {
  console.log('init user service');

  const cachePolicy = () => {
    if (noCache) {
      return 'no-cache, max-age=0';
    } else {
      return 'max-age=120';
    }
  };

  const list = <ST = User>(limit = 25, page = 0, filter?: FilterType<User>, params?: Record<string, string>) => {
    const query = queryString.stringify({
      limit,
      page,
      filter: Object.keys(filter ?? {}).length > 0 ? JSON.stringify(filter) : undefined,
      ...(params ?? {}),
    });
    return axios
      .get(`${API}/users?${query}`, { headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() } })
      .then((res) => {
        return { items: _get(res, 'data.data.items') as ST[], count: _get(res, 'data.data.count') as number };
      })
      .catch(async (e) => {
        if (e.code === 'ERR_NETWORK' && offline) {
          // try to get from offline store
          const items = await offline.list<ST>(filter);
          return { items, count: -1 };
        }

        addError(handleError(e));
        return { items: [] as ST[], count: 0 };
      });
  };

  const search = <ST = User>(
    q: string,
    limit = 25,
    page = 0,
    filter?: FilterType<User>,
    params?: Record<string, string>,
  ) => {
    const query = queryString.stringify({
      q,
      limit,
      page,
      filter: Object.keys(filter ?? {}).length > 0 ? JSON.stringify(filter) : undefined,
      ...(params ?? {}),
    });
    return axios
      .get(`${API}/users?${query}`, { headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() } })
      .then((res) => {
        return { items: _get(res, 'data.data.items') as ST[], count: _get(res, 'data.data.count') as number };
      })
      .catch((e) => {
        addError(handleError(e));
        return { items: [] as ST[], count: 0 };
      });
  };

  const get = <ST = User>(id: string) => {
    return axios
      .get(`${API}/users/${id}`, {
        headers: { Authorization: getToken(addError, id === 'me'), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        return _get(res, 'data.data') as ST;
      })
      .catch((e) => {
        // If we are offline, try to get the tour from the cache
        if (e.code === 'ERR_NETWORK') {
          if (!offline) return Promise.reject();
          return userStore.getItem(id).then((data) => {
            if (data) return Promise.resolve(data as ST);
            return Promise.resolve({} as ST);
          });
        }

        addError(handleError(e));
        return {} as ST;
      });
  };

  const destroy = (id: string, suppress = false) => {
    return axios
      .post(
        `${API}/users/${id}/lock`,
        { locked: true },
        { headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() } },
      )
      .then((res) => {
        return true;
      })
      .catch((e) => {
        const handledError = handleError(e);
        if (suppress) {
          return handledError;
        } else {
          addError(handledError);
          return false;
        }
      });
  };

  const restore = (id: string, suppress = false) => {
    return axios
      .post(
        `${API}/users/${id}/lock`,
        { locked: false },
        { headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() } },
      )
      .then((res) => {
        return true;
      })
      .catch((e) => {
        const handledError = handleError(e);
        if (suppress) {
          return handledError;
        } else {
          addError(handledError);
          return false;
        }
      });
  };

  const create = (data: Omit<NewUser, 'id' | 'created_at' | 'updated_at' | 'deleted_at'>, suppress = false) => {
    return axios
      .post(`${API}/auth/register`, data, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        return _get(res, 'data.data.id') as number;
      })
      .catch((e) => {
        const handledError = handleError(e);
        if (suppress) {
          return handledError;
        } else {
          addError(handledError);
          return -1;
        }
      });
  };

  const update = (
    id: string,
    data: Partial<Omit<User, 'id' | 'created_at' | 'updated_at' | 'deleted_at'>>,
    suppress = false,
  ) => {
    return axios
      .put(`${API}/users/${id}`, data, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then(() => {
        return true;
      })
      .catch((e) => {
        const handledError = handleError(e);
        if (suppress) {
          return handledError;
        } else {
          addError(handledError);
          return false;
        }
      });
  };

  const me = (): Promise<User> => get('me');

  const syncPreferences = async (): Promise<void> => {
    if (!getToken(addError)) return Promise.resolve();
    const local = await preferencesStore.keyed(preferencesStore.instance);
    const remote = await updatePreferences(local);
    if (remote) {
      await Promise.all(
        Object.keys(remote).map(async (k) => {
          await preferencesStore.setItem(k, remote[k]);
        }),
      );
      await preferencesStore.setItem('timestamp', new Date().toISOString());
    }
  };

  const updatePreferences = (p: Preferences): Promise<Preferences | undefined> => {
    return axios
      .put(`${API}/users/me/preferences`, p, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        return res.data as Preferences;
      })
      .catch((e) => {
        if (e.code === 'ERR_NETWORK') {
          return Promise.resolve(undefined);
        }
        addError(handleError(e));
        return Promise.resolve(undefined);
      });
  };

  const getPreference = <R extends PreferenceValue>(key: string): Promise<R | undefined> => {
    return preferencesStore.getItem(key).then((v) => {
      if (v) return Promise.resolve(v as R);
      return Promise.resolve(undefined);
    });
  };

  const setPreference = async (key: string, value: PreferenceValue): Promise<void> => {
    await preferencesStore.setItem(key, value);
    await preferencesStore.setItem('timestamp', new Date().toISOString());
    syncPreferences();
  };

  const impersonate = (id: number): Promise<string | undefined> => {
    return axios
      .post(
        `${API}/auth/impersonate`,
        { id },
        { headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() } },
      )
      .then((res) => {
        return _get(res, 'data.data', undefined);
      })
      .catch((e) => {
        addError(handleError(e));
        return Promise.resolve(undefined);
      });
  };

  return {
    me,
    list,
    get,
    impersonate,
    update,
    destroy,
    restore,
    create,
    search,
    getPreference,
    syncPreferences,
    setPreference,
  };
};

export default UserService;
