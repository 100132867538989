import { AddErrorFunction, useAddError } from '../../context/error';
import React, { useEffect, useState } from 'react';
import { get, reduce } from 'lodash';
import { ReactComponent as FilterIcon } from './Filter.svg';
import { Table as BSTable } from 'react-bootstrap';
import { Leaves } from '../../interfaces/keyof-of-a-nested-object';
import LoadingIndicator from '../common/LoadingIndicator';
import { ReactElement } from 'react';
import { filter } from '../Filter';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import PagesCounter from './PagesCounter';
import PaginationButton, { PaginationButtonDirection } from './PaginationButton';
import PerPageSelector from './PerPageSelector';
import { dateFilter } from '../Filter/date';

type ColWidth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto';

export type GetValueFunction<T> = (item: T, key: keyof Leaves<T, 2> | keyof T | TableColPropsKeyFunction<T>) => any;

export const MinP = styled.div`
  padding: 0;
  margin: 0;
  min-height: 36px;
  max-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Row = styled.tr`
  padding: 16px 0;
  border-radius: 3px;
  background: #fff;
  user-select: none;
  cursor: pointer;
  &:hover {
    background: #eee;
  }
`;

export const Col = styled.td<{ width?: ColWidth }>`
  min-width: 40px;
  min-height: 100px;
  padding: 16px 16px 16px 16px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 9px 0;
  color: #000;
  flex: 0 0 ${(props) => (!props.width || props.width === 'auto' ? 'auto' : (100 / 12) * props.width)};
  a {
    color: #000;
    text-decoration: none;
    background-color: initial;
    outline: none;
    user-select: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ColH = styled.th<{
  active?: boolean;
  width?: ColWidth;
  filterable?: boolean;
}>`
  min-width: 40px;
  max-height: 44px;
  padding: 16px 16px 0px 16px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 9px 0;
  border: 0 !important;
  color: ${(props) => (props.active ? 'var(--bs-primary)' : '#a1a1a1')};
  cursor: ${(props) => (props.filterable ? 'pointer' : 'auto')};
  flex: 0 0 ${(props) => (!props.width || props.width === 'auto' ? 'auto' : (100 / 12) * props.width)};
  user-select: ${(props) => (props.filterable ? 'auto' : 'none')};
  svg {
    display: ${(props) => (props.filterable ? 'inline' : 'none')};
    width: 12px;
    margin: 0 4px;
    color: ${(props) => (props.active ? 'var(--bs-primary)' : '#a1a1a1')};
    fill: ${(props) => (props.active ? 'var(--bs-primary)' : '#a1a1a1')};
    stroke: ${(props) => (props.active ? 'var(--bs-primary)' : '#a1a1a1')};
  }
`;

const THead = styled.thead`
  font-size: 11px !important;
  letter-spacing: 0;
  line-height: 22px;
  text-transform: uppercase;
  font-weight: 700;
`;

const TFoot = styled.tfoot`
  font-size: 11px !important;
  letter-spacing: 0;
  line-height: 22px;
  text-transform: uppercase;
  font-weight: 700;
`;

const StyledTable = styled(BSTable)`
  border-collapse: separate;
  border-spacing: 0 15px;
  font-size: 12px;
`;

const TFootContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type TableColPropsKeyFunction<T> = (item: T) => string | React.ReactNode;

export type TableColProps<T> = BaseTableColProps<T> | FilterableTableColProps<T>;

interface BaseTableColProps<T> {
  values: Leaves<T, 4> | TableColPropsKeyFunction<T> | 'custom';
  title: string;
  width?: ColWidth;
  showActive?: boolean;
}

export type FilterableTableColProps<T> =
  | FilterableTableStringColProps<T>
  | FilterableTableNumberColProps<T>
  | FilterableTableBooleanColProps<T>
  | FilterableTableDateColProps<T>;

export type CallableFilterableTableColProps<T> = FilterableTableStringColProps<T> | FilterableTableNumberColProps<T>;

type CallableFilterable<R> = (
  addError: AddErrorFunction,
  limit: number,
  skip: number,
  query: string,
) => Promise<{ value: R | undefined; title: string }[]>;

type FilterableTableStringColProps<T> = BaseTableColProps<T> & {
  key: string;
  filterKey?: string;
  type?: 'string' | undefined;
  filterable: CallableFilterable<string>;
};

type FilterableTableNumberColProps<T> = BaseTableColProps<T> & {
  key: string;
  filterKey?: string;
  type: 'number';
  filterable: CallableFilterable<number>;
};

type FilterableTableBooleanColProps<T> = BaseTableColProps<T> & {
  key: string;
  filterKey?: string;
  type: 'boolean';
  filterable: true;
};

export type FilterableTableDateColProps<T> = BaseTableColProps<T> & {
  key: string;
  filterKey?: string;
  type: 'date';
  filterable: true;
};

export type ItemWithId<T> = T & { id: number };

export interface TableProps<T> {
  loading?: boolean;
  name?: keyof T;
  items: Array<T>;
  columns?: TableColProps<T>[];
  navigatePrefix?: string;
  onFilterChanged?: (columnFilters: Record<string, (number | string | boolean | [Date, Date])[]>) => void;
  onPagination?: (page: number, size: number) => void;
  pages?: number;
  defaultPage?: number;
  defaultLimit?: number;
  columnFilters?: Record<string, (number | string | boolean | [Date, Date] | undefined)[]>;
  onClick?: (item: T) => void;
  renderItem?: (
    index: number,
    item: ItemWithId<T>,
    col: TableColProps<T>,
    getValue: GetValueFunction<T>,
  ) => JSX.Element;
}

function defaultRenderer<T>(
  index: number,
  item: ItemWithId<T>,
  col: TableColProps<T>,
  getValue: GetValueFunction<T>,
): JSX.Element {
  return (
    <Col key={index} width={col.width}>
      <MinP>{getValue(item, col.values as any)}</MinP>
    </Col>
  );
}

const F = styled.div`
  display: flex;
`;

function Table<T extends { id: number }>({
  columns,
  items,
  name,
  loading,
  navigatePrefix,
  onFilterChanged,
  columnFilters,
  onClick,
  onPagination,
  pages,
  defaultPage,
  defaultLimit,
  renderItem = defaultRenderer,
}: TableProps<T>): ReactElement<TableProps<T>> {
  const navigate = useNavigate();
  const addError = useAddError();

  const itemsToRender: T[] = items && Array.isArray(items) && items.length > 0 ? items : [];

  const [page, _setPage] = useState(defaultPage ?? 0);
  const [limit, _setLimit] = useState(defaultLimit ?? 25);

  useEffect(() => {
    _setPage(defaultPage ?? 0);
  }, [defaultPage]);

  useEffect(() => {
    _setLimit(defaultLimit ?? 25);
  }, [defaultLimit]);

  const setPage = (page: number) => {
    _setPage(page);
    console.log('setPage');
    onPagination && onPagination(page, limit);
  };

  const setLimit = (limit: number) => {
    _setLimit(limit);
    _setPage(0);
    console.log('setLimit');
    onPagination && onPagination(0, limit);
  };

  const hasMore = (pages ?? 1) > page + 1;
  const pagination = !!pages && onPagination;

  const [activeCols, setActiveCols] = useState<string[]>([]);

  const getKey = (item: T) => {
    return getValue(item, name ?? 'id');
  };

  const getValueFromFunction = (item: T, func: TableColPropsKeyFunction<T>) => {
    return func(item);
  };

  const getValueFromKey = (item: T, key: keyof Leaves<T, 2> | keyof T) => {
    return get(item, key);
  };

  const getValue: GetValueFunction<T> = (item: T, key: keyof Leaves<T, 2> | keyof T | TableColPropsKeyFunction<T>) => {
    if (typeof key === 'function') {
      return getValueFromFunction(item, key);
    } else {
      return getValueFromKey(item, key);
    }
  };

  useEffect(() => {
    setActiveCols([
      ...Object.keys(columnFilters ?? {}),
      ...(columns
        ?.filter((c: any) => c.showActive === true && (c.key || c.filterKey))
        .map((c: any) => (c.filterKey ? (c.filterKey as string) : (c.key as string))) ?? []),
    ]);
  }, [columnFilters, columns]);

  const openFilter = async (params: FilterableTableColProps<T>) => {
    const { filterable, filterKey } = params;

    if (!filterable && !filterKey) {
      return;
    }

    if (params.type === 'date') {
      return openDateFilter(params);
    }

    if (params.type === 'boolean') {
      return openBooleanFilter(params);
    }

    return openListFilter(params);
  };

  const openListFilter = async ({ filterable, title, key, filterKey }: CallableFilterableTableColProps<T>) => {
    const preSelected = ((columnFilters ?? {})[key] ?? []).filter((v) => v !== undefined) as (
      | string
      | number
      | boolean
    )[];
    const usedKey = filterKey ?? key;
    const f = await filter(title, preSelected, (limit, page, query) => filterable(addError, limit, page, query));
    if (f) {
      const filterItems = JSON.parse(f);
      const _n = reduce<Record<string, (number | string | boolean)[]>, Record<string, (number | string | boolean)[]>>(
        {
          ...(columnFilters ?? {}),
          [usedKey]: filterItems,
        },
        (prev, curr, key) => {
          if (curr && curr.length > 0) {
            prev[key] = curr;
          }
          return prev;
        },
        {},
      );
      onFilterChanged && onFilterChanged(_n);
    }
  };

  const openBooleanFilter = async ({ title, key, filterKey }: FilterableTableBooleanColProps<T>) => {
    const preSelected = ((columnFilters ?? {})[key] ?? []).filter((v) => v !== undefined) as (
      | string
      | number
      | boolean
    )[];
    const filterable = [
      { value: true, title: 'Ja' },
      { value: false, title: 'Nein' },
      { value: null, title: 'Kein Wert' },
    ];
    const usedKey = filterKey ?? key;
    const f = await filter(title, preSelected, () => Promise.resolve(filterable));
    if (f) {
      const filterItems = JSON.parse(f);
      const _n = reduce<Record<string, (number | string | boolean)[]>, Record<string, (number | string | boolean)[]>>(
        {
          ...(columnFilters ?? {}),
          [usedKey]: filterItems,
        },
        (prev, curr, key) => {
          if (curr && curr.length > 0) {
            prev[key] = curr;
          }
          return prev;
        },
        {},
      );
      onFilterChanged && onFilterChanged(_n);
    }
  };

  const openDateFilter = async ({ title, key, filterKey }: FilterableTableDateColProps<T>) => {
    const usedKey = filterKey ?? key;

    const preSelected = ((columnFilters ?? {})[key] ?? []).filter((v) => v !== undefined) as unknown as [Date, Date];
    const f = await dateFilter(title, preSelected);
    if (f) {
      const filterItems = JSON.parse(f);
      const _n = reduce<Record<string, (number | string | boolean)[]>, Record<string, (number | string | boolean)[]>>(
        {
          ...(columnFilters ?? {}),
          [usedKey]: filterItems,
        },
        (prev, curr, key) => {
          if (curr && curr.length > 0) {
            prev[key] = curr;
          }
          return prev;
        },
        {},
      );
      onFilterChanged && onFilterChanged(_n);
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <StyledTable responsive>
      <THead>
        <tr>
          {(columns ?? []).map((col) => (
            <ColH
              key={col.values.toString()}
              filterable={!!(col as any).filterable || !!(col as any).filterKey}
              active={activeCols.indexOf((col as any).key) > -1 || activeCols.indexOf((col as any).filterKey) > -1}
              width={col.width}
              onClick={() => openFilter(col as FilterableTableColProps<T>)}
            >
              <span>{col.title}</span>
              <FilterIcon />
            </ColH>
          ))}
        </tr>
      </THead>
      {pagination && (
        <TFoot>
          <tr>
            <td colSpan={9999}>
              <TFootContent>
                <div>
                  <PaginationButton
                    direction={PaginationButtonDirection.backwards}
                    disabled={page <= 0}
                    onClick={() => setPage(page - 1)}
                  />
                </div>
                <div>
                  <PagesCounter count={pages ?? 1} pageIndex={page} />
                </div>
                <F>
                  <PerPageSelector
                    pageSize={limit}
                    setPageSize={(value) => {
                      setLimit(value);
                    }}
                  />
                  <PaginationButton
                    direction={PaginationButtonDirection.forwards}
                    disabled={!hasMore}
                    onClick={() => setPage(page + 1)}
                  />
                </F>
              </TFootContent>
            </td>
          </tr>
        </TFoot>
      )}
      <tbody>
        {itemsToRender.map((item) => (
          <Row
            key={getKey(item)}
            onClick={() => {
              if (onClick) {
                onClick(item);
              } else if (item.id && typeof item.id === 'number') {
                navigate(`${navigatePrefix ?? ''}${item.id.toFixed(0)}`);
              } else if (item.id && typeof item.id === 'string') {
                navigate(`${navigatePrefix ?? ''}${item.id}`);
              }
            }}
          >
            {(columns ?? []).map((col, i) => renderItem(i, item, col, getValue))}
          </Row>
        ))}
      </tbody>
    </StyledTable>
  );
}

export default Table;
