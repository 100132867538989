import { Button as BButton, Modal } from 'react-bootstrap';
import { FunctionComponent, useCallback, useState } from 'react';

import { ReactComponent as ArrowIcon } from './arrow.svg';
import { ButtonWithIcon } from '../buttons/ButtonWithIcon';
import LoadingIndicator from '../common/LoadingIndicator';
import { Tester } from '../../interfaces/Tester';
import { TesterService } from '../../services';
import { debounce } from 'lodash';
import styled from 'styled-components';
import { useAddError } from '../../context/error';

const SearchInput = styled.input`
  background-color: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255);
  color: rgb(84, 84, 84);
  padding: 0.75rem;
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  text-align: left;
  text-decoration: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  width: 100%;
  border: 0px;
  -webkit-appearance: none;
  outline-color: rgba(0, 0, 0, 0);
  margin-bottom: 2rem;
`;

const P = styled.p<{ small: boolean }>`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  color: ${(props) => (props.small ? '#b3b3b3' : '#000')};
`;

const Footer = styled(Modal.Footer)`
  justify-content: space-between;
`;

// const ButtonWithIcon = styled(BButton)`
//   float: left;
//   color: #a1a1a1;
//   opacity: 1;
//   svg {
//     width: 14px;
//     height: 14px;
//     display: inline-block;
//     margin: 11px 0 11px 11px;
//     &,
//     path,
//     g {
//       fill: #a1a1a1;
//       color: #a1a1a1;
//     }
//   }
//   span {
//     display: inline-block;
//     vertical-align: bottom;
//     margin-bottom: 1px;
//   }
//   &:disabled,
//   &.disabled {
//     cursor: not-allowed;
//     opacity: 0.5;
//     color: #a1a1a1 !important;
//   }
//   &:hover {
//     color: var(--bs-primary);
//     opacity: 0.8;
//     &,
//     path,
//     g {
//       fill: var(--bs-primary);
//       color: var(--bs-primary);
//     }
//   }
// `;

const TesterButton = styled(BButton)`
  display: block;
  width: 100%;
  height: 3.125rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 9px;
  &.active,
  &:active,
  &:hover {
    p {
      color: #fff;
    }
  }
`;

const BGModalBody = styled(Modal.Body)`
  background: #f2f2f2;
  padding: 32px;
  max-height: 500px;
  min-height: 286px;
  overflow-y: scroll;
`;

const Items = styled.div`
  display: block;
  min-height: 286px;
`;

const SelectTesterButton: FunctionComponent<{
  editing: boolean;
  testers: Tester[];
  onSelectTesters?: (testers: Tester[] | undefined) => void;
}> = ({ onSelectTesters, editing, testers }) => {
  const addError = useAddError();

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [possibleTesters, setPossibleTesters] = useState<Tester[]>([]);
  const [selectedTesters, selectTesters] = useState<Tester[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [query, setQuery] = useState<string>();

  const loadTesters = useCallback(async () => {
    try {
      setLoading(true);
      let { items } = await TesterService(addError).list(50);

      items = items.sort((a, b) => {
        if (selectedTesters.some((t) => t.id === a.id)) {
          return -2;
        }
        if (selectedTesters.some((t) => t.id === b.id)) {
          return 2;
        }
        return a.user.name.localeCompare(b.user.name);
      });

      setPossibleTesters(items);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [addError, selectedTesters]);

  const openModal = () => {
    setPossibleTesters([]);
    setQuery('');
    selectTesters(testers);
    loadTesters();
    setModalShow(true);
  };

  const search = debounce(async (_value: string) => {
    try {
      setLoading(true);
      const { items } = await TesterService(addError).search(_value);
      setPossibleTesters(items);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, 300);

  const _handleInputChange = (query: string) => {
    setQuery(query);
    search(query);
  };

  if (!editing && testers.length > 0) {
    return (
      <ButtonWithIcon variant="link" disabled>
        <span>
          <P small={false}>
            {testers
              .map((t) => `${t.user.name} ${t.user.deleted_at ? ' (Gesperrt)' : ''}`)
              .sort((a, b) => a.localeCompare(b))
              .join(', ')}
          </P>
          {/* <P small={true}>{testers.length} Prüfer ausgewählt</P> */}
        </span>
        <ArrowIcon />
      </ButtonWithIcon>
    );
  }

  if (!editing && (!testers || testers.length === 0)) {
    return (
      <ButtonWithIcon disabled variant="link">
        <span>
          <P small={false}>Kein Prüfer</P>
          <P small={true}>Wählen Sie im Bearbeitungsmodus ein Prüfer für diesen Testgerät aus.</P>
        </span>
        <ArrowIcon />
      </ButtonWithIcon>
    );
  }

  return (
    <>
      <ButtonWithIcon variant="link" onClick={() => openModal()}>
        <span>
          <P small={false}>
            {testers
              .map((t) => t.user?.name)
              .sort((a, b) => a.localeCompare(b))
              .join(', ') ?? 'Prüfer auswählen'}
          </P>
          <P small={true}>Wählen Sie einen oder mehrere Prüfer für dieses Testgerät aus.</P>
        </span>
        <ArrowIcon />
      </ButtonWithIcon>
      <Modal aria-labelledby="contained-modal-title-vcenter" centered show={modalShow}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Prüfer auswählen</Modal.Title>
        </Modal.Header>
        <BGModalBody>
          <SearchInput placeholder="Prüfer suchen..." onChange={(event) => _handleInputChange(event.target.value)} />
          {loading && <LoadingIndicator />}
          {!loading && (
            <Items>
              {possibleTesters.slice(0, 5).map((tester) => {
                const active = selectedTesters.some((t) => t.id === tester.id);
                return (
                  <TesterButton
                    key={tester.id}
                    active={selectedTesters.some((t) => t.id === tester.id)}
                    onClick={() => {
                      if (active) {
                        selectTesters(selectedTesters.filter((t) => t.id !== tester.id));
                      } else {
                        selectTesters([...selectedTesters, tester]);
                      }
                    }}
                  >
                    <P small={false}> {tester.user.name}</P>
                    <P small={true}>{tester.initials}</P>
                  </TesterButton>
                );
              })}
            </Items>
          )}
        </BGModalBody>
        <Footer>
          <BButton
            variant="danger"
            disabled={loading}
            onClick={() => {
              onSelectTesters && onSelectTesters([]);
              setModalShow(false);
            }}
          >
            Kein Tester
          </BButton>
          <div>
            <BButton variant="light" onClick={() => setModalShow(false)}>
              Abbrechen
            </BButton>
            <BButton
              disabled={loading || selectedTesters.length === 0}
              variant="primary"
              onClick={() => {
                selectedTesters.length > 0 && onSelectTesters && onSelectTesters(selectedTesters);
                setModalShow(false);
              }}
            >
              Auswählen
            </BButton>{' '}
          </div>
        </Footer>
      </Modal>
    </>
  );
};

export default SelectTesterButton;
