import { AddErrorFunction } from '../context/error';
import { FilterType, Service } from './Service';

import axios from 'axios';
import { get as _get } from 'lodash';
import queryString from 'query-string';
import { API } from '../config';
import { Tour } from '../interfaces/Tour';
import { daysStore } from '../storage';
import handleError from './errorHandler';
import getToken from './getToken';
import { DateTime } from 'luxon';
import { TourStop } from '../interfaces/TourStop';

export type ReadOnlyService<T> = Pick<Service<T, never, never>, 'list' | 'get'>;
export type DateTourStopsMap = {
  [key: string]: TourStop[];
};

type TourServiceType = ReadOnlyService<Tour> & {
  atDate: (date: Date | string) => Promise<Tour | undefined>;
  atDateRange: (from: Date | string, to: Date | string) => Promise<DateTourStopsMap | undefined>;
};

const TourService = (addError: AddErrorFunction, offline = true, noCache = false): TourServiceType => {
  const cachePolicy = () => {
    if (noCache) {
      return 'no-cache, max-age=0';
    } else {
      return 'max-age=120';
    }
  };

  const list = <ST = Tour>(limit = 25, page = 0, filter?: FilterType<Tour>) => {
    const query = queryString.stringify({ limit, page });
    return axios
      .get(`${API}/tours?${query}`, { headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() } })
      .then((res) => {
        return { items: _get(res, 'data.data.items') as ST[], count: _get(res, 'data.data.count') as number };
      })
      .catch((e) => {
        addError(handleError(e));
        return { items: [] as ST[], count: 0 };
      });
  };

  const get = <ST = Tour>(id: string) => {
    return axios
      .get(`${API}/tours/${id}`, { headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() } })
      .then((res) => {
        return _get(res, 'data.data') as ST;
      })
      .catch((e) => {
        addError(handleError(e));
        return {} as ST;
      });
  };

  const atDate = (date: Date | string): Promise<Tour | undefined> => {
    const d = typeof date === 'string' ? date : date.toISOString();
    return axios
      .get(`${API}/tours/atDate?date=${d}`, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        return _get(res, 'data.data') as Tour;
      })
      .catch((e) => {
        // If we are offline, try to get the tour from the cache
        if (e.code === 'ERR_NETWORK') {
          if (!offline) return Promise.resolve(undefined);
          return daysStore.getItem(d).then((tour) => {
            if (tour) return Promise.resolve(tour as Tour);
            return Promise.resolve(undefined);
          });
        }

        const code = _get(e, 'response.status');
        if (code === 404) {
          return undefined;
        } else {
          addError(handleError(e));
          return {} as Tour;
        }
      });
  };

  const atDateRange = (from: Date | string, to: Date | string): Promise<DateTourStopsMap | undefined> => {
    const f = (typeof from === 'string' ? DateTime.fromISO(from) : DateTime.fromJSDate(from)).toFormat('yyyy-MM-dd');
    const t = (typeof to === 'string' ? DateTime.fromISO(to) : DateTime.fromJSDate(to)).toFormat('yyyy-MM-dd');
    const d = `${f}_${t}`;
    return axios
      .get(`${API}/tours/atDateRange?from=${f}&to=${t}`, {
        headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
      })
      .then((res) => {
        return _get(res, 'data.data') as DateTourStopsMap;
      })
      .catch((e) => {
        // If we are offline, try to get the tour from the cache
        if (e.code === 'ERR_NETWORK') {
          if (!offline) return Promise.resolve(undefined);
          return daysStore.getItem(d).then((tours) => {
            if (tours) return Promise.resolve(tours as DateTourStopsMap);
            return Promise.resolve(undefined);
          });
        }

        const code = _get(e, 'response.status');
        if (code === 404) {
          return undefined;
        } else {
          addError(handleError(e));
          return {} as DateTourStopsMap;
        }
      });
  };

  return { atDateRange, atDate, list, get };
};

export default TourService;
