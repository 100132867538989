import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface PagesCounterProps {
  pageIndex: number;
  count: number;
}

const Semibold = styled.span`
  font-weight: semibold;
`;

const P = styled.p`
  margin: 0;
`;

const PagesCounter: FunctionComponent<PagesCounterProps> = ({ pageIndex, count }) => {
  if (count < 2) return <></>;
  return (
    <P>
      Seite <Semibold>{pageIndex + 1}</Semibold> von <Semibold>{count}</Semibold>
    </P>
  );
};

export default PagesCounter;
