import React, { FunctionComponent, useEffect, useState } from 'react';
import { useMain } from '../../context/main';
import OfflineService from '../../services/OfflineService';
import { alert } from '../dialogs/Alert';
import { useNetworkStatus } from 'react-network-status';
import { NETWORK_CHECK_CONFIG } from '../../config';

const WatchLocationOffline: FunctionComponent = () => {
  const { selectedLocation, selectLocation } = useMain();

  const [networkStatus, setNetworkStatus] = useState(true);

  useNetworkStatus((networkStatusUpdate) => {
    setNetworkStatus(networkStatusUpdate);
  }, NETWORK_CHECK_CONFIG);

  useEffect(() => {
    const check = async () => {
      const location = selectedLocation![0];
      const ol = await OfflineService().localLocation(location.value);
      if (!ol) {
        alert(
          'Standort nicht verfügbar',
          'Der ausgewählte Standort ist offline nicht verfügbar. Bitte wählen Sie einen anderen Standort aus der Tour.',
        );
        selectLocation(undefined);
      }
    };
    if (!networkStatus && selectedLocation && selectedLocation.length > 0) check();
  }, [selectLocation, networkStatus, selectedLocation]);

  return <></>;
};

export default WatchLocationOffline;
