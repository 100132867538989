import BareInput, { NumberInput } from '../../components/form/BareInput';
import { ToggleInputBooleanValueUndefined } from '../../components/form/ToggleInput';

import { FormikErrors } from 'formik';
import { get } from 'lodash';
import { Col } from 'react-bootstrap';
import InputGroup from '../../components/form/InputGroup';
import ToggleInput from '../../components/form/ToggleInput';
import { TestAdditionalInformationField } from '../../interfaces/TestAdditionalInformationField';
import { ValueType } from '../../interfaces/ValueType';

interface AdditionalInformationProps<T> {
  errors: FormikErrors<T>;
  name: string;
  isSubmitting: boolean;
  f: TestAdditionalInformationField;
}

function AdditionalInformation<T>({ isSubmitting, name, errors, f }: AdditionalInformationProps<T>) {
  const error = get(errors, name);
  return (
    <Col key={name} xs={12} md={6} lg={4} xl={3}>
      <InputGroup error={error} valid={!error} title={f.name} name={name} unit={f.unit}>
        {f.valueType === ValueType.STRING && (
          <BareInput
            validate={(value: any) => {
              return value === undefined || value === null || value.toString().trim() === '';
            }}
            disabled={isSubmitting}
            type="text"
            name={name}
          />
        )}
        {(f.valueType === ValueType.NUMBER || f.valueType === ValueType.FLOAT) && (
          <NumberInput name={name} disabled={isSubmitting} float={f.valueType === ValueType.FLOAT} />
        )}
        {f.valueType === ValueType.BOOLEAN && (
          <ToggleInput
            validate={(value: any) => value === ToggleInputBooleanValueUndefined}
            name={name}
            disabled={isSubmitting}
          />
        )}
      </InputGroup>
    </Col>
  );
}
export default AdditionalInformation;
