import { EmptyTestingDevice, TestingDevice } from '../../interfaces/TestingDevice';
import { ErrorLevel, useAddError, useClearError } from '../../context/error';
import { Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';
import { get, reduce } from 'lodash';

import BareInput from '../../components/form/BareInput';
import FormikDebug from '../../components/dev/formik-debug';
import InputGroup from '../../components/form/InputGroup';
import InputSection from '../../components/form/InputSection';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { Row } from 'react-bootstrap';
import SelectTesterButton from '../../components/SelectTesterButton';
import { TestingDeviceService } from '../../services';
import Title from '../../components/title';
import { confirm } from '../../components/dialogs/Confirmation';
import qs from 'query-string';
import validateMinLength from '../../helpers/validate/validateMinLength';
import TestingDeviceDefaultTestTasksButton from '../../components/TestingDeviceDefaultTestTasks';
import { TestTask } from '../../interfaces/TestTask';

const getChangedValues = (
  values: Omit<TestingDevice, 'defaultTestingDeviceUsage'>,
  initialValues: Omit<TestingDevice, 'defaultTestingDeviceUsage'>,
  ignoredKeys: string[] = [],
): Partial<A> => {
  return reduce(
    Object.entries(values),
    (acc, [key, value]) => {
      if (ignoredKeys.includes(key)) return acc;
      const hasChanged = (initialValues as any)[key] !== value;
      if (hasChanged) {
        (acc as any)[key] = value;
      }
      return acc;
    },
    {},
  );
};

const shouldEditing = (location: Location) => {
  const queryParams = qs.parse(location.search, {});
  return queryParams.edit === '1' || queryParams.edit === 'true';
};

function replaceObjectKeyWithID<T>(obj: T, keys: (keyof T)[]) {
  return reduce(
    keys,
    (acc, key) => {
      const data = acc[key] as any;

      if (!data) {
        return {
          ...acc,
          [key]: undefined,
        };
      }

      if (Array.isArray(data)) {
        return {
          ...acc,
          [key]: data.map((d) => d.id),
        };
      }

      return {
        ...acc,
        [key]: data.id,
      };
    },
    obj,
  );
}

type A = Omit<TestingDevice, 'defaultTestingDeviceUsage'> & {
  defaultTestTasksChanged: boolean;
};

const Show: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(shouldEditing(location));

  const [item, setItem] = useState<A>({ ...EmptyTestingDevice, defaultTestTasks: [], defaultTestTasksChanged: false });

  const loadData = useCallback(
    async (id: string) => {
      try {
        if (!id) {
          return;
        }
        const _item = await TestingDeviceService(addError).get(id);
        if (!_item || !_item.id) {
          navigate('..');
        }
        setItem({
          ..._item,
          // defaultTestTasks: _item.defaultTestingDeviceUsage.map((t) => t.testTask),
          defaultTestTasksChanged: false,
        });
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [addError, navigate],
  );

  useEffect(() => {
    if (params.id) loadData(params.id);
  }, [loadData, params.id]);

  const onSubmit = async (values: A, { setSubmitting }: FormikHelpers<A>) => {
    setSubmitting(true);
    try {
      if (params.id) {
        clearError();
        const data: any = getChangedValues(
          replaceObjectKeyWithID(values, ['testers', 'defaultTestTasks']),
          replaceObjectKeyWithID(item, ['testers', 'defaultTestTasks']),
          ['defaultTestingDeviceUsage', 'defaultTestTasksChanged'],
        );

        // if (values.defaultTestTasksChanged) {
        //   data.defaultTestTasks = values.defaultTestTasks;
        // }

        await TestingDeviceService(addError).update(params.id, data);
        setEditing(false);
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onDelete = async (setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true);
    try {
      if (params.id) {
        if (
          await confirm(
            'Prüfmittel löschen?',
            'Das Prüfmittel wird entgültig gelöscht. Sind Sie sich sicher?',
            'Löschen',
            'Abbruch',
            {
              okColor: 'danger',
            },
          )
        ) {
          await TestingDeviceService(addError).destroy(params.id);
          navigate('..');
        }
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Formik<A> enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
      {({
        handleSubmit,
        isValidating,
        setFieldValue,
        isValid,
        errors,
        values,
        resetForm,
        isSubmitting,
        setSubmitting,
      }) => (
        <>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Title
              onEdit={() => setEditing(true)}
              onDelete={() => onDelete(setSubmitting)}
              onCancel={() => {
                resetForm({
                  values: item,
                });
                setEditing(false);
              }}
              editing={editing}
              editingValid={!isValidating && isValid}
              isSubmitting={isSubmitting}
              to="/settings/testing-devices"
              title={`${item.vendor} - ${item.name}`}
            />
            <Row>
              <InputSection title="Stammdaten" xs={12} xl={6}>
                <InputGroup
                  error={errors.vendor}
                  valid={editing ? !errors.vendor : undefined}
                  title="Hersteller"
                  name="vendor"
                >
                  <BareInput
                    disabled={!editing || isSubmitting}
                    type="text"
                    name="vendor"
                    validate={(v: any) => validateMinLength(1, v)}
                  />
                </InputGroup>
                <InputGroup error={errors.name} valid={editing ? !errors.name : undefined} title="Name" name="name">
                  <BareInput
                    disabled={!editing || isSubmitting}
                    type="text"
                    name="name"
                    validate={(v: any) => validateMinLength(1, v)}
                  />
                </InputGroup>
                <InputGroup
                  error={errors.serial}
                  valid={editing ? !errors.serial : undefined}
                  title="Seriennummer"
                  name="serial"
                >
                  <BareInput
                    disabled={!editing || isSubmitting}
                    type="text"
                    name="serial"
                    validate={(v: any) => validateMinLength(3, v)}
                  />
                </InputGroup>
              </InputSection>
              <InputSection title="Personen" xs={12} xl={6}>
                <InputGroup
                  error={errors.testers}
                  valid={editing ? !errors.testers : undefined}
                  title="Prüfer"
                  name="tester"
                >
                  <SelectTesterButton
                    testers={values.testers}
                    editing={editing}
                    onSelectTesters={(testers) => {
                      setFieldValue('testers', testers, true);
                      if (!testers) {
                        setFieldValue('defaultTestTasks', [], true);
                        setFieldValue('defaultTestingDeviceUsage', [], true);
                        setFieldValue('defaultTestTasksChanged', true, true);
                      }
                    }}
                  />
                </InputGroup>
                <InputGroup title="Standardprüfungen" name="defaultTestingDeviceUsage" valid={true}>
                  {values.testers && values.testers.length > 0 && (
                    <TestingDeviceDefaultTestTasksButton
                      editing={editing}
                      testers={values.testers}
                      defaultValue={values.defaultTestTasks}
                      onSelectTestTasks={(testTasks: TestTask[]) => {
                        setFieldValue('defaultTestTasks', testTasks, true);
                        setFieldValue('defaultTestTasksChanged', true, true);
                      }}
                    />
                  )}
                </InputGroup>
              </InputSection>
            </Row>
          </form>
          <FormikDebug />
        </>
      )}
    </Formik>
  );
};

export default Show;
