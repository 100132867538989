import { FunctionComponent } from 'react';
import Table, { TableColProps } from '../../table';

import { Due } from '../../../interfaces/Due';

interface DueListProps {
  items: Due[];
  // loading: boolean;
  // onPagination?: (page: number, size: number) => void;
  // pages?: number;
  // columnFilters?: Record<string, (number | string | boolean)[]>;
  // onFilterChanged?: (f: Record<string, (number | string | boolean)[]>) => void;
}

const columns: TableColProps<Due>[] = [
  {
    title: 'Prüfart',
    width: 2,
    values: 'testTask.initials',
    key: 'testTask.id',
  },
  {
    title: 'Datum',
    width: 4,
    values: (item: Due) => {
      return new Date(item.due).toLocaleDateString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },
  },
];

const DueList: FunctionComponent<DueListProps> = ({
  items,
  // loading,
  // pages,
  // onPagination,
  // columnFilters,
  // onFilterChanged,
}) => {
  return (
    <Table
      // loading={loading}
      items={items}
      columns={columns}
      // pages={pages}
      // onPagination={onPagination}
      // columnFilters={columnFilters}
      // onFilterChanged={onFilterChanged}
    />
  );
};

export default DueList;
