import { Button as BButton, Modal } from 'react-bootstrap';
import { FieldValidator, useField } from 'formik';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { ReactComponent as ArrowIcon } from './arrow.svg';
import { ButtonWithIcon } from '../buttons/ButtonWithIcon';
import LoadingIndicator from '../common/LoadingIndicator';
import { SafeplanAdapterService } from '../../services';
import { SafeplanTechnician } from '../../interfaces/SafeplanTechnician';
import { debounce } from 'lodash';
import styled from 'styled-components';
import { useAddError } from '../../context/error';

const ClearButton = styled(BButton)`
  color: #dc3545;
`;

const SearchInput = styled.input`
  background-color: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255);
  color: rgb(84, 84, 84);
  padding: 0.75rem;
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  text-align: left;
  text-decoration: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  width: 100%;
  border: 0px;
  -webkit-appearance: none;
  outline-color: rgba(0, 0, 0, 0);
  margin-bottom: 2rem;
`;

const P = styled.p<{ small: boolean }>`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  color: ${(props) => (props.small ? '#b3b3b3' : '#000')};
`;

// const ButtonWithIcon = styled(BButton)`
//   float: left;
//   color: #a1a1a1;
//   opacity: 1;
//   svg {
//     width: 14px;
//     height: 14px;
//     display: inline-block;
//     margin: 11px 0 11px 11px;
//     &,
//     path,
//     g {
//       fill: #a1a1a1;
//       color: #a1a1a1;
//     }
//   }
//   span {
//     display: inline-block;
//     vertical-align: bottom;
//     margin-bottom: 1px;
//   }
//   &:disabled,
//   &.disabled {
//     cursor: not-allowed;
//     opacity: 0.5;
//     color: #a1a1a1 !important;
//   }
//   &:hover {
//     color: var(--bs-primary);
//     opacity: 0.8;
//     &,
//     path,
//     g {
//       fill: var(--bs-primary);
//       color: var(--bs-primary);
//     }
//   }
// `;

const TesterButton = styled(BButton)`
  display: block;
  width: 100%;
  height: 3.125rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 9px;
  &.active,
  &:active,
  &:hover {
    p {
      color: #fff;
    }
  }
`;

const BGModalBody = styled(Modal.Body)`
  background: #f2f2f2;
  padding: 32px;
  max-height: 500px;
  min-height: 286px;
  overflow-y: scroll;
`;

const Items = styled.div`
  display: block;
  min-height: 286px;
`;

const SelectSafeplanTechnicians: FunctionComponent<{
  editing: boolean;
  required?: boolean;
  disabled?: boolean;
  validate?: FieldValidator;
  name: string;
}> = (props) => {
  const addError = useAddError();

  const { editing } = props;
  const [field, , helper] = useField(props);

  const [testers, setTesters] = useState<SafeplanTechnician[]>([]);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const [tester, setTester] = useState<SafeplanTechnician>();
  const [selectedTester, selectTester] = useState<SafeplanTechnician>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [query, setQuery] = useState<string>();

  const loadTesters = useCallback(async () => {
    try {
      setLoading(true);
      const _items = await SafeplanAdapterService(addError).technicians();
      setTesters(_items);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [addError]);

  const openModal = () => {
    setTesters([]);
    setQuery('');
    selectTester(undefined);
    loadTesters();
    setModalShow(true);
  };

  useEffect(() => {
    loadTesters();
  }, [loadTesters]);

  useEffect(() => {
    const t = testers.find((s) => s.sp_id === field.value);
    selectTester(t);
    setTester(t);
  }, [field.value, testers]);

  const search = debounce(async (_value: string) => {
    try {
      setLoading(true);
      const _items = await SafeplanAdapterService(addError).technicians(_value);
      setTesters(_items);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, 300);

  const _handleInputChange = (query: string) => {
    setQuery(query);
    search(query);
  };

  if (!editing && tester) {
    return (
      <ButtonWithIcon variant="link">
        <span>
          <P small={false}>{tester?.name}</P>
          <P small={true}>{tester?.initials}</P>
        </span>
        <ArrowIcon />
      </ButtonWithIcon>
    );
  }

  if (!editing && !tester) {
    return (
      <ButtonWithIcon disabled variant="link">
        <span>
          <P small={false}>Kein Techniker</P>
          <P small={true}>Wählen Sie im Bearbeitungsmodus einen safeplan-Techniker für diesen Prüfer aus.</P>
        </span>
        <ArrowIcon />
      </ButtonWithIcon>
    );
  }

  return (
    <>
      <ButtonWithIcon variant="link" onClick={() => openModal()}>
        <span>
          <P small={false}>{tester?.name ?? 'safeplan-Techniker auswählen'}</P>
          <P small={true}>Wählen Sie einen safeplan-Techniker für diesen Prüfer aus.</P>
        </span>
        <ArrowIcon />
      </ButtonWithIcon>
      <Modal aria-labelledby="contained-modal-title-vcenter" centered show={modalShow}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">safeplan-Techniker auswählen</Modal.Title>
        </Modal.Header>
        <BGModalBody>
          <SearchInput placeholder="Prüfer suchen..." onChange={(event) => _handleInputChange(event.target.value)} />
          {loading && <LoadingIndicator />}
          {!loading && (
            <Items>
              {testers.map((tester) => (
                <TesterButton active={selectedTester?.sp_id === tester.sp_id} onClick={() => selectTester(tester)}>
                  <P small={false}> {tester.name}</P>
                  <P small={true}>{tester.initials}</P>
                </TesterButton>
              ))}
            </Items>
          )}
        </BGModalBody>
        <Modal.Footer>
          <ClearButton
            variant="light"
            onClick={() => {
              helper.setValue(undefined, true);
              helper.setTouched(true);
              setModalShow(false);
            }}
          >
            Kein Techniker
          </ClearButton>
          <BButton variant="light" onClick={() => setModalShow(false)}>
            Abbrechen
          </BButton>
          <BButton
            disabled={loading || !selectedTester}
            variant="primary"
            onClick={() => {
              if (selectedTester) {
                helper.setValue(selectedTester.sp_id, true);
                helper.setTouched(true);
              }
              setModalShow(false);
            }}
          >
            Auswählen
          </BButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectSafeplanTechnicians;
