import { Location } from '../interfaces/Location';
import React from 'react';
import useSelectedLocation from '../helpers/hooks/useSelectedLocation';

interface MainContextType {
  selectedLocation: Record<string, any>[] | undefined;
  selectLocation: (x: Record<string, any>[] | undefined) => void;
  selectLocationByObject: (location: Location) => void;
}

const MainContext = React.createContext<MainContextType>(null!);

export function MainProvider({ children }: { children: React.ReactNode }) {
  const [selectedLocation, selectLocation] = useSelectedLocation();
  const selectLocationByObject = (location: Location) => {
    const data: Record<string, any>[] = [
      {
        label1: `[${location.tag}] ${location.name}`,
        label2: `${location.address.street}, ${location.address.postalCode} ${location.address.city}`,
        value: location.id,
        locked: location.locked,
      },
    ];
    selectLocation(data);
  };
  const value = { selectedLocation, selectLocation, selectLocationByObject };
  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
}

export function useMain() {
  return React.useContext(MainContext);
}
