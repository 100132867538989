export enum JobState {
  NotStarted = 'NotStarted', // Job is in queue
  Provisioning = 'Provisioning', // The job operator has started provisioning the job
  Activating = 'Activating', // The job has been provisioned and pulled the job from the queue
  Running = 'Running', // The job is running
  Failed = 'Failed', // The job has failed
  Completed = 'Completed', // The job has completed
}

export interface Job {
  id: number;
  status: JobState;
  name: string;
  config: object;
  progress?: number;
  created_at: Date;
  updated_at: Date;
  deleted_at?: Date;
}
