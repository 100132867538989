import { AddSafeDocError, ErrorLevel } from '../../context/error';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isNetworkError = (error: any): null | AddSafeDocError => {
  if (error && error.message && error.message === 'Network Error') {
    return {
      level: ErrorLevel.danger,
      title: 'Verbindung',
      message: 'Es konnte keine Verbindung zum Server hergestellt werden.',
    };
  }
  return null;
};

export default isNetworkError;
