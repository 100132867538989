import { AddSafeDocError, ErrorLevel } from '../../context/error';

import { get } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isGeneralError = (error: any): null | AddSafeDocError => {
  const errorName = get(error, 'response.data.errorType');
  if (errorName === 'General') {
    return {
      level: ErrorLevel.danger,
      requestID: get(error, 'response.headers["x-request-id"]'),
      message: get(error, 'response.data.errorMessage'),
      title: 'Fehler',
    };
  }

  return null;
};

export default isGeneralError;
