import { EmptyTestValue, TestValue } from './TestValue';
import { NewType, UpdateType } from './removeDates';

export enum ThresholdType {
  // 'ALL' = 'all',
  'LOWER' = 'lower',
  'HIHGER' = 'higher',
  'UNEQUAL' = 'unequal',
  'EQUAL' = 'equal',
}

export interface TestValueThreshold {
  id: number;
  description: string;
  triggersFailure: boolean;
  testValue: TestValue;
  thresholdType: ThresholdType;
  thresholdValue: string;
  prio: number;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export type NewTestValueThreshold = NewType<
  TestValueThreshold,
  'description' | 'triggersFailure' | 'thresholdType' | 'prio' | 'thresholdValue'
>;

export type UpdateTestValueThreshold = UpdateType<
  TestValueThreshold,
  'description' | 'triggersFailure' | 'thresholdType' | 'prio' | 'thresholdValue'
>;

export const EmptyNewTestValueThreshold: NewTestValueThreshold = {
  description: '',
  triggersFailure: false,
  testValue: EmptyTestValue,
  thresholdType: ThresholdType.EQUAL,
  thresholdValue: '0',
  prio: 0,
};

export const EmptyTestValueThreshold: TestValueThreshold = {
  id: 0,
  description: '',
  triggersFailure: false,
  testValue: EmptyTestValue,
  thresholdType: ThresholdType.EQUAL,
  thresholdValue: '0',
  prio: 0,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
};
