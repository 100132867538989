import { EmptyNewTestingDevice, NewTestingDevice } from '../../interfaces/TestingDevice';
import { ErrorLevel, useAddError, useClearError } from '../../context/error';
import { Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useState } from 'react';

import BareInput from '../../components/form/BareInput';
import FormikDebug from '../../components/dev/formik-debug';
import InputGroup from '../../components/form/InputGroup';
import InputSection from '../../components/form/InputSection';
import { Row } from 'react-bootstrap';
import { TestingDeviceService } from '../../services';
import Title from '../../components/title';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import validateMinLength from '../../helpers/validate/validateMinLength';

const New: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const navigate = useNavigate();

  const [item] = useState<NewTestingDevice>(EmptyNewTestingDevice);

  const onSubmit = async (values: NewTestingDevice, { setSubmitting }: FormikHelpers<NewTestingDevice>) => {
    setSubmitting(true);
    clearError();
    try {
      const id = await TestingDeviceService(addError).create(values);
      if (id && id > 0) {
        navigate(`/settings/testing-devices/${id}`);
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isValidating, isValid, errors, isSubmitting, touched }) => (
        <>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Title
              onCancel={() => {
                navigate('..');
              }}
              editing={true}
              editingValid={!isValidating && isValid && Object.keys(touched).length > 0}
              isSubmitting={isSubmitting}
              title={'Neues Testgerät'}
            />
            <Row>
              <InputSection title="Stammdaten" xs={12} xl={6}>
                <InputGroup error={errors.vendor} valid={!errors.vendor} title="Hersteller" name="vendor">
                  <BareInput
                    disabled={isSubmitting}
                    type="text"
                    name="vendor"
                    validate={(v: any) => validateMinLength(1, v)}
                  />
                </InputGroup>
                <InputGroup error={errors.name} valid={!errors.name} title="Name" name="name">
                  <BareInput
                    disabled={isSubmitting}
                    type="text"
                    name="name"
                    validate={(v: any) => validateMinLength(1, v)}
                  />
                </InputGroup>
                <InputGroup error={errors.serial} valid={!errors.serial} title="Seriennummer" name="serial">
                  <BareInput
                    disabled={isSubmitting}
                    type="text"
                    name="serial"
                    validate={(v: any) => validateMinLength(3, v)}
                  />
                </InputGroup>
              </InputSection>
            </Row>
          </form>
          <FormikDebug />
        </>
      )}
    </Formik>
  );
};

export default New;
