import { DateTime } from 'luxon';
import React, { FunctionComponent, useEffect, useState } from 'react';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import Table, { Col as TCol, MinP, TableColProps } from '../../components/table';
import Title from '../../components/title';
import { useAddError } from '../../context/error';
import { TesterPerformance, TesterPerformanceLocation } from '../../interfaces/PerformanceToday';
import AnalyticsService from '../../services/AnalyticsService';

import 'react-datepicker/dist/react-datepicker.css';
import { Col, Row } from 'react-bootstrap';
import { debounce } from 'lodash';

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import InputSection from '../../components/form/InputSection';
import { DateRangePicker, DTP } from '../../components/common/DateRangePicker';
import TargetBar from './TargetBar';

registerLocale('de', de);
setDefaultLocale('de');

const columns: TableColProps<TesterPerformanceLocation>[] = [
  { title: 'Standort', values: 'custom', key: 'location' },
  { title: 'Prüfungen', values: 'count', key: 'count' },
  { title: 'erste Prüfung', values: 'first', key: 'first' },
  { title: 'letzte Prüfung', values: 'last', key: 'last' },
  { title: 'Zeit pro Prüfung', values: 'custom', key: 'avg' },
];

const Performance: FunctionComponent = () => {
  const addError = useAddError();
  const [target, setTarget] = useState<number>(0);
  const [range, setRange] = useState<number>(0);
  const [testers, setTesters] = useState<TesterPerformance[]>([]);
  const [loading, setLoading] = useState(true);

  const startOfToday = DateTime.local().startOf('day').toJSDate();
  const endOfToday = DateTime.local().endOf('day').toJSDate();

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([startOfToday, endOfToday]);
  const [searchRange, _setSearchRange] = useState<[Date | null, Date | null]>([startOfToday, endOfToday]);
  const setSearchRange = debounce(_setSearchRange, 1400);

  const [startDate, endDate] = dateRange;

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const { range, target } = await AnalyticsService(addError).performanceTarget();
        setTarget(target);
        setRange(range);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError]);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const data = await AnalyticsService(addError).testerPerformance(searchRange);
        setTesters(data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, searchRange]);

  if (loading) {
    <LoadingIndicator />;
  }

  return (
    <>
      <Title title="Technikerkennzahlen">
        <DateRangePicker float="right" block={true}>
          <DTP
            locale="de"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            maxDate={new Date()}
            todayButton="Heute"
            dateFormat="dd.MM.yyyy"
            showWeekNumbers
            onChange={(update: [Date | null, Date | null]) => {
              if (update[0] && update[1] && update[0].getTime() === update[1].getTime()) {
                update[0] = DateTime.fromJSDate(update[0]).startOf('day').toJSDate();
                update[1] = DateTime.fromJSDate(update[1]).endOf('day').toJSDate();
              }
              setDateRange(update);
              setSearchRange(update);
            }}
            withPortal
          />
        </DateRangePicker>
      </Title>
      <Row>
        <Col className="col-12">
          {testers.map((tester) => (
            <InputSection title={tester.name} xs={12}>
              <Table
                // onClick={async ({ tag, location, customer, city, postal_code, street }) => {
                //   const res = await confirm('Standort wechseln', 'Möchten Sie den Standort wechseln?', 'Ja', 'Nein');
                //   if (res) {
                //     selectLocation([
                //       {
                //         label1: `[${tag}] ${customer}`,
                //         label2: `${street} ${postal_code} ${city}`,
                //         value: location,
                //       },
                //     ]);
                //     setTimeout(() => {
                //       navigate('/tests');
                //     }, 100);
                //   }
                // }}
                items={tester.locations}
                columns={columns}
                renderItem={(index, location, col) => {
                  if (index === 0) {
                    return (
                      <TCol key={index} width={col.width}>
                        <MinP>
                          {location.customer}
                          <br />
                          {location.street}, {location.postal_code} {location.city}
                        </MinP>
                      </TCol>
                    );
                  }

                  if (index === 1) {
                    return (
                      <TCol key={index} width={col.width}>
                        <MinP>{location.count}</MinP>
                      </TCol>
                    );
                  }

                  if (index === 2) {
                    return (
                      <TCol key={index} width={col.width}>
                        <MinP>
                          {DateTime.fromISO(location.first).toLocaleString({
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                        </MinP>
                      </TCol>
                    );
                  }

                  if (index === 3) {
                    return (
                      <TCol key={index} width={col.width}>
                        <MinP>
                          {DateTime.fromISO(location.last).toLocaleString({
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                        </MinP>
                      </TCol>
                    );
                  }

                  if (index === 4) {
                    const v = Math.round((location.minutes / location.count) * 10) / 10;
                    return (
                      <TCol key={index} width={col.width}>
                        <MinP>
                          {v} Min
                          <TargetBar target={target} range={range} value={v} />
                        </MinP>
                      </TCol>
                    );
                  }

                  return <></>;
                }}
              />
            </InputSection>
          ))}
        </Col>
      </Row>
    </>
  );
};

export default Performance;
