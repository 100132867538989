import { Formik, FormikHelpers } from 'formik';
import { get, reduce } from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';
import BareInput, { NumberInput } from '../../../../components/form/BareInput';
import { ErrorLevel, useAddError, useClearError } from '../../../../context/error';
import { EmptyTestValueThreshold, TestValueThreshold } from '../../../../interfaces/TestValueThreshold';

import qs from 'query-string';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import FormikDebug from '../../../../components/dev/formik-debug';
import { confirm } from '../../../../components/dialogs/Confirmation';
import InputGroup from '../../../../components/form/InputGroup';
import InputSection from '../../../../components/form/InputSection';
import { default as SelectInput, default as ToggleInput } from '../../../../components/form/ToggleInput';
import Title from '../../../../components/title';
import validateTestValueThresholdName from '../../../../helpers/validate/validateTestValueThresholdName';
import { ValueType } from '../../../../interfaces/ValueType';
import { TestValueThresholdService } from '../../../../services';
import { SELECTABLE_THRESHOLD_TYPE, SELECTABLE_THRESHOLD_TYPE_NUM } from '../statics';

const getChangedValues = (
  values: TestValueThreshold,
  initialValues: TestValueThreshold,
): Partial<TestValueThreshold> => {
  return reduce(
    Object.entries(values),
    (acc, [key, value]) => {
      const hasChanged = (initialValues as any)[key] !== value;
      if (hasChanged) {
        (acc as any)[key] = value;
      }
      return acc;
    },
    {},
  );
};

const shouldEditing = (location: Location) => {
  const queryParams = qs.parse(location.search, {});
  return queryParams.edit === '1' || queryParams.edit === 'true';
};

const Show: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(shouldEditing(location));

  const [item, setItem] = useState<TestValueThreshold>(EmptyTestValueThreshold);

  useEffect(() => {
    const loadData = async () => {
      try {
        if (!params.opid) {
          return;
        }
        const _item = await TestValueThresholdService(addError).get(params.opid);
        if (!_item || !_item.id) {
          navigate(`/settings/tasks/${params.id}/test-value-groups/${params.tgid}/test-values/${params.tvid}`);
        }
        setItem(_item);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, navigate, params.id, params.opid, params.tgid, params.tvid]);

  const onSubmit = async (values: TestValueThreshold, { setSubmitting }: FormikHelpers<TestValueThreshold>) => {
    setSubmitting(true);
    try {
      if (params.opid) {
        clearError();
        const data = getChangedValues(values, item);
        await TestValueThresholdService(addError).update(params.opid, data);
        setEditing(false);
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onDelete = async (setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true);
    try {
      if (params.opid) {
        if (
          await confirm(
            'Schwellwerte löschen?',
            'Der Schwellwerte wird entgültig gelöscht. Sind Sie sich sicher?',
            'Löschen',
            'Abbruch',
            {
              okColor: 'danger',
            },
          )
        ) {
          await TestValueThresholdService(addError).destroy(params.opid);
          navigate(`/settings/tasks/${params.id}/test-value-groups/${params.tgid}/test-values/${params.tvid}`);
        }
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isValidating, isValid, errors, resetForm, isSubmitting, setSubmitting }) => (
        <>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Title
              onEdit={() => setEditing(true)}
              onDelete={() => onDelete(setSubmitting)}
              onCancel={() => {
                resetForm({
                  values: item,
                });
                setEditing(false);
              }}
              editing={editing}
              editingValid={!isValidating && isValid}
              isSubmitting={isSubmitting}
              to={`/settings/tasks/${params.id}/test-value-groups/${params.tgid}/test-values/${params.tvid}`}
              title={`${item.description} - Schwellwert`}
            />
            <Row>
              <InputSection title="Stammdaten" xs={12} xl={6}>
                <InputGroup
                  error={errors.description}
                  valid={editing ? !errors.description : undefined}
                  title="Beschreibung"
                  name="description"
                >
                  <BareInput
                    disabled={!editing || isSubmitting}
                    type="text"
                    name="description"
                    validate={validateTestValueThresholdName}
                  />
                </InputGroup>
                <InputGroup
                  error={errors.prio}
                  valid={editing ? !errors.prio : undefined}
                  title="Priorität"
                  name="prio"
                >
                  <NumberInput required={true} name="prio" disabled={!editing || isSubmitting} float={false} />
                </InputGroup>
                <InputGroup error={errors.thresholdType} valid={!errors.thresholdType} title="Art" name="thresholdType">
                  <SelectInput valueType="text" name="thresholdType" disabled={!editing || isSubmitting}>
                    {((item.testValue && item.testValue.valueType === ValueType.NUMBER) ||
                    (item.testValue && item.testValue.valueType === ValueType.FLOAT)
                      ? SELECTABLE_THRESHOLD_TYPE_NUM
                      : SELECTABLE_THRESHOLD_TYPE
                    ).map(({ value, title }, idx) => (
                      <option key={idx} value={value}>
                        {title}
                      </option>
                    ))}
                  </SelectInput>
                </InputGroup>
                <InputGroup
                  error={errors.triggersFailure}
                  valid={!errors.triggersFailure}
                  title="Prüfungen scheitert"
                  name="triggersFailure"
                >
                  <ToggleInput name="triggersFailure" disabled={!editing || isSubmitting} />
                </InputGroup>
                <InputGroup
                  error={errors.thresholdValue}
                  valid={!errors.thresholdValue}
                  title="Wert"
                  name="thresholdValue"
                >
                  {item.testValue && item.testValue.valueType === ValueType.STRING && (
                    <BareInput type="text" name="thresholdValue" disabled={!editing || isSubmitting} />
                  )}
                  {item.testValue &&
                    (item.testValue.valueType === ValueType.NUMBER || item.testValue.valueType === ValueType.FLOAT) && (
                      <NumberInput
                        name="thresholdValue"
                        float={item.testValue.valueType === ValueType.FLOAT}
                        disabled={!editing || isSubmitting}
                      />
                    )}
                  {item.testValue && item.testValue.valueType === ValueType.BOOLEAN && (
                    <ToggleInput name="thresholdValue" disabled={!editing || isSubmitting} />
                  )}
                </InputGroup>
              </InputSection>
            </Row>
          </form>
          <FormikDebug />
        </>
      )}
    </Formik>
  );
};

export default Show;
