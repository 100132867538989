import { Navigate, Route } from 'react-router';
import React from 'react';

import MainLayout from '../../layouts/MainLayout';

import RequireAuth from '../../guards/RequireAuth';
import RequireTrue from '../../guards/RequireTrue';

/* Request from show on next week also implies that a tester is created, otherwise the value is undefined */

const Routes = (
  <Route
    path="/dashboard"
    element={
      <RequireAuth>
        <MainLayout />
      </RequireAuth>
    }
  >
    <Route
      path="/dashboard"
      element={
        <RequireTrue k="tester.pref_show_my_week" noTesterChildren={<Navigate to="/locations" />}>
          <Navigate to="/tours/this-week" />
        </RequireTrue>
      }
    />
  </Route>
);

export default Routes;
