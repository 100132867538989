import { EmptyNewUser, NewUser } from '../../interfaces/User';
import { ErrorLevel, useAddError, useClearError } from '../../context/error';
import { Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useState } from 'react';

import BareInput from '../../components/form/BareInput';
import InputGroup from '../../components/form/InputGroup';
import InputSection from '../../components/form/InputSection';
import { Row } from 'react-bootstrap';
import Title from '../../components/title';
import { UserService } from '../../services';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from '../../helpers/validate/validateEmail';
import validateName from '../../helpers/validate/validateName';
import RoleView from '../../components/RoleView';

const New: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const navigate = useNavigate();

  const [item] = useState<NewUser>(EmptyNewUser);

  const onSubmit = async (values: NewUser, { setSubmitting }: FormikHelpers<NewUser>) => {
    setSubmitting(true);
    clearError();
    try {
      const id = await UserService(addError).create(values);
      if (id && typeof id === 'number' && id > 0) {
        navigate(`/settings/users/${id}`);
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isValidating, isValid, errors, isSubmitting, touched }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Title
            onCancel={() => {
              navigate('..');
            }}
            editing={true}
            editingValid={!isValidating && isValid && Object.keys(touched).length > 0}
            isSubmitting={isSubmitting}
            title={'Neuer Benutzer'}
          />
          <Row>
            <InputSection title="Stammdaten" xs={12} xl={6}>
              <InputGroup error={errors.name} valid={!errors.name} title="Name" name="name">
                <BareInput disabled={isSubmitting} type="text" name="name" validate={validateName} />
              </InputGroup>
              <InputGroup error={errors.company} valid={!errors.company} title="Unternehmen" name="company">
                <BareInput disabled={isSubmitting} type="text" name="company" />
              </InputGroup>
              <InputGroup error={errors.email} valid={!errors.email} title="E-Mail" name="email">
                <BareInput disabled={isSubmitting} type="email" name="email" validate={validateEmail} />
              </InputGroup>
            </InputSection>
            <InputSection title="Rechte" xs={12} xl={6}>
              <InputGroup error={errors.role} valid={!errors.role} title="Rolle" name="role">
                <BareInput as="select" name="role" disabled={isSubmitting}>
                  <option value="ADMINISTRATOR">
                    <RoleView role={'ADMINISTRATOR'} />
                  </option>
                  <option value="MANAGER">
                    <RoleView role={'MANAGER'} />
                  </option>
                  <option value="STANDARD">
                    <RoleView role={'STANDARD'} />
                  </option>
                  <option value="VIEWER">
                    <RoleView role={'VIEWER'} />
                  </option>
                </BareInput>
              </InputGroup>
            </InputSection>
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default New;
