import { FunctionComponent, useState } from 'react';

import Title from '../../components/title';
import styled from 'styled-components';
import { ErrorLevel, useAddError } from '../../context/error';
import OfflineService from '../../services/OfflineService';
import SyncModal from './SyncModal';
import ExamineeList from './ExamineeList';
import TestsList from './TestsList';
import { get } from 'lodash';
import { datadogRum } from '@datadog/browser-rum';

const H = styled.h1`
  font-size: 18px;
  letter-spacing: 0;
  line-height: 25px;
  font-weight: 700;
  padding: 6px 0;
  margin: 0;
`;

const OfflineList: FunctionComponent = () => {
  const [syncing, setSyncing] = useState(false);
  const addError = useAddError();
  const [status, setStatus] = useState<string | undefined>(undefined);

  const onSync = async () => {
    // try {
    setSyncing(true);
    OfflineService()
      .sync()
      .subscribe({
        next: (status) => {
          setStatus(status);
        },
        error: (error) => {
          setSyncing(false);
          const message = get(error, 'response.data.errorMessage', 'Bitte versuchen Sie es später noch einmal.');
          datadogRum.addError(error);
          addError({
            title: 'Fehler beim Upload',
            message,
            level: ErrorLevel.danger,
          });
        },
        complete: () => {
          setSyncing(false);
          setTimeout(() => {
            window.location.reload();
          }, 120);
        },
      });
  };

  return (
    <>
      <Title title={`Noch nicht übertragen`} onSync={onSync} isSyncing={syncing} />
      <H>Prüflinge</H>
      <ExamineeList />
      <H>Tests</H>
      <TestsList />
      {syncing && <SyncModal status={status} />}
    </>
  );
};

export default OfflineList;
