import { ExamineesUtils } from '../../services';

export default async function validateExamineeTagFree(value?: string) {
  if (!value || value.length === 0) {
    return 'Bitte geben Sie die eindeutige ID des Prüflings ein.';
  }

  const failed = await ExamineesUtils()
    .checkTag(value)
    .catch(() => true);

  if (failed) {
    return 'Diese ID wird schon für einen Prüfling verwendet.';
  }

  return undefined;
}
