import { EmptyExamineeType, ExamineeType, UpdateExamineeType } from '../../interfaces/ExamineeType';
import { ErrorLevel, useAddError, useClearError } from '../../context/error';
import { ExamineeFamiliesService, ExamineeTypesService } from '../../services';
import { Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useEffect, useState } from 'react';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';
import { get, reduce } from 'lodash';

import BareInput, { NumberInput } from '../../components/form/BareInput';
import { ExamineeFamily } from '../../interfaces/ExamineeFamily';
import InputGroup from '../../components/form/InputGroup';
import InputSection from '../../components/form/InputSection';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { Row } from 'react-bootstrap';
import SelectInput from '../../components/form/SelectInput';
import Title from '../../components/title';
import { confirm } from '../../components/dialogs/Confirmation';
import qs from 'query-string';
import validateName from '../../helpers/validate/validateName';

const getChangedValues = (values: ExamineeType, initialValues: ExamineeType): Partial<UpdateExamineeType> => {
  return reduce(
    Object.entries(values),
    (acc, [key, value]) => {
      const hasChanged = (initialValues as any)[key] !== value;
      if (hasChanged) {
        (acc as any)[key] = value;
      }
      return acc;
    },
    {},
  );
};

const shouldEditing = (location: Location) => {
  const queryParams = qs.parse(location.search, {});
  return queryParams.edit === '1' || queryParams.edit === 'true';
};

const Show: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(shouldEditing(location));

  const [item, setItem] = useState<ExamineeType>(EmptyExamineeType);
  const [families, setFamilies] = useState<ExamineeFamily[]>([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        if (!params.id) {
          return;
        }
        const [_item, { items: _families }] = await Promise.all([
          ExamineeTypesService(addError).get(params.id),
          ExamineeFamiliesService(addError).list(),
        ]);
        if (!_item || !_item.id) {
          navigate('..');
        }
        setItem(_item);
        setFamilies(_families);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, navigate, params.id]);

  const onSubmit = async (values: ExamineeType, { setSubmitting }: FormikHelpers<ExamineeType>) => {
    setSubmitting(true);
    try {
      if (params.id) {
        clearError();
        const data = getChangedValues(values, item);
        await ExamineeTypesService(addError).update(params.id, data);
        setEditing(false);
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onDelete = async (setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true);
    try {
      if (params.id) {
        if (
          await confirm(
            'Prüflingsart löschen?',
            'Die Prüflingsart wird entgültig gelöscht. Sind Sie sich sicher?',
            'Löschen',
            'Abbruch',
            {
              okColor: 'danger',
            },
          )
        ) {
          await ExamineeTypesService(addError).destroy(params.id);
          navigate('..');
        }
      }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isValidating, isValid, errors, values, touched, resetForm, isSubmitting, setSubmitting }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Title
            onEdit={() => setEditing(true)}
            onDelete={() => onDelete(setSubmitting)}
            onCancel={() => {
              resetForm({
                values: item,
              });
              setEditing(false);
            }}
            editing={editing}
            editingValid={!isValidating && isValid}
            isSubmitting={isSubmitting}
            to="/settings/examinee-types"
            title={item.name}
          />
          <Row>
            <InputSection title="Stammdaten" xs={12} xl={6}>
              <InputGroup error={errors.name} valid={editing ? !errors.name : undefined} title="Name" name="name">
                <BareInput disabled={!editing || isSubmitting} type="text" name="name" validate={validateName} />
              </InputGroup>
              <InputGroup error={errors.family ? '' : undefined} valid={!errors.family} title="Gattung" name="family">
                <SelectInput disabled={!editing || isSubmitting} name="family">
                  <option disabled selected value={0} label="Prüflingsgattung auswählen" />
                  {families.map((family) => (
                    <option key={family.id} value={family.id}>
                      {family.name}
                    </option>
                  ))}
                </SelectInput>
              </InputGroup>
              <InputGroup error={errors.prio} valid={editing ? !errors.prio : undefined} title="Priorität" name="prio">
                <NumberInput required={true} name="prio" disabled={!editing || isSubmitting} float={false} />
              </InputGroup>
            </InputSection>
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default Show;
