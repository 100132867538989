import List from './List';
import MainLayout from '../../layouts/MainLayout';
import React from 'react';
import RequireAuth from '../../guards/RequireAuth';
import { Route } from 'react-router';
import Show from './Show';

const Routes = (
  <Route
    path="/settings/preferences"
    element={
      <RequireAuth>
        <MainLayout />
      </RequireAuth>
    }
  >
    <Route path="/settings/preferences" element={<List />} />
    <Route path="/settings/preferences/:id" element={<Show />} />
  </Route>
);

export default Routes;
