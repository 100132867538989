import { Col, Row } from 'react-bootstrap';

const NoTester = () => {
  return (
    <Row className="justify-content-md-center mt-5">
      <Col xs={12} md={8} lg={6}>
        <p>Dieser Benutzer wurde nicht als Prüfer angelegt und kann keine Prüfungen durchführen.</p>
      </Col>
    </Row>
  );
};

export default NoTester;
