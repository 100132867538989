import Table, { TableColProps } from '../../../../components/table';

import { FunctionComponent } from 'react';
import { TestValue } from '../../../../interfaces/TestValue';
import { TestValueOption } from '../../../../interfaces/TestValueOption';
import { useParams } from 'react-router-dom';

interface TestValeOptionListProps {
  item: TestValue;
  loading: boolean;
}

const columns: TableColProps<TestValueOption>[] = [{ title: 'Auswahlmöglichkeit', values: 'option' }];

const TestValeOptionList: FunctionComponent<TestValeOptionListProps> = ({ loading, item }) => {
  const params = useParams();
  return (
    <Table
      loading={loading}
      items={item.valueOptions.sort((a, b) => a.option.localeCompare(b.option))}
      columns={columns}
      navigatePrefix={`/settings/tasks/${params.id}/test-value-groups/${params.tgid}/test-values/${params.tvid}/options/`}
    />
  );
};

export default TestValeOptionList;
