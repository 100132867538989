import { AddErrorFunction } from '../context/error';

import axios from 'axios';
import { get } from 'lodash';
import { API } from '../config';
import { Examinee } from '../interfaces/Examinee';
import { OfflineError } from './BaseService';
import getToken from './getToken';

const ExamineesUtils = (addError?: AddErrorFunction, noCache = false) => {
  const cachePolicy = () => {
    if (noCache) {
      return 'no-cache, max-age=0';
    } else {
      return 'max-age=120';
    }
  };

  const checkTag = (tag: string): Promise<boolean> => {
    return getByTag(tag)
      .then((examinee) => {
        return !(examinee === undefined || examinee === null);
      })
      .catch(() => {
        return false;
      });
  };

  const getByTag = (tag: string, extend?: boolean) =>
    axios
      .post(
        `${API}/examinees/by-tag`,
        { tag, extend },
        {
          headers: { Authorization: getToken(addError), 'Cache-Control': cachePolicy() },
        },
      )
      .then((e) => {
        return e.data.data as Examinee;
      })
      .catch((e) => {
        if (e.code === 'ERR_NETWORK') {
          throw new OfflineError();
        }
        return undefined;
      });

  const getByTagPublic = (tag: string, extend?: boolean) =>
    axios
      .post(`${API}/public//examinee-by-tag`, { tag, extend })
      .then((e) => {
        return get(e, 'data.data', undefined);
      })
      .catch(() => {
        return undefined;
      });

  const getLastAndNextTests = (id: number) =>
    axios
      .get(`${API}/examinees/${id}/tests`)
      .then((e) => {
        return e.data.data;
      })
      .catch(() => {
        return undefined;
      });

  return { checkTag, getByTag, getByTagPublic, getLastAndNextTests };
};

export default ExamineesUtils;
