import { Button, Modal } from 'react-bootstrap';
import React, { FunctionComponent } from 'react';

import LoadingIndicator from '../common/LoadingIndicator';
import styled from 'styled-components';

interface WizardProps {
  children?: React.ReactNode;
  preparing?: boolean;
  step: number;
  showHeader?: boolean;
  onChange?: (step: number) => void;
  onClose: () => void;
  onComplete?: () => void;
  saveText?: string;
  nextText?: string;
}

interface StepStyleProps {
  padding?: string;
  alignItems?: string;
}

interface StepProps {
  children?: React.ReactNode;
  title: string;
  description?: string;
  valid?: boolean;
  notCloseable?: boolean;
}

const ColoredButton = styled(Button)`
  color: #fff;
  &:disabled,
  &.disabled,
  &:hover {
    color: #fff;
  }
`;

const getPropsFromStep = (step?: React.ReactChild | React.ReactFragment | React.ReactPortal): StepProps => {
  return step
    ? (step as any).props ?? {
        description: '',
        title: '',
      }
    : { description: '', title: '' };
};

const StepButtonContainer = styled.div`
   {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: stretch;
    align-items: center;
    position: relative;
    width: 100%;
    &:before {
      content: '';
      height: 1px;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      background: #b3b3b3;
      z-index: 1;
    }
  }
`;

const StepButton = styled.button<{ active: boolean }>`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  font-size: 1rem;
  background: #fff;
  z-index: 10;
  border: 1px solid ${(props) => (props.active ? 'var(--bs-primary)' : '#b3b3b3')};
  color: ${(props) => (props.active ? 'var(--bs-primary)' : '#b3b3b3')};
`;

const Title = styled.p`
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
`;

const Content = styled.div``;

export const Wizard: FunctionComponent<WizardProps> = ({
  step: currentIndex,
  showHeader,
  saveText,
  nextText,
  ...props
}) => {
  const steps = React.Children.toArray(props.children);

  const prevStep = getPropsFromStep(currentIndex !== 0 ? steps[currentIndex - 1] : undefined);
  const nextStep = getPropsFromStep(currentIndex !== steps.length - 1 ? steps[currentIndex + 1] : undefined);

  const currStep = getPropsFromStep(steps[currentIndex]);

  return (
    <div>
      {!props.preparing && showHeader !== false && (
        <Modal.Header>
          <StepButtonContainer>
            {steps.map((step, index) => {
              const sp = getPropsFromStep(step);
              return (
                <StepButton
                  type="button"
                  key={sp.title}
                  onClick={() => props.onChange && props.onChange(index)}
                  active={index === currentIndex}
                  title={sp.description}
                >
                  {index + 1}
                </StepButton>
              );
            })}
          </StepButtonContainer>
        </Modal.Header>
      )}
      <Modal.Body>
        {!props.preparing && (
          <>
            <Title>{currStep.title}</Title>
            <Content>{steps[currentIndex]}</Content>
          </>
        )}
        {props.preparing && (
          <SStep>
            <LoadingIndicator size={40} />
          </SStep>
        )}
      </Modal.Body>
      {!props.preparing && !currStep.notCloseable && (
        <Modal.Footer>
          <Button
            variant="light"
            hidden={!prevStep || currStep.notCloseable}
            onClick={() => {
              if (currentIndex === 0 || currentIndex === steps.length - 1) {
                props.onClose();
              } else {
                props.onChange && props.onChange(currentIndex - 1);
              }
            }}
            title={prevStep.description}
          >
            {(currentIndex === 0 || currentIndex === steps.length - 1) && 'Schließen'}
            {currentIndex > 0 && currentIndex !== steps.length - 1 && 'Zurück'}
          </Button>
          <ColoredButton
            variant="success"
            hidden={!nextStep || currentIndex === steps.length - 1}
            disabled={!currStep.valid}
            onClick={() => {
              props.onChange && props.onChange(currentIndex + 1);
              if (currentIndex + 1 === steps.length - 1 && props.onComplete) {
                props.onComplete();
              }
            }}
            title={nextStep.description}
          >
            {currentIndex < steps.length - 2 && (nextText ?? 'Weiter')}
            {currentIndex === steps.length - 2 && (saveText ?? 'Speichern')}
          </ColoredButton>
        </Modal.Footer>
      )}
    </div>
  );
};

const SStep = styled.div<StepStyleProps>`
  min-height: 200px;
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  flex-direction: column;
  padding: ${(props) => props.padding ?? '2rem'};
  align-items: ${(props) => props.alignItems ?? 'center'};
  justify-content: ${(props) => props.alignItems ?? 'center'};
`;

export const Step: FunctionComponent<StepProps & StepStyleProps> = ({ children, ...rest }) => {
  return <SStep {...rest}>{children}</SStep>;
};

// function getClsNavBtn(active: boolean) {
//   return "btn btn-primary flex-fill" + (active ? " active" : "");
// }

// function Button({ visible, ...props }) {
//   return (
//     <button className={visible ? "btn btn-primary" : "invisible"} {...props} />
//   );
// }
