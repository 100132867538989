import { FunctionComponent, useState } from 'react';
import MultipleTypeaheadInput from '../../components/form/MultipleTypeaheadInput';
import { useAddError } from '../../context/error';
import { SafeplanCustomerGroup } from '../../interfaces/SafeplanCustomerGroup';
import { SafeplanAdapterService } from '../../services';
import { useEffect } from 'react';
import InputGroup from '../../components/form/InputGroup';
import { useFormikContext } from 'formik';
import { SafeplanCustomer } from '../../interfaces/SafeplanCustomer';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CustomerGroupPickerProps {
  variant?: 'light' | 'gray-200';
  editing: boolean;
  name?: string;
}

const CustomerGroupPicker: FunctionComponent<CustomerGroupPickerProps> = ({ editing, name, variant }) => {
  const addError = useAddError();
  const [loading, setLoading] = useState(false);

  const { errors, isSubmitting } = useFormikContext<{
    scopes: number[];
  }>();

  const [customerGroups, setCustomerGroups] = useState<SafeplanCustomerGroup[]>([]);
  const [customers, setCustomes] = useState<SafeplanCustomer[]>([]);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      try {
        const [_customerGroups, _customers] = await Promise.all([
          SafeplanAdapterService(addError).customerGroups(),
          SafeplanAdapterService(addError).customers(),
        ]);
        setCustomerGroups(_customerGroups);
        setCustomes(_customers);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [addError]);

  return (
    <InputGroup
      error={errors.scopes ? '' : undefined}
      valid={editing ? !errors.scopes : undefined}
      title="Kunden(-gruppen)"
      name={name ?? 'scopes'}
      variant={variant}
      loading={loading}
    >
      <MultipleTypeaheadInput
        name={name ?? 'scopes'}
        options={[
          ...customerGroups.map((is) => ({
            label: `Gruppe: ${is.name}`,
            value: `cg_${is.sp_id}`,
          })),
          ...customers.map((is) => ({
            label: `Kunde: ${is.name}`,
            value: `c_${is.sp_id}`,
          })),
        ]}
        forbidNew={true}
        disabled={!editing || isSubmitting}
        placeholder={editing ? 'Suche nach Kundengruppe...' : 'Keine Kundengruppe ausgewählt'}
      />
    </InputGroup>
  );
};

export default CustomerGroupPicker;
