import { Button, Modal } from 'react-bootstrap';
import { ReactConfirmProps, confirmable, createConfirmation } from 'react-confirm';

import React from 'react';
import styled from 'styled-components';

interface AlertProps extends ReactConfirmProps {
  okColor?: 'primary' | 'secondary' | 'danger';
  okLabbel: string;
  proceedLabel: string;
  title: string;
  test: string;
  show: boolean;
  proceed: (value?: string | boolean) => void;
  enableEscape: boolean;
}

const Body = styled(Modal.Body)`
  white-space: pre-line;
  overflow-wrap: break-word;
`;

class Alert extends React.Component<AlertProps> {
  render() {
    const { proceedLabel, title, confirmation, show, proceed, enableEscape = true, okColor = 'primary' } = this.props;
    return (
      <div className="static-modal">
        <Modal
          show={show}
          onHide={() => proceed(false)}
          backdrop={enableEscape ? true : 'static'}
          keyboard={enableEscape}
        >
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Body>{confirmation}</Body>
          <Modal.Footer>
            <Button variant={okColor} onClick={() => proceed(true)}>
              {proceedLabel}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export function alert(
  title: string,
  confirmation: string,
  proceedLabel = 'Schließen',
  options = {
    okColor: 'primary',
  },
) {
  return createConfirmation(confirmable(Alert))({
    title,
    confirmation,
    proceedLabel,
    ...options,
  });
}
