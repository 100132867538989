import { FunctionComponent, ReactNode, useState } from 'react';
import { useNetworkStatus } from 'react-network-status';
import { NETWORK_CHECK_CONFIG, FORCE_OFFLINE } from '../../config';

const ShowOffline: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const [networkStatus, setNetworkStatus] = useState(true);

  useNetworkStatus((networkStatusUpdate) => {
    setNetworkStatus(networkStatusUpdate);
  }, NETWORK_CHECK_CONFIG);

  if (FORCE_OFFLINE === true) {
    return <>{children}</>;
  }

  if (!networkStatus) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export default ShowOffline;
