import { FunctionComponent } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 9px 0;
  align-items: center;
  padding: 1rem 1rem 1rem 0;
  display: block;
  width: 100%;
  border-radius: 0.3rem;
  border-color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
`;

const P = styled.p<{ small: boolean }>`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  color: ${(props) => (props.small ? '#b3b3b3' : '#000')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
`;

const Content = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  padding: 0 0 0 1rem;
`;

const NoStop: FunctionComponent = () => {
  return (
    <>
      <Wrapper>
        <Content>
          <P small={false}>Keine Aufträge</P>
          <P small={true}>Für diesen Tag wurden keine Aufträge gefunden.</P>
        </Content>
      </Wrapper>
    </>
  );
};

export default NoStop;
