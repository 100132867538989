import { NewType, UpdateType } from './removeDates';

import { TestAdditionalInformationField } from './TestAdditionalInformationField';
import { TestValue } from './TestValue';
import { TestValueGroup } from './TestValueGroup';

export interface DefaultMeasureGroups {
  id: number;
  tag: string;
  name: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export type NewDefaultMeasureGroups = NewType<DefaultMeasureGroups, 'name' | 'tag'>;

export type UpdateDefaultMeasureGroups = UpdateType<DefaultMeasureGroups, 'name'>;

export interface TestTask {
  id: number;
  name: string;
  initials: string;
  norm?: string;
  testValues: TestValue[];
  testValueGroups: TestValueGroup[];
  testAdditionalInformationFields: TestAdditionalInformationField[];
  defaultInterval: number;
  defaultMeasureGroups?: DefaultMeasureGroups[];
  maxMeasureGroups?: number;
  withoutTestingDevice?: boolean;
  prio: number;
  reportFooter?: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export type NewTestTask = NewType<
  TestTask,
  'name' | 'initials' | 'norm' | 'defaultInterval' | 'prio' | 'withoutTestingDevice' | 'reportFooter'
>;

export type UpdateTestTask = UpdateType<
  TestTask,
  'name' | 'initials' | 'norm' | 'defaultInterval' | 'prio' | 'reportFooter'
>;

export const EmptyNewTestTask: NewTestTask = {
  initials: '',
  name: '',
  norm: '',
  defaultInterval: 0,
  prio: 0,
  withoutTestingDevice: false,
  reportFooter: undefined,
};

export const EmptyTestTask: TestTask = {
  id: 0,
  initials: '',
  name: '',
  norm: '',
  testValues: [],
  testAdditionalInformationFields: [],
  defaultInterval: 0,
  prio: 0,
  testValueGroups: [],
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
};

export const EmptyNewDefaultMeasureGroups: NewDefaultMeasureGroups = {
  name: '',
  tag: '',
};

export const EmptyDefaultMeasureGroups: DefaultMeasureGroups = {
  id: 0,
  name: '',
  tag: '',
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
};
