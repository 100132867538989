import { AuthService, UserService } from '../services';
import React, { useCallback, useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { JwtPayload, jwtDecode } from 'jwt-decode';

import Cookies from 'js-cookie';
import { Role, User } from '../interfaces/User';
import { useAddError } from './error';
import { clearOffline, userStore } from '../storage';
import { offline } from '../offline';

interface AuthContextType {
  user: any;
  token?: string;
  signin: (email: string, password: string) => Promise<void>;
  signout: () => void;
  loadUser: () => Promise<void>;
  changePassword: (password: string, newPassword: string) => Promise<boolean>;
  resetPassword: (email: string) => Promise<boolean>;
  impersonate: (id: number) => Promise<void>;
  isImpersonating: () => ({ name: string; role: string } & JwtPayload) | undefined;
  stopImpersonate: () => Promise<void>;
  role: () => Role | undefined;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const addError = useAddError();

  const [user, _setUser] = React.useState<User | undefined>();
  const [token, _setToken] = React.useState<string | undefined>(Cookies.get('token'));

  const setUser = (value: User | undefined) => {
    _setUser(value);
    if (value === undefined || value.id === undefined) {
      datadogRum.clearUser();
    } else {
      datadogRum.setUser({ id: value.id.toString(), email: value.email, name: value.name });
    }
  };

  const setToken = (value: string | undefined, impersonate = false) => {
    const key = impersonate ? 'impersonate' : 'token';
    if (value === undefined) {
      Cookies.remove(key);
    } else {
      Cookies.set(key, value, { expires: 7 });
    }
    _setToken(value);
  };

  const signin = (email: string, password: string) => {
    return AuthService(addError)
      .signin(email, password)
      .then((_token) => {
        setToken(_token);
        offline();
      });
  };

  const impersonate = async (id: number) => {
    const _token = await UserService(addError).impersonate(id);
    setToken(_token, true);
    await clearOffline();
    offline();
  };

  const stopImpersonate = async () => {
    setToken(undefined, true);
    await clearOffline();
    offline();
  };

  const isImpersonating = () => {
    const token = Cookies.get('impersonate');
    if (token !== undefined) {
      return jwtDecode<{ name: string; role: string } & JwtPayload>(token);
    } else {
      return undefined;
    }
  };

  const changePassword = (password: string, newPassword: string) => {
    return AuthService(addError).changePassword(password, newPassword, token ?? '');
  };

  const resetPassword = (email: string) => {
    return AuthService(addError).resetPassword(email, token ?? '');
  };

  const loadUser = useCallback(async () => {
    try {
      if (!token) {
        setUser(undefined);
      } else {
        const _user = await UserService(addError, userStore).me();
        setUser(_user);
      }
    } catch (error) {
      console.error(error);
    }
  }, [addError, token]);

  useEffect(() => {
    loadUser();
  }, [addError, loadUser, token]);

  const signout = () => {
    setUser(undefined);
    setToken(undefined);
    setToken(undefined, true);
  };

  const role = () => {
    const u = isImpersonating() ?? user;
    if (!u) {
      return undefined;
    }
    return u.role as Role;
  };

  const value = {
    token,
    user,
    role,
    signin,
    impersonate,
    isImpersonating,
    stopImpersonate,
    signout,
    changePassword,
    resetPassword,
    loadUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}
