import { Button, Modal } from 'react-bootstrap';
import { ReactConfirmProps, confirmable, createConfirmation } from 'react-confirm';

import React from 'react';

interface ConfirmationProps extends ReactConfirmProps {
  okColor?: 'primary' | 'secondary' | 'danger';
  okLabbel: string;
  cancelLabel: string;
  proceedLabel: string;
  title: string;
  confirmation: string;
  show: boolean;
  proceed: (value?: string | boolean) => void;
  enableEscape: boolean;
}

class Confirmation extends React.Component<ConfirmationProps> {
  render() {
    const {
      proceedLabel,
      cancelLabel,
      title,
      confirmation,
      show,
      proceed,
      enableEscape = true,
      okColor = 'primary',
    } = this.props;
    return (
      <div className="static-modal">
        <Modal
          show={show}
          onHide={() => proceed(false)}
          backdrop={enableEscape ? true : 'static'}
          keyboard={enableEscape}
        >
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{confirmation}</Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={() => proceed(false)}>
              {cancelLabel}
            </Button>
            <Button variant={okColor} onClick={() => proceed(true)}>
              {proceedLabel}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export function confirm(
  title: string,
  confirmation: string,
  proceedLabel = 'Weiter',
  cancelLabel = 'Abbruch',
  options = {
    okColor: 'primary',
  },
) {
  return createConfirmation(confirmable(Confirmation))({
    title,
    confirmation,
    proceedLabel,
    cancelLabel,
    ...options,
  });
}
