import React from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
registerLocale('de', de);
setDefaultLocale('de');

// const getColor = (colored?: 'true' | 'danger') => {
//   if (colored === 'danger') return 'var(--bs-red)';
//   if (colored === 'true') return 'var(--bs-primary)';
//   return '#a1a1a1';
// };

export const DateRangePicker = styled.div<{
  colored?: 'true' | 'danger';
  hovercolored?: 'true' | 'danger';
  float?: 'left' | 'right';
  block?: boolean;
  onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void;
}>``;

export const DTP = styled(DatePicker)`
  border: 0;
  z-index: 999;
  background: transparent;
  -webkit-appearance: none;
  outline-color: rgba(0, 0, 0, 0);
  font-size: 18px;
  letter-spacing: 0;
  line-height: 25px;
  font-weight: 700;
  padding: 6px 0;
  user-select: none;
  cursor: pointer;
  margin: 0;
`;
