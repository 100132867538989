import { Formik, FormikHelpers } from 'formik';
import { get, reduce } from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ErrorLevel, useAddError, useClearError } from '../../context/error';
import { EmptyPaper, Paper } from '../../interfaces/Paper';

import LoadingIndicator from '../../components/common/LoadingIndicator';
import InputGroup from '../../components/form/InputGroup';
import InputSection from '../../components/form/InputSection';
import Title from '../../components/title';

import ColorInput from '../../components/form/ColorInput';
import ImageInput from '../../components/form/ImageInput';
import { PaperService } from '../../services';
import FooterColInput from './FooterColInput';
import BareInput from '../../components/form/BareInput';

const getChangedValues = (values: FormPaper, initialValues: FormPaper): Partial<FormPaper> => {
  return reduce(
    Object.entries(values),
    (acc, [key, value]) => {
      const hasChanged = (initialValues as any)[key] !== value;
      if (hasChanged) {
        (acc as any)[key] = value;
      }
      return acc;
    },
    {},
  );
};

type FormPaper = Omit<Paper, 'logo'> & {
  logo?: File | string;
};

const Show: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);

  const [item, setItem] = useState<FormPaper>(EmptyPaper);

  useEffect(() => {
    const loadData = async () => {
      try {
        const _item = await PaperService(addError).get();
        if (!_item || _item === undefined) {
          navigate('..');
        }
        setItem(_item as Paper);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, navigate]);

  const onSubmit = async (values: FormPaper, { setSubmitting }: FormikHelpers<FormPaper>) => {
    setSubmitting(true);
    try {
      clearError();
      const data = getChangedValues(values, item);
      await PaperService(addError).update(data as FormData);
      setEditing(false);
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Formik enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isValidating, isValid, errors, resetForm, isSubmitting, setSubmitting }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Title
            onEdit={() => setEditing(true)}
            onCancel={() => {
              resetForm({
                values: item,
              });
              setEditing(false);
            }}
            editing={editing}
            editingValid={!isValidating && isValid}
            isSubmitting={isSubmitting}
            to="/settings"
            title={`Briefpapier`}
          />
          <Row>
            <InputSection title="Dokument" xs={12} md={6} lg={4} xl={3}>
              <InputGroup
                error={errors.author}
                valid={editing ? !errors.author : undefined}
                title="Author"
                name="author"
              >
                <BareInput disabled={!editing || isSubmitting} name="author" />
              </InputGroup>
            </InputSection>
            <InputSection title="Farben" xs={12} md={6} lg={4} xl={3}>
              <InputGroup
                error={errors.primary}
                valid={editing ? !errors.primary : undefined}
                title="primär Farbe"
                name="primary"
              >
                <ColorInput disabled={!editing || isSubmitting} name="primary" />
              </InputGroup>
              <InputGroup
                error={errors.secondary}
                valid={editing ? !errors.secondary : undefined}
                title="sekundäre Farbe"
                name="secondary"
              >
                <ColorInput disabled={!editing || isSubmitting} name="secondary" />
              </InputGroup>
            </InputSection>
            <InputSection title="Logo" xs={12} md={6} lg={4} xl={3}>
              <InputGroup error={errors.logo} valid={editing ? !errors.primary : undefined} title="Logo" name="logo">
                <ImageInput disabled={!editing || isSubmitting} name="logo" />
              </InputGroup>
            </InputSection>
            <InputSection title="Fußzeile" xs={12}>
              <InputGroup title="Erste Spalte" name="footer.0">
                <FooterColInput disabled={!editing || isSubmitting} name="footer.0" />
              </InputGroup>
              <InputGroup title="Zweite Spalte" name="footer.1">
                <FooterColInput disabled={!editing || isSubmitting} name="footer.1" />
              </InputGroup>
              <InputGroup title="Dritte Spalte" name="footer.2">
                <FooterColInput disabled={!editing || isSubmitting} name="footer.2" />
              </InputGroup>
            </InputSection>
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default Show;
