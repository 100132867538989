import { EmptyExaminee, Examinee } from './Examinee';
import { EmptyTestTask, TestTask } from './TestTask';
import { EmptyTester, Tester } from './Tester';
import { EmptyTestingDevice, TestingDevice } from './TestingDevice';

import { MeasureGroup } from './MeasureGroup';
import { TestAdditionalInformationValue } from './TestAdditionalInformationValue';
import { TestValueThreshold } from './TestValueThreshold';

export type MesureGroupType = {
  name: string;
  tag: string;
  group: number;
  values: Record<string, boolean | number | string | undefined>;
};

export type ThresholdDefect = {
  name: string;
  description: string;
  triggersFailure: boolean;
  threshold: TestValueThreshold;
};

export type NewThresholdDefect = {
  name: string;
  description: string;
  triggersFailure: boolean;
  threshold: number;
};

export interface Test {
  id: number;
  examinee: Examinee;
  testTask: TestTask;
  tester: Tester;
  testingDevice: TestingDevice;
  additionalInformation: TestAdditionalInformationValue[];
  measureGroups: MeasureGroup[];
  comment?: string;
  defects?: string;
  thresholdDefects: ThresholdDefect[];
  result: boolean;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export type NewTest = {
  examinee: number;
  testTask: number;
  tester: number;
  // group: number;
  testingDevice: number;
  groups: MesureGroupType[];
  additionalInformation: Record<string | number, string | Date | number | null>;
  thresholdDefects: NewThresholdDefect[];
};

export const EmptyTest: Test = {
  id: 0,
  examinee: EmptyExaminee,
  testTask: EmptyTestTask,
  tester: EmptyTester,
  testingDevice: EmptyTestingDevice,
  additionalInformation: [],
  measureGroups: [],
  thresholdDefects: [],
  result: false,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
};
