import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { NewVendor, Vendor } from '../../../interfaces/Vendor';

import { CommonExamineeSelectProps } from '.';
import InputGroup from '../../form/InputGroup';
import TypeaheadInput from '../../form/TypeaheadInput';
import { VendorsService } from '../../../services';
import { useAddError } from '../../../context/error';
import { useFormikContext } from 'formik';
import { vendorsStore } from '../../../storage';

// type VendorSelectProps = CommonExamineeSelectProps<{
//   vendor: number;
// }>;

const VendorSelect: FunctionComponent<CommonExamineeSelectProps> = ({ variant, editing, name }) => {
  const addError = useAddError();

  const { errors, isSubmitting } = useFormikContext<{
    vendor: number;
  }>();

  const [loading, setLoading] = useState(true);

  const [vendors, setVendors] = useState<Vendor[]>([]);

  const loadVendors = useCallback(async () => {
    const { items } = await VendorsService(addError, vendorsStore).list(3000);
    setVendors(items);
  }, [addError]);

  useEffect(() => {
    const loadData = async () => {
      try {
        loadVendors();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, loadVendors]);

  return (
    <InputGroup
      valid={!errors.vendor}
      error={errors.vendor ? '' : undefined}
      title="Hersteller"
      name={name ?? 'vendor'}
      loading={loading}
      variant={variant}
    >
      <TypeaheadInput
        name={name ?? 'vendor'}
        options={vendors.map((is) => ({
          label: is.name,
          value: is.id,
        }))}
        disabled={!editing || isSubmitting}
        emptyLabel="Hersteller auswählen"
        newSelectionPrefix="neuer Hersteller: "
        onNewOption={(name: string) => {
          const values: NewVendor = {
            name,
          };
          return VendorsService(addError, vendorsStore)
            .create(values)
            .then(async (id) => {
              await loadVendors();
              return id;
            });
        }}
      />
    </InputGroup>
  );
};

export default VendorSelect;
