import List from './List';
import MainLayout from '../../layouts/MainLayout';
import New from './New';
import NewTestValueGroupModal from './test-value-groups/NewModal';
import NewTestValueModal from './test-values/NewModal';
import React from 'react';
import RequireAuth from '../../guards/RequireAuth';
import { Route } from 'react-router';
import Show from './Show';
import ShowTestValue from './test-values/Show';
import ShowTestValueGroup from './test-value-groups/Show';
import TestValeThresholdNewModal from './test-values/test-value-thresholds/NewModal';
import TestValeOptionNewModal from './test-values/test-value-options/NewModal';
import ShowTestValeOption from './test-values/test-value-options/Show';

import ShowDefaultMeasureGroups from './default-measure-groups/Show';
import NewDefaultMeasureGroups from './default-measure-groups/NewModal';
import ShowTestValueThreshold from './test-values/test-value-thresholds/Show';

const Routes = (
  <Route
    path="/settings/tasks"
    element={
      <RequireAuth>
        <MainLayout />
      </RequireAuth>
    }
  >
    <Route path="/settings/tasks" element={<List />} />
    <Route path="new" element={<New />} />
    <Route path=":id/*" element={<Show />} />
    <Route path=":id/test-value-groups/:tgid" element={<ShowTestValueGroup />} />
    <Route path=":id/test-value-groups/new" element={<NewTestValueGroupModal />} />
    <Route path=":id/test-value-groups/:tgid/test-values/:tvid" element={<ShowTestValue />} />
    <Route path=":id/test-value-groups/:tgid/test-values/new" element={<NewTestValueModal />} />
    <Route path=":id/test-value-groups/:tgid/test-values/:tvid/new-threshold" element={<TestValeThresholdNewModal />} />
    <Route path=":id/test-value-groups/:tgid/test-values/:tvid/thresholds/:opid" element={<ShowTestValueThreshold />} />
    <Route path=":id/test-value-groups/:tgid/test-values/:tvid/new-option" element={<TestValeOptionNewModal />} />
    <Route path=":id/test-value-groups/:tgid/test-values/:tvid/options/:opid" element={<ShowTestValeOption />} />
    <Route path=":id/default-measure-groups/:tgid" element={<ShowDefaultMeasureGroups />} />
    <Route path=":id/default-measure-groups/new" element={<NewDefaultMeasureGroups />} />
  </Route>
);

export default Routes;
