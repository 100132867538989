import { Col, Row } from 'react-bootstrap';
import BareInput, { NumberInput } from '../../components/form/BareInput';
import SelectInput, { ToggleInputBooleanValueUndefined } from '../../components/form/ToggleInput';

import { FormikErrors } from 'formik';
import { get, reduce } from 'lodash';
import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import InputGroup from '../../components/form/InputGroup';
import InputSection from '../../components/form/InputSection';
import ToggleInput from '../../components/form/ToggleInput';
import TypeaheadInput from '../../components/form/TypeaheadInput';
import { TestTask } from '../../interfaces/TestTask';
import { TestValue } from '../../interfaces/TestValue';
import { ValueType } from '../../interfaces/ValueType';

const IEANote = styled.p`
  align-self: auto;
  display: block;
  padding: 4px 14px 36px;
  font-size: 11px;
  letter-spacing: 0;
  text-transform: uppercase;
  line-height: 22px;
  font-weight: 700;
  color: #984200;
  flex: 1 1 auto;
  user-select: none;
  cursor: pointer;
`;

interface MesureGroupProps<T> {
  name: string;
  group: number;
  errors: FormikErrors<T>;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  index: number;
  isSubmitting: boolean;
  onRemove?: (index: number) => void;
  testTask?: TestTask;
}

function MesureGroup<T>({
  testTask,
  isSubmitting,
  errors,
  index,
  name,
  onRemove,
  setFieldValue,
  group,
}: MesureGroupProps<T>) {
  const testValues: TestValue[] = testTask?.testValueGroups.find((id) => id.id === group)?.testValues ?? [];

  const defaultValues = useCallback(
    (newgroup: number) => {
      const testValues: TestValue[] = testTask?.testValueGroups.find((id) => id.id === newgroup)?.testValues ?? [];

      const tvd: TestValue[] = (testValues ?? []).filter((tv) => tv.defaultValue);
      const _dfv = reduce(
        tvd,
        (prev, curr) => {
          if (curr.valueType === 'boolean' && curr.defaultValue !== undefined) {
            prev[`mgv${curr.id}`] = curr.defaultValue === 'true';
          }
          const nv = Number(curr.defaultValue);
          if ((curr.valueType === 'number' || curr.valueType === 'float') && !isNaN(nv)) {
            prev[`mgv${curr.id}`] = nv;
          }
          if (
            (curr.valueType === 'string' || curr.valueType === 'option') &&
            curr.defaultValue &&
            curr.defaultValue.length > 0
          ) {
            prev[`mgv${curr.id}`] = curr.defaultValue;
          }
          if (curr.valueType === 'option' && curr.defaultValue && curr.defaultValue.length > 0) {
            prev[`mgv${curr.id}`] = curr.defaultValue;
          }
          return prev;
        },
        {} as Record<string, any>,
      );
      return _dfv;
    },
    [testTask],
  );

  const _setFieldValue = useCallback(
    (field: string, value: any, shouldValidate?: boolean | undefined) =>
      setFieldValue && setFieldValue(field, value, shouldValidate),
    [setFieldValue],
  );

  useEffect(() => {
    const l = testTask ? testTask.testValueGroups.length : 0;
    if (l === 1 && testTask) {
      const gr = testTask.testValueGroups[0];
      const dv = defaultValues(gr?.id ?? 0);
      _setFieldValue(`groups[${index}].group`, gr?.id ?? 0);
      _setFieldValue(`groups[${index}].values`, dv);
    }
  }, [index, testTask, _setFieldValue, defaultValues]);

  return (
    <InputSection title={name} xs={12} onDelete={() => onRemove && onRemove(index)}>
      <Row>
        {testTask && testTask.testValueGroups.length > 0 && (
          <Col xs={12} md={6} lg={4} xl={3}>
            <InputGroup
              error={group > 0 ? undefined : 'Gruppe auswählen'}
              valid={group > 0}
              title="Gruppe / SK"
              name={`groups[${index}].group`}
            >
              <TypeaheadInput
                forbidNew={true}
                onChange={(option?: { label: string; value: number }) => {
                  if (!option || index === undefined || index === null || index < 0) return;
                  const gr = testTask.testValueGroups.find((g) => g.id === option.value);
                  const dv = defaultValues(gr?.id ?? 0);
                  setFieldValue && setFieldValue(`groups[${index}].group`, gr?.id ?? 0);
                  setFieldValue && setFieldValue(`groups[${index}].values`, dv);
                }}
                name={`groups[${index}].group`}
                options={[
                  ...testTask.testValueGroups.map((is) => ({
                    label: `${is.name}`,
                    value: is.id,
                  })),
                ]}
                disabled={isSubmitting}
                emptyLabel="Gruppe / Schutzklasse auswählen"
              />
            </InputGroup>
          </Col>
        )}
        {testValues
          .sort((a, b) => a.prio - b.prio)
          .map((tv) => {
            const name = `groups[${index}].values.mgv${tv.id}`;
            const error = get(errors, name);
            return (
              <Col key={tv.id} xs={12} md={6} lg={4} xl={3}>
                <InputGroup error={error} valid={!error} title={tv.name} name={name} unit={tv.unit}>
                  {tv.valueType === ValueType.STRING && (
                    <BareInput
                      validate={(value: any) => {
                        const isRequired = tv.required;
                        const empty = value === undefined || value === null || value.toString().trim() === '';

                        if (isRequired && empty) {
                          return 'Darf nicht leer sein';
                        }

                        return undefined;
                      }}
                      disabled={isSubmitting}
                      type="text"
                      name={name}
                    />
                  )}
                  {(tv.valueType === ValueType.NUMBER || tv.valueType === ValueType.FLOAT) && (
                    <NumberInput
                      required={tv.required}
                      name={name}
                      disabled={isSubmitting}
                      float={tv.valueType === ValueType.FLOAT}
                      thresholds={tv.thresholds}
                    />
                  )}
                  {tv.valueType === ValueType.BOOLEAN && (
                    <ToggleInput
                      required={tv.required}
                      validate={(value: any) => {
                        const isRequired = tv.required;
                        const empty = value === ToggleInputBooleanValueUndefined;

                        if (isRequired && empty) {
                          return 'Darf nicht leer sein';
                        }

                        return undefined;
                      }}
                      name={name}
                      disabled={isSubmitting}
                    />
                  )}
                  {tv.valueType === ValueType.OPTION && (
                    <SelectInput
                      validate={(value: any) => {
                        const isRequired = tv.required;
                        const empty = value === ToggleInputBooleanValueUndefined;

                        if (isRequired && empty) {
                          return 'Darf nicht leer sein';
                        }

                        return undefined;
                      }}
                      valueType="text"
                      name={name}
                      disabled={isSubmitting}
                    >
                      <option disabled selected value={ToggleInputBooleanValueUndefined}>
                        Bitte auswählen
                      </option>
                      {tv.valueOptions.map((opt) => (
                        <option value={opt.option}>{opt.option}</option>
                      ))}
                    </SelectInput>
                  )}
                </InputGroup>
                {tv.name.toLowerCase() === 'iea' && setFieldValue && (
                  <IEANote
                    onClick={() => {
                      setFieldValue(name, -1, true);
                    }}
                  >
                    Für 'good' hier klicken.
                  </IEANote>
                )}
              </Col>
            );
          })}
      </Row>
    </InputSection>
  );
}
export default MesureGroup;
