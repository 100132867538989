import { AddSafeDocError, ErrorLevel, useAddError, useClearError } from '../../../context/error';
import { Formik, FormikHelpers } from 'formik';
import { FunctionComponent, useEffect, useState } from 'react';
import { Step, Wizard } from '../../../components/wizard';
import { TestTaskService, TestValueGroupService } from '../../../services';
import { useNavigate, useParams } from 'react-router-dom';

import BareInput from '../../../components/form/BareInput';
import InputGroup from '../../../components/form/InputGroup';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { Modal } from 'react-bootstrap';
import { NewTestValueGroup } from '../../../interfaces/TestValueGroup';
import { TestTask } from '../../../interfaces/TestTask';
import { ValueType } from '../../../interfaces/ValueType';
import { get } from 'lodash';
import validateMinLength from '../../../helpers/validate/validateMinLength';

type VT = Omit<NewTestValueGroup, 'testTask' | 'valueType' | 'required'> & {
  valueType?: ValueType;
  required?: boolean;
};

const getTitleForSavingStep = (step: number, hasError?: boolean) => {
  if (hasError) return 'Es ist ein Fehler aufgetreten!';
  if (step === 0) return 'Prüfwertgruppe wird gespeichert...';
  if (step === 1) return 'Fertig!';
  return '';
};

const NewModal: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const navigate = useNavigate();
  const params = useParams();

  const [task, setTask] = useState<TestTask>();

  const [savingStep, setSavingStep] = useState(-1);
  const [savingResult, setSavingResult] = useState<number | AddSafeDocError>();

  useEffect(() => {
    const loadTask = async () => {
      if (params.id) {
        const _task = await TestTaskService(addError).get(params.id);
        setTask(_task);
      }
    };
    loadTask();
  }, [addError, params.id]);

  const [item] = useState<VT>({
    name: '',
    unit: '',
    valueType: undefined,
    required: undefined,
  });

  const [step, setStep] = useState(0);

  const onSubmit = async (values: VT, { setSubmitting }: FormikHelpers<VT>) => {
    setSubmitting(true);
    clearError();
    try {
      setSavingStep(0);

      const newTestValueGroup: NewTestValueGroup = {
        name: values.name,
        testTask: task!.id,
      };

      const res = await TestValueGroupService(addError).create(newTestValueGroup, true);
      setSavingResult(res);
      setSavingStep(1);
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal size="lg" centered show={true}>
      <Formik validateOnChange enableReinitialize={true} initialValues={item} onSubmit={onSubmit}>
        {({ setFieldTouched, handleChange, errors, isSubmitting, touched, values, setFieldValue, submitForm }) => (
          <form>
            <Wizard
              step={step}
              preparing={!task}
              onChange={(s) => {
                setStep(s);
              }}
              onClose={() =>
                // navigate(pathname.split("/").slice(0, -1).join("/"))
                navigate(`/settings/tasks/${task?.id}`)
              }
              onComplete={() => {
                submitForm();
              }}
            >
              <Step title={`Neuer Prüfwertgruppe bei ${task?.initials}-Prüfungen`} description="Start" valid={true}>
                <p>Dieser Assistent unterstütz Sie bei der Erstellung einer neuen Gruppe für Prüfwerte.</p>
              </Step>

              <Step title="Name der Prüfwertegruppe" description="Name" valid={!errors.name && !!touched.name}>
                <p>Bitte geben Sie den Namen der Prüfwertegruppe ein.</p>
                <InputGroup error={errors.name} valid={!errors.name} title="Name" name="name" variant="gray-200">
                  <BareInput
                    disabled={isSubmitting}
                    type="text"
                    name="name"
                    minLength={1}
                    validate={(v: any) => validateMinLength(1, v)}
                    onChange={(e: any) => {
                      setFieldTouched('name');
                      handleChange(e);
                    }}
                  />
                </InputGroup>
              </Step>

              <Step
                title={getTitleForSavingStep(savingStep, typeof savingResult !== 'number')}
                description="Abschluss"
                notCloseable={savingStep !== 1}
              >
                {savingStep < 1 && <LoadingIndicator size={40} />}
                {savingStep === 1 && typeof savingResult === 'number' && (
                  <p>Der Prüfwertgruppe {values?.name} wurde erfolgreich erstellt.</p>
                )}
                {savingStep === 1 && typeof savingResult !== 'number' && <p>{savingResult?.message}</p>}
              </Step>
            </Wizard>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default NewModal;
